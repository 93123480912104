"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file salaries.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalariesService = void 0;
const salaries_scailo_pb_js_1 = require("./salaries.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each salary
 *
 * @generated from service Scailo.SalariesService
 */
exports.SalariesService = {
    typeName: "Scailo.SalariesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.SalariesService.Create
         */
        create: {
            name: "Create",
            I: salaries_scailo_pb_js_1.SalariesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.SalariesService.Draft
         */
        draft: {
            name: "Draft",
            I: salaries_scailo_pb_js_1.SalariesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.SalariesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: salaries_scailo_pb_js_1.SalariesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.SalariesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.SalariesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.SalariesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.SalariesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.SalariesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: salaries_scailo_pb_js_1.SalariesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.SalariesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.SalariesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.SalariesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.SalariesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.SalariesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.SalariesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.SalariesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.SalariesService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the salary
         *
         * @generated from rpc Scailo.SalariesService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: salaries_scailo_pb_js_1.SalariesServiceAutofillRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * --------------------------------------------------------------------
         * Addition Records
         * Add an addition item to a salary
         *
         * @generated from rpc Scailo.SalariesService.AddSalaryAdditionItem
         */
        addSalaryAdditionItem: {
            name: "AddSalaryAdditionItem",
            I: salaries_scailo_pb_js_1.SalariesServiceAdditionItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an addition item in a salary
         *
         * @generated from rpc Scailo.SalariesService.ModifySalaryAdditionItem
         */
        modifySalaryAdditionItem: {
            name: "ModifySalaryAdditionItem",
            I: salaries_scailo_pb_js_1.SalariesServiceAdditionItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an addition item in a salary
         *
         * @generated from rpc Scailo.SalariesService.ApproveSalaryAdditionItem
         */
        approveSalaryAdditionItem: {
            name: "ApproveSalaryAdditionItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an addition item in a salary
         *
         * @generated from rpc Scailo.SalariesService.DeleteSalaryAdditionItem
         */
        deleteSalaryAdditionItem: {
            name: "DeleteSalaryAdditionItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder addition items in a salary
         *
         * @generated from rpc Scailo.SalariesService.ReorderSalaryAdditionItems
         */
        reorderSalaryAdditionItems: {
            name: "ReorderSalaryAdditionItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Salary Addition Item by ID
         *
         * @generated from rpc Scailo.SalariesService.ViewSalaryAdditionItemByID
         */
        viewSalaryAdditionItemByID: {
            name: "ViewSalaryAdditionItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: salaries_scailo_pb_js_1.SalaryAdditionItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved salary addition items for given salary ID
         *
         * @generated from rpc Scailo.SalariesService.ViewApprovedSalaryAdditionItems
         */
        viewApprovedSalaryAdditionItems: {
            name: "ViewApprovedSalaryAdditionItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: salaries_scailo_pb_js_1.SalariesAdditionItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved salary addition items for given salary ID
         *
         * @generated from rpc Scailo.SalariesService.ViewUnapprovedSalaryAdditionItems
         */
        viewUnapprovedSalaryAdditionItems: {
            name: "ViewUnapprovedSalaryAdditionItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: salaries_scailo_pb_js_1.SalariesAdditionItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the salary addition item
         *
         * @generated from rpc Scailo.SalariesService.ViewSalaryAdditionItemHistory
         */
        viewSalaryAdditionItemHistory: {
            name: "ViewSalaryAdditionItemHistory",
            I: salaries_scailo_pb_js_1.SalaryAdditionItemHistoryRequest,
            O: salaries_scailo_pb_js_1.SalariesAdditionItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective addition item info
         *
         * @generated from rpc Scailo.SalariesService.ViewProspectiveSalaryAdditionItem
         */
        viewProspectiveSalaryAdditionItem: {
            name: "ViewProspectiveSalaryAdditionItem",
            I: salaries_scailo_pb_js_1.SalaryAdditionItemProspectiveInfoRequest,
            O: salaries_scailo_pb_js_1.SalariesServiceAdditionItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * --------------------------------------------------------------------
         * Deduction Records
         * Add an deduction item to a salary
         *
         * @generated from rpc Scailo.SalariesService.AddSalaryDeductionItem
         */
        addSalaryDeductionItem: {
            name: "AddSalaryDeductionItem",
            I: salaries_scailo_pb_js_1.SalariesServiceDeductionItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an deduction item in a salary
         *
         * @generated from rpc Scailo.SalariesService.ModifySalaryDeductionItem
         */
        modifySalaryDeductionItem: {
            name: "ModifySalaryDeductionItem",
            I: salaries_scailo_pb_js_1.SalariesServiceDeductionItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an deduction item in a salary
         *
         * @generated from rpc Scailo.SalariesService.ApproveSalaryDeductionItem
         */
        approveSalaryDeductionItem: {
            name: "ApproveSalaryDeductionItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an deduction item in a salary
         *
         * @generated from rpc Scailo.SalariesService.DeleteSalaryDeductionItem
         */
        deleteSalaryDeductionItem: {
            name: "DeleteSalaryDeductionItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder deduction items in a salary
         *
         * @generated from rpc Scailo.SalariesService.ReorderSalaryDeductionItems
         */
        reorderSalaryDeductionItems: {
            name: "ReorderSalaryDeductionItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Salary Deduction Item by ID
         *
         * @generated from rpc Scailo.SalariesService.ViewSalaryDeductionItemByID
         */
        viewSalaryDeductionItemByID: {
            name: "ViewSalaryDeductionItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: salaries_scailo_pb_js_1.SalaryDeductionItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved salary deduction items for given salary ID
         *
         * @generated from rpc Scailo.SalariesService.ViewApprovedSalaryDeductionItems
         */
        viewApprovedSalaryDeductionItems: {
            name: "ViewApprovedSalaryDeductionItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: salaries_scailo_pb_js_1.SalariesDeductionItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved salary deduction items for given salary ID
         *
         * @generated from rpc Scailo.SalariesService.ViewUnapprovedSalaryDeductionItems
         */
        viewUnapprovedSalaryDeductionItems: {
            name: "ViewUnapprovedSalaryDeductionItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: salaries_scailo_pb_js_1.SalariesDeductionItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the salary deduction item
         *
         * @generated from rpc Scailo.SalariesService.ViewSalaryDeductionItemHistory
         */
        viewSalaryDeductionItemHistory: {
            name: "ViewSalaryDeductionItemHistory",
            I: salaries_scailo_pb_js_1.SalaryDeductionItemHistoryRequest,
            O: salaries_scailo_pb_js_1.SalariesDeductionItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective deduction item info
         *
         * @generated from rpc Scailo.SalariesService.ViewProspectiveSalaryDeductionItem
         */
        viewProspectiveSalaryDeductionItem: {
            name: "ViewProspectiveSalaryDeductionItem",
            I: salaries_scailo_pb_js_1.SalaryDeductionItemProspectiveInfoRequest,
            O: salaries_scailo_pb_js_1.SalariesServiceDeductionItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * --------------------------------------------------------------------
         * Reimbursement Records
         * Add an reimbursement item to a salary
         *
         * @generated from rpc Scailo.SalariesService.AddSalaryReimbursementItem
         */
        addSalaryReimbursementItem: {
            name: "AddSalaryReimbursementItem",
            I: salaries_scailo_pb_js_1.SalariesServiceReimbursementItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an reimbursement item in a salary
         *
         * @generated from rpc Scailo.SalariesService.ModifySalaryReimbursementItem
         */
        modifySalaryReimbursementItem: {
            name: "ModifySalaryReimbursementItem",
            I: salaries_scailo_pb_js_1.SalariesServiceReimbursementItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an reimbursement item in a salary
         *
         * @generated from rpc Scailo.SalariesService.ApproveSalaryReimbursementItem
         */
        approveSalaryReimbursementItem: {
            name: "ApproveSalaryReimbursementItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an reimbursement item in a salary
         *
         * @generated from rpc Scailo.SalariesService.DeleteSalaryReimbursementItem
         */
        deleteSalaryReimbursementItem: {
            name: "DeleteSalaryReimbursementItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder reimbursement items in a salary
         *
         * @generated from rpc Scailo.SalariesService.ReorderSalaryReimbursementItems
         */
        reorderSalaryReimbursementItems: {
            name: "ReorderSalaryReimbursementItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Salary Reimbursement Item by ID
         *
         * @generated from rpc Scailo.SalariesService.ViewSalaryReimbursementItemByID
         */
        viewSalaryReimbursementItemByID: {
            name: "ViewSalaryReimbursementItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: salaries_scailo_pb_js_1.SalaryReimbursementItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved salary reimbursement items for given salary ID
         *
         * @generated from rpc Scailo.SalariesService.ViewApprovedSalaryReimbursementItems
         */
        viewApprovedSalaryReimbursementItems: {
            name: "ViewApprovedSalaryReimbursementItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: salaries_scailo_pb_js_1.SalariesReimbursementItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved salary reimbursement items for given salary ID
         *
         * @generated from rpc Scailo.SalariesService.ViewUnapprovedSalaryReimbursementItems
         */
        viewUnapprovedSalaryReimbursementItems: {
            name: "ViewUnapprovedSalaryReimbursementItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: salaries_scailo_pb_js_1.SalariesReimbursementItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the salary reimbursement item
         *
         * @generated from rpc Scailo.SalariesService.ViewSalaryReimbursementItemHistory
         */
        viewSalaryReimbursementItemHistory: {
            name: "ViewSalaryReimbursementItemHistory",
            I: salaries_scailo_pb_js_1.SalaryReimbursementItemHistoryRequest,
            O: salaries_scailo_pb_js_1.SalariesReimbursementItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective reimbursement item info
         *
         * @generated from rpc Scailo.SalariesService.ViewProspectiveSalaryReimbursementItem
         */
        viewProspectiveSalaryReimbursementItem: {
            name: "ViewProspectiveSalaryReimbursementItem",
            I: salaries_scailo_pb_js_1.SalaryReimbursementItemProspectiveInfoRequest,
            O: salaries_scailo_pb_js_1.SalariesServiceReimbursementItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.SalariesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: salaries_scailo_pb_js_1.Salary,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.SalariesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: salaries_scailo_pb_js_1.SalariesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.SalariesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: salaries_scailo_pb_js_1.SalariesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.SalariesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: salaries_scailo_pb_js_1.SalariesServicePaginationReq,
            O: salaries_scailo_pb_js_1.SalariesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.SalariesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: salaries_scailo_pb_js_1.SalariesServiceSearchAllReq,
            O: salaries_scailo_pb_js_1.SalariesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.SalariesService.Filter
         */
        filter: {
            name: "Filter",
            I: salaries_scailo_pb_js_1.SalariesServiceFilterReq,
            O: salaries_scailo_pb_js_1.SalariesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.SalariesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.SalariesService.Count
         */
        count: {
            name: "Count",
            I: salaries_scailo_pb_js_1.SalariesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
