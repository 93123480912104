"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file leaves_logs.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeavesLogsService = void 0;
const leaves_logs_scailo_pb_js_1 = require("./leaves_logs.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each record
 *
 * Create and send for verification
 * rpc Create (LeavesLogsServiceCreateRequest) returns (IdentifierResponse);
 *
 * @generated from service Scailo.LeavesLogsService
 */
exports.LeavesLogsService = {
    typeName: "Scailo.LeavesLogsService",
    methods: {
        /**
         * ------------------------------------------------------
         * Search operations are listed below
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.LeavesLogsService.Filter
         */
        filter: {
            name: "Filter",
            I: leaves_logs_scailo_pb_js_1.LeavesLogsServiceFilterReq,
            O: leaves_logs_scailo_pb_js_1.LeavesLogsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count leaves of employee
         *
         * @generated from rpc Scailo.LeavesLogsService.CountEmployeeLeaves
         */
        countEmployeeLeaves: {
            name: "CountEmployeeLeaves",
            I: leaves_logs_scailo_pb_js_1.LeavesLogsCountEmployeeLeavesRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.LeavesLogsService.Count
         */
        count: {
            name: "Count",
            I: leaves_logs_scailo_pb_js_1.LeavesLogsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
