"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file sales_enquiries.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesEnquiryContactsList = exports.SalesEnquiryContact = exports.SalesEnquiriesServiceContactCreateRequest = exports.SalesEnquiriesServiceSearchAllReq = exports.SalesEnquiriesServiceCountReq = exports.SalesEnquiriesServiceFilterReq = exports.SalesEnquiriesServicePaginationResponse = exports.SalesEnquiriesServicePaginationReq = exports.SalesEnquiriesServicePaginatedItemsResponse = exports.SalesEnquiryItemsSearchRequest = exports.SalesEnquiryItemHistoryRequest = exports.SalesEnquiriesItemsList = exports.SalesEnquiriesList = exports.SalesEnquiryItem = exports.SalesEnquiriesServiceItemUpdateRequest = exports.SalesEnquiriesServiceItemCreateRequest = exports.SalesEnquiry = exports.SalesEnquiryAncillaryParameters = exports.SalesEnquiriesServiceUpdateRequest = exports.SalesEnquiriesServiceCreateRequest = exports.SALES_ENQUIRY_SORT_KEY = exports.SALES_ENQUIRY_ITEM_STATUS = exports.SALES_ENQUIRY_ITEM_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.SALES_ENQUIRY_ITEM_SORT_KEY
 */
var SALES_ENQUIRY_ITEM_SORT_KEY;
(function (SALES_ENQUIRY_ITEM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: SALES_ENQUIRY_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    SALES_ENQUIRY_ITEM_SORT_KEY[SALES_ENQUIRY_ITEM_SORT_KEY["SALES_ENQUIRY_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "SALES_ENQUIRY_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: SALES_ENQUIRY_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    SALES_ENQUIRY_ITEM_SORT_KEY[SALES_ENQUIRY_ITEM_SORT_KEY["SALES_ENQUIRY_ITEM_SORT_KEY_CREATED_AT"] = 1] = "SALES_ENQUIRY_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: SALES_ENQUIRY_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    SALES_ENQUIRY_ITEM_SORT_KEY[SALES_ENQUIRY_ITEM_SORT_KEY["SALES_ENQUIRY_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "SALES_ENQUIRY_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: SALES_ENQUIRY_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    SALES_ENQUIRY_ITEM_SORT_KEY[SALES_ENQUIRY_ITEM_SORT_KEY["SALES_ENQUIRY_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "SALES_ENQUIRY_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: SALES_ENQUIRY_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    SALES_ENQUIRY_ITEM_SORT_KEY[SALES_ENQUIRY_ITEM_SORT_KEY["SALES_ENQUIRY_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "SALES_ENQUIRY_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: SALES_ENQUIRY_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    SALES_ENQUIRY_ITEM_SORT_KEY[SALES_ENQUIRY_ITEM_SORT_KEY["SALES_ENQUIRY_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "SALES_ENQUIRY_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: SALES_ENQUIRY_ITEM_SORT_KEY_NAME = 10;
     */
    SALES_ENQUIRY_ITEM_SORT_KEY[SALES_ENQUIRY_ITEM_SORT_KEY["SALES_ENQUIRY_ITEM_SORT_KEY_NAME"] = 10] = "SALES_ENQUIRY_ITEM_SORT_KEY_NAME";
    /**
     * Fetch ordered results by the internal quantity
     *
     * @generated from enum value: SALES_ENQUIRY_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    SALES_ENQUIRY_ITEM_SORT_KEY[SALES_ENQUIRY_ITEM_SORT_KEY["SALES_ENQUIRY_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "SALES_ENQUIRY_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch ordered results by the unit price
     *
     * @generated from enum value: SALES_ENQUIRY_ITEM_SORT_KEY_UNIT_PRICE = 12;
     */
    SALES_ENQUIRY_ITEM_SORT_KEY[SALES_ENQUIRY_ITEM_SORT_KEY["SALES_ENQUIRY_ITEM_SORT_KEY_UNIT_PRICE"] = 12] = "SALES_ENQUIRY_ITEM_SORT_KEY_UNIT_PRICE";
    /**
     * Fetch ordered results by the discount
     *
     * @generated from enum value: SALES_ENQUIRY_ITEM_SORT_KEY_DISCOUNT = 13;
     */
    SALES_ENQUIRY_ITEM_SORT_KEY[SALES_ENQUIRY_ITEM_SORT_KEY["SALES_ENQUIRY_ITEM_SORT_KEY_DISCOUNT"] = 13] = "SALES_ENQUIRY_ITEM_SORT_KEY_DISCOUNT";
    /**
     * Fetch ordered results by the delivery date
     *
     * @generated from enum value: SALES_ENQUIRY_ITEM_SORT_KEY_DELIVERY_DATE = 14;
     */
    SALES_ENQUIRY_ITEM_SORT_KEY[SALES_ENQUIRY_ITEM_SORT_KEY["SALES_ENQUIRY_ITEM_SORT_KEY_DELIVERY_DATE"] = 14] = "SALES_ENQUIRY_ITEM_SORT_KEY_DELIVERY_DATE";
})(SALES_ENQUIRY_ITEM_SORT_KEY || (exports.SALES_ENQUIRY_ITEM_SORT_KEY = SALES_ENQUIRY_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_ENQUIRY_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(SALES_ENQUIRY_ITEM_SORT_KEY, "Scailo.SALES_ENQUIRY_ITEM_SORT_KEY", [
    { no: 0, name: "SALES_ENQUIRY_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "SALES_ENQUIRY_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "SALES_ENQUIRY_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "SALES_ENQUIRY_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "SALES_ENQUIRY_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "SALES_ENQUIRY_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "SALES_ENQUIRY_ITEM_SORT_KEY_NAME" },
    { no: 11, name: "SALES_ENQUIRY_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "SALES_ENQUIRY_ITEM_SORT_KEY_UNIT_PRICE" },
    { no: 13, name: "SALES_ENQUIRY_ITEM_SORT_KEY_DISCOUNT" },
    { no: 14, name: "SALES_ENQUIRY_ITEM_SORT_KEY_DELIVERY_DATE" },
]);
/**
 *
 * Describes the applicable statuses of sales enquiry items
 *
 * @generated from enum Scailo.SALES_ENQUIRY_ITEM_STATUS
 */
var SALES_ENQUIRY_ITEM_STATUS;
(function (SALES_ENQUIRY_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: SALES_ENQUIRY_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    SALES_ENQUIRY_ITEM_STATUS[SALES_ENQUIRY_ITEM_STATUS["SALES_ENQUIRY_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "SALES_ENQUIRY_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the sales enquiry items must have been approved
     *
     * @generated from enum value: SALES_ENQUIRY_ITEM_STATUS_APPROVED = 1;
     */
    SALES_ENQUIRY_ITEM_STATUS[SALES_ENQUIRY_ITEM_STATUS["SALES_ENQUIRY_ITEM_STATUS_APPROVED"] = 1] = "SALES_ENQUIRY_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the sales enquiry items must be waiting for approval
     *
     * @generated from enum value: SALES_ENQUIRY_ITEM_STATUS_UNAPPROVED = 2;
     */
    SALES_ENQUIRY_ITEM_STATUS[SALES_ENQUIRY_ITEM_STATUS["SALES_ENQUIRY_ITEM_STATUS_UNAPPROVED"] = 2] = "SALES_ENQUIRY_ITEM_STATUS_UNAPPROVED";
})(SALES_ENQUIRY_ITEM_STATUS || (exports.SALES_ENQUIRY_ITEM_STATUS = SALES_ENQUIRY_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_ENQUIRY_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(SALES_ENQUIRY_ITEM_STATUS, "Scailo.SALES_ENQUIRY_ITEM_STATUS", [
    { no: 0, name: "SALES_ENQUIRY_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "SALES_ENQUIRY_ITEM_STATUS_APPROVED" },
    { no: 2, name: "SALES_ENQUIRY_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.SALES_ENQUIRY_SORT_KEY
 */
var SALES_ENQUIRY_SORT_KEY;
(function (SALES_ENQUIRY_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: SALES_ENQUIRY_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    SALES_ENQUIRY_SORT_KEY[SALES_ENQUIRY_SORT_KEY["SALES_ENQUIRY_SORT_KEY_ID_UNSPECIFIED"] = 0] = "SALES_ENQUIRY_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: SALES_ENQUIRY_SORT_KEY_CREATED_AT = 1;
     */
    SALES_ENQUIRY_SORT_KEY[SALES_ENQUIRY_SORT_KEY["SALES_ENQUIRY_SORT_KEY_CREATED_AT"] = 1] = "SALES_ENQUIRY_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: SALES_ENQUIRY_SORT_KEY_MODIFIED_AT = 2;
     */
    SALES_ENQUIRY_SORT_KEY[SALES_ENQUIRY_SORT_KEY["SALES_ENQUIRY_SORT_KEY_MODIFIED_AT"] = 2] = "SALES_ENQUIRY_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: SALES_ENQUIRY_SORT_KEY_APPROVED_ON = 3;
     */
    SALES_ENQUIRY_SORT_KEY[SALES_ENQUIRY_SORT_KEY["SALES_ENQUIRY_SORT_KEY_APPROVED_ON"] = 3] = "SALES_ENQUIRY_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: SALES_ENQUIRY_SORT_KEY_APPROVED_BY = 4;
     */
    SALES_ENQUIRY_SORT_KEY[SALES_ENQUIRY_SORT_KEY["SALES_ENQUIRY_SORT_KEY_APPROVED_BY"] = 4] = "SALES_ENQUIRY_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: SALES_ENQUIRY_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    SALES_ENQUIRY_SORT_KEY[SALES_ENQUIRY_SORT_KEY["SALES_ENQUIRY_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "SALES_ENQUIRY_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: SALES_ENQUIRY_SORT_KEY_COMPLETED_ON = 6;
     */
    SALES_ENQUIRY_SORT_KEY[SALES_ENQUIRY_SORT_KEY["SALES_ENQUIRY_SORT_KEY_COMPLETED_ON"] = 6] = "SALES_ENQUIRY_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: SALES_ENQUIRY_SORT_KEY_REFERENCE_ID = 10;
     */
    SALES_ENQUIRY_SORT_KEY[SALES_ENQUIRY_SORT_KEY["SALES_ENQUIRY_SORT_KEY_REFERENCE_ID"] = 10] = "SALES_ENQUIRY_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: SALES_ENQUIRY_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    SALES_ENQUIRY_SORT_KEY[SALES_ENQUIRY_SORT_KEY["SALES_ENQUIRY_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "SALES_ENQUIRY_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the consignee client ID
     *
     * @generated from enum value: SALES_ENQUIRY_SORT_KEY_CONSIGNEE_CLIENT_ID = 12;
     */
    SALES_ENQUIRY_SORT_KEY[SALES_ENQUIRY_SORT_KEY["SALES_ENQUIRY_SORT_KEY_CONSIGNEE_CLIENT_ID"] = 12] = "SALES_ENQUIRY_SORT_KEY_CONSIGNEE_CLIENT_ID";
    /**
     * Fetch ordered results by the buyer client ID
     *
     * @generated from enum value: SALES_ENQUIRY_SORT_KEY_BUYER_CLIENT_ID = 13;
     */
    SALES_ENQUIRY_SORT_KEY[SALES_ENQUIRY_SORT_KEY["SALES_ENQUIRY_SORT_KEY_BUYER_CLIENT_ID"] = 13] = "SALES_ENQUIRY_SORT_KEY_BUYER_CLIENT_ID";
    /**
     * Fetch ordered results by the priority
     *
     * @generated from enum value: SALES_ENQUIRY_SORT_KEY_PRIORITY = 14;
     */
    SALES_ENQUIRY_SORT_KEY[SALES_ENQUIRY_SORT_KEY["SALES_ENQUIRY_SORT_KEY_PRIORITY"] = 14] = "SALES_ENQUIRY_SORT_KEY_PRIORITY";
    /**
     * Fetch ordered results by the amendment count
     *
     * @generated from enum value: SALES_ENQUIRY_SORT_KEY_AMENDMENT_COUNT = 15;
     */
    SALES_ENQUIRY_SORT_KEY[SALES_ENQUIRY_SORT_KEY["SALES_ENQUIRY_SORT_KEY_AMENDMENT_COUNT"] = 15] = "SALES_ENQUIRY_SORT_KEY_AMENDMENT_COUNT";
})(SALES_ENQUIRY_SORT_KEY || (exports.SALES_ENQUIRY_SORT_KEY = SALES_ENQUIRY_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_ENQUIRY_SORT_KEY)
protobuf_1.proto3.util.setEnumType(SALES_ENQUIRY_SORT_KEY, "Scailo.SALES_ENQUIRY_SORT_KEY", [
    { no: 0, name: "SALES_ENQUIRY_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "SALES_ENQUIRY_SORT_KEY_CREATED_AT" },
    { no: 2, name: "SALES_ENQUIRY_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "SALES_ENQUIRY_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "SALES_ENQUIRY_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "SALES_ENQUIRY_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "SALES_ENQUIRY_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "SALES_ENQUIRY_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "SALES_ENQUIRY_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "SALES_ENQUIRY_SORT_KEY_CONSIGNEE_CLIENT_ID" },
    { no: 13, name: "SALES_ENQUIRY_SORT_KEY_BUYER_CLIENT_ID" },
    { no: 14, name: "SALES_ENQUIRY_SORT_KEY_PRIORITY" },
    { no: 15, name: "SALES_ENQUIRY_SORT_KEY_AMENDMENT_COUNT" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.SalesEnquiriesServiceCreateRequest
 */
class SalesEnquiriesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales enquiry
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the consignee
     *
     * @generated from field: uint64 consignee_client_id = 12;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the buyer
     *
     * @generated from field: uint64 buyer_client_id = 13;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The priority of the sales enquiry. Possible values are "low", "medium", "high".
     *
     * @generated from field: string priority = 14;
     */
    priority = "";
    /**
     * The ID of the currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The description of the sales enquiry
     *
     * @generated from field: string description = 16;
     */
    description = "";
    /**
     * The miscellaneous cost (in cents)
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The overall discount (in cents)
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The round off amount (in cents) (both positive and negative values are allowed)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiriesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiriesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiriesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiriesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiriesServiceCreateRequest, a, b);
    }
}
exports.SalesEnquiriesServiceCreateRequest = SalesEnquiriesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.SalesEnquiriesServiceUpdateRequest
 */
class SalesEnquiriesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales enquiry
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the consignee
     *
     * @generated from field: uint64 consignee_client_id = 12;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the buyer
     *
     * @generated from field: uint64 buyer_client_id = 13;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The priority of the sales enquiry. Possible values are "low", "medium", "high".
     *
     * @generated from field: string priority = 14;
     */
    priority = "";
    /**
     * The ID of the currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The description of the sales enquiry
     *
     * @generated from field: string description = 16;
     */
    description = "";
    /**
     * The miscellaneous cost (in cents)
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The overall discount (in cents)
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The round off amount (in cents) (both positive and negative values are allowed)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiriesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiriesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiriesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiriesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiriesServiceUpdateRequest, a, b);
    }
}
exports.SalesEnquiriesServiceUpdateRequest = SalesEnquiriesServiceUpdateRequest;
/**
 *
 * Stores the UUID references of the record
 *
 * @generated from message Scailo.SalesEnquiryAncillaryParameters
 */
class SalesEnquiryAncillaryParameters extends protobuf_1.Message {
    /**
     * The UUID of the consignee client (the UUID of the associated consignee_client_id)
     *
     * @generated from field: string consignee_client_uuid = 212;
     */
    consigneeClientUuid = "";
    /**
     * The UUID of the buyer client (the UUID of the associated buyer_client_id)
     *
     * @generated from field: string buyer_client_uuid = 213;
     */
    buyerClientUuid = "";
    /**
     * The UUID of the currency (the UUID of the associated currency)
     *
     * @generated from field: string currency_uuid = 215;
     */
    currencyUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiryAncillaryParameters";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 212, name: "consignee_client_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 213, name: "buyer_client_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 215, name: "currency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiryAncillaryParameters().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiryAncillaryParameters().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiryAncillaryParameters().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiryAncillaryParameters, a, b);
    }
}
exports.SalesEnquiryAncillaryParameters = SalesEnquiryAncillaryParameters;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.SalesEnquiry
 */
class SalesEnquiry extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales enquiry
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this sales enquiry
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this sales enquiry
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this sales enquiry was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales enquiry
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The ID of the consignee
     *
     * @generated from field: uint64 consignee_client_id = 12;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the buyer
     *
     * @generated from field: uint64 buyer_client_id = 13;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The priority of the sales enquiry. Possible values are "low", "medium", "high".
     *
     * @generated from field: string priority = 14;
     */
    priority = "";
    /**
     * The ID of the currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The description of the sales enquiry
     *
     * @generated from field: string description = 16;
     */
    description = "";
    /**
     * The miscellaneous cost (in cents)
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The overall discount (in cents)
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The round off amount (in cents)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The number of times that the sales enquiry has been amended
     *
     * @generated from field: uint64 amendment_count = 20;
     */
    amendmentCount = protobuf_1.protoInt64.zero;
    /**
     * The list of associated sales enquiry items
     *
     * @generated from field: repeated Scailo.SalesEnquiryItem list = 30;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 40;
     */
    formData = [];
    /**
     * The total price of the sales enquiry
     *
     * @generated from field: double total_price = 50;
     */
    totalPrice = 0;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiry";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "amendment_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "list", kind: "message", T: SalesEnquiryItem, repeated: true },
        { no: 40, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
        { no: 50, name: "total_price", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiry().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiry().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiry().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiry, a, b);
    }
}
exports.SalesEnquiry = SalesEnquiry;
/**
 *
 * Describes the parameters required to add an item to a sales enquiry
 *
 * @generated from message Scailo.SalesEnquiriesServiceItemCreateRequest
 */
class SalesEnquiriesServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the sales enquiry ID
     *
     * @generated from field: uint64 sales_enquiry_id = 10;
     */
    salesEnquiryId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * The quantity being offered (in cents)
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 13;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The unit price
     *
     * @generated from field: uint64 unit_price = 14;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The discount percentage (in cents)
     *
     * @generated from field: uint64 discount = 15;
     */
    discount = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The round off (in cents) (both positive and negative values are allowed)
     *
     * @generated from field: int64 round_off = 17;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The delivery date
     *
     * @generated from field: string delivery_date = 18;
     */
    deliveryDate = "";
    /**
     * The specifications
     *
     * @generated from field: string specifications = 19;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiriesServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_enquiry_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 18, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiriesServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiriesServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiriesServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiriesServiceItemCreateRequest, a, b);
    }
}
exports.SalesEnquiriesServiceItemCreateRequest = SalesEnquiriesServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a sales enquiry
 *
 * @generated from message Scailo.SalesEnquiriesServiceItemUpdateRequest
 */
class SalesEnquiriesServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * The quantity being offered (in cents)
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 13;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The unit price
     *
     * @generated from field: uint64 unit_price = 14;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The discount percentage (in cents)
     *
     * @generated from field: uint64 discount = 15;
     */
    discount = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The round off (in cents) (both positive and negative values are allowed)
     *
     * @generated from field: int64 round_off = 17;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The delivery date
     *
     * @generated from field: string delivery_date = 18;
     */
    deliveryDate = "";
    /**
     * The specifications
     *
     * @generated from field: string specifications = 19;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiriesServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 18, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiriesServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiriesServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiriesServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiriesServiceItemUpdateRequest, a, b);
    }
}
exports.SalesEnquiriesServiceItemUpdateRequest = SalesEnquiriesServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a sales enquiry
 *
 * @generated from message Scailo.SalesEnquiryItem
 */
class SalesEnquiryItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales enquiry
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the sales enquiry ID
     *
     * @generated from field: uint64 sales_enquiry_id = 10;
     */
    salesEnquiryId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    /**
     * The quantity being offered (in cents)
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated unit of material
     *
     * @generated from field: uint64 uom_id = 13;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The unit price
     *
     * @generated from field: uint64 unit_price = 14;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The discount percentage (in cents)
     *
     * @generated from field: uint64 discount = 15;
     */
    discount = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The round off (in cents)
     *
     * @generated from field: int64 round_off = 17;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The delivery date
     *
     * @generated from field: string delivery_date = 18;
     */
    deliveryDate = "";
    /**
     * The specifications
     *
     * @generated from field: string specifications = 19;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiryItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_enquiry_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 18, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiryItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiryItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiryItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiryItem, a, b);
    }
}
exports.SalesEnquiryItem = SalesEnquiryItem;
/**
 *
 * Describes the message consisting of the list of sales enquiries
 *
 * @generated from message Scailo.SalesEnquiriesList
 */
class SalesEnquiriesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesEnquiry list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiriesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesEnquiry, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiriesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiriesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiriesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiriesList, a, b);
    }
}
exports.SalesEnquiriesList = SalesEnquiriesList;
/**
 *
 * Describes the message consisting of the list of sales enquiry items
 *
 * @generated from message Scailo.SalesEnquiriesItemsList
 */
class SalesEnquiriesItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesEnquiryItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiriesItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesEnquiryItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiriesItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiriesItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiriesItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiriesItemsList, a, b);
    }
}
exports.SalesEnquiriesItemsList = SalesEnquiriesItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.SalesEnquiryItemHistoryRequest
 */
class SalesEnquiryItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the sales enquiry ID
     *
     * @generated from field: uint64 sales_enquiry_id = 10;
     */
    salesEnquiryId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiryItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "sales_enquiry_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiryItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiryItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiryItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiryItemHistoryRequest, a, b);
    }
}
exports.SalesEnquiryItemHistoryRequest = SalesEnquiryItemHistoryRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.SalesEnquiryItemsSearchRequest
 */
class SalesEnquiryItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_ENQUIRY_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_ENQUIRY_ITEM_SORT_KEY.SALES_ENQUIRY_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.SALES_ENQUIRY_ITEM_STATUS status = 7;
     */
    status = SALES_ENQUIRY_ITEM_STATUS.SALES_ENQUIRY_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * Stores the sales enquiry ID
     *
     * @generated from field: uint64 sales_enquiry_id = 20;
     */
    salesEnquiryId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the unit of material
     *
     * @generated from field: uint64 uom_id = 21;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 22;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the sales enquiry
     *
     * @generated from field: string delivery_date_exact = 28;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the sales enquiry
     *
     * @generated from field: string delivery_date_start = 29;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the sales enquiry
     *
     * @generated from field: string delivery_date_end = 30;
     */
    deliveryDateEnd = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiryItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_ENQUIRY_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_ENQUIRY_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "sales_enquiry_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 29, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiryItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiryItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiryItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiryItemsSearchRequest, a, b);
    }
}
exports.SalesEnquiryItemsSearchRequest = SalesEnquiryItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.SalesEnquiriesServicePaginatedItemsResponse
 */
class SalesEnquiriesServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.SalesEnquiryItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiriesServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: SalesEnquiryItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiriesServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiriesServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiriesServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiriesServicePaginatedItemsResponse, a, b);
    }
}
exports.SalesEnquiriesServicePaginatedItemsResponse = SalesEnquiriesServicePaginatedItemsResponse;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.SalesEnquiriesServicePaginationReq
 */
class SalesEnquiriesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_ENQUIRY_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_ENQUIRY_SORT_KEY.SALES_ENQUIRY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this sales enquiry
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiriesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_ENQUIRY_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiriesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiriesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiriesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiriesServicePaginationReq, a, b);
    }
}
exports.SalesEnquiriesServicePaginationReq = SalesEnquiriesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.SalesEnquiriesServicePaginationResponse
 */
class SalesEnquiriesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.SalesEnquiry payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiriesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: SalesEnquiry, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiriesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiriesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiriesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiriesServicePaginationResponse, a, b);
    }
}
exports.SalesEnquiriesServicePaginationResponse = SalesEnquiriesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.SalesEnquiriesServiceFilterReq
 */
class SalesEnquiriesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_ENQUIRY_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_ENQUIRY_SORT_KEY.SALES_ENQUIRY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this sales enquiry
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales enquiry
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the consignee
     *
     * @generated from field: uint64 consignee_client_id = 22;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the buyer
     *
     * @generated from field: uint64 buyer_client_id = 23;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The priority of the sales enquiry. Possible values are "low", "medium", "high".
     *
     * @generated from field: string priority = 24;
     */
    priority = "";
    /**
     * The ID of the currency
     *
     * @generated from field: uint64 currency_id = 25;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the sales enquiry
     *
     * @generated from field: string delivery_date_exact = 41;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the sales enquiry
     *
     * @generated from field: string delivery_date_start = 42;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the sales enquiry
     *
     * @generated from field: string delivery_date_end = 43;
     */
    deliveryDateEnd = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiriesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_ENQUIRY_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 42, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 43, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiriesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiriesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiriesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiriesServiceFilterReq, a, b);
    }
}
exports.SalesEnquiriesServiceFilterReq = SalesEnquiriesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.SalesEnquiriesServiceCountReq
 */
class SalesEnquiriesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this sales enquiry
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales enquiry
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the consignee
     *
     * @generated from field: uint64 consignee_client_id = 22;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the buyer
     *
     * @generated from field: uint64 buyer_client_id = 23;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The priority of the sales enquiry. Possible values are "low", "medium", "high".
     *
     * @generated from field: string priority = 24;
     */
    priority = "";
    /**
     * The ID of the currency
     *
     * @generated from field: uint64 currency_id = 25;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the sales enquiry
     *
     * @generated from field: string delivery_date_exact = 41;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the sales enquiry
     *
     * @generated from field: string delivery_date_start = 42;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the sales enquiry
     *
     * @generated from field: string delivery_date_end = 43;
     */
    deliveryDateEnd = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiriesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 42, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 43, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiriesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiriesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiriesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiriesServiceCountReq, a, b);
    }
}
exports.SalesEnquiriesServiceCountReq = SalesEnquiriesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.SalesEnquiriesServiceSearchAllReq
 */
class SalesEnquiriesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_ENQUIRY_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_ENQUIRY_SORT_KEY.SALES_ENQUIRY_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The ID of the consignee
     *
     * @generated from field: uint64 consignee_client_id = 22;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the buyer
     *
     * @generated from field: uint64 buyer_client_id = 23;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiriesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_ENQUIRY_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiriesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiriesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiriesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiriesServiceSearchAllReq, a, b);
    }
}
exports.SalesEnquiriesServiceSearchAllReq = SalesEnquiriesServiceSearchAllReq;
/**
 *
 * Describes the parameters necessary to create a sales enquiry contact
 *
 * @generated from message Scailo.SalesEnquiriesServiceContactCreateRequest
 */
class SalesEnquiriesServiceContactCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the sales enquiry ID
     *
     * @generated from field: uint64 sales_enquiry_id = 10;
     */
    salesEnquiryId = protobuf_1.protoInt64.zero;
    /**
     * Stores the associate ID
     *
     * @generated from field: uint64 associate_id = 11;
     */
    associateId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiriesServiceContactCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_enquiry_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiriesServiceContactCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiriesServiceContactCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiriesServiceContactCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiriesServiceContactCreateRequest, a, b);
    }
}
exports.SalesEnquiriesServiceContactCreateRequest = SalesEnquiriesServiceContactCreateRequest;
/**
 *
 * Describes the parameters that constitute a sales enquiry contact
 *
 * @generated from message Scailo.SalesEnquiryContact
 */
class SalesEnquiryContact extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales enquiry
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the sales enquiry ID
     *
     * @generated from field: uint64 sales_enquiry_id = 10;
     */
    salesEnquiryId = protobuf_1.protoInt64.zero;
    /**
     * Stores the associate ID
     *
     * @generated from field: uint64 associate_id = 11;
     */
    associateId = protobuf_1.protoInt64.zero;
    /**
     * Stores the UUID of the associate
     *
     * @generated from field: string associate_uuid = 211;
     */
    associateUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiryContact";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_enquiry_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "associate_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 211, name: "associate_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiryContact().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiryContact().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiryContact().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiryContact, a, b);
    }
}
exports.SalesEnquiryContact = SalesEnquiryContact;
/**
 *
 * Describes the message consisting of the list of sales enquiry contacts
 *
 * @generated from message Scailo.SalesEnquiryContactsList
 */
class SalesEnquiryContactsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesEnquiryContact list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesEnquiryContactsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesEnquiryContact, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesEnquiryContactsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesEnquiryContactsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesEnquiryContactsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesEnquiryContactsList, a, b);
    }
}
exports.SalesEnquiryContactsList = SalesEnquiryContactsList;
