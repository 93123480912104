import { productionPlanFiltersMenu, productionPlanIndividualMenu, productionPlanInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleProductionPlanFilters } from "./productionplans.filters";
import { handleProductionPlanInsights } from "./productionplans.insights";
import { handleIndividualProductionPlan } from "./individual";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(productionPlanFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, productionPlanFiltersMenu)) {
            handleProductionPlanFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(productionPlanInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, productionPlanInsightsMenu)) {
            handleProductionPlanInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(productionPlanIndividualMenu.href, async (ctx) => {
        if (await interceptPage(ctx, productionPlanFiltersMenu)) {
            handleIndividualProductionPlan(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}