"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file supply_offers.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplyOffersServicePaginatedItemsResponse = exports.SupplyOfferItemsSearchRequest = exports.SupplyOffersServiceSearchAllReq = exports.SupplyOffersServiceCountReq = exports.SupplyOffersServiceFilterReq = exports.SupplyOffersServicePaginationResponse = exports.SupplyOffersServicePaginationReq = exports.SupplyOfferItemProspectiveInfoRequest = exports.SupplyOfferItemHistoryRequest = exports.SupplyOfferItemsList = exports.SupplyOffersList = exports.SupplyOfferItem = exports.SupplyOffersServiceItemUpdateRequest = exports.SupplyOffersServiceItemCreateRequest = exports.SupplyOffer = exports.SupplyOfferAncillaryParameters = exports.SupplyOffersServiceAutofillRequest = exports.SupplyOffersServiceUpdateRequest = exports.SupplyOffersServiceCreateRequest = exports.SUPPLY_OFFER_ITEM_STATUS = exports.SUPPLY_OFFER_ITEM_SORT_KEY = exports.SUPPLY_OFFER_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.SUPPLY_OFFER_SORT_KEY
 */
var SUPPLY_OFFER_SORT_KEY;
(function (SUPPLY_OFFER_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_ID_UNSPECIFIED"] = 0] = "SUPPLY_OFFER_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_CREATED_AT = 1;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_CREATED_AT"] = 1] = "SUPPLY_OFFER_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_MODIFIED_AT = 2;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_MODIFIED_AT"] = 2] = "SUPPLY_OFFER_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_APPROVED_ON = 3;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_APPROVED_ON"] = 3] = "SUPPLY_OFFER_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_APPROVED_BY = 4;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_APPROVED_BY"] = 4] = "SUPPLY_OFFER_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "SUPPLY_OFFER_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_COMPLETED_ON = 6;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_COMPLETED_ON"] = 6] = "SUPPLY_OFFER_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_REFERENCE_ID = 10;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_REFERENCE_ID"] = 10] = "SUPPLY_OFFER_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "SUPPLY_OFFER_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the consignee location ID
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_CONSIGNEE_LOCATION_ID = 12;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_CONSIGNEE_LOCATION_ID"] = 12] = "SUPPLY_OFFER_SORT_KEY_CONSIGNEE_LOCATION_ID";
    /**
     * Fetch ordered results by the buyer location ID
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_BUYER_LOCATION_ID = 13;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_BUYER_LOCATION_ID"] = 13] = "SUPPLY_OFFER_SORT_KEY_BUYER_LOCATION_ID";
    /**
     * Fetch ordered results by the vendor ID
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_VENDOR_ID = 14;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_VENDOR_ID"] = 14] = "SUPPLY_OFFER_SORT_KEY_VENDOR_ID";
    /**
     * Fetch ordered results by the currency ID
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_CURRENCY_ID = 15;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_CURRENCY_ID"] = 15] = "SUPPLY_OFFER_SORT_KEY_CURRENCY_ID";
    /**
     * Fetch ordered results by the project ID
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_PROJECT_ID = 16;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_PROJECT_ID"] = 16] = "SUPPLY_OFFER_SORT_KEY_PROJECT_ID";
    /**
     * Fetch ordered results by the advance payment
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_PAYMENT_ADVANCE = 17;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_PAYMENT_ADVANCE"] = 17] = "SUPPLY_OFFER_SORT_KEY_PAYMENT_ADVANCE";
    /**
     * Fetch ordered results by the amendment count
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_AMENDMENT_COUNT = 18;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_AMENDMENT_COUNT"] = 18] = "SUPPLY_OFFER_SORT_KEY_AMENDMENT_COUNT";
    /**
     * Fetch ordered results by the total value
     *
     * @generated from enum value: SUPPLY_OFFER_SORT_KEY_TOTAL_VALUE = 30;
     */
    SUPPLY_OFFER_SORT_KEY[SUPPLY_OFFER_SORT_KEY["SUPPLY_OFFER_SORT_KEY_TOTAL_VALUE"] = 30] = "SUPPLY_OFFER_SORT_KEY_TOTAL_VALUE";
})(SUPPLY_OFFER_SORT_KEY || (exports.SUPPLY_OFFER_SORT_KEY = SUPPLY_OFFER_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(SUPPLY_OFFER_SORT_KEY)
protobuf_1.proto3.util.setEnumType(SUPPLY_OFFER_SORT_KEY, "Scailo.SUPPLY_OFFER_SORT_KEY", [
    { no: 0, name: "SUPPLY_OFFER_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "SUPPLY_OFFER_SORT_KEY_CREATED_AT" },
    { no: 2, name: "SUPPLY_OFFER_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "SUPPLY_OFFER_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "SUPPLY_OFFER_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "SUPPLY_OFFER_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "SUPPLY_OFFER_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "SUPPLY_OFFER_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "SUPPLY_OFFER_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "SUPPLY_OFFER_SORT_KEY_CONSIGNEE_LOCATION_ID" },
    { no: 13, name: "SUPPLY_OFFER_SORT_KEY_BUYER_LOCATION_ID" },
    { no: 14, name: "SUPPLY_OFFER_SORT_KEY_VENDOR_ID" },
    { no: 15, name: "SUPPLY_OFFER_SORT_KEY_CURRENCY_ID" },
    { no: 16, name: "SUPPLY_OFFER_SORT_KEY_PROJECT_ID" },
    { no: 17, name: "SUPPLY_OFFER_SORT_KEY_PAYMENT_ADVANCE" },
    { no: 18, name: "SUPPLY_OFFER_SORT_KEY_AMENDMENT_COUNT" },
    { no: 30, name: "SUPPLY_OFFER_SORT_KEY_TOTAL_VALUE" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.SUPPLY_OFFER_ITEM_SORT_KEY
 */
var SUPPLY_OFFER_ITEM_SORT_KEY;
(function (SUPPLY_OFFER_ITEM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    SUPPLY_OFFER_ITEM_SORT_KEY[SUPPLY_OFFER_ITEM_SORT_KEY["SUPPLY_OFFER_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "SUPPLY_OFFER_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    SUPPLY_OFFER_ITEM_SORT_KEY[SUPPLY_OFFER_ITEM_SORT_KEY["SUPPLY_OFFER_ITEM_SORT_KEY_CREATED_AT"] = 1] = "SUPPLY_OFFER_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    SUPPLY_OFFER_ITEM_SORT_KEY[SUPPLY_OFFER_ITEM_SORT_KEY["SUPPLY_OFFER_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "SUPPLY_OFFER_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    SUPPLY_OFFER_ITEM_SORT_KEY[SUPPLY_OFFER_ITEM_SORT_KEY["SUPPLY_OFFER_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "SUPPLY_OFFER_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    SUPPLY_OFFER_ITEM_SORT_KEY[SUPPLY_OFFER_ITEM_SORT_KEY["SUPPLY_OFFER_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "SUPPLY_OFFER_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    SUPPLY_OFFER_ITEM_SORT_KEY[SUPPLY_OFFER_ITEM_SORT_KEY["SUPPLY_OFFER_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "SUPPLY_OFFER_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the family ID
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    SUPPLY_OFFER_ITEM_SORT_KEY[SUPPLY_OFFER_ITEM_SORT_KEY["SUPPLY_OFFER_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "SUPPLY_OFFER_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch ordered results by the internal quantity
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    SUPPLY_OFFER_ITEM_SORT_KEY[SUPPLY_OFFER_ITEM_SORT_KEY["SUPPLY_OFFER_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "SUPPLY_OFFER_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch ordered results by the vendor unit of material ID
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_SORT_KEY_VENDOR_UOM_ID = 12;
     */
    SUPPLY_OFFER_ITEM_SORT_KEY[SUPPLY_OFFER_ITEM_SORT_KEY["SUPPLY_OFFER_ITEM_SORT_KEY_VENDOR_UOM_ID"] = 12] = "SUPPLY_OFFER_ITEM_SORT_KEY_VENDOR_UOM_ID";
    /**
     * Fetch ordered results by the vendor quantity
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_SORT_KEY_VENDOR_QUANTITY = 13;
     */
    SUPPLY_OFFER_ITEM_SORT_KEY[SUPPLY_OFFER_ITEM_SORT_KEY["SUPPLY_OFFER_ITEM_SORT_KEY_VENDOR_QUANTITY"] = 13] = "SUPPLY_OFFER_ITEM_SORT_KEY_VENDOR_QUANTITY";
    /**
     * Fetch ordered results by the vendor unit price
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_SORT_KEY_VENDOR_UNIT_PRICE = 14;
     */
    SUPPLY_OFFER_ITEM_SORT_KEY[SUPPLY_OFFER_ITEM_SORT_KEY["SUPPLY_OFFER_ITEM_SORT_KEY_VENDOR_UNIT_PRICE"] = 14] = "SUPPLY_OFFER_ITEM_SORT_KEY_VENDOR_UNIT_PRICE";
    /**
     * Fetch ordered results by the tax group ID
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_SORT_KEY_TAX_GROUP_ID = 15;
     */
    SUPPLY_OFFER_ITEM_SORT_KEY[SUPPLY_OFFER_ITEM_SORT_KEY["SUPPLY_OFFER_ITEM_SORT_KEY_TAX_GROUP_ID"] = 15] = "SUPPLY_OFFER_ITEM_SORT_KEY_TAX_GROUP_ID";
    /**
     * Fetch ordered results by the discount
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_SORT_KEY_DISCOUNT = 16;
     */
    SUPPLY_OFFER_ITEM_SORT_KEY[SUPPLY_OFFER_ITEM_SORT_KEY["SUPPLY_OFFER_ITEM_SORT_KEY_DISCOUNT"] = 16] = "SUPPLY_OFFER_ITEM_SORT_KEY_DISCOUNT";
    /**
     * Fetch ordered results by the delivery date
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_SORT_KEY_DELIVERY_DATE = 17;
     */
    SUPPLY_OFFER_ITEM_SORT_KEY[SUPPLY_OFFER_ITEM_SORT_KEY["SUPPLY_OFFER_ITEM_SORT_KEY_DELIVERY_DATE"] = 17] = "SUPPLY_OFFER_ITEM_SORT_KEY_DELIVERY_DATE";
})(SUPPLY_OFFER_ITEM_SORT_KEY || (exports.SUPPLY_OFFER_ITEM_SORT_KEY = SUPPLY_OFFER_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(SUPPLY_OFFER_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(SUPPLY_OFFER_ITEM_SORT_KEY, "Scailo.SUPPLY_OFFER_ITEM_SORT_KEY", [
    { no: 0, name: "SUPPLY_OFFER_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "SUPPLY_OFFER_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "SUPPLY_OFFER_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "SUPPLY_OFFER_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "SUPPLY_OFFER_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "SUPPLY_OFFER_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "SUPPLY_OFFER_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "SUPPLY_OFFER_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "SUPPLY_OFFER_ITEM_SORT_KEY_VENDOR_UOM_ID" },
    { no: 13, name: "SUPPLY_OFFER_ITEM_SORT_KEY_VENDOR_QUANTITY" },
    { no: 14, name: "SUPPLY_OFFER_ITEM_SORT_KEY_VENDOR_UNIT_PRICE" },
    { no: 15, name: "SUPPLY_OFFER_ITEM_SORT_KEY_TAX_GROUP_ID" },
    { no: 16, name: "SUPPLY_OFFER_ITEM_SORT_KEY_DISCOUNT" },
    { no: 17, name: "SUPPLY_OFFER_ITEM_SORT_KEY_DELIVERY_DATE" },
]);
/**
 *
 * Describes the applicable statuses of supply offer items
 *
 * @generated from enum Scailo.SUPPLY_OFFER_ITEM_STATUS
 */
var SUPPLY_OFFER_ITEM_STATUS;
(function (SUPPLY_OFFER_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    SUPPLY_OFFER_ITEM_STATUS[SUPPLY_OFFER_ITEM_STATUS["SUPPLY_OFFER_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "SUPPLY_OFFER_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the supply offer items must have been approved
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_STATUS_APPROVED = 1;
     */
    SUPPLY_OFFER_ITEM_STATUS[SUPPLY_OFFER_ITEM_STATUS["SUPPLY_OFFER_ITEM_STATUS_APPROVED"] = 1] = "SUPPLY_OFFER_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the supply offer items must be waiting for approval
     *
     * @generated from enum value: SUPPLY_OFFER_ITEM_STATUS_UNAPPROVED = 2;
     */
    SUPPLY_OFFER_ITEM_STATUS[SUPPLY_OFFER_ITEM_STATUS["SUPPLY_OFFER_ITEM_STATUS_UNAPPROVED"] = 2] = "SUPPLY_OFFER_ITEM_STATUS_UNAPPROVED";
})(SUPPLY_OFFER_ITEM_STATUS || (exports.SUPPLY_OFFER_ITEM_STATUS = SUPPLY_OFFER_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(SUPPLY_OFFER_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(SUPPLY_OFFER_ITEM_STATUS, "Scailo.SUPPLY_OFFER_ITEM_STATUS", [
    { no: 0, name: "SUPPLY_OFFER_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "SUPPLY_OFFER_ITEM_STATUS_APPROVED" },
    { no: 2, name: "SUPPLY_OFFER_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.SupplyOffersServiceCreateRequest
 */
class SupplyOffersServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the supply offer
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The location ID of the consignee
     *
     * @generated from field: uint64 consignee_location_id = 12;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of the buyer
     *
     * @generated from field: uint64 buyer_location_id = 13;
     */
    buyerLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the vendor
     *
     * @generated from field: uint64 vendor_id = 14;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 16;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The amount paid in advance
     *
     * @generated from field: uint64 payment_advance = 20;
     */
    paymentAdvance = protobuf_1.protoInt64.zero;
    /**
     * The payment cycle in days
     *
     * @generated from field: uint64 payment_cycle_in_days = 21;
     */
    paymentCycleInDays = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOffersServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "payment_advance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "payment_cycle_in_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOffersServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOffersServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOffersServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOffersServiceCreateRequest, a, b);
    }
}
exports.SupplyOffersServiceCreateRequest = SupplyOffersServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.SupplyOffersServiceUpdateRequest
 */
class SupplyOffersServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the supply offer
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The location ID of the consignee
     *
     * @generated from field: uint64 consignee_location_id = 12;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of the buyer
     *
     * @generated from field: uint64 buyer_location_id = 13;
     */
    buyerLocationId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 16;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The amount paid in advance
     *
     * @generated from field: uint64 payment_advance = 20;
     */
    paymentAdvance = protobuf_1.protoInt64.zero;
    /**
     * The payment cycle in days
     *
     * @generated from field: uint64 payment_cycle_in_days = 21;
     */
    paymentCycleInDays = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOffersServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "payment_advance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "payment_cycle_in_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOffersServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOffersServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOffersServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOffersServiceUpdateRequest, a, b);
    }
}
exports.SupplyOffersServiceUpdateRequest = SupplyOffersServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.SupplyOffersServiceAutofillRequest
 */
class SupplyOffersServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The UUID of the record that needs to be updated
     *
     * @generated from field: string uuid = 2;
     */
    uuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOffersServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOffersServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOffersServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOffersServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOffersServiceAutofillRequest, a, b);
    }
}
exports.SupplyOffersServiceAutofillRequest = SupplyOffersServiceAutofillRequest;
/**
 *
 * Stores the UUID references of the record
 *
 * @generated from message Scailo.SupplyOfferAncillaryParameters
 */
class SupplyOfferAncillaryParameters extends protobuf_1.Message {
    /**
     * The UUID of the consignee location (the UUID of the associated consignee_location_id)
     *
     * @generated from field: string consignee_location_uuid = 212;
     */
    consigneeLocationUuid = "";
    /**
     * The UUID of the buyer location (the UUID of the associated buyer_location_id)
     *
     * @generated from field: string buyer_location_uuid = 213;
     */
    buyerLocationUuid = "";
    /**
     * The UUID of the vendor (the UUID of the associated vendor_id)
     *
     * @generated from field: string vendor_uuid = 214;
     */
    vendorUuid = "";
    /**
     * The UUID of the currency (the UUID of the associated currency)
     *
     * @generated from field: string currency_uuid = 215;
     */
    currencyUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOfferAncillaryParameters";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 212, name: "consignee_location_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 213, name: "buyer_location_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 214, name: "vendor_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 215, name: "currency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOfferAncillaryParameters().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOfferAncillaryParameters().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOfferAncillaryParameters().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOfferAncillaryParameters, a, b);
    }
}
exports.SupplyOfferAncillaryParameters = SupplyOfferAncillaryParameters;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.SupplyOffer
 */
class SupplyOffer extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this supply offer
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this supply offer
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this supply offer
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this supply offer was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the supply offer
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The location ID of the consignee
     *
     * @generated from field: uint64 consignee_location_id = 12;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of the buyer
     *
     * @generated from field: uint64 buyer_location_id = 13;
     */
    buyerLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the vendor
     *
     * @generated from field: uint64 vendor_id = 14;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The optional associated ID of the project
     *
     * @generated from field: uint64 project_id = 16;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 17;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 18;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 19;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The amount paid in advance
     *
     * @generated from field: uint64 payment_advance = 20;
     */
    paymentAdvance = protobuf_1.protoInt64.zero;
    /**
     * The payment cycle in days
     *
     * @generated from field: uint64 payment_cycle_in_days = 21;
     */
    paymentCycleInDays = protobuf_1.protoInt64.zero;
    /**
     * The number of times that the supply offer has been amended
     *
     * @generated from field: uint64 amendment_count = 22;
     */
    amendmentCount = protobuf_1.protoInt64.zero;
    /**
     * Stores the total value of the supply offer (as a double, which requires no adjustments)
     *
     * @generated from field: double total_value = 30;
     */
    totalValue = 0;
    /**
     * The list of associated supply offer items
     *
     * @generated from field: repeated Scailo.SupplyOfferItem list = 40;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 50;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOffer";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "buyer_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 20, name: "payment_advance", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "payment_cycle_in_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "amendment_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "total_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
        { no: 40, name: "list", kind: "message", T: SupplyOfferItem, repeated: true },
        { no: 50, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOffer().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOffer().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOffer().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOffer, a, b);
    }
}
exports.SupplyOffer = SupplyOffer;
/**
 *
 * Describes the parameters required to add an item to a supply offer
 *
 * @generated from message Scailo.SupplyOffersServiceItemCreateRequest
 */
class SupplyOffersServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the supply offer ID
     *
     * @generated from field: uint64 supply_offer_id = 10;
     */
    supplyOfferId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 14;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    /**
     * The unit price of the item (as supplied by the vendor)
     *
     * @generated from field: uint64 vendor_unit_price = 15;
     */
    vendorUnitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable discount percentage (in cents)
     *
     * @generated from field: uint64 discount = 17;
     */
    discount = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 18;
     */
    deliveryDate = "";
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 19;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOffersServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "supply_offer_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOffersServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOffersServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOffersServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOffersServiceItemCreateRequest, a, b);
    }
}
exports.SupplyOffersServiceItemCreateRequest = SupplyOffersServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a supply offer
 *
 * @generated from message Scailo.SupplyOffersServiceItemUpdateRequest
 */
class SupplyOffersServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 14;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    /**
     * The unit price of the item (as supplied by the vendor)
     *
     * @generated from field: uint64 vendor_unit_price = 15;
     */
    vendorUnitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable discount percentage (in cents)
     *
     * @generated from field: uint64 discount = 17;
     */
    discount = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 18;
     */
    deliveryDate = "";
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 19;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOffersServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOffersServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOffersServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOffersServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOffersServiceItemUpdateRequest, a, b);
    }
}
exports.SupplyOffersServiceItemUpdateRequest = SupplyOffersServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a supply offer
 *
 * @generated from message Scailo.SupplyOfferItem
 */
class SupplyOfferItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this supply offer
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the supply offer ID
     *
     * @generated from field: uint64 supply_offer_id = 10;
     */
    supplyOfferId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being ordered in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being ordered in vendor's unit of material
     *
     * @generated from field: uint64 vendor_quantity = 14;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    /**
     * The unit price of the item (as supplied by the vendor)
     *
     * @generated from field: uint64 vendor_unit_price = 15;
     */
    vendorUnitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 16;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable discount percentage (in cents)
     *
     * @generated from field: uint64 discount = 17;
     */
    discount = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 18;
     */
    deliveryDate = "";
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 19;
     */
    specifications = "";
    /**
     * Stores the vendor unit price after factoring in the discount
     *
     * @generated from field: uint64 discounted_vendor_unit_price = 30;
     */
    discountedVendorUnitPrice = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOfferItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "supply_offer_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "discounted_vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOfferItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOfferItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOfferItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOfferItem, a, b);
    }
}
exports.SupplyOfferItem = SupplyOfferItem;
/**
 *
 * Describes the message consisting of the list of supply offers
 *
 * @generated from message Scailo.SupplyOffersList
 */
class SupplyOffersList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SupplyOffer list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOffersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SupplyOffer, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOffersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOffersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOffersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOffersList, a, b);
    }
}
exports.SupplyOffersList = SupplyOffersList;
/**
 *
 * Describes the message consisting of the list of supply offer items
 *
 * @generated from message Scailo.SupplyOfferItemsList
 */
class SupplyOfferItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SupplyOfferItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOfferItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SupplyOfferItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOfferItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOfferItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOfferItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOfferItemsList, a, b);
    }
}
exports.SupplyOfferItemsList = SupplyOfferItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.SupplyOfferItemHistoryRequest
 */
class SupplyOfferItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the supply offer ID
     *
     * @generated from field: uint64 supply_offer_id = 10;
     */
    supplyOfferId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOfferItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "supply_offer_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOfferItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOfferItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOfferItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOfferItemHistoryRequest, a, b);
    }
}
exports.SupplyOfferItemHistoryRequest = SupplyOfferItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective supply offer item
 *
 * @generated from message Scailo.SupplyOfferItemProspectiveInfoRequest
 */
class SupplyOfferItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the supply offer ID
     *
     * @generated from field: uint64 supply_offer_id = 10;
     */
    supplyOfferId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the vendor's unit of material (if 0, the value is ignored while retrieving records)
     *
     * @generated from field: uint64 vendor_uom_id = 13;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOfferItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "supply_offer_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOfferItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOfferItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOfferItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOfferItemProspectiveInfoRequest, a, b);
    }
}
exports.SupplyOfferItemProspectiveInfoRequest = SupplyOfferItemProspectiveInfoRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.SupplyOffersServicePaginationReq
 */
class SupplyOffersServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SUPPLY_OFFER_SORT_KEY sort_key = 5;
     */
    sortKey = SUPPLY_OFFER_SORT_KEY.SUPPLY_OFFER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this supply offer
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOffersServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SUPPLY_OFFER_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOffersServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOffersServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOffersServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOffersServicePaginationReq, a, b);
    }
}
exports.SupplyOffersServicePaginationReq = SupplyOffersServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.SupplyOffersServicePaginationResponse
 */
class SupplyOffersServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.SupplyOffer payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOffersServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: SupplyOffer, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOffersServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOffersServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOffersServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOffersServicePaginationResponse, a, b);
    }
}
exports.SupplyOffersServicePaginationResponse = SupplyOffersServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.SupplyOffersServiceFilterReq
 */
class SupplyOffersServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SUPPLY_OFFER_SORT_KEY sort_key = 5;
     */
    sortKey = SUPPLY_OFFER_SORT_KEY.SUPPLY_OFFER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this supply offer
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the supply offer
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated consignee location ID
     *
     * @generated from field: uint64 consignee_location_id = 22;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer location ID
     *
     * @generated from field: uint64 buyer_location_id = 23;
     */
    buyerLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 24;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 25;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 26;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the supply offer
     *
     * @generated from field: string delivery_date_exact = 41;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the supply offer
     *
     * @generated from field: string delivery_date_start = 42;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the supply offer
     *
     * @generated from field: string delivery_date_end = 43;
     */
    deliveryDateEnd = "";
    /**
     * Stores the minimum value of the supply offer (ignored if 0)
     *
     * @generated from field: uint64 total_value_min = 50;
     */
    totalValueMin = protobuf_1.protoInt64.zero;
    /**
     * Stores the maximum value of the supply offer (ignored if 0)
     *
     * @generated from field: uint64 total_value_max = 51;
     */
    totalValueMax = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOffersServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SUPPLY_OFFER_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 42, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 43, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "total_value_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "total_value_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOffersServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOffersServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOffersServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOffersServiceFilterReq, a, b);
    }
}
exports.SupplyOffersServiceFilterReq = SupplyOffersServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.SupplyOffersServiceCountReq
 */
class SupplyOffersServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this supply offer
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the supply offer
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated consignee location ID
     *
     * @generated from field: uint64 consignee_location_id = 22;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer location ID
     *
     * @generated from field: uint64 buyer_location_id = 23;
     */
    buyerLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 24;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 25;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 26;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the supply offer
     *
     * @generated from field: string delivery_date_exact = 41;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the supply offer
     *
     * @generated from field: string delivery_date_start = 42;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the supply offer
     *
     * @generated from field: string delivery_date_end = 43;
     */
    deliveryDateEnd = "";
    /**
     * Stores the minimum value of the supply offer (ignored if 0)
     *
     * @generated from field: uint64 total_value_min = 50;
     */
    totalValueMin = protobuf_1.protoInt64.zero;
    /**
     * Stores the maximum value of the supply offer (ignored if 0)
     *
     * @generated from field: uint64 total_value_max = 51;
     */
    totalValueMax = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOffersServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 42, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 43, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "total_value_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "total_value_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOffersServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOffersServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOffersServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOffersServiceCountReq, a, b);
    }
}
exports.SupplyOffersServiceCountReq = SupplyOffersServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.SupplyOffersServiceSearchAllReq
 */
class SupplyOffersServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SUPPLY_OFFER_SORT_KEY sort_key = 5;
     */
    sortKey = SUPPLY_OFFER_SORT_KEY.SUPPLY_OFFER_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated consignee location ID
     *
     * @generated from field: uint64 consignee_location_id = 22;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer location ID
     *
     * @generated from field: uint64 buyer_location_id = 23;
     */
    buyerLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 24;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 25;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project
     *
     * @generated from field: uint64 project_id = 26;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOffersServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SUPPLY_OFFER_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "buyer_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOffersServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOffersServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOffersServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOffersServiceSearchAllReq, a, b);
    }
}
exports.SupplyOffersServiceSearchAllReq = SupplyOffersServiceSearchAllReq;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.SupplyOfferItemsSearchRequest
 */
class SupplyOfferItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SUPPLY_OFFER_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = SUPPLY_OFFER_ITEM_SORT_KEY.SUPPLY_OFFER_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.SUPPLY_OFFER_ITEM_STATUS status = 7;
     */
    status = SUPPLY_OFFER_ITEM_STATUS.SUPPLY_OFFER_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the supply offer
     *
     * @generated from field: uint64 supply_offer_id = 20;
     */
    supplyOfferId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 22;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 23;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the supply offer
     *
     * @generated from field: string delivery_date_exact = 28;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the supply offer
     *
     * @generated from field: string delivery_date_start = 29;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the supply offer
     *
     * @generated from field: string delivery_date_end = 30;
     */
    deliveryDateEnd = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOfferItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SUPPLY_OFFER_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(SUPPLY_OFFER_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "supply_offer_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 29, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOfferItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOfferItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOfferItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOfferItemsSearchRequest, a, b);
    }
}
exports.SupplyOfferItemsSearchRequest = SupplyOfferItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.SupplyOffersServicePaginatedItemsResponse
 */
class SupplyOffersServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.SupplyOfferItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SupplyOffersServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: SupplyOfferItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SupplyOffersServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SupplyOffersServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SupplyOffersServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SupplyOffersServicePaginatedItemsResponse, a, b);
    }
}
exports.SupplyOffersServicePaginatedItemsResponse = SupplyOffersServicePaginatedItemsResponse;
