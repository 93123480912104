"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file purchases_indents.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurchaseIndentOrderedStatisticsList = exports.PurchaseIndentOrderedStatistics = exports.PurchasesIndentsServicePaginatedItemsResponse = exports.PurchaseIndentItemsSearchRequest = exports.PurchasesIndentsServiceSearchAllReq = exports.PurchasesIndentsServiceCountReq = exports.PurchasesIndentsServiceFilterReq = exports.PurchasesIndentsServicePaginationResponse = exports.PurchasesIndentsServicePaginationReq = exports.PurchaseIndentItemProspectiveInfoRequest = exports.PurchaseIndentItemHistoryRequest = exports.PurchasesIndentsItemsList = exports.PurchasesIndentsList = exports.PurchaseIndentItem = exports.PurchasesIndentsServiceItemUpdateRequest = exports.PurchasesIndentsServiceMultipleItemsCreateRequest = exports.PurchasesIndentsServiceMultipleItemsSingleton = exports.PurchasesIndentsServiceItemCreateRequest = exports.PurchaseIndent = exports.PurchasesIndentsServiceAutofillRequest = exports.PurchasesIndentsServiceUpdateRequest = exports.PurchasesIndentsServiceCreateRequest = exports.PURCHASE_INDENT_ITEM_STATUS = exports.PURCHASE_INDENT_ITEM_SORT_KEY = exports.PURCHASE_INDENT_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PURCHASE_INDENT_SORT_KEY
 */
var PURCHASE_INDENT_SORT_KEY;
(function (PURCHASE_INDENT_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: PURCHASE_INDENT_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PURCHASE_INDENT_SORT_KEY[PURCHASE_INDENT_SORT_KEY["PURCHASE_INDENT_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PURCHASE_INDENT_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: PURCHASE_INDENT_SORT_KEY_CREATED_AT = 1;
     */
    PURCHASE_INDENT_SORT_KEY[PURCHASE_INDENT_SORT_KEY["PURCHASE_INDENT_SORT_KEY_CREATED_AT"] = 1] = "PURCHASE_INDENT_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: PURCHASE_INDENT_SORT_KEY_MODIFIED_AT = 2;
     */
    PURCHASE_INDENT_SORT_KEY[PURCHASE_INDENT_SORT_KEY["PURCHASE_INDENT_SORT_KEY_MODIFIED_AT"] = 2] = "PURCHASE_INDENT_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: PURCHASE_INDENT_SORT_KEY_APPROVED_ON = 3;
     */
    PURCHASE_INDENT_SORT_KEY[PURCHASE_INDENT_SORT_KEY["PURCHASE_INDENT_SORT_KEY_APPROVED_ON"] = 3] = "PURCHASE_INDENT_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: PURCHASE_INDENT_SORT_KEY_APPROVED_BY = 4;
     */
    PURCHASE_INDENT_SORT_KEY[PURCHASE_INDENT_SORT_KEY["PURCHASE_INDENT_SORT_KEY_APPROVED_BY"] = 4] = "PURCHASE_INDENT_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: PURCHASE_INDENT_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PURCHASE_INDENT_SORT_KEY[PURCHASE_INDENT_SORT_KEY["PURCHASE_INDENT_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PURCHASE_INDENT_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: PURCHASE_INDENT_SORT_KEY_COMPLETED_ON = 6;
     */
    PURCHASE_INDENT_SORT_KEY[PURCHASE_INDENT_SORT_KEY["PURCHASE_INDENT_SORT_KEY_COMPLETED_ON"] = 6] = "PURCHASE_INDENT_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: PURCHASE_INDENT_SORT_KEY_REFERENCE_ID = 10;
     */
    PURCHASE_INDENT_SORT_KEY[PURCHASE_INDENT_SORT_KEY["PURCHASE_INDENT_SORT_KEY_REFERENCE_ID"] = 10] = "PURCHASE_INDENT_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: PURCHASE_INDENT_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    PURCHASE_INDENT_SORT_KEY[PURCHASE_INDENT_SORT_KEY["PURCHASE_INDENT_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "PURCHASE_INDENT_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the location ID
     *
     * @generated from enum value: PURCHASE_INDENT_SORT_KEY_CONSIGNEE_LOCATION_ID = 12;
     */
    PURCHASE_INDENT_SORT_KEY[PURCHASE_INDENT_SORT_KEY["PURCHASE_INDENT_SORT_KEY_CONSIGNEE_LOCATION_ID"] = 12] = "PURCHASE_INDENT_SORT_KEY_CONSIGNEE_LOCATION_ID";
})(PURCHASE_INDENT_SORT_KEY || (exports.PURCHASE_INDENT_SORT_KEY = PURCHASE_INDENT_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PURCHASE_INDENT_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PURCHASE_INDENT_SORT_KEY, "Scailo.PURCHASE_INDENT_SORT_KEY", [
    { no: 0, name: "PURCHASE_INDENT_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PURCHASE_INDENT_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PURCHASE_INDENT_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PURCHASE_INDENT_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PURCHASE_INDENT_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PURCHASE_INDENT_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "PURCHASE_INDENT_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "PURCHASE_INDENT_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "PURCHASE_INDENT_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "PURCHASE_INDENT_SORT_KEY_CONSIGNEE_LOCATION_ID" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PURCHASE_INDENT_ITEM_SORT_KEY
 */
var PURCHASE_INDENT_ITEM_SORT_KEY;
(function (PURCHASE_INDENT_ITEM_SORT_KEY) {
    /**
     * Fetch invoiced results by id
     *
     * @generated from enum value: PURCHASE_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PURCHASE_INDENT_ITEM_SORT_KEY[PURCHASE_INDENT_ITEM_SORT_KEY["PURCHASE_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PURCHASE_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch invoiced results by the creation timestamp
     *
     * @generated from enum value: PURCHASE_INDENT_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    PURCHASE_INDENT_ITEM_SORT_KEY[PURCHASE_INDENT_ITEM_SORT_KEY["PURCHASE_INDENT_ITEM_SORT_KEY_CREATED_AT"] = 1] = "PURCHASE_INDENT_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch invoiced results by the modified timestamp
     *
     * @generated from enum value: PURCHASE_INDENT_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    PURCHASE_INDENT_ITEM_SORT_KEY[PURCHASE_INDENT_ITEM_SORT_KEY["PURCHASE_INDENT_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "PURCHASE_INDENT_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch invoiced results by the approved on timestamp
     *
     * @generated from enum value: PURCHASE_INDENT_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    PURCHASE_INDENT_ITEM_SORT_KEY[PURCHASE_INDENT_ITEM_SORT_KEY["PURCHASE_INDENT_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "PURCHASE_INDENT_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch invoiced results by the approved by field
     *
     * @generated from enum value: PURCHASE_INDENT_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    PURCHASE_INDENT_ITEM_SORT_KEY[PURCHASE_INDENT_ITEM_SORT_KEY["PURCHASE_INDENT_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "PURCHASE_INDENT_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch invoiced results by the approver's role ID
     *
     * @generated from enum value: PURCHASE_INDENT_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PURCHASE_INDENT_ITEM_SORT_KEY[PURCHASE_INDENT_ITEM_SORT_KEY["PURCHASE_INDENT_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PURCHASE_INDENT_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch invoiced results by the family ID
     *
     * @generated from enum value: PURCHASE_INDENT_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    PURCHASE_INDENT_ITEM_SORT_KEY[PURCHASE_INDENT_ITEM_SORT_KEY["PURCHASE_INDENT_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "PURCHASE_INDENT_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch invoiced results by the internal quantity
     *
     * @generated from enum value: PURCHASE_INDENT_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    PURCHASE_INDENT_ITEM_SORT_KEY[PURCHASE_INDENT_ITEM_SORT_KEY["PURCHASE_INDENT_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "PURCHASE_INDENT_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch ordered results by the delivery date
     *
     * @generated from enum value: PURCHASE_INDENT_ITEM_SORT_KEY_DELIVERY_DATE = 12;
     */
    PURCHASE_INDENT_ITEM_SORT_KEY[PURCHASE_INDENT_ITEM_SORT_KEY["PURCHASE_INDENT_ITEM_SORT_KEY_DELIVERY_DATE"] = 12] = "PURCHASE_INDENT_ITEM_SORT_KEY_DELIVERY_DATE";
})(PURCHASE_INDENT_ITEM_SORT_KEY || (exports.PURCHASE_INDENT_ITEM_SORT_KEY = PURCHASE_INDENT_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PURCHASE_INDENT_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PURCHASE_INDENT_ITEM_SORT_KEY, "Scailo.PURCHASE_INDENT_ITEM_SORT_KEY", [
    { no: 0, name: "PURCHASE_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PURCHASE_INDENT_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PURCHASE_INDENT_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PURCHASE_INDENT_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PURCHASE_INDENT_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PURCHASE_INDENT_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "PURCHASE_INDENT_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "PURCHASE_INDENT_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "PURCHASE_INDENT_ITEM_SORT_KEY_DELIVERY_DATE" },
]);
/**
 *
 * Describes the applicable statuses of purchase indent items
 *
 * @generated from enum Scailo.PURCHASE_INDENT_ITEM_STATUS
 */
var PURCHASE_INDENT_ITEM_STATUS;
(function (PURCHASE_INDENT_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: PURCHASE_INDENT_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    PURCHASE_INDENT_ITEM_STATUS[PURCHASE_INDENT_ITEM_STATUS["PURCHASE_INDENT_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "PURCHASE_INDENT_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the purchase indent items must have been approved
     *
     * @generated from enum value: PURCHASE_INDENT_ITEM_STATUS_APPROVED = 1;
     */
    PURCHASE_INDENT_ITEM_STATUS[PURCHASE_INDENT_ITEM_STATUS["PURCHASE_INDENT_ITEM_STATUS_APPROVED"] = 1] = "PURCHASE_INDENT_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the purchase indent items must be waiting for approval
     *
     * @generated from enum value: PURCHASE_INDENT_ITEM_STATUS_UNAPPROVED = 2;
     */
    PURCHASE_INDENT_ITEM_STATUS[PURCHASE_INDENT_ITEM_STATUS["PURCHASE_INDENT_ITEM_STATUS_UNAPPROVED"] = 2] = "PURCHASE_INDENT_ITEM_STATUS_UNAPPROVED";
})(PURCHASE_INDENT_ITEM_STATUS || (exports.PURCHASE_INDENT_ITEM_STATUS = PURCHASE_INDENT_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(PURCHASE_INDENT_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(PURCHASE_INDENT_ITEM_STATUS, "Scailo.PURCHASE_INDENT_ITEM_STATUS", [
    { no: 0, name: "PURCHASE_INDENT_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "PURCHASE_INDENT_ITEM_STATUS_APPROVED" },
    { no: 2, name: "PURCHASE_INDENT_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.PurchasesIndentsServiceCreateRequest
 */
class PurchasesIndentsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase indent
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 consignee_location_id = 12;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesIndentsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesIndentsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesIndentsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesIndentsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesIndentsServiceCreateRequest, a, b);
    }
}
exports.PurchasesIndentsServiceCreateRequest = PurchasesIndentsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.PurchasesIndentsServiceUpdateRequest
 */
class PurchasesIndentsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase indent
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesIndentsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesIndentsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesIndentsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesIndentsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesIndentsServiceUpdateRequest, a, b);
    }
}
exports.PurchasesIndentsServiceUpdateRequest = PurchasesIndentsServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.PurchasesIndentsServiceAutofillRequest
 */
class PurchasesIndentsServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The UUID of the record that needs to be updated
     *
     * @generated from field: string uuid = 2;
     */
    uuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesIndentsServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesIndentsServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesIndentsServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesIndentsServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesIndentsServiceAutofillRequest, a, b);
    }
}
exports.PurchasesIndentsServiceAutofillRequest = PurchasesIndentsServiceAutofillRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.PurchaseIndent
 */
class PurchaseIndent extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this purchase indent
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this purchase indent
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this purchase indent
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this purchase indent was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase indent
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 consignee_location_id = 12;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The list of associated purchase indent items
     *
     * @generated from field: repeated Scailo.PurchaseIndentItem list = 20;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseIndent";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "list", kind: "message", T: PurchaseIndentItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseIndent().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseIndent().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseIndent().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseIndent, a, b);
    }
}
exports.PurchaseIndent = PurchaseIndent;
/**
 *
 * Describes the parameters required to add an item to a purchase indent
 *
 * @generated from message Scailo.PurchasesIndentsServiceItemCreateRequest
 */
class PurchasesIndentsServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the purchase indent ID
     *
     * @generated from field: uint64 purchase_indent_id = 10;
     */
    purchaseIndentId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the item
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 13;
     */
    deliveryDate = "";
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesIndentsServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "purchase_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesIndentsServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesIndentsServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesIndentsServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesIndentsServiceItemCreateRequest, a, b);
    }
}
exports.PurchasesIndentsServiceItemCreateRequest = PurchasesIndentsServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to add an individual item as part of multiple item addition to a purchase indent
 *
 * @generated from message Scailo.PurchasesIndentsServiceMultipleItemsSingleton
 */
class PurchasesIndentsServiceMultipleItemsSingleton extends protobuf_1.Message {
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the item
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 13;
     */
    deliveryDate = "";
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesIndentsServiceMultipleItemsSingleton";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesIndentsServiceMultipleItemsSingleton().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesIndentsServiceMultipleItemsSingleton().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesIndentsServiceMultipleItemsSingleton().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesIndentsServiceMultipleItemsSingleton, a, b);
    }
}
exports.PurchasesIndentsServiceMultipleItemsSingleton = PurchasesIndentsServiceMultipleItemsSingleton;
/**
 *
 * Describes the parameters required to add multiple items to a purchase indent
 *
 * @generated from message Scailo.PurchasesIndentsServiceMultipleItemsCreateRequest
 */
class PurchasesIndentsServiceMultipleItemsCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the purchase indent ID
     *
     * @generated from field: uint64 purchase_indent_id = 10;
     */
    purchaseIndentId = protobuf_1.protoInt64.zero;
    /**
     * List of items
     *
     * @generated from field: repeated Scailo.PurchasesIndentsServiceMultipleItemsSingleton list = 11;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesIndentsServiceMultipleItemsCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "purchase_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "list", kind: "message", T: PurchasesIndentsServiceMultipleItemsSingleton, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesIndentsServiceMultipleItemsCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesIndentsServiceMultipleItemsCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesIndentsServiceMultipleItemsCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesIndentsServiceMultipleItemsCreateRequest, a, b);
    }
}
exports.PurchasesIndentsServiceMultipleItemsCreateRequest = PurchasesIndentsServiceMultipleItemsCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a purchase indent
 *
 * @generated from message Scailo.PurchasesIndentsServiceItemUpdateRequest
 */
class PurchasesIndentsServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the item
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 13;
     */
    deliveryDate = "";
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesIndentsServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesIndentsServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesIndentsServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesIndentsServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesIndentsServiceItemUpdateRequest, a, b);
    }
}
exports.PurchasesIndentsServiceItemUpdateRequest = PurchasesIndentsServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a purchase indent
 *
 * @generated from message Scailo.PurchaseIndentItem
 */
class PurchaseIndentItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this purchase indent
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the purchase indent ID
     *
     * @generated from field: uint64 purchase_indent_id = 10;
     */
    purchaseIndentId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity of the item
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The delivery date of the item
     *
     * @generated from field: string delivery_date = 13;
     */
    deliveryDate = "";
    /**
     * Any extra specifications with regards to the item
     *
     * @generated from field: string specifications = 14;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseIndentItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "purchase_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "delivery_date", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseIndentItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseIndentItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseIndentItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseIndentItem, a, b);
    }
}
exports.PurchaseIndentItem = PurchaseIndentItem;
/**
 *
 * Describes the message consisting of the list of purchases indents
 *
 * @generated from message Scailo.PurchasesIndentsList
 */
class PurchasesIndentsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.PurchaseIndent list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesIndentsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: PurchaseIndent, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesIndentsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesIndentsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesIndentsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesIndentsList, a, b);
    }
}
exports.PurchasesIndentsList = PurchasesIndentsList;
/**
 *
 * Describes the message consisting of the list of purchase indent items
 *
 * @generated from message Scailo.PurchasesIndentsItemsList
 */
class PurchasesIndentsItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.PurchaseIndentItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesIndentsItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: PurchaseIndentItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesIndentsItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesIndentsItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesIndentsItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesIndentsItemsList, a, b);
    }
}
exports.PurchasesIndentsItemsList = PurchasesIndentsItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.PurchaseIndentItemHistoryRequest
 */
class PurchaseIndentItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the purchase indent ID
     *
     * @generated from field: uint64 purchase_indent_id = 10;
     */
    purchaseIndentId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseIndentItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "purchase_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseIndentItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseIndentItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseIndentItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseIndentItemHistoryRequest, a, b);
    }
}
exports.PurchaseIndentItemHistoryRequest = PurchaseIndentItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective purchase indent item
 *
 * @generated from message Scailo.PurchaseIndentItemProspectiveInfoRequest
 */
class PurchaseIndentItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the purchase indent ID
     *
     * @generated from field: uint64 purchase_indent_id = 10;
     */
    purchaseIndentId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseIndentItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "purchase_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseIndentItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseIndentItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseIndentItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseIndentItemProspectiveInfoRequest, a, b);
    }
}
exports.PurchaseIndentItemProspectiveInfoRequest = PurchaseIndentItemProspectiveInfoRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.PurchasesIndentsServicePaginationReq
 */
class PurchasesIndentsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_INDENT_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_INDENT_SORT_KEY.PURCHASE_INDENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this purchase indent
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesIndentsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_INDENT_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesIndentsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesIndentsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesIndentsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesIndentsServicePaginationReq, a, b);
    }
}
exports.PurchasesIndentsServicePaginationReq = PurchasesIndentsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.PurchasesIndentsServicePaginationResponse
 */
class PurchasesIndentsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.PurchaseIndent payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesIndentsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: PurchaseIndent, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesIndentsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesIndentsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesIndentsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesIndentsServicePaginationResponse, a, b);
    }
}
exports.PurchasesIndentsServicePaginationResponse = PurchasesIndentsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.PurchasesIndentsServiceFilterReq
 */
class PurchasesIndentsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_INDENT_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_INDENT_SORT_KEY.PURCHASE_INDENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this purchase indent
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of any item in the purchase indent
     *
     * @generated from field: string delivery_date_exact = 17;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of any item in the purchase indent
     *
     * @generated from field: string delivery_date_start = 18;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of any item in the purchase indent
     *
     * @generated from field: string delivery_date_end = 19;
     */
    deliveryDateEnd = "";
    /**
     * The reference ID of the purchase indent
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 consignee_location_id = 22;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesIndentsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_INDENT_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesIndentsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesIndentsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesIndentsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesIndentsServiceFilterReq, a, b);
    }
}
exports.PurchasesIndentsServiceFilterReq = PurchasesIndentsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.PurchasesIndentsServiceCountReq
 */
class PurchasesIndentsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this purchase indent
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of any item in the purchase indent
     *
     * @generated from field: string delivery_date_exact = 17;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of any item in the purchase indent
     *
     * @generated from field: string delivery_date_start = 18;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of any item in the purchase indent
     *
     * @generated from field: string delivery_date_end = 19;
     */
    deliveryDateEnd = "";
    /**
     * The reference ID of the purchase indent
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 consignee_location_id = 22;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesIndentsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 19, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesIndentsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesIndentsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesIndentsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesIndentsServiceCountReq, a, b);
    }
}
exports.PurchasesIndentsServiceCountReq = PurchasesIndentsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.PurchasesIndentsServiceSearchAllReq
 */
class PurchasesIndentsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_INDENT_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_INDENT_SORT_KEY.PURCHASE_INDENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The ID of the location
     *
     * @generated from field: uint64 consignee_location_id = 20;
     */
    consigneeLocationId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesIndentsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_INDENT_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "consignee_location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesIndentsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesIndentsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesIndentsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesIndentsServiceSearchAllReq, a, b);
    }
}
exports.PurchasesIndentsServiceSearchAllReq = PurchasesIndentsServiceSearchAllReq;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.PurchaseIndentItemsSearchRequest
 */
class PurchaseIndentItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_INDENT_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_INDENT_ITEM_SORT_KEY.PURCHASE_INDENT_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.PURCHASE_INDENT_ITEM_STATUS status = 7;
     */
    status = PURCHASE_INDENT_ITEM_STATUS.PURCHASE_INDENT_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the purchase indent
     *
     * @generated from field: uint64 purchase_indent_id = 20;
     */
    purchaseIndentId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The exact delivery date of the item in the purchase indent
     *
     * @generated from field: string delivery_date_exact = 28;
     */
    deliveryDateExact = "";
    /**
     * The start delivery date of the item in the purchase indent
     *
     * @generated from field: string delivery_date_start = 29;
     */
    deliveryDateStart = "";
    /**
     * The end delivery date of the item in the purchase indent
     *
     * @generated from field: string delivery_date_end = 30;
     */
    deliveryDateEnd = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseIndentItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_INDENT_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_INDENT_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "purchase_indent_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 28, name: "delivery_date_exact", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 29, name: "delivery_date_start", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "delivery_date_end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseIndentItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseIndentItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseIndentItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseIndentItemsSearchRequest, a, b);
    }
}
exports.PurchaseIndentItemsSearchRequest = PurchaseIndentItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.PurchasesIndentsServicePaginatedItemsResponse
 */
class PurchasesIndentsServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.PurchaseIndentItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesIndentsServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: PurchaseIndentItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesIndentsServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesIndentsServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesIndentsServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesIndentsServicePaginatedItemsResponse, a, b);
    }
}
exports.PurchasesIndentsServicePaginatedItemsResponse = PurchasesIndentsServicePaginatedItemsResponse;
/**
 *
 * Describes the ordered statistics of the purchase indent
 *
 * @generated from message Scailo.PurchaseIndentOrderedStatistics
 */
class PurchaseIndentOrderedStatistics extends protobuf_1.Message {
    /**
     * Stores the ID of the family
     *
     * @generated from field: uint64 family_id = 1;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the indented quantity
     *
     * @generated from field: uint64 indented_quantity = 2;
     */
    indentedQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ordered quantity
     *
     * @generated from field: uint64 ordered_quantity = 3;
     */
    orderedQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseIndentOrderedStatistics";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "indented_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "ordered_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseIndentOrderedStatistics().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseIndentOrderedStatistics().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseIndentOrderedStatistics().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseIndentOrderedStatistics, a, b);
    }
}
exports.PurchaseIndentOrderedStatistics = PurchaseIndentOrderedStatistics;
/**
 *
 * Describes the list of ordered statistics of the purchase indent
 *
 * @generated from message Scailo.PurchaseIndentOrderedStatisticsList
 */
class PurchaseIndentOrderedStatisticsList extends protobuf_1.Message {
    /**
     * @generated from field: repeated Scailo.PurchaseIndentOrderedStatistics list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchaseIndentOrderedStatisticsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: PurchaseIndentOrderedStatistics, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchaseIndentOrderedStatisticsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchaseIndentOrderedStatisticsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchaseIndentOrderedStatisticsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchaseIndentOrderedStatisticsList, a, b);
    }
}
exports.PurchaseIndentOrderedStatisticsList = PurchaseIndentOrderedStatisticsList;
