"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file purchases_payments.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurchasesPaymentsServiceSearchAllReq = exports.PurchasesPaymentsServiceCountReq = exports.PurchasesPaymentsServiceFilterReq = exports.PurchasesPaymentsServicePaginationResponse = exports.PurchasesPaymentsServicePaginationReq = exports.PurchasesPaymentsList = exports.PurchasePayment = exports.PurchasePaymentAncillaryParameters = exports.PurchasesPaymentsServiceUpdateRequest = exports.PurchasesPaymentsServiceCreateRequest = exports.PURCHASE_PAYMENT_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PURCHASE_PAYMENT_SORT_KEY
 */
var PURCHASE_PAYMENT_SORT_KEY;
(function (PURCHASE_PAYMENT_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: PURCHASE_PAYMENT_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PURCHASE_PAYMENT_SORT_KEY[PURCHASE_PAYMENT_SORT_KEY["PURCHASE_PAYMENT_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PURCHASE_PAYMENT_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: PURCHASE_PAYMENT_SORT_KEY_CREATED_AT = 1;
     */
    PURCHASE_PAYMENT_SORT_KEY[PURCHASE_PAYMENT_SORT_KEY["PURCHASE_PAYMENT_SORT_KEY_CREATED_AT"] = 1] = "PURCHASE_PAYMENT_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: PURCHASE_PAYMENT_SORT_KEY_MODIFIED_AT = 2;
     */
    PURCHASE_PAYMENT_SORT_KEY[PURCHASE_PAYMENT_SORT_KEY["PURCHASE_PAYMENT_SORT_KEY_MODIFIED_AT"] = 2] = "PURCHASE_PAYMENT_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: PURCHASE_PAYMENT_SORT_KEY_APPROVED_ON = 3;
     */
    PURCHASE_PAYMENT_SORT_KEY[PURCHASE_PAYMENT_SORT_KEY["PURCHASE_PAYMENT_SORT_KEY_APPROVED_ON"] = 3] = "PURCHASE_PAYMENT_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: PURCHASE_PAYMENT_SORT_KEY_APPROVED_BY = 4;
     */
    PURCHASE_PAYMENT_SORT_KEY[PURCHASE_PAYMENT_SORT_KEY["PURCHASE_PAYMENT_SORT_KEY_APPROVED_BY"] = 4] = "PURCHASE_PAYMENT_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: PURCHASE_PAYMENT_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PURCHASE_PAYMENT_SORT_KEY[PURCHASE_PAYMENT_SORT_KEY["PURCHASE_PAYMENT_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PURCHASE_PAYMENT_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: PURCHASE_PAYMENT_SORT_KEY_COMPLETED_ON = 6;
     */
    PURCHASE_PAYMENT_SORT_KEY[PURCHASE_PAYMENT_SORT_KEY["PURCHASE_PAYMENT_SORT_KEY_COMPLETED_ON"] = 6] = "PURCHASE_PAYMENT_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: PURCHASE_PAYMENT_SORT_KEY_REFERENCE_ID = 10;
     */
    PURCHASE_PAYMENT_SORT_KEY[PURCHASE_PAYMENT_SORT_KEY["PURCHASE_PAYMENT_SORT_KEY_REFERENCE_ID"] = 10] = "PURCHASE_PAYMENT_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: PURCHASE_PAYMENT_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    PURCHASE_PAYMENT_SORT_KEY[PURCHASE_PAYMENT_SORT_KEY["PURCHASE_PAYMENT_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "PURCHASE_PAYMENT_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the payment timestamp
     *
     * @generated from enum value: PURCHASE_PAYMENT_SORT_KEY_PAYMENT_TIMESTAMP = 12;
     */
    PURCHASE_PAYMENT_SORT_KEY[PURCHASE_PAYMENT_SORT_KEY["PURCHASE_PAYMENT_SORT_KEY_PAYMENT_TIMESTAMP"] = 12] = "PURCHASE_PAYMENT_SORT_KEY_PAYMENT_TIMESTAMP";
})(PURCHASE_PAYMENT_SORT_KEY || (exports.PURCHASE_PAYMENT_SORT_KEY = PURCHASE_PAYMENT_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PURCHASE_PAYMENT_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PURCHASE_PAYMENT_SORT_KEY, "Scailo.PURCHASE_PAYMENT_SORT_KEY", [
    { no: 0, name: "PURCHASE_PAYMENT_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PURCHASE_PAYMENT_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PURCHASE_PAYMENT_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PURCHASE_PAYMENT_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PURCHASE_PAYMENT_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PURCHASE_PAYMENT_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "PURCHASE_PAYMENT_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "PURCHASE_PAYMENT_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "PURCHASE_PAYMENT_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 12, name: "PURCHASE_PAYMENT_SORT_KEY_PAYMENT_TIMESTAMP" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.PurchasesPaymentsServiceCreateRequest
 */
class PurchasesPaymentsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase payment
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The reference on which the purchase payment has been created
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The ID of the associated reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated bank account
     *
     * @generated from field: uint64 bank_account_id = 15;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 16;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The type of the transaction
     *
     * @generated from field: string transaction_type = 17;
     */
    transactionType = "";
    /**
     * The initial amount in cents, without any deductions
     *
     * @generated from field: uint64 amount_base = 18;
     */
    amountBase = protobuf_1.protoInt64.zero;
    /**
     * The final amount in cents, after all the deductions
     *
     * @generated from field: uint64 amount_net = 19;
     */
    amountNet = protobuf_1.protoInt64.zero;
    /**
     * The date & time of when the payment needs to be made
     *
     * @generated from field: uint64 payment_timestamp = 20;
     */
    paymentTimestamp = protobuf_1.protoInt64.zero;
    /**
     * The description of the purchase payment
     *
     * @generated from field: string description = 21;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesPaymentsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "bank_account_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "transaction_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "amount_base", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "amount_net", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "payment_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesPaymentsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesPaymentsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesPaymentsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesPaymentsServiceCreateRequest, a, b);
    }
}
exports.PurchasesPaymentsServiceCreateRequest = PurchasesPaymentsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.PurchasesPaymentsServiceUpdateRequest
 */
class PurchasesPaymentsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase payment
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The ID of the associated bank account
     *
     * @generated from field: uint64 bank_account_id = 15;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 16;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The type of the transaction
     *
     * @generated from field: string transaction_type = 17;
     */
    transactionType = "";
    /**
     * The initial amount in cents, without any deductions
     *
     * @generated from field: uint64 amount_base = 18;
     */
    amountBase = protobuf_1.protoInt64.zero;
    /**
     * The final amount in cents, after all the deductions
     *
     * @generated from field: uint64 amount_net = 19;
     */
    amountNet = protobuf_1.protoInt64.zero;
    /**
     * The date & time of when the payment needs to be made
     *
     * @generated from field: uint64 payment_timestamp = 20;
     */
    paymentTimestamp = protobuf_1.protoInt64.zero;
    /**
     * The description of the purchase payment
     *
     * @generated from field: string description = 21;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesPaymentsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "bank_account_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "transaction_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "amount_base", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "amount_net", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "payment_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesPaymentsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesPaymentsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesPaymentsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesPaymentsServiceUpdateRequest, a, b);
    }
}
exports.PurchasesPaymentsServiceUpdateRequest = PurchasesPaymentsServiceUpdateRequest;
/**
 *
 * Stores the UUID references of the record
 *
 * @generated from message Scailo.PurchasePaymentAncillaryParameters
 */
class PurchasePaymentAncillaryParameters extends protobuf_1.Message {
    /**
     * The UUID of the ref_id (the UUID of the associated ref_id)
     *
     * @generated from field: string ref_uuid = 213;
     */
    refUuid = "";
    /**
     * The UUID of the vendor (the UUID of the associated vendor_id)
     *
     * @generated from field: string vendor_uuid = 214;
     */
    vendorUuid = "";
    /**
     * The UUID of the bank account (the UUID of the bank_account_id)
     *
     * @generated from field: string bank_account_uuid = 215;
     */
    bankAccountUuid = "";
    /**
     * The UUID of the currency (the UUID of the associated currency)
     *
     * @generated from field: string currency_uuid = 216;
     */
    currencyUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasePaymentAncillaryParameters";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 213, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 214, name: "vendor_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 215, name: "bank_account_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 216, name: "currency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasePaymentAncillaryParameters().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasePaymentAncillaryParameters().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasePaymentAncillaryParameters().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasePaymentAncillaryParameters, a, b);
    }
}
exports.PurchasePaymentAncillaryParameters = PurchasePaymentAncillaryParameters;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.PurchasePayment
 */
class PurchasePayment extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this purchase payment
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this purchase payment
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this purchase payment
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this purchase payment was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the purchase payment
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The reference on which the purchase payment has been created
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The ID of the associated reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 14;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated bank account
     *
     * @generated from field: uint64 bank_account_id = 15;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 16;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The type of the transaction
     *
     * @generated from field: string transaction_type = 17;
     */
    transactionType = "";
    /**
     * The initial amount in cents, without any deductions
     *
     * @generated from field: uint64 amount_base = 18;
     */
    amountBase = protobuf_1.protoInt64.zero;
    /**
     * The final amount in cents, after all the deductions
     *
     * @generated from field: uint64 amount_net = 19;
     */
    amountNet = protobuf_1.protoInt64.zero;
    /**
     * The date & time of when the payment needs to be made
     *
     * @generated from field: uint64 payment_timestamp = 20;
     */
    paymentTimestamp = protobuf_1.protoInt64.zero;
    /**
     * The description of the purchase payment
     *
     * @generated from field: string description = 21;
     */
    description = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasePayment";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "bank_account_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "transaction_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 18, name: "amount_base", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "amount_net", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "payment_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasePayment().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasePayment().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasePayment().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasePayment, a, b);
    }
}
exports.PurchasePayment = PurchasePayment;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.PurchasesPaymentsList
 */
class PurchasesPaymentsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.PurchasePayment list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesPaymentsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: PurchasePayment, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesPaymentsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesPaymentsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesPaymentsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesPaymentsList, a, b);
    }
}
exports.PurchasesPaymentsList = PurchasesPaymentsList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.PurchasesPaymentsServicePaginationReq
 */
class PurchasesPaymentsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_PAYMENT_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_PAYMENT_SORT_KEY.PURCHASE_PAYMENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this purchase payment
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesPaymentsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_PAYMENT_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesPaymentsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesPaymentsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesPaymentsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesPaymentsServicePaginationReq, a, b);
    }
}
exports.PurchasesPaymentsServicePaginationReq = PurchasesPaymentsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.PurchasesPaymentsServicePaginationResponse
 */
class PurchasesPaymentsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.PurchasePayment payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesPaymentsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: PurchasePayment, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesPaymentsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesPaymentsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesPaymentsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesPaymentsServicePaginationResponse, a, b);
    }
}
exports.PurchasesPaymentsServicePaginationResponse = PurchasesPaymentsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.PurchasesPaymentsServiceFilterReq
 */
class PurchasesPaymentsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_PAYMENT_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_PAYMENT_SORT_KEY.PURCHASE_PAYMENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this purchase payment
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference_id of the purchase payment
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The reference on which the purchase payment has been created
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The ID of the associated reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 24;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated bank account
     *
     * @generated from field: uint64 bank_account_id = 25;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 26;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The type of the transaction
     *
     * @generated from field: string transaction_type = 27;
     */
    transactionType = "";
    /**
     * The start range of the payment timestamp
     *
     * @generated from field: uint64 payment_timestamp_start = 28;
     */
    paymentTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of the payment timestamp
     *
     * @generated from field: uint64 payment_timestamp_end = 29;
     */
    paymentTimestampEnd = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesPaymentsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_PAYMENT_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "bank_account_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "transaction_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 28, name: "payment_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 29, name: "payment_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesPaymentsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesPaymentsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesPaymentsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesPaymentsServiceFilterReq, a, b);
    }
}
exports.PurchasesPaymentsServiceFilterReq = PurchasesPaymentsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.PurchasesPaymentsServiceCountReq
 */
class PurchasesPaymentsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this purchase payment
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference_id of the purchase payment
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The reference on which the purchase payment has been created
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The ID of the associated reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 24;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated bank account
     *
     * @generated from field: uint64 bank_account_id = 25;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 26;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The type of the transaction
     *
     * @generated from field: string transaction_type = 27;
     */
    transactionType = "";
    /**
     * The start range of the payment timestamp
     *
     * @generated from field: uint64 payment_timestamp_start = 28;
     */
    paymentTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of the payment timestamp
     *
     * @generated from field: uint64 payment_timestamp_end = 29;
     */
    paymentTimestampEnd = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesPaymentsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "bank_account_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 27, name: "transaction_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 28, name: "payment_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 29, name: "payment_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesPaymentsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesPaymentsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesPaymentsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesPaymentsServiceCountReq, a, b);
    }
}
exports.PurchasesPaymentsServiceCountReq = PurchasesPaymentsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.PurchasesPaymentsServiceSearchAllReq
 */
class PurchasesPaymentsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PURCHASE_PAYMENT_SORT_KEY sort_key = 5;
     */
    sortKey = PURCHASE_PAYMENT_SORT_KEY.PURCHASE_PAYMENT_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The ID of the associated vendor
     *
     * @generated from field: uint64 vendor_id = 25;
     */
    vendorId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.PurchasesPaymentsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PURCHASE_PAYMENT_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 25, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new PurchasesPaymentsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PurchasesPaymentsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PurchasesPaymentsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(PurchasesPaymentsServiceSearchAllReq, a, b);
    }
}
exports.PurchasesPaymentsServiceSearchAllReq = PurchasesPaymentsServiceSearchAllReq;
