import { workOrderFiltersMenu, workOrderIndividualMenu, workOrderInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleWorkOrderFilters } from "./workorders.filters";
import { handleWorkOrderInsights } from "./workorders.insights";
import { handleIndividualWorkOrder } from "./individual";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(workOrderFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, workOrderFiltersMenu)) {
            handleWorkOrderFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(workOrderInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, workOrderInsightsMenu)) {
            handleWorkOrderInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(workOrderIndividualMenu.href, async (ctx) => {
        if (await interceptPage(ctx, workOrderFiltersMenu)) {
            handleIndividualWorkOrder(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}