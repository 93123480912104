import { PartialMessage, protoInt64 } from '@bufbuild/protobuf';
import { PromiseClient } from '@connectrpc/connect';
import { BOOL_FILTER, CREDIT_NOTE_SORT_KEY, CreditNote, CreditNotesService, CreditNotesServiceSearchAllReq, CurrenciesService, CurrenciesServiceSearchAllReq, Currency, CURRENCY_SORT_KEY, EmployeeMetadata, GOODS_DISPATCH_SORT_KEY, GoodsDispatch, GoodsDispatchesService, GoodsDispatchesServiceSearchAllReq, INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY, INWARD_JOB_FREE_ISSUE_MATERIAL_SORT_KEY, INWARD_JOB_SORT_KEY, InwardJob, InwardJobFreeIssueMaterial, InwardJobFreeIssueMaterialReturn, InwardJobsFreeIssueMaterialsReturnsService, InwardJobsFreeIssueMaterialsReturnsServiceSearchAllReq, InwardJobsFreeIssueMaterialsService, InwardJobsFreeIssueMaterialsServiceSearchAllReq, InwardJobsService, InwardJobsServiceSearchAllReq, Location, LOCATION_SORT_KEY, LocationsService, LocationsServiceSearchAllReq, PRODUCTION_PLAN_SORT_KEY, ProductionPlan, ProductionPlansService, ProductionPlansServiceSearchAllReq, PROFORMA_INVOICE_SORT_KEY, ProformaInvoice, ProformaInvoicesService, ProformaInvoicesServiceSearchAllReq, QC_SAMPLE_LIFECYCLE, QC_SAMPLE_SORT_KEY, QCSample, QCSamplesService, QCSamplesServiceSearchAllReq, QCSampleWithMetadata, SALE_RECEIPT_SORT_KEY, SaleReceipt, SALES_ENQUIRY_SORT_KEY, SALES_INVOICE_SORT_KEY, SALES_ORDER_SORT_KEY, SALES_QUOTATION_SORT_KEY, SALES_RETURN_SORT_KEY, SalesEnquiriesService, SalesEnquiriesServiceSearchAllReq, SalesEnquiry, SalesInvoice, SalesInvoicesService, SalesInvoicesServiceSearchAllReq, SalesOrder, SalesOrdersService, SalesOrdersServiceSearchAllReq, SalesQuotation, SalesQuotationsService, SalesQuotationsServiceSearchAllReq, SalesReceiptsService, SalesReceiptsServiceSearchAllReq, SalesReturn, SalesReturnsService, SalesReturnsServiceSearchAllReq, SORT_ORDER, STANDARD_LIFECYCLE_STATUS, WORK_ORDER_SORT_KEY, WorkOrder, WorkOrdersService, WorkOrdersServiceSearchAllReq } from '@kernelminds/scailo-sdk';
import Choices, { Options } from 'choices.js';

function choicesOpts(): Partial<Options> {
    return {
        removeItemButton: true,
        itemSelectText: 'Select',
        searchFloor: 1,
        searchResultLimit: -1,
        noChoicesText: 'Kindly enter a search key',
    }
}

export function setupSalesOrderSearchable(elementId: string, accessClient: PromiseClient<typeof SalesOrdersService>, filter: PartialMessage<SalesOrdersServiceSearchAllReq>) {
    let salesOrderReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(salesOrderReference, choicesOpts());
    salesOrderReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: SALES_ORDER_SORT_KEY.SALES_ORDER_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new SalesOrder({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupSalesInvoiceSearchable(elementId: string, accessClient: PromiseClient<typeof SalesInvoicesService>, filter: PartialMessage<SalesInvoicesServiceSearchAllReq>) {
    let salesInvoiceReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(salesInvoiceReference, choicesOpts());
    salesInvoiceReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: SALES_INVOICE_SORT_KEY.SALES_INVOICE_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new SalesInvoice({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupProformaInvoiceSearchable(elementId: string, accessClient: PromiseClient<typeof ProformaInvoicesService>, filter: PartialMessage<ProformaInvoicesServiceSearchAllReq>) {
    let proformaInvoiceReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(proformaInvoiceReference, choicesOpts());
    proformaInvoiceReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: PROFORMA_INVOICE_SORT_KEY.PROFORMA_INVOICE_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new ProformaInvoice({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupSalesReturnSearchable(elementId: string, accessClient: PromiseClient<typeof SalesReturnsService>, filter: PartialMessage<SalesReturnsServiceSearchAllReq>) {
    let salesReturnReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(salesReturnReference, choicesOpts());
    salesReturnReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: SALES_RETURN_SORT_KEY.SALES_RETURN_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new SalesReturn({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupGoodsDispatchSearchable(elementId: string, accessClient: PromiseClient<typeof GoodsDispatchesService>, filter: PartialMessage<GoodsDispatchesServiceSearchAllReq>) {
    let goodsDispatchReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(goodsDispatchReference, choicesOpts());
    goodsDispatchReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: GOODS_DISPATCH_SORT_KEY.GOODS_DISPATCH_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new GoodsDispatch({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupWorkOrderSearchable(elementId: string, accessClient: PromiseClient<typeof WorkOrdersService>, filter: PartialMessage<WorkOrdersServiceSearchAllReq>) {
    let workOrderReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(workOrderReference, choicesOpts());
    workOrderReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: WORK_ORDER_SORT_KEY.WORK_ORDER_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new WorkOrder({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupInwardJobSearchable(elementId: string, accessClient: PromiseClient<typeof InwardJobsService>, filter: PartialMessage<InwardJobsServiceSearchAllReq>) {
    let inwardJobReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(inwardJobReference, choicesOpts());
    inwardJobReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: INWARD_JOB_SORT_KEY.INWARD_JOB_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new InwardJob({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupInwardJobFreeIssueMaterialSearchable(elementId: string, accessClient: PromiseClient<typeof InwardJobsFreeIssueMaterialsService>, filter: PartialMessage<InwardJobsFreeIssueMaterialsServiceSearchAllReq>) {
    let inwardJobFreeIssueMaterialReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(inwardJobFreeIssueMaterialReference, choicesOpts());
    inwardJobFreeIssueMaterialReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: INWARD_JOB_FREE_ISSUE_MATERIAL_SORT_KEY.INWARD_JOB_FREE_ISSUE_MATERIAL_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new InwardJobFreeIssueMaterial({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupInwardJobFreeIssueMaterialReturnSearchable(elementId: string, accessClient: PromiseClient<typeof InwardJobsFreeIssueMaterialsReturnsService>, filter: PartialMessage<InwardJobsFreeIssueMaterialsReturnsServiceSearchAllReq>) {
    let inwardJobFreeIssueMaterialReturnReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(inwardJobFreeIssueMaterialReturnReference, choicesOpts());
    inwardJobFreeIssueMaterialReturnReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new InwardJobFreeIssueMaterialReturn({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupCreditNoteSearchable(elementId: string, accessClient: PromiseClient<typeof CreditNotesService>, filter: PartialMessage<CreditNotesServiceSearchAllReq>) {
    let creditNoteReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(creditNoteReference, choicesOpts());
    creditNoteReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: CREDIT_NOTE_SORT_KEY.CREDIT_NOTE_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new CreditNote({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupSalesReceiptSearchable(elementId: string, accessClient: PromiseClient<typeof SalesReceiptsService>, filter: PartialMessage<SalesReceiptsServiceSearchAllReq>) {
    let salesReceiptReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(salesReceiptReference, choicesOpts());
    salesReceiptReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: SALE_RECEIPT_SORT_KEY.SALE_RECEIPT_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new SaleReceipt({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupProductionPlanSearchable(elementId: string, accessClient: PromiseClient<typeof ProductionPlansService>, filter: PartialMessage<ProductionPlansServiceSearchAllReq>) {
    let productionPlanReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(productionPlanReference, choicesOpts());
    productionPlanReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: PRODUCTION_PLAN_SORT_KEY.PRODUCTION_PLAN_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new ProductionPlan({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupQCSampleSearchable(elementId: string, accessClient: PromiseClient<typeof QCSamplesService>, filter: PartialMessage<QCSamplesServiceSearchAllReq>) {
    let qcSampleReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(qcSampleReference, choicesOpts());
    qcSampleReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: QC_SAMPLE_SORT_KEY.QC_SAMPLE_SORT_KEY_MODIFIED_AT,
            status: QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_ANY_UNSPECIFIED,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new QCSampleWithMetadata({ qcSample: new QCSample({ metadata: new EmployeeMetadata({ id: protoInt64.zero }) }) }));

        choice.setChoices(results.list.map(r => {
            return { value: r.qcSample?.metadata?.id, label: `${r.qcSample?.name}`, disabled: false };
        }));
    });
}

export function setupSalesQuotationSearchable(elementId: string, accessClient: PromiseClient<typeof SalesQuotationsService>, filter: PartialMessage<SalesQuotationsServiceSearchAllReq>) {
    let salesQuotationReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(salesQuotationReference, choicesOpts());
    salesQuotationReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: SALES_QUOTATION_SORT_KEY.SALES_QUOTATION_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new SalesQuotation({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupSalesEnquirySearchable(elementId: string, accessClient: PromiseClient<typeof SalesEnquiriesService>, filter: PartialMessage<SalesEnquiriesServiceSearchAllReq>) {
    let salesEnquiryReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(salesEnquiryReference, choicesOpts());
    salesEnquiryReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;

        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(10),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: SALES_ENQUIRY_SORT_KEY.SALES_ENQUIRY_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new SalesEnquiry({ metadata: new EmployeeMetadata({ id: protoInt64.zero }), referenceId: "", finalRefNumber: "" }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.approvalMetadata?.approvedOn! > 0 ? r.finalRefNumber : r.referenceId}`, disabled: false };
        }));
    });
}

export function setupFamilySearchable(elementId: string, onSearch: (searchKey: string, choice: Choices) => void, onSelect: (value: string) => void) {
    let familyReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(familyReference, choicesOpts());
    familyReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;
        onSearch(searchKey, choice);
    });
    familyReference.addEventListener('choice', async (e) => {
        const selectedValue = (<any>e).detail.value;
        onSelect(selectedValue);
    });

    // Trigger the first default value
    onSearch('', choice);
}

export function setupCurrencySearchable(elementId: string, accessClient: PromiseClient<typeof CurrenciesService>, filter: PartialMessage<CurrenciesServiceSearchAllReq>) {
    let currencyReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(currencyReference, choicesOpts());
    currencyReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;
        
        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(-1),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: CURRENCY_SORT_KEY.CURRENCY_SORT_KEY_MODIFIED_AT,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new Currency({ metadata: new EmployeeMetadata({ id: protoInt64.zero }) }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.symbol.length > 0 ? `(${r.symbol})` : ''} ${r.name}`, disabled: false };
        }));
    });

    currencyReference.dispatchEvent(new CustomEvent('search', { detail: { value: '' } }));
}

export function setupLocationSearchable(elementId: string, accessClient: PromiseClient<typeof LocationsService>, filter: PartialMessage<LocationsServiceSearchAllReq>) {
    let locationReference = document.getElementById(elementId) as HTMLInputElement;
    let choice = new Choices(locationReference, choicesOpts());
    locationReference.addEventListener('search', async (e) => {
        const searchKey = (<any>e).detail.value;
        
        let results = await accessClient.searchAll({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(-1),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: LOCATION_SORT_KEY.LOCATION_SORT_KEY_NAME,
            status: STANDARD_LIFECYCLE_STATUS.STANDING,
            searchKey: searchKey,
            ...filter,
        });

        results.list.unshift(new Location({ metadata: new EmployeeMetadata({ id: protoInt64.zero }) }));

        choice.setChoices(results.list.map(r => {
            return { value: r.metadata?.id, label: `${r.code.length > 0 ? `(${r.code}) ` : ''} ${r.name}`, disabled: false };
        }));
    });

    locationReference.dispatchEvent(new CustomEvent('search', { detail: { value: '' } }));
}