"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file inward_jobs_free_issue_materials_returns.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.InwardJobsFreeIssueMaterialsReturnsServiceSearchAllReq = exports.InwardJobsFreeIssueMaterialsReturnsServiceCountReq = exports.InwardJobsFreeIssueMaterialsReturnsServiceFilterReq = exports.InwardJobsFreeIssueMaterialsReturnsServicePaginationResponse = exports.InwardJobsFreeIssueMaterialsReturnsServicePaginationReq = exports.InwardJobsFreeIssueMaterialsReturnsServiceAlreadyAddedQuantityForSourceRequest = exports.InwardJobsFreeIssueMaterialsReturnsServicePaginatedItemsResponse = exports.InwardJobFreeIssueMaterialReturnItemsSearchRequest = exports.InwardJobFreeIssueMaterialReturnItemProspectiveInfoRequest = exports.InwardJobFreeIssueMaterialReturnItemHistoryRequest = exports.InwardJobsFreeIssueMaterialsReturnsItemsList = exports.InwardJobsFreeIssueMaterialsReturnsList = exports.InwardJobFreeIssueMaterialReturnItem = exports.InwardJobsFreeIssueMaterialsReturnsServiceItemUpdateRequest = exports.InwardJobsFreeIssueMaterialsReturnsServiceItemCreateRequest = exports.InwardJobFreeIssueMaterialReturn = exports.InwardJobFreeIssueMaterialReturnAncillaryParameters = exports.InwardJobsFreeIssueMaterialsReturnsServiceUpdateRequest = exports.InwardJobsFreeIssueMaterialsReturnsServiceCreateRequest = exports.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY = exports.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS = exports.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY
 */
var INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY;
(function (INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_CREATED_AT"] = 1] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the family ID
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch ordered results by the internal quantity
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch ordered results by the client unit of material ID
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_CLIENT_UOM_ID = 12;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_CLIENT_UOM_ID"] = 12] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_CLIENT_UOM_ID";
    /**
     * Fetch ordered results by the client quantity
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_CLIENT_QUANTITY = 13;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_CLIENT_QUANTITY"] = 13] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_CLIENT_QUANTITY";
    /**
     * Fetch ordered results by the client family code
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_CLIENT_FAMILY_CODE = 14;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_CLIENT_FAMILY_CODE"] = 14] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_CLIENT_FAMILY_CODE";
})(INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY || (exports.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY = INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY, "Scailo.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY", [
    { no: 0, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_CLIENT_UOM_ID" },
    { no: 13, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_CLIENT_QUANTITY" },
    { no: 14, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_CLIENT_FAMILY_CODE" },
]);
/**
 *
 * Describes the applicable statuses of inward job free issue material return items
 *
 * @generated from enum Scailo.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS
 */
var INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS;
(function (INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the inward job free issue material return items must have been approved
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS_APPROVED = 1;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS_APPROVED"] = 1] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the inward job free issue material return items must be waiting for approval
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS_UNAPPROVED = 2;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS_UNAPPROVED"] = 2] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS_UNAPPROVED";
})(INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS || (exports.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS = INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS, "Scailo.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS", [
    { no: 0, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS_APPROVED" },
    { no: 2, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY
 */
var INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY;
(function (INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_ID_UNSPECIFIED"] = 0] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_CREATED_AT = 1;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_CREATED_AT"] = 1] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_MODIFIED_AT = 2;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_MODIFIED_AT"] = 2] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_APPROVED_ON = 3;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_APPROVED_ON"] = 3] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_APPROVED_BY = 4;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_APPROVED_BY"] = 4] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_COMPLETED_ON = 6;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_COMPLETED_ON"] = 6] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_REFERENCE_ID = 10;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_REFERENCE_ID"] = 10] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY[INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY["INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_FINAL_REF_NUMBER";
})(INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY || (exports.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY = INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY)
protobuf_1.proto3.util.setEnumType(INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY, "Scailo.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY", [
    { no: 0, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_CREATED_AT" },
    { no: 2, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_FINAL_REF_NUMBER" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.InwardJobsFreeIssueMaterialsReturnsServiceCreateRequest
 */
class InwardJobsFreeIssueMaterialsReturnsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the inward job free issue material return
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 15;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsFreeIssueMaterialsReturnsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsFreeIssueMaterialsReturnsServiceCreateRequest, a, b);
    }
}
exports.InwardJobsFreeIssueMaterialsReturnsServiceCreateRequest = InwardJobsFreeIssueMaterialsReturnsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.InwardJobsFreeIssueMaterialsReturnsServiceUpdateRequest
 */
class InwardJobsFreeIssueMaterialsReturnsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the inward job free issue material return
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsFreeIssueMaterialsReturnsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsFreeIssueMaterialsReturnsServiceUpdateRequest, a, b);
    }
}
exports.InwardJobsFreeIssueMaterialsReturnsServiceUpdateRequest = InwardJobsFreeIssueMaterialsReturnsServiceUpdateRequest;
/**
 *
 * Stores the UUID references of the record
 *
 * @generated from message Scailo.InwardJobFreeIssueMaterialReturnAncillaryParameters
 */
class InwardJobFreeIssueMaterialReturnAncillaryParameters extends protobuf_1.Message {
    /**
     * The UUID of the ref_id (the UUID of the associated ref_id)
     *
     * @generated from field: string ref_uuid = 213;
     */
    refUuid = "";
    /**
     * The UUID of the location (the UUID of the associated location_id)
     *
     * @generated from field: string location_uuid = 215;
     */
    locationUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobFreeIssueMaterialReturnAncillaryParameters";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 213, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 215, name: "location_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobFreeIssueMaterialReturnAncillaryParameters().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobFreeIssueMaterialReturnAncillaryParameters().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobFreeIssueMaterialReturnAncillaryParameters().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobFreeIssueMaterialReturnAncillaryParameters, a, b);
    }
}
exports.InwardJobFreeIssueMaterialReturnAncillaryParameters = InwardJobFreeIssueMaterialReturnAncillaryParameters;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.InwardJobFreeIssueMaterialReturn
 */
class InwardJobFreeIssueMaterialReturn extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this inward job free issue material return
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this inward job free issue material return
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this inward job free issue material return
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this inward job free issue material return was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the inward job free issue material return
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 15;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The list of associated inward job free issue material return items
     *
     * @generated from field: repeated Scailo.InwardJobFreeIssueMaterialReturnItem list = 20;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobFreeIssueMaterialReturn";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "list", kind: "message", T: InwardJobFreeIssueMaterialReturnItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobFreeIssueMaterialReturn().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobFreeIssueMaterialReturn().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobFreeIssueMaterialReturn().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobFreeIssueMaterialReturn, a, b);
    }
}
exports.InwardJobFreeIssueMaterialReturn = InwardJobFreeIssueMaterialReturn;
/**
 *
 * Describes the parameters required to add an item to a inward job free issue material return
 *
 * @generated from message Scailo.InwardJobsFreeIssueMaterialsReturnsServiceItemCreateRequest
 */
class InwardJobsFreeIssueMaterialsReturnsServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the inward job free issue material return ID
     *
     * @generated from field: uint64 inward_job_free_issue_material_return_id = 10;
     */
    inwardJobFreeIssueMaterialReturnId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash to be dispatched
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The quantity (in cents) being dispatched in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 14;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being dispatched in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 15;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as defined by the client
     *
     * @generated from field: string client_family_code = 16;
     */
    clientFamilyCode = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsFreeIssueMaterialsReturnsServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "inward_job_free_issue_material_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsFreeIssueMaterialsReturnsServiceItemCreateRequest, a, b);
    }
}
exports.InwardJobsFreeIssueMaterialsReturnsServiceItemCreateRequest = InwardJobsFreeIssueMaterialsReturnsServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a inward job free issue material return
 *
 * @generated from message Scailo.InwardJobsFreeIssueMaterialsReturnsServiceItemUpdateRequest
 */
class InwardJobsFreeIssueMaterialsReturnsServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash to be dispatched
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The quantity (in cents) being dispatched in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 14;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being dispatched in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 15;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as defined by the client
     *
     * @generated from field: string client_family_code = 16;
     */
    clientFamilyCode = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsFreeIssueMaterialsReturnsServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsFreeIssueMaterialsReturnsServiceItemUpdateRequest, a, b);
    }
}
exports.InwardJobsFreeIssueMaterialsReturnsServiceItemUpdateRequest = InwardJobsFreeIssueMaterialsReturnsServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a inward job free issue material return
 *
 * @generated from message Scailo.InwardJobFreeIssueMaterialReturnItem
 */
class InwardJobFreeIssueMaterialReturnItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this inward job free issue material return
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the inward job free issue material return ID
     *
     * @generated from field: uint64 inward_job_free_issue_material_return_id = 10;
     */
    inwardJobFreeIssueMaterialReturnId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash to be dispatched
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The quantity (in cents) being dispatched in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 14;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being dispatched in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 15;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as defined by the client
     *
     * @generated from field: string client_family_code = 16;
     */
    clientFamilyCode = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobFreeIssueMaterialReturnItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "inward_job_free_issue_material_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobFreeIssueMaterialReturnItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobFreeIssueMaterialReturnItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobFreeIssueMaterialReturnItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobFreeIssueMaterialReturnItem, a, b);
    }
}
exports.InwardJobFreeIssueMaterialReturnItem = InwardJobFreeIssueMaterialReturnItem;
/**
 *
 * Describes the message consisting of the list of inward job free issue material returns
 *
 * @generated from message Scailo.InwardJobsFreeIssueMaterialsReturnsList
 */
class InwardJobsFreeIssueMaterialsReturnsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.InwardJobFreeIssueMaterialReturn list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsFreeIssueMaterialsReturnsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: InwardJobFreeIssueMaterialReturn, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsFreeIssueMaterialsReturnsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsFreeIssueMaterialsReturnsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsFreeIssueMaterialsReturnsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsFreeIssueMaterialsReturnsList, a, b);
    }
}
exports.InwardJobsFreeIssueMaterialsReturnsList = InwardJobsFreeIssueMaterialsReturnsList;
/**
 *
 * Describes the message consisting of the list of inward job free issue material return items
 *
 * @generated from message Scailo.InwardJobsFreeIssueMaterialsReturnsItemsList
 */
class InwardJobsFreeIssueMaterialsReturnsItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.InwardJobFreeIssueMaterialReturnItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsFreeIssueMaterialsReturnsItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: InwardJobFreeIssueMaterialReturnItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsFreeIssueMaterialsReturnsItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsFreeIssueMaterialsReturnsItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsFreeIssueMaterialsReturnsItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsFreeIssueMaterialsReturnsItemsList, a, b);
    }
}
exports.InwardJobsFreeIssueMaterialsReturnsItemsList = InwardJobsFreeIssueMaterialsReturnsItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.InwardJobFreeIssueMaterialReturnItemHistoryRequest
 */
class InwardJobFreeIssueMaterialReturnItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the inward job free issue material return ID
     *
     * @generated from field: uint64 inward_job_free_issue_material_return_id = 10;
     */
    inwardJobFreeIssueMaterialReturnId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobFreeIssueMaterialReturnItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "inward_job_free_issue_material_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobFreeIssueMaterialReturnItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobFreeIssueMaterialReturnItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobFreeIssueMaterialReturnItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobFreeIssueMaterialReturnItemHistoryRequest, a, b);
    }
}
exports.InwardJobFreeIssueMaterialReturnItemHistoryRequest = InwardJobFreeIssueMaterialReturnItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective inward job free issue material return item
 *
 * @generated from message Scailo.InwardJobFreeIssueMaterialReturnItemProspectiveInfoRequest
 */
class InwardJobFreeIssueMaterialReturnItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the inward job free issue material return ID
     *
     * @generated from field: uint64 inward_job_free_issue_material_return_id = 10;
     */
    inwardJobFreeIssueMaterialReturnId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobFreeIssueMaterialReturnItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "inward_job_free_issue_material_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobFreeIssueMaterialReturnItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobFreeIssueMaterialReturnItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobFreeIssueMaterialReturnItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobFreeIssueMaterialReturnItemProspectiveInfoRequest, a, b);
    }
}
exports.InwardJobFreeIssueMaterialReturnItemProspectiveInfoRequest = InwardJobFreeIssueMaterialReturnItemProspectiveInfoRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.InwardJobFreeIssueMaterialReturnItemsSearchRequest
 */
class InwardJobFreeIssueMaterialReturnItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS status = 7;
     */
    status = INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the inward job free issue material return
     *
     * @generated from field: uint64 inward_job_free_issue_material_return_id = 20;
     */
    inwardJobFreeIssueMaterialReturnId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 22;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash to be dispatched
     *
     * @generated from field: string item_hash = 23;
     */
    itemHash = "";
    /**
     * The ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 24;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 26;
     */
    clientFamilyCode = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobFreeIssueMaterialReturnItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "inward_job_free_issue_material_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobFreeIssueMaterialReturnItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobFreeIssueMaterialReturnItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobFreeIssueMaterialReturnItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobFreeIssueMaterialReturnItemsSearchRequest, a, b);
    }
}
exports.InwardJobFreeIssueMaterialReturnItemsSearchRequest = InwardJobFreeIssueMaterialReturnItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.InwardJobsFreeIssueMaterialsReturnsServicePaginatedItemsResponse
 */
class InwardJobsFreeIssueMaterialsReturnsServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.InwardJobFreeIssueMaterialReturnItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsFreeIssueMaterialsReturnsServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: InwardJobFreeIssueMaterialReturnItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsFreeIssueMaterialsReturnsServicePaginatedItemsResponse, a, b);
    }
}
exports.InwardJobsFreeIssueMaterialsReturnsServicePaginatedItemsResponse = InwardJobsFreeIssueMaterialsReturnsServicePaginatedItemsResponse;
/**
 *
 * Describes the request payload to retrieve the quantity that has already been added for the specific ref_from, ref_id and family_id
 *
 * @generated from message Scailo.InwardJobsFreeIssueMaterialsReturnsServiceAlreadyAddedQuantityForSourceRequest
 */
class InwardJobsFreeIssueMaterialsReturnsServiceAlreadyAddedQuantityForSourceRequest extends protobuf_1.Message {
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 1;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 2;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated family ID
     *
     * @generated from field: uint64 family_id = 3;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsFreeIssueMaterialsReturnsServiceAlreadyAddedQuantityForSourceRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceAlreadyAddedQuantityForSourceRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceAlreadyAddedQuantityForSourceRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceAlreadyAddedQuantityForSourceRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsFreeIssueMaterialsReturnsServiceAlreadyAddedQuantityForSourceRequest, a, b);
    }
}
exports.InwardJobsFreeIssueMaterialsReturnsServiceAlreadyAddedQuantityForSourceRequest = InwardJobsFreeIssueMaterialsReturnsServiceAlreadyAddedQuantityForSourceRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.InwardJobsFreeIssueMaterialsReturnsServicePaginationReq
 */
class InwardJobsFreeIssueMaterialsReturnsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY sort_key = 5;
     */
    sortKey = INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this inward job free issue material return
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsFreeIssueMaterialsReturnsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsFreeIssueMaterialsReturnsServicePaginationReq, a, b);
    }
}
exports.InwardJobsFreeIssueMaterialsReturnsServicePaginationReq = InwardJobsFreeIssueMaterialsReturnsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.InwardJobsFreeIssueMaterialsReturnsServicePaginationResponse
 */
class InwardJobsFreeIssueMaterialsReturnsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.InwardJobFreeIssueMaterialReturn payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsFreeIssueMaterialsReturnsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: InwardJobFreeIssueMaterialReturn, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsFreeIssueMaterialsReturnsServicePaginationResponse, a, b);
    }
}
exports.InwardJobsFreeIssueMaterialsReturnsServicePaginationResponse = InwardJobsFreeIssueMaterialsReturnsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.InwardJobsFreeIssueMaterialsReturnsServiceFilterReq
 */
class InwardJobsFreeIssueMaterialsReturnsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY sort_key = 5;
     */
    sortKey = INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this inward job free issue material return
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the inward job free issue material return
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 25;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 30;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Inward Job related filters
     * The associated consignee client ID of the linked inward job
     *
     * @generated from field: uint64 consignee_client_id = 50;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked inward job
     *
     * @generated from field: uint64 buyer_client_id = 51;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project of the linked inward job
     *
     * @generated from field: uint64 project_id = 52;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsFreeIssueMaterialsReturnsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsFreeIssueMaterialsReturnsServiceFilterReq, a, b);
    }
}
exports.InwardJobsFreeIssueMaterialsReturnsServiceFilterReq = InwardJobsFreeIssueMaterialsReturnsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.InwardJobsFreeIssueMaterialsReturnsServiceCountReq
 */
class InwardJobsFreeIssueMaterialsReturnsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this inward job free issue material return
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the inward job free issue material return
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 25;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 30;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Inward Job related filters
     * The associated consignee client ID of the linked inward job
     *
     * @generated from field: uint64 consignee_client_id = 50;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked inward job
     *
     * @generated from field: uint64 buyer_client_id = 51;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project of the linked inward job
     *
     * @generated from field: uint64 project_id = 52;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsFreeIssueMaterialsReturnsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsFreeIssueMaterialsReturnsServiceCountReq, a, b);
    }
}
exports.InwardJobsFreeIssueMaterialsReturnsServiceCountReq = InwardJobsFreeIssueMaterialsReturnsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.InwardJobsFreeIssueMaterialsReturnsServiceSearchAllReq
 */
class InwardJobsFreeIssueMaterialsReturnsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY sort_key = 5;
     */
    sortKey = INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY.INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.InwardJobsFreeIssueMaterialsReturnsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(INWARD_JOB_FREE_ISSUE_MATERIAL_RETURN_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new InwardJobsFreeIssueMaterialsReturnsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(InwardJobsFreeIssueMaterialsReturnsServiceSearchAllReq, a, b);
    }
}
exports.InwardJobsFreeIssueMaterialsReturnsServiceSearchAllReq = InwardJobsFreeIssueMaterialsReturnsServiceSearchAllReq;
