"use strict";
// addHolidayShiftGroup
// modifyHolidayShiftGroup
// reorderHolidayShiftsGroups
// deleteHolidayShiftGroup
// approveHolidayShiftGroup
Object.defineProperty(exports, "__esModule", { value: true });
exports.HolidaysService = void 0;
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file holidays.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
const holidays_scailo_pb_js_1 = require("./holidays.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each shift group
 *
 * @generated from service Scailo.HolidaysService
 */
exports.HolidaysService = {
    typeName: "Scailo.HolidaysService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.HolidaysService.Create
         */
        create: {
            name: "Create",
            I: holidays_scailo_pb_js_1.HolidaysServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.HolidaysService.Draft
         */
        draft: {
            name: "Draft",
            I: holidays_scailo_pb_js_1.HolidaysServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.HolidaysService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: holidays_scailo_pb_js_1.HolidaysServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.HolidaysService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.HolidaysService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.HolidaysService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.HolidaysService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.HolidaysService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: holidays_scailo_pb_js_1.HolidaysServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.HolidaysService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.HolidaysService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.HolidaysService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.HolidaysService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.HolidaysService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail (Identifier) returns (IdentifierResponse);
         *
         * @generated from rpc Scailo.HolidaysService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a shift to a shift group
         *
         * @generated from rpc Scailo.HolidaysService.AddHolidayShiftGroup
         */
        addHolidayShiftGroup: {
            name: "AddHolidayShiftGroup",
            I: holidays_scailo_pb_js_1.HolidaysServiceShiftGroupCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify a shift in a shift group
         *
         * @generated from rpc Scailo.HolidaysService.ModifyHolidayShiftGroup
         */
        modifyHolidayShiftGroup: {
            name: "ModifyHolidayShiftGroup",
            I: holidays_scailo_pb_js_1.HolidaysServiceShiftGroupUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a shift in a shift group
         *
         * @generated from rpc Scailo.HolidaysService.ApproveHolidayShiftGroup
         */
        approveHolidayShiftGroup: {
            name: "ApproveHolidayShiftGroup",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a shift in a shift group
         *
         * @generated from rpc Scailo.HolidaysService.DeleteHolidayShiftGroup
         */
        deleteHolidayShiftGroup: {
            name: "DeleteHolidayShiftGroup",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder shifts in a shift group
         *
         * @generated from rpc Scailo.HolidaysService.ReorderHolidayShiftsGroups
         */
        reorderHolidayShiftsGroups: {
            name: "ReorderHolidayShiftsGroups",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Holiday Shift Group by ID
         *
         * @generated from rpc Scailo.HolidaysService.ViewHolidayShiftGroupByID
         */
        viewHolidayShiftGroupByID: {
            name: "ViewHolidayShiftGroupByID",
            I: base_scailo_pb_js_1.Identifier,
            O: holidays_scailo_pb_js_1.HolidayShiftGroup,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved holiday shift groups for given shift group ID
         *
         * @generated from rpc Scailo.HolidaysService.ViewApprovedHolidayShiftsGroups
         */
        viewApprovedHolidayShiftsGroups: {
            name: "ViewApprovedHolidayShiftsGroups",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: holidays_scailo_pb_js_1.HolidaysShiftsGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved holiday shift groups for given shift group ID
         *
         * @generated from rpc Scailo.HolidaysService.ViewUnapprovedHolidayShiftsGroups
         */
        viewUnapprovedHolidayShiftsGroups: {
            name: "ViewUnapprovedHolidayShiftsGroups",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: holidays_scailo_pb_js_1.HolidaysShiftsGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the shift group shift
         *
         * @generated from rpc Scailo.HolidaysService.ViewHolidayShiftsGroupsHistory
         */
        viewHolidayShiftsGroupsHistory: {
            name: "ViewHolidayShiftsGroupsHistory",
            I: holidays_scailo_pb_js_1.HolidaysShiftsGroupsHistoryRequest,
            O: holidays_scailo_pb_js_1.HolidaysShiftsGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.HolidaysService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: holidays_scailo_pb_js_1.Holiday,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.HolidaysService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: holidays_scailo_pb_js_1.HolidaysList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.HolidaysService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: holidays_scailo_pb_js_1.HolidaysList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.HolidaysService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: holidays_scailo_pb_js_1.HolidaysServicePaginationReq,
            O: holidays_scailo_pb_js_1.HolidaysServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all the holidays on the given timestamp
         *
         * @generated from rpc Scailo.HolidaysService.ViewHolidaysOn
         */
        viewHolidaysOn: {
            name: "ViewHolidaysOn",
            I: holidays_scailo_pb_js_1.HolidaysServiceViewHolidaysOnTimestampRequest,
            O: holidays_scailo_pb_js_1.HolidaysList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.HolidaysService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: holidays_scailo_pb_js_1.HolidaysServiceSearchAllReq,
            O: holidays_scailo_pb_js_1.HolidaysList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.HolidaysService.Filter
         */
        filter: {
            name: "Filter",
            I: holidays_scailo_pb_js_1.HolidaysServiceFilterReq,
            O: holidays_scailo_pb_js_1.HolidaysList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.HolidaysService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.HolidaysService.Count
         */
        count: {
            name: "Count",
            I: holidays_scailo_pb_js_1.HolidaysServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
