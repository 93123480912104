"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file goods_dispatches.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoodsDispatchesServiceSearchAllReq = exports.GoodsDispatchesServiceCountReq = exports.GoodsDispatchesServiceFilterReq = exports.GoodsDispatchesServicePaginationResponse = exports.GoodsDispatchesServicePaginationReq = exports.GoodsDispatchesServiceAlreadyAddedQuantityForSourceRequest = exports.GoodsDispatchesServicePaginatedItemsResponse = exports.GoodsDispatchItemsSearchRequest = exports.GoodsDispatchItemProspectiveInfoRequest = exports.GoodsDispatchItemHistoryRequest = exports.GoodsDispatchesItemsList = exports.GoodsDispatchesList = exports.GoodsDispatchItem = exports.GoodsDispatchesServiceItemUpdateRequest = exports.GoodsDispatchesServiceItemCreateRequest = exports.GoodsDispatch = exports.GoodsDispatchAncillaryParameters = exports.GoodsDispatchesServiceAutofillRequest = exports.GoodsDispatchesServiceUpdateRequest = exports.GoodsDispatchesServiceCreateRequest = exports.GOODS_DISPATCH_BILLING_STATUS = exports.GOODS_DISPATCH_SORT_KEY = exports.GOODS_DISPATCH_ITEM_STATUS = exports.GOODS_DISPATCH_ITEM_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.GOODS_DISPATCH_ITEM_SORT_KEY
 */
var GOODS_DISPATCH_ITEM_SORT_KEY;
(function (GOODS_DISPATCH_ITEM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: GOODS_DISPATCH_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    GOODS_DISPATCH_ITEM_SORT_KEY[GOODS_DISPATCH_ITEM_SORT_KEY["GOODS_DISPATCH_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "GOODS_DISPATCH_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: GOODS_DISPATCH_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    GOODS_DISPATCH_ITEM_SORT_KEY[GOODS_DISPATCH_ITEM_SORT_KEY["GOODS_DISPATCH_ITEM_SORT_KEY_CREATED_AT"] = 1] = "GOODS_DISPATCH_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: GOODS_DISPATCH_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    GOODS_DISPATCH_ITEM_SORT_KEY[GOODS_DISPATCH_ITEM_SORT_KEY["GOODS_DISPATCH_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "GOODS_DISPATCH_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: GOODS_DISPATCH_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    GOODS_DISPATCH_ITEM_SORT_KEY[GOODS_DISPATCH_ITEM_SORT_KEY["GOODS_DISPATCH_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "GOODS_DISPATCH_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: GOODS_DISPATCH_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    GOODS_DISPATCH_ITEM_SORT_KEY[GOODS_DISPATCH_ITEM_SORT_KEY["GOODS_DISPATCH_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "GOODS_DISPATCH_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: GOODS_DISPATCH_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    GOODS_DISPATCH_ITEM_SORT_KEY[GOODS_DISPATCH_ITEM_SORT_KEY["GOODS_DISPATCH_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "GOODS_DISPATCH_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the family ID
     *
     * @generated from enum value: GOODS_DISPATCH_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    GOODS_DISPATCH_ITEM_SORT_KEY[GOODS_DISPATCH_ITEM_SORT_KEY["GOODS_DISPATCH_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "GOODS_DISPATCH_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch ordered results by the internal quantity
     *
     * @generated from enum value: GOODS_DISPATCH_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    GOODS_DISPATCH_ITEM_SORT_KEY[GOODS_DISPATCH_ITEM_SORT_KEY["GOODS_DISPATCH_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "GOODS_DISPATCH_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch ordered results by the client unit of material ID
     *
     * @generated from enum value: GOODS_DISPATCH_ITEM_SORT_KEY_CLIENT_UOM_ID = 12;
     */
    GOODS_DISPATCH_ITEM_SORT_KEY[GOODS_DISPATCH_ITEM_SORT_KEY["GOODS_DISPATCH_ITEM_SORT_KEY_CLIENT_UOM_ID"] = 12] = "GOODS_DISPATCH_ITEM_SORT_KEY_CLIENT_UOM_ID";
    /**
     * Fetch ordered results by the client quantity
     *
     * @generated from enum value: GOODS_DISPATCH_ITEM_SORT_KEY_CLIENT_QUANTITY = 13;
     */
    GOODS_DISPATCH_ITEM_SORT_KEY[GOODS_DISPATCH_ITEM_SORT_KEY["GOODS_DISPATCH_ITEM_SORT_KEY_CLIENT_QUANTITY"] = 13] = "GOODS_DISPATCH_ITEM_SORT_KEY_CLIENT_QUANTITY";
    /**
     * Fetch ordered results by the client family code
     *
     * @generated from enum value: GOODS_DISPATCH_ITEM_SORT_KEY_CLIENT_FAMILY_CODE = 14;
     */
    GOODS_DISPATCH_ITEM_SORT_KEY[GOODS_DISPATCH_ITEM_SORT_KEY["GOODS_DISPATCH_ITEM_SORT_KEY_CLIENT_FAMILY_CODE"] = 14] = "GOODS_DISPATCH_ITEM_SORT_KEY_CLIENT_FAMILY_CODE";
})(GOODS_DISPATCH_ITEM_SORT_KEY || (exports.GOODS_DISPATCH_ITEM_SORT_KEY = GOODS_DISPATCH_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(GOODS_DISPATCH_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(GOODS_DISPATCH_ITEM_SORT_KEY, "Scailo.GOODS_DISPATCH_ITEM_SORT_KEY", [
    { no: 0, name: "GOODS_DISPATCH_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "GOODS_DISPATCH_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "GOODS_DISPATCH_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "GOODS_DISPATCH_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "GOODS_DISPATCH_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "GOODS_DISPATCH_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "GOODS_DISPATCH_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "GOODS_DISPATCH_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "GOODS_DISPATCH_ITEM_SORT_KEY_CLIENT_UOM_ID" },
    { no: 13, name: "GOODS_DISPATCH_ITEM_SORT_KEY_CLIENT_QUANTITY" },
    { no: 14, name: "GOODS_DISPATCH_ITEM_SORT_KEY_CLIENT_FAMILY_CODE" },
]);
/**
 *
 * Describes the applicable statuses of goods dispatch items
 *
 * @generated from enum Scailo.GOODS_DISPATCH_ITEM_STATUS
 */
var GOODS_DISPATCH_ITEM_STATUS;
(function (GOODS_DISPATCH_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: GOODS_DISPATCH_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    GOODS_DISPATCH_ITEM_STATUS[GOODS_DISPATCH_ITEM_STATUS["GOODS_DISPATCH_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "GOODS_DISPATCH_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the goods dispatch items must have been approved
     *
     * @generated from enum value: GOODS_DISPATCH_ITEM_STATUS_APPROVED = 1;
     */
    GOODS_DISPATCH_ITEM_STATUS[GOODS_DISPATCH_ITEM_STATUS["GOODS_DISPATCH_ITEM_STATUS_APPROVED"] = 1] = "GOODS_DISPATCH_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the goods dispatch items must be waiting for approval
     *
     * @generated from enum value: GOODS_DISPATCH_ITEM_STATUS_UNAPPROVED = 2;
     */
    GOODS_DISPATCH_ITEM_STATUS[GOODS_DISPATCH_ITEM_STATUS["GOODS_DISPATCH_ITEM_STATUS_UNAPPROVED"] = 2] = "GOODS_DISPATCH_ITEM_STATUS_UNAPPROVED";
})(GOODS_DISPATCH_ITEM_STATUS || (exports.GOODS_DISPATCH_ITEM_STATUS = GOODS_DISPATCH_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(GOODS_DISPATCH_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(GOODS_DISPATCH_ITEM_STATUS, "Scailo.GOODS_DISPATCH_ITEM_STATUS", [
    { no: 0, name: "GOODS_DISPATCH_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "GOODS_DISPATCH_ITEM_STATUS_APPROVED" },
    { no: 2, name: "GOODS_DISPATCH_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.GOODS_DISPATCH_SORT_KEY
 */
var GOODS_DISPATCH_SORT_KEY;
(function (GOODS_DISPATCH_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: GOODS_DISPATCH_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    GOODS_DISPATCH_SORT_KEY[GOODS_DISPATCH_SORT_KEY["GOODS_DISPATCH_SORT_KEY_ID_UNSPECIFIED"] = 0] = "GOODS_DISPATCH_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: GOODS_DISPATCH_SORT_KEY_CREATED_AT = 1;
     */
    GOODS_DISPATCH_SORT_KEY[GOODS_DISPATCH_SORT_KEY["GOODS_DISPATCH_SORT_KEY_CREATED_AT"] = 1] = "GOODS_DISPATCH_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: GOODS_DISPATCH_SORT_KEY_MODIFIED_AT = 2;
     */
    GOODS_DISPATCH_SORT_KEY[GOODS_DISPATCH_SORT_KEY["GOODS_DISPATCH_SORT_KEY_MODIFIED_AT"] = 2] = "GOODS_DISPATCH_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: GOODS_DISPATCH_SORT_KEY_APPROVED_ON = 3;
     */
    GOODS_DISPATCH_SORT_KEY[GOODS_DISPATCH_SORT_KEY["GOODS_DISPATCH_SORT_KEY_APPROVED_ON"] = 3] = "GOODS_DISPATCH_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: GOODS_DISPATCH_SORT_KEY_APPROVED_BY = 4;
     */
    GOODS_DISPATCH_SORT_KEY[GOODS_DISPATCH_SORT_KEY["GOODS_DISPATCH_SORT_KEY_APPROVED_BY"] = 4] = "GOODS_DISPATCH_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: GOODS_DISPATCH_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    GOODS_DISPATCH_SORT_KEY[GOODS_DISPATCH_SORT_KEY["GOODS_DISPATCH_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "GOODS_DISPATCH_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: GOODS_DISPATCH_SORT_KEY_COMPLETED_ON = 6;
     */
    GOODS_DISPATCH_SORT_KEY[GOODS_DISPATCH_SORT_KEY["GOODS_DISPATCH_SORT_KEY_COMPLETED_ON"] = 6] = "GOODS_DISPATCH_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: GOODS_DISPATCH_SORT_KEY_REFERENCE_ID = 10;
     */
    GOODS_DISPATCH_SORT_KEY[GOODS_DISPATCH_SORT_KEY["GOODS_DISPATCH_SORT_KEY_REFERENCE_ID"] = 10] = "GOODS_DISPATCH_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: GOODS_DISPATCH_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    GOODS_DISPATCH_SORT_KEY[GOODS_DISPATCH_SORT_KEY["GOODS_DISPATCH_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "GOODS_DISPATCH_SORT_KEY_FINAL_REF_NUMBER";
})(GOODS_DISPATCH_SORT_KEY || (exports.GOODS_DISPATCH_SORT_KEY = GOODS_DISPATCH_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(GOODS_DISPATCH_SORT_KEY)
protobuf_1.proto3.util.setEnumType(GOODS_DISPATCH_SORT_KEY, "Scailo.GOODS_DISPATCH_SORT_KEY", [
    { no: 0, name: "GOODS_DISPATCH_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "GOODS_DISPATCH_SORT_KEY_CREATED_AT" },
    { no: 2, name: "GOODS_DISPATCH_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "GOODS_DISPATCH_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "GOODS_DISPATCH_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "GOODS_DISPATCH_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "GOODS_DISPATCH_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "GOODS_DISPATCH_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "GOODS_DISPATCH_SORT_KEY_FINAL_REF_NUMBER" },
]);
/**
 *
 * Describes the available billing statuses
 *
 * @generated from enum Scailo.GOODS_DISPATCH_BILLING_STATUS
 */
var GOODS_DISPATCH_BILLING_STATUS;
(function (GOODS_DISPATCH_BILLING_STATUS) {
    /**
     * Any billing status
     *
     * @generated from enum value: GOODS_DISPATCH_BILLING_STATUS_ANY_UNSPECIFIED = 0;
     */
    GOODS_DISPATCH_BILLING_STATUS[GOODS_DISPATCH_BILLING_STATUS["GOODS_DISPATCH_BILLING_STATUS_ANY_UNSPECIFIED"] = 0] = "GOODS_DISPATCH_BILLING_STATUS_ANY_UNSPECIFIED";
    /**
     * Goods dispatch is billed
     *
     * @generated from enum value: GOODS_DISPATCH_BILLING_STATUS_BILLED = 1;
     */
    GOODS_DISPATCH_BILLING_STATUS[GOODS_DISPATCH_BILLING_STATUS["GOODS_DISPATCH_BILLING_STATUS_BILLED"] = 1] = "GOODS_DISPATCH_BILLING_STATUS_BILLED";
    /**
     * Goods dispatch is unbilled
     *
     * @generated from enum value: GOODS_DISPATCH_BILLING_STATUS_UNBILLED = 2;
     */
    GOODS_DISPATCH_BILLING_STATUS[GOODS_DISPATCH_BILLING_STATUS["GOODS_DISPATCH_BILLING_STATUS_UNBILLED"] = 2] = "GOODS_DISPATCH_BILLING_STATUS_UNBILLED";
})(GOODS_DISPATCH_BILLING_STATUS || (exports.GOODS_DISPATCH_BILLING_STATUS = GOODS_DISPATCH_BILLING_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(GOODS_DISPATCH_BILLING_STATUS)
protobuf_1.proto3.util.setEnumType(GOODS_DISPATCH_BILLING_STATUS, "Scailo.GOODS_DISPATCH_BILLING_STATUS", [
    { no: 0, name: "GOODS_DISPATCH_BILLING_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "GOODS_DISPATCH_BILLING_STATUS_BILLED" },
    { no: 2, name: "GOODS_DISPATCH_BILLING_STATUS_UNBILLED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.GoodsDispatchesServiceCreateRequest
 */
class GoodsDispatchesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the goods dispatch
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 15;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchesServiceCreateRequest, a, b);
    }
}
exports.GoodsDispatchesServiceCreateRequest = GoodsDispatchesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.GoodsDispatchesServiceUpdateRequest
 */
class GoodsDispatchesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the goods dispatch
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchesServiceUpdateRequest, a, b);
    }
}
exports.GoodsDispatchesServiceUpdateRequest = GoodsDispatchesServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.GoodsDispatchesServiceAutofillRequest
 */
class GoodsDispatchesServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The UUID of the record that needs to be updated
     *
     * @generated from field: string uuid = 2;
     */
    uuid = "";
    /**
     * Stores if the inventory needs to be split into unit quantities
     *
     * @generated from field: bool split_into_unit_quantity = 10;
     */
    splitIntoUnitQuantity = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchesServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "split_into_unit_quantity", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchesServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchesServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchesServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchesServiceAutofillRequest, a, b);
    }
}
exports.GoodsDispatchesServiceAutofillRequest = GoodsDispatchesServiceAutofillRequest;
/**
 *
 * Stores the UUID references of the record
 *
 * @generated from message Scailo.GoodsDispatchAncillaryParameters
 */
class GoodsDispatchAncillaryParameters extends protobuf_1.Message {
    /**
     * The UUID of the ref_id (the UUID of the associated ref_id)
     *
     * @generated from field: string ref_uuid = 213;
     */
    refUuid = "";
    /**
     * The UUID of the location (the UUID of the associated location_id)
     *
     * @generated from field: string location_uuid = 215;
     */
    locationUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchAncillaryParameters";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 213, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 215, name: "location_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchAncillaryParameters().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchAncillaryParameters().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchAncillaryParameters().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchAncillaryParameters, a, b);
    }
}
exports.GoodsDispatchAncillaryParameters = GoodsDispatchAncillaryParameters;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.GoodsDispatch
 */
class GoodsDispatch extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this goods dispatch
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this goods dispatch
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this goods dispatch
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this goods dispatch was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the goods dispatch
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 15;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The list of associated goods dispatch items
     *
     * @generated from field: repeated Scailo.GoodsDispatchItem list = 20;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatch";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "list", kind: "message", T: GoodsDispatchItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatch().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatch().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatch().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatch, a, b);
    }
}
exports.GoodsDispatch = GoodsDispatch;
/**
 *
 * Describes the parameters required to add an item to a goods dispatch
 *
 * @generated from message Scailo.GoodsDispatchesServiceItemCreateRequest
 */
class GoodsDispatchesServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the goods dispatch ID
     *
     * @generated from field: uint64 goods_dispatch_id = 10;
     */
    goodsDispatchId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash to be dispatched
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The quantity (in cents) being dispatched in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 14;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being dispatched in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 15;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as defined by the client
     *
     * @generated from field: string client_family_code = 16;
     */
    clientFamilyCode = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchesServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "goods_dispatch_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchesServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchesServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchesServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchesServiceItemCreateRequest, a, b);
    }
}
exports.GoodsDispatchesServiceItemCreateRequest = GoodsDispatchesServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a goods dispatch
 *
 * @generated from message Scailo.GoodsDispatchesServiceItemUpdateRequest
 */
class GoodsDispatchesServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash to be dispatched
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The quantity (in cents) being dispatched in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 14;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being dispatched in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 15;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as defined by the client
     *
     * @generated from field: string client_family_code = 16;
     */
    clientFamilyCode = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchesServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchesServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchesServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchesServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchesServiceItemUpdateRequest, a, b);
    }
}
exports.GoodsDispatchesServiceItemUpdateRequest = GoodsDispatchesServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a goods dispatch
 *
 * @generated from message Scailo.GoodsDispatchItem
 */
class GoodsDispatchItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this goods dispatch
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the goods dispatch ID
     *
     * @generated from field: uint64 goods_dispatch_id = 10;
     */
    goodsDispatchId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash to be dispatched
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The quantity (in cents) being dispatched in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 14;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being dispatched in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 15;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as defined by the client
     *
     * @generated from field: string client_family_code = 16;
     */
    clientFamilyCode = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "goods_dispatch_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchItem, a, b);
    }
}
exports.GoodsDispatchItem = GoodsDispatchItem;
/**
 *
 * Describes the message consisting of the list of goods dispatches
 *
 * @generated from message Scailo.GoodsDispatchesList
 */
class GoodsDispatchesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.GoodsDispatch list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: GoodsDispatch, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchesList, a, b);
    }
}
exports.GoodsDispatchesList = GoodsDispatchesList;
/**
 *
 * Describes the message consisting of the list of goods dispatch items
 *
 * @generated from message Scailo.GoodsDispatchesItemsList
 */
class GoodsDispatchesItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.GoodsDispatchItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchesItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: GoodsDispatchItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchesItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchesItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchesItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchesItemsList, a, b);
    }
}
exports.GoodsDispatchesItemsList = GoodsDispatchesItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.GoodsDispatchItemHistoryRequest
 */
class GoodsDispatchItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the goods dispatch ID
     *
     * @generated from field: uint64 goods_dispatch_id = 10;
     */
    goodsDispatchId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "goods_dispatch_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchItemHistoryRequest, a, b);
    }
}
exports.GoodsDispatchItemHistoryRequest = GoodsDispatchItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective goods dispatch item
 *
 * @generated from message Scailo.GoodsDispatchItemProspectiveInfoRequest
 */
class GoodsDispatchItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the goods dispatch ID
     *
     * @generated from field: uint64 goods_dispatch_id = 10;
     */
    goodsDispatchId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "goods_dispatch_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchItemProspectiveInfoRequest, a, b);
    }
}
exports.GoodsDispatchItemProspectiveInfoRequest = GoodsDispatchItemProspectiveInfoRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.GoodsDispatchItemsSearchRequest
 */
class GoodsDispatchItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.GOODS_DISPATCH_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = GOODS_DISPATCH_ITEM_SORT_KEY.GOODS_DISPATCH_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.GOODS_DISPATCH_ITEM_STATUS status = 7;
     */
    status = GOODS_DISPATCH_ITEM_STATUS.GOODS_DISPATCH_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the goods dispatch
     *
     * @generated from field: uint64 goods_dispatch_id = 20;
     */
    goodsDispatchId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 22;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash to be dispatched
     *
     * @generated from field: string item_hash = 23;
     */
    itemHash = "";
    /**
     * The ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 24;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 26;
     */
    clientFamilyCode = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(GOODS_DISPATCH_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(GOODS_DISPATCH_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "goods_dispatch_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchItemsSearchRequest, a, b);
    }
}
exports.GoodsDispatchItemsSearchRequest = GoodsDispatchItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.GoodsDispatchesServicePaginatedItemsResponse
 */
class GoodsDispatchesServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.GoodsDispatchItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchesServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: GoodsDispatchItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchesServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchesServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchesServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchesServicePaginatedItemsResponse, a, b);
    }
}
exports.GoodsDispatchesServicePaginatedItemsResponse = GoodsDispatchesServicePaginatedItemsResponse;
/**
 *
 * Describes the request payload to retrieve the quantity that has already been added for the specific ref_from, ref_id and family_id
 *
 * @generated from message Scailo.GoodsDispatchesServiceAlreadyAddedQuantityForSourceRequest
 */
class GoodsDispatchesServiceAlreadyAddedQuantityForSourceRequest extends protobuf_1.Message {
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 1;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 2;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated family ID
     *
     * @generated from field: uint64 family_id = 3;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchesServiceAlreadyAddedQuantityForSourceRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchesServiceAlreadyAddedQuantityForSourceRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchesServiceAlreadyAddedQuantityForSourceRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchesServiceAlreadyAddedQuantityForSourceRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchesServiceAlreadyAddedQuantityForSourceRequest, a, b);
    }
}
exports.GoodsDispatchesServiceAlreadyAddedQuantityForSourceRequest = GoodsDispatchesServiceAlreadyAddedQuantityForSourceRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.GoodsDispatchesServicePaginationReq
 */
class GoodsDispatchesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.GOODS_DISPATCH_SORT_KEY sort_key = 5;
     */
    sortKey = GOODS_DISPATCH_SORT_KEY.GOODS_DISPATCH_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this goods dispatch
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(GOODS_DISPATCH_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchesServicePaginationReq, a, b);
    }
}
exports.GoodsDispatchesServicePaginationReq = GoodsDispatchesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.GoodsDispatchesServicePaginationResponse
 */
class GoodsDispatchesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.GoodsDispatch payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: GoodsDispatch, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchesServicePaginationResponse, a, b);
    }
}
exports.GoodsDispatchesServicePaginationResponse = GoodsDispatchesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.GoodsDispatchesServiceFilterReq
 */
class GoodsDispatchesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.GOODS_DISPATCH_SORT_KEY sort_key = 5;
     */
    sortKey = GOODS_DISPATCH_SORT_KEY.GOODS_DISPATCH_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this goods dispatch
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the goods dispatch
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 25;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 30;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The status of the goods dispatch bill
     *
     * @generated from field: Scailo.GOODS_DISPATCH_BILLING_STATUS billing_status = 40;
     */
    billingStatus = GOODS_DISPATCH_BILLING_STATUS.GOODS_DISPATCH_BILLING_STATUS_ANY_UNSPECIFIED;
    /**
     * Sales Order related filters
     * The associated consignee client ID of the linked sales order
     *
     * @generated from field: uint64 consignee_client_id = 50;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked sales order
     *
     * @generated from field: uint64 buyer_client_id = 51;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project of the linked sales order
     *
     * @generated from field: uint64 project_id = 52;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(GOODS_DISPATCH_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "billing_status", kind: "enum", T: protobuf_1.proto3.getEnumType(GOODS_DISPATCH_BILLING_STATUS) },
        { no: 50, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchesServiceFilterReq, a, b);
    }
}
exports.GoodsDispatchesServiceFilterReq = GoodsDispatchesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.GoodsDispatchesServiceCountReq
 */
class GoodsDispatchesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this goods dispatch
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the goods dispatch
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 25;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 30;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The status of the goods dispatch bill
     *
     * @generated from field: Scailo.GOODS_DISPATCH_BILLING_STATUS billing_status = 40;
     */
    billingStatus = GOODS_DISPATCH_BILLING_STATUS.GOODS_DISPATCH_BILLING_STATUS_ANY_UNSPECIFIED;
    /**
     * Sales Order related filters
     * The associated consignee client ID of the linked sales order
     *
     * @generated from field: uint64 consignee_client_id = 50;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked sales order
     *
     * @generated from field: uint64 buyer_client_id = 51;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project of the linked sales order
     *
     * @generated from field: uint64 project_id = 52;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "billing_status", kind: "enum", T: protobuf_1.proto3.getEnumType(GOODS_DISPATCH_BILLING_STATUS) },
        { no: 50, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 52, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchesServiceCountReq, a, b);
    }
}
exports.GoodsDispatchesServiceCountReq = GoodsDispatchesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.GoodsDispatchesServiceSearchAllReq
 */
class GoodsDispatchesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.GOODS_DISPATCH_SORT_KEY sort_key = 5;
     */
    sortKey = GOODS_DISPATCH_SORT_KEY.GOODS_DISPATCH_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The status of the goods dispatch bill
     *
     * @generated from field: Scailo.GOODS_DISPATCH_BILLING_STATUS billing_status = 40;
     */
    billingStatus = GOODS_DISPATCH_BILLING_STATUS.GOODS_DISPATCH_BILLING_STATUS_ANY_UNSPECIFIED;
    /**
     * Sales Order related filters
     * The associated consignee client ID of the linked sales order
     *
     * @generated from field: uint64 consignee_client_id = 50;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked sales order
     *
     * @generated from field: uint64 buyer_client_id = 51;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.GoodsDispatchesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(GOODS_DISPATCH_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "billing_status", kind: "enum", T: protobuf_1.proto3.getEnumType(GOODS_DISPATCH_BILLING_STATUS) },
        { no: 50, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new GoodsDispatchesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GoodsDispatchesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GoodsDispatchesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(GoodsDispatchesServiceSearchAllReq, a, b);
    }
}
exports.GoodsDispatchesServiceSearchAllReq = GoodsDispatchesServiceSearchAllReq;
