"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file vendors.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.VendorsService = void 0;
const vendors_scailo_pb_js_1 = require("./vendors.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each vendor
 *
 * @generated from service Scailo.VendorsService
 */
exports.VendorsService = {
    typeName: "Scailo.VendorsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.VendorsService.Create
         */
        create: {
            name: "Create",
            I: vendors_scailo_pb_js_1.VendorsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.VendorsService.Draft
         */
        draft: {
            name: "Draft",
            I: vendors_scailo_pb_js_1.VendorsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.VendorsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: vendors_scailo_pb_js_1.VendorsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.VendorsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.VendorsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.VendorsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.VendorsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.VendorsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: vendors_scailo_pb_js_1.VendorsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.VendorsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.VendorsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.VendorsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.VendorsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.VendorsService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a vendor
         *
         * @generated from rpc Scailo.VendorsService.AddVendorItem
         */
        addVendorItem: {
            name: "AddVendorItem",
            I: vendors_scailo_pb_js_1.VendorsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a vendor
         *
         * @generated from rpc Scailo.VendorsService.ModifyVendorItem
         */
        modifyVendorItem: {
            name: "ModifyVendorItem",
            I: vendors_scailo_pb_js_1.VendorsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a vendor
         *
         * @generated from rpc Scailo.VendorsService.ApproveVendorItem
         */
        approveVendorItem: {
            name: "ApproveVendorItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a vendor
         *
         * @generated from rpc Scailo.VendorsService.DeleteVendorItem
         */
        deleteVendorItem: {
            name: "DeleteVendorItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a vendor
         * rpc ReorderVendorItems(ReorderItemsRequest) returns (IdentifierResponse);
         * View Vendor Item by ID
         *
         * @generated from rpc Scailo.VendorsService.ViewVendorItemByID
         */
        viewVendorItemByID: {
            name: "ViewVendorItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: vendors_scailo_pb_js_1.VendorItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved vendor items for given vendor ID with pagination
         *
         * @generated from rpc Scailo.VendorsService.ViewPaginatedApprovedVendorItems
         */
        viewPaginatedApprovedVendorItems: {
            name: "ViewPaginatedApprovedVendorItems",
            I: vendors_scailo_pb_js_1.VendorItemsSearchRequest,
            O: vendors_scailo_pb_js_1.VendorsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved vendor items for given vendor ID with pagination
         *
         * @generated from rpc Scailo.VendorsService.ViewPaginatedUnapprovedVendorItems
         */
        viewPaginatedUnapprovedVendorItems: {
            name: "ViewPaginatedUnapprovedVendorItems",
            I: vendors_scailo_pb_js_1.VendorItemsSearchRequest,
            O: vendors_scailo_pb_js_1.VendorsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the vendor item
         *
         * @generated from rpc Scailo.VendorsService.ViewVendorItemHistory
         */
        viewVendorItemHistory: {
            name: "ViewVendorItemHistory",
            I: vendors_scailo_pb_js_1.VendorItemHistoryRequest,
            O: vendors_scailo_pb_js_1.VendorItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through vendor items with pagination
         *
         * @generated from rpc Scailo.VendorsService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: vendors_scailo_pb_js_1.VendorItemsSearchRequest,
            O: vendors_scailo_pb_js_1.VendorsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through vendor items that are required with pagination
         *
         * @generated from rpc Scailo.VendorsService.SearchRequiredItemsWithPagination
         */
        searchRequiredItemsWithPagination: {
            name: "SearchRequiredItemsWithPagination",
            I: vendors_scailo_pb_js_1.VendorItemsSearchRequest,
            O: vendors_scailo_pb_js_1.VendorsServicePaginatedRequiredItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a user
         *
         * @generated from rpc Scailo.VendorsService.AddVendorUser
         */
        addVendorUser: {
            name: "AddVendorUser",
            I: vendors_scailo_pb_js_1.VendorsServiceUserCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a user
         *
         * @generated from rpc Scailo.VendorsService.ApproveVendorUser
         */
        approveVendorUser: {
            name: "ApproveVendorUser",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a user
         *
         * @generated from rpc Scailo.VendorsService.DeleteVendorUser
         */
        deleteVendorUser: {
            name: "DeleteVendorUser",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a user for the given ID
         *
         * @generated from rpc Scailo.VendorsService.ViewVendorUserByID
         */
        viewVendorUserByID: {
            name: "ViewVendorUserByID",
            I: base_scailo_pb_js_1.Identifier,
            O: vendors_scailo_pb_js_1.VendorUser,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all users for given vendor ID
         *
         * @generated from rpc Scailo.VendorsService.ViewVendorUsers
         */
        viewVendorUsers: {
            name: "ViewVendorUsers",
            I: base_scailo_pb_js_1.Identifier,
            O: vendors_scailo_pb_js_1.VendorUsersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through vendor users with pagination
         *
         * @generated from rpc Scailo.VendorsService.SearchVendorUsersWithPagination
         */
        searchVendorUsersWithPagination: {
            name: "SearchVendorUsersWithPagination",
            I: vendors_scailo_pb_js_1.VendorUsersSearchRequest,
            O: vendors_scailo_pb_js_1.VendorsServicePaginatedUsersResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.VendorsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: vendors_scailo_pb_js_1.Vendor,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.VendorsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vendors_scailo_pb_js_1.Vendor,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.VendorsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: vendors_scailo_pb_js_1.VendorsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.VendorsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: vendors_scailo_pb_js_1.VendorsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.VendorsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: vendors_scailo_pb_js_1.VendorsServicePaginationReq,
            O: vendors_scailo_pb_js_1.VendorsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View vendors that have been associated with the given family ID (returns vendors that have not been approved for the family as well)
         *
         * @generated from rpc Scailo.VendorsService.ViewVendorsForFamily
         */
        viewVendorsForFamily: {
            name: "ViewVendorsForFamily",
            I: base_scailo_pb_js_1.Identifier,
            O: vendors_scailo_pb_js_1.VendorsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Other view operations
         * Download vendor with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.VendorsService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.VendorsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: vendors_scailo_pb_js_1.VendorsServiceSearchAllReq,
            O: vendors_scailo_pb_js_1.VendorsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.VendorsService.Filter
         */
        filter: {
            name: "Filter",
            I: vendors_scailo_pb_js_1.VendorsServiceFilterReq,
            O: vendors_scailo_pb_js_1.VendorsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.VendorsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.VendorsService.Count
         */
        count: {
            name: "Count",
            I: vendors_scailo_pb_js_1.VendorsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
