"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file credit_notes.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreditNotesService = void 0;
const credit_notes_scailo_pb_js_1 = require("./credit_notes.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each credit note
 *
 * @generated from service Scailo.CreditNotesService
 */
exports.CreditNotesService = {
    typeName: "Scailo.CreditNotesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.CreditNotesService.Create
         */
        create: {
            name: "Create",
            I: credit_notes_scailo_pb_js_1.CreditNotesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.CreditNotesService.Draft
         */
        draft: {
            name: "Draft",
            I: credit_notes_scailo_pb_js_1.CreditNotesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.CreditNotesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: credit_notes_scailo_pb_js_1.CreditNotesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.CreditNotesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.CreditNotesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.CreditNotesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.CreditNotesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.CreditNotesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: credit_notes_scailo_pb_js_1.CreditNotesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.CreditNotesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.CreditNotesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.CreditNotesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.CreditNotesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.CreditNotesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.CreditNotesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.CreditNotesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.CreditNotesService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the credit note
         *
         * @generated from rpc Scailo.CreditNotesService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: credit_notes_scailo_pb_js_1.CreditNotesServiceAutofillRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a credit note
         *
         * @generated from rpc Scailo.CreditNotesService.AddCreditNoteItem
         */
        addCreditNoteItem: {
            name: "AddCreditNoteItem",
            I: credit_notes_scailo_pb_js_1.CreditNotesServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a credit note
         *
         * @generated from rpc Scailo.CreditNotesService.ModifyCreditNoteItem
         */
        modifyCreditNoteItem: {
            name: "ModifyCreditNoteItem",
            I: credit_notes_scailo_pb_js_1.CreditNotesServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a credit note
         *
         * @generated from rpc Scailo.CreditNotesService.ApproveCreditNoteItem
         */
        approveCreditNoteItem: {
            name: "ApproveCreditNoteItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a credit note
         *
         * @generated from rpc Scailo.CreditNotesService.DeleteCreditNoteItem
         */
        deleteCreditNoteItem: {
            name: "DeleteCreditNoteItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a credit note
         *
         * @generated from rpc Scailo.CreditNotesService.ReorderCreditNoteItems
         */
        reorderCreditNoteItems: {
            name: "ReorderCreditNoteItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Credit Note Item by ID
         *
         * @generated from rpc Scailo.CreditNotesService.ViewCreditNoteItemByID
         */
        viewCreditNoteItemByID: {
            name: "ViewCreditNoteItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: credit_notes_scailo_pb_js_1.CreditNoteItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved credit note items for given credit note ID
         *
         * @generated from rpc Scailo.CreditNotesService.ViewApprovedCreditNoteItems
         */
        viewApprovedCreditNoteItems: {
            name: "ViewApprovedCreditNoteItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: credit_notes_scailo_pb_js_1.CreditNoteItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved credit note items for given credit note ID
         *
         * @generated from rpc Scailo.CreditNotesService.ViewUnapprovedCreditNoteItems
         */
        viewUnapprovedCreditNoteItems: {
            name: "ViewUnapprovedCreditNoteItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: credit_notes_scailo_pb_js_1.CreditNoteItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the credit note item
         *
         * @generated from rpc Scailo.CreditNotesService.ViewCreditNoteItemHistory
         */
        viewCreditNoteItemHistory: {
            name: "ViewCreditNoteItemHistory",
            I: credit_notes_scailo_pb_js_1.CreditNoteItemHistoryRequest,
            O: credit_notes_scailo_pb_js_1.CreditNoteItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved credit note items for given credit note ID with pagination
         *
         * @generated from rpc Scailo.CreditNotesService.ViewPaginatedApprovedCreditNoteItems
         */
        viewPaginatedApprovedCreditNoteItems: {
            name: "ViewPaginatedApprovedCreditNoteItems",
            I: credit_notes_scailo_pb_js_1.CreditNoteItemsSearchRequest,
            O: credit_notes_scailo_pb_js_1.CreditNotesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved credit note items for given credit note ID with pagination
         *
         * @generated from rpc Scailo.CreditNotesService.ViewPaginatedUnapprovedCreditNoteItems
         */
        viewPaginatedUnapprovedCreditNoteItems: {
            name: "ViewPaginatedUnapprovedCreditNoteItems",
            I: credit_notes_scailo_pb_js_1.CreditNoteItemsSearchRequest,
            O: credit_notes_scailo_pb_js_1.CreditNotesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through credit note items with pagination
         *
         * @generated from rpc Scailo.CreditNotesService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: credit_notes_scailo_pb_js_1.CreditNoteItemsSearchRequest,
            O: credit_notes_scailo_pb_js_1.CreditNotesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a reference
         *
         * @generated from rpc Scailo.CreditNotesService.AddCreditNoteReference
         */
        addCreditNoteReference: {
            name: "AddCreditNoteReference",
            I: credit_notes_scailo_pb_js_1.CreditNotesServiceReferenceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a reference
         *
         * @generated from rpc Scailo.CreditNotesService.ApproveCreditNoteReference
         */
        approveCreditNoteReference: {
            name: "ApproveCreditNoteReference",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a reference
         *
         * @generated from rpc Scailo.CreditNotesService.DeleteCreditNoteReference
         */
        deleteCreditNoteReference: {
            name: "DeleteCreditNoteReference",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a reference for the given ID
         *
         * @generated from rpc Scailo.CreditNotesService.ViewCreditNoteReferenceByID
         */
        viewCreditNoteReferenceByID: {
            name: "ViewCreditNoteReferenceByID",
            I: base_scailo_pb_js_1.Identifier,
            O: credit_notes_scailo_pb_js_1.CreditNoteReference,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all references for given credit note ID
         *
         * @generated from rpc Scailo.CreditNotesService.ViewCreditNoteReferences
         */
        viewCreditNoteReferences: {
            name: "ViewCreditNoteReferences",
            I: base_scailo_pb_js_1.Identifier,
            O: credit_notes_scailo_pb_js_1.CreditNoteReferencesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.CreditNotesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: credit_notes_scailo_pb_js_1.CreditNote,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.CreditNotesService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: credit_notes_scailo_pb_js_1.CreditNote,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.CreditNotesService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: credit_notes_scailo_pb_js_1.CreditNoteAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.CreditNotesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: credit_notes_scailo_pb_js_1.CreditNotesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.CreditNotesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: credit_notes_scailo_pb_js_1.CreditNotesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.CreditNotesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: credit_notes_scailo_pb_js_1.CreditNotesServicePaginationReq,
            O: credit_notes_scailo_pb_js_1.CreditNotesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given credit note
         *
         * @generated from rpc Scailo.CreditNotesService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective credit note item info for the given family ID and credit note ID
         *
         * @generated from rpc Scailo.CreditNotesService.ViewProspectiveCreditNoteItem
         */
        viewProspectiveCreditNoteItem: {
            name: "ViewProspectiveCreditNoteItem",
            I: credit_notes_scailo_pb_js_1.CreditNoteItemProspectiveInfoRequest,
            O: credit_notes_scailo_pb_js_1.CreditNotesServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Other view operations
         * Download credit note with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.CreditNotesService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View already added quantities
         *
         * @generated from rpc Scailo.CreditNotesService.ViewAddedFamilyQuantityForSource
         */
        viewAddedFamilyQuantityForSource: {
            name: "ViewAddedFamilyQuantityForSource",
            I: credit_notes_scailo_pb_js_1.CreditNotesServiceAlreadyAddedQuantityForSourceRequest,
            O: base_scailo_pb_js_1.DualQuantitiesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.CreditNotesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: credit_notes_scailo_pb_js_1.CreditNotesServiceSearchAllReq,
            O: credit_notes_scailo_pb_js_1.CreditNotesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.CreditNotesService.Filter
         */
        filter: {
            name: "Filter",
            I: credit_notes_scailo_pb_js_1.CreditNotesServiceFilterReq,
            O: credit_notes_scailo_pb_js_1.CreditNotesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.CreditNotesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given count criteria
         *
         * @generated from rpc Scailo.CreditNotesService.Count
         */
        count: {
            name: "Count",
            I: credit_notes_scailo_pb_js_1.CreditNotesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
