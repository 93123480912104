"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file activities.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivitiesService = void 0;
const activities_scailo_pb_js_1 = require("./activities.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each activity
 *
 * @generated from service Scailo.ActivitiesService
 */
exports.ActivitiesService = {
    typeName: "Scailo.ActivitiesService",
    methods: {
        /**
         * Create
         *
         * @generated from rpc Scailo.ActivitiesService.Create
         */
        create: {
            name: "Create",
            I: activities_scailo_pb_js_1.ActivitiesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update
         *
         * @generated from rpc Scailo.ActivitiesService.Update
         */
        update: {
            name: "Update",
            I: activities_scailo_pb_js_1.ActivitiesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Cancel
         *
         * @generated from rpc Scailo.ActivitiesService.Cancel
         */
        cancel: {
            name: "Cancel",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.ActivitiesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.ActivitiesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.ActivitiesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.ActivitiesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.ActivitiesService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierUUID,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an action to an activity
         *
         * @generated from rpc Scailo.ActivitiesService.AddAction
         */
        addAction: {
            name: "AddAction",
            I: activities_scailo_pb_js_1.ActivitiesServiceActionCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an action with an activity timer to an activity
         *
         * @generated from rpc Scailo.ActivitiesService.AddActionWithTimer
         */
        addActionWithTimer: {
            name: "AddActionWithTimer",
            I: activities_scailo_pb_js_1.ActivitiesServiceActionWithTimerCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an action in an activity
         *
         * @generated from rpc Scailo.ActivitiesService.ModifyAction
         */
        modifyAction: {
            name: "ModifyAction",
            I: activities_scailo_pb_js_1.ActivitiesServiceActionUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an action in an activity
         *
         * @generated from rpc Scailo.ActivitiesService.DeleteAction
         */
        deleteAction: {
            name: "DeleteAction",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder actions in an activity
         *
         * @generated from rpc Scailo.ActivitiesService.ReorderActions
         */
        reorderActions: {
            name: "ReorderActions",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Activity Action by ID
         *
         * @generated from rpc Scailo.ActivitiesService.ViewActionByID
         */
        viewActionByID: {
            name: "ViewActionByID",
            I: base_scailo_pb_js_1.Identifier,
            O: activities_scailo_pb_js_1.ActivityAction,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Statistics of Activity Action by ID
         *
         * @generated from rpc Scailo.ActivitiesService.ViewActionStatistics
         */
        viewActionStatistics: {
            name: "ViewActionStatistics",
            I: base_scailo_pb_js_1.Identifier,
            O: activities_scailo_pb_js_1.ActivityActionStatistics,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all actions for given activity ID
         *
         * @generated from rpc Scailo.ActivitiesService.ViewActions
         */
        viewActions: {
            name: "ViewActions",
            I: base_scailo_pb_js_1.Identifier,
            O: activities_scailo_pb_js_1.ActivityActionsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the activity action
         *
         * @generated from rpc Scailo.ActivitiesService.ViewActionHistory
         */
        viewActionHistory: {
            name: "ViewActionHistory",
            I: activities_scailo_pb_js_1.ActivityActionHistoryRequest,
            O: activities_scailo_pb_js_1.ActivityActionsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View activity actions for given activity ID with pagination
         *
         * @generated from rpc Scailo.ActivitiesService.ViewPaginatedActions
         */
        viewPaginatedActions: {
            name: "ViewPaginatedActions",
            I: activities_scailo_pb_js_1.ActivityActionsSearchRequest,
            O: activities_scailo_pb_js_1.ActivitiesServicePaginatedActionsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through activity actions with pagination
         *
         * @generated from rpc Scailo.ActivitiesService.SearchActionsWithPagination
         */
        searchActionsWithPagination: {
            name: "SearchActionsWithPagination",
            I: activities_scailo_pb_js_1.ActivityActionsSearchRequest,
            O: activities_scailo_pb_js_1.ActivitiesServicePaginatedActionsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an activity tag
         *
         * @generated from rpc Scailo.ActivitiesService.AddActivityTagAssociation
         */
        addActivityTagAssociation: {
            name: "AddActivityTagAssociation",
            I: activities_scailo_pb_js_1.ActivitiesServiceActivityTagAssociationCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an activity tag
         *
         * @generated from rpc Scailo.ActivitiesService.DeleteActivityTagAssociation
         */
        deleteActivityTagAssociation: {
            name: "DeleteActivityTagAssociation",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View activity tag for the given ID
         *
         * @generated from rpc Scailo.ActivitiesService.ViewActivityTagAssociationByID
         */
        viewActivityTagAssociationByID: {
            name: "ViewActivityTagAssociationByID",
            I: base_scailo_pb_js_1.Identifier,
            O: activities_scailo_pb_js_1.ActivityTagAssociation,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all activities tags for given activity ID
         *
         * @generated from rpc Scailo.ActivitiesService.ViewActivityTagAssociations
         */
        viewActivityTagAssociations: {
            name: "ViewActivityTagAssociations",
            I: base_scailo_pb_js_1.Identifier,
            O: activities_scailo_pb_js_1.ActivityTagAssociationsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an owner
         *
         * @generated from rpc Scailo.ActivitiesService.AddOwner
         */
        addOwner: {
            name: "AddOwner",
            I: activities_scailo_pb_js_1.ActivitiesServiceOwnerCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an owner
         *
         * @generated from rpc Scailo.ActivitiesService.DeleteOwner
         */
        deleteOwner: {
            name: "DeleteOwner",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View an owner for the given ID
         *
         * @generated from rpc Scailo.ActivitiesService.ViewOwnerByID
         */
        viewOwnerByID: {
            name: "ViewOwnerByID",
            I: base_scailo_pb_js_1.Identifier,
            O: activities_scailo_pb_js_1.ActivityOwner,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all owners for given activity ID
         *
         * @generated from rpc Scailo.ActivitiesService.ViewOwners
         */
        viewOwners: {
            name: "ViewOwners",
            I: base_scailo_pb_js_1.Identifier,
            O: activities_scailo_pb_js_1.ActivityOwnersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Import employees as owners from team and returns the ID of the activity
         *
         * @generated from rpc Scailo.ActivitiesService.ImportOwnersFromTeam
         */
        importOwnersFromTeam: {
            name: "ImportOwnersFromTeam",
            I: activities_scailo_pb_js_1.ActivitiesServiceImportOwnersRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Import employees as owners from department and returns the ID of the activity
         *
         * @generated from rpc Scailo.ActivitiesService.ImportOwnersFromDepartment
         */
        importOwnersFromDepartment: {
            name: "ImportOwnersFromDepartment",
            I: activities_scailo_pb_js_1.ActivitiesServiceImportOwnersRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a supervisor
         *
         * @generated from rpc Scailo.ActivitiesService.AddSupervisor
         */
        addSupervisor: {
            name: "AddSupervisor",
            I: activities_scailo_pb_js_1.ActivitiesServiceSupervisorCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a supervisor
         *
         * @generated from rpc Scailo.ActivitiesService.DeleteSupervisor
         */
        deleteSupervisor: {
            name: "DeleteSupervisor",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View supervisor for the given ID
         *
         * @generated from rpc Scailo.ActivitiesService.ViewSupervisorByID
         */
        viewSupervisorByID: {
            name: "ViewSupervisorByID",
            I: base_scailo_pb_js_1.Identifier,
            O: activities_scailo_pb_js_1.ActivitySupervisor,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all supervisors for given activity ID
         *
         * @generated from rpc Scailo.ActivitiesService.ViewSupervisors
         */
        viewSupervisors: {
            name: "ViewSupervisors",
            I: base_scailo_pb_js_1.Identifier,
            O: activities_scailo_pb_js_1.ActivitySupervisorsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a timer
         *
         * @generated from rpc Scailo.ActivitiesService.AddTimer
         */
        addTimer: {
            name: "AddTimer",
            I: activities_scailo_pb_js_1.ActivitiesServiceTimerCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * End a timer
         *
         * @generated from rpc Scailo.ActivitiesService.EndTimer
         */
        endTimer: {
            name: "EndTimer",
            I: activities_scailo_pb_js_1.ActivitiesServiceTimerEndRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a timer for the given ID
         *
         * @generated from rpc Scailo.ActivitiesService.ViewTimerByID
         */
        viewTimerByID: {
            name: "ViewTimerByID",
            I: base_scailo_pb_js_1.Identifier,
            O: activities_scailo_pb_js_1.ActivityTimer,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all timers for given activity ID
         *
         * @generated from rpc Scailo.ActivitiesService.ViewTimers
         */
        viewTimers: {
            name: "ViewTimers",
            I: base_scailo_pb_js_1.Identifier,
            O: activities_scailo_pb_js_1.ActivityTimersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View activity timers for given activity ID with pagination
         *
         * @generated from rpc Scailo.ActivitiesService.ViewPaginatedTimers
         */
        viewPaginatedTimers: {
            name: "ViewPaginatedTimers",
            I: activities_scailo_pb_js_1.ActivityTimersSearchRequest,
            O: activities_scailo_pb_js_1.ActivitiesServicePaginatedTimersResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through activity timers with pagination
         *
         * @generated from rpc Scailo.ActivitiesService.SearchTimersWithPagination
         */
        searchTimersWithPagination: {
            name: "SearchTimersWithPagination",
            I: activities_scailo_pb_js_1.ActivityTimersSearchRequest,
            O: activities_scailo_pb_js_1.ActivitiesServicePaginatedTimersResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.ActivitiesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: activities_scailo_pb_js_1.Activity,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.ActivitiesService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: activities_scailo_pb_js_1.Activity,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.ActivitiesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: activities_scailo_pb_js_1.ActivitiesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.ActivitiesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: activities_scailo_pb_js_1.ActivitiesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.ActivitiesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: activities_scailo_pb_js_1.ActivitiesServicePaginationReq,
            O: activities_scailo_pb_js_1.ActivitiesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Statistics of Activity
         *
         * @generated from rpc Scailo.ActivitiesService.ViewStatistics
         */
        viewStatistics: {
            name: "ViewStatistics",
            I: base_scailo_pb_js_1.Identifier,
            O: activities_scailo_pb_js_1.ActivityStatistics,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.ActivitiesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: activities_scailo_pb_js_1.ActivitiesServiceSearchAllReq,
            O: activities_scailo_pb_js_1.ActivitiesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.ActivitiesService.Filter
         */
        filter: {
            name: "Filter",
            I: activities_scailo_pb_js_1.ActivitiesServiceFilterReq,
            O: activities_scailo_pb_js_1.ActivitiesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.ActivitiesService.Count
         */
        count: {
            name: "Count",
            I: activities_scailo_pb_js_1.ActivitiesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
