"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file users.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersService = void 0;
const users_scailo_pb_js_1 = require("./users.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each user
 *
 * @generated from service Scailo.UsersService
 */
exports.UsersService = {
    typeName: "Scailo.UsersService",
    methods: {
        /**
         * Register user's mobile device for push notifications. Returns the ID of the user device record
         *
         * @generated from rpc Scailo.UsersService.RegisterMobileDevice
         */
        registerMobileDevice: {
            name: "RegisterMobileDevice",
            I: users_scailo_pb_js_1.UsersServiceRegisterMobileDeviceRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.UsersService.Create
         */
        create: {
            name: "Create",
            I: users_scailo_pb_js_1.UsersServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.UsersService.Draft
         */
        draft: {
            name: "Draft",
            I: users_scailo_pb_js_1.UsersServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.UsersService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: users_scailo_pb_js_1.UsersServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.UsersService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.UsersService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.UsersService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.UsersService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.UsersService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: users_scailo_pb_js_1.UsersServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.UsersService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.UsersService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.UsersService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail (Identifier) returns (IdentifierResponse);
         *
         * @generated from rpc Scailo.UsersService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update user's password by another user (such as an administrator)
         *
         * @generated from rpc Scailo.UsersService.UpdatePassword
         */
        updatePassword: {
            name: "UpdatePassword",
            I: base_scailo_pb_js_1.UpdatePasswordReq,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update user's own password
         *
         * @generated from rpc Scailo.UsersService.UpdateOwnPassword
         */
        updateOwnPassword: {
            name: "UpdateOwnPassword",
            I: base_scailo_pb_js_1.UpdateOwnPasswordReq,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update the user's profile picture
         *
         * @generated from rpc Scailo.UsersService.UpdateProfilePicture
         */
        updateProfilePicture: {
            name: "UpdateProfilePicture",
            I: base_scailo_pb_js_1.UploadPictureReq,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update the user's signature
         *
         * @generated from rpc Scailo.UsersService.UpdateSignature
         */
        updateSignature: {
            name: "UpdateSignature",
            I: base_scailo_pb_js_1.UploadPictureReq,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Enable MFA for user
         *
         * @generated from rpc Scailo.UsersService.MFAEnable
         */
        mFAEnable: {
            name: "MFAEnable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.ImageResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reset MFA for user
         *
         * @generated from rpc Scailo.UsersService.MFAReset
         */
        mFAReset: {
            name: "MFAReset",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID (returns the entire information about the user, including the logs)
         *
         * @generated from rpc Scailo.UsersService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.IdentifierZeroable,
            O: users_scailo_pb_js_1.User,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID (logs aren't returned)
         *
         * @generated from rpc Scailo.UsersService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: users_scailo_pb_js_1.User,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by username (logs aren't returned)
         *
         * @generated from rpc Scailo.UsersService.ViewByUsername
         */
        viewByUsername: {
            name: "ViewByUsername",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: users_scailo_pb_js_1.User,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by user's code (logs aren't returned)
         *
         * @generated from rpc Scailo.UsersService.ViewByCode
         */
        viewByCode: {
            name: "ViewByCode",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: users_scailo_pb_js_1.User,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.UsersService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: users_scailo_pb_js_1.UsersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.UsersService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: users_scailo_pb_js_1.UsersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.UsersService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: users_scailo_pb_js_1.UsersServicePaginationReq,
            O: users_scailo_pb_js_1.UsersServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all users with the given IDs
         *
         * @generated from rpc Scailo.UsersService.ViewFromIDs
         */
        viewFromIDs: {
            name: "ViewFromIDs",
            I: base_scailo_pb_js_1.IdentifiersList,
            O: users_scailo_pb_js_1.UsersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all users with the given UUIDs
         *
         * @generated from rpc Scailo.UsersService.ViewFromUUIDs
         */
        viewFromUUIDs: {
            name: "ViewFromUUIDs",
            I: base_scailo_pb_js_1.IdentifierUUIDsList,
            O: users_scailo_pb_js_1.UsersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all users with the given usernames
         *
         * @generated from rpc Scailo.UsersService.ViewFromUsernames
         */
        viewFromUsernames: {
            name: "ViewFromUsernames",
            I: base_scailo_pb_js_1.StringsList,
            O: users_scailo_pb_js_1.UsersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View self user (the profile of the logged in user)
         *
         * @generated from rpc Scailo.UsersService.ViewSelf
         */
        viewSelf: {
            name: "ViewSelf",
            I: base_scailo_pb_js_1.Empty,
            O: users_scailo_pb_js_1.User,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all users with birthdays on the given date
         *
         * @generated from rpc Scailo.UsersService.ViewBirthdaysOn
         */
        viewBirthdaysOn: {
            name: "ViewBirthdaysOn",
            I: base_scailo_pb_js_1.MonthAndDayFilter,
            O: users_scailo_pb_js_1.UsersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View user's signature
         *
         * @generated from rpc Scailo.UsersService.ViewSignature
         */
        viewSignature: {
            name: "ViewSignature",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.Base64String,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View user's profile picture
         *
         * @generated from rpc Scailo.UsersService.ViewProfilePicture
         */
        viewProfilePicture: {
            name: "ViewProfilePicture",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.ImageResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View user's thumbnail picture
         *
         * @generated from rpc Scailo.UsersService.ViewThumbnailPicture
         */
        viewThumbnailPicture: {
            name: "ViewThumbnailPicture",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.ImageResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View user's VCard
         *
         * @generated from rpc Scailo.UsersService.ViewVCard
         */
        viewVCard: {
            name: "ViewVCard",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.ImageResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View user's QR Code as image
         *
         * @generated from rpc Scailo.UsersService.ViewQRImage
         */
        viewQRImage: {
            name: "ViewQRImage",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.ImageResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View user's QR Code as string
         *
         * @generated from rpc Scailo.UsersService.ViewQRString
         */
        viewQRString: {
            name: "ViewQRString",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.StringResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View user info on the basis of the provided image. The image should consist of only the user's face. Will return an error if the image has not been recognized.
         *
         * @generated from rpc Scailo.UsersService.IdentifyCroppedFace
         */
        identifyCroppedFace: {
            name: "IdentifyCroppedFace",
            I: base_scailo_pb_js_1.StandardFile,
            O: users_scailo_pb_js_1.User,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View user info on the basis of the provided image. The image should consist of just the user (might be a full sized photo). The face will be cropped. Will return an error if the image has not been recognized.
         *
         * @generated from rpc Scailo.UsersService.IdentifyFullFace
         */
        identifyFullFace: {
            name: "IdentifyFullFace",
            I: base_scailo_pb_js_1.StandardFile,
            O: users_scailo_pb_js_1.User,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.UsersService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: users_scailo_pb_js_1.UsersServiceSearchAllReq,
            O: users_scailo_pb_js_1.UsersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.UsersService.Filter
         */
        filter: {
            name: "Filter",
            I: users_scailo_pb_js_1.UsersServiceFilterReq,
            O: users_scailo_pb_js_1.UsersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.UsersService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.UsersService.Count
         */
        count: {
            name: "Count",
            I: users_scailo_pb_js_1.UsersServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
