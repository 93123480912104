"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file sales_enquiries.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesEnquiriesService = void 0;
const sales_enquiries_scailo_pb_js_1 = require("./sales_enquiries.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each sales enquiry
 *
 * @generated from service Scailo.SalesEnquiriesService
 */
exports.SalesEnquiriesService = {
    typeName: "Scailo.SalesEnquiriesService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.SalesEnquiriesService.Create
         */
        create: {
            name: "Create",
            I: sales_enquiries_scailo_pb_js_1.SalesEnquiriesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.SalesEnquiriesService.Draft
         */
        draft: {
            name: "Draft",
            I: sales_enquiries_scailo_pb_js_1.SalesEnquiriesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.SalesEnquiriesService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: sales_enquiries_scailo_pb_js_1.SalesEnquiriesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.SalesEnquiriesService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.SalesEnquiriesService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.SalesEnquiriesService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.SalesEnquiriesService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.SalesEnquiriesService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: sales_enquiries_scailo_pb_js_1.SalesEnquiriesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.SalesEnquiriesService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.SalesEnquiriesService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.SalesEnquiriesService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.SalesEnquiriesService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.SalesEnquiriesService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.RepeatWithDeliveryDate,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.SalesEnquiriesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.SalesEnquiriesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.SalesEnquiriesService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Amend the sales enquiry and send for revision
         *
         * @generated from rpc Scailo.SalesEnquiriesService.Amend
         */
        amend: {
            name: "Amend",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a sales enquiry
         *
         * @generated from rpc Scailo.SalesEnquiriesService.AddSalesEnquiryItem
         */
        addSalesEnquiryItem: {
            name: "AddSalesEnquiryItem",
            I: sales_enquiries_scailo_pb_js_1.SalesEnquiriesServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a sales enquiry
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ModifySalesEnquiryItem
         */
        modifySalesEnquiryItem: {
            name: "ModifySalesEnquiryItem",
            I: sales_enquiries_scailo_pb_js_1.SalesEnquiriesServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a sales enquiry
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ApproveSalesEnquiryItem
         */
        approveSalesEnquiryItem: {
            name: "ApproveSalesEnquiryItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a sales enquiry
         *
         * @generated from rpc Scailo.SalesEnquiriesService.DeleteSalesEnquiryItem
         */
        deleteSalesEnquiryItem: {
            name: "DeleteSalesEnquiryItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a sales enquiry
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ReorderSalesEnquiryItems
         */
        reorderSalesEnquiryItems: {
            name: "ReorderSalesEnquiryItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Sales Enquiry Item by ID
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ViewSalesEnquiryItemByID
         */
        viewSalesEnquiryItemByID: {
            name: "ViewSalesEnquiryItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiryItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved sales enquiry items for given sales enquiry ID
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ViewApprovedSalesEnquiryItems
         */
        viewApprovedSalesEnquiryItems: {
            name: "ViewApprovedSalesEnquiryItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiriesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved sales enquiry items for given sales enquiry ID
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ViewUnapprovedSalesEnquiryItems
         */
        viewUnapprovedSalesEnquiryItems: {
            name: "ViewUnapprovedSalesEnquiryItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiriesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the sales enquiry item
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ViewSalesEnquiryItemHistory
         */
        viewSalesEnquiryItemHistory: {
            name: "ViewSalesEnquiryItemHistory",
            I: sales_enquiries_scailo_pb_js_1.SalesEnquiryItemHistoryRequest,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiriesItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved sales enquiry items for given sales enquiry ID with pagination
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ViewPaginatedApprovedSalesEnquiryItems
         */
        viewPaginatedApprovedSalesEnquiryItems: {
            name: "ViewPaginatedApprovedSalesEnquiryItems",
            I: sales_enquiries_scailo_pb_js_1.SalesEnquiryItemsSearchRequest,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiriesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved sales enquiry items for given sales enquiry ID with pagination
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ViewPaginatedUnapprovedSalesEnquiryItems
         */
        viewPaginatedUnapprovedSalesEnquiryItems: {
            name: "ViewPaginatedUnapprovedSalesEnquiryItems",
            I: sales_enquiries_scailo_pb_js_1.SalesEnquiryItemsSearchRequest,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiriesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through sales enquiry items with pagination
         *
         * @generated from rpc Scailo.SalesEnquiriesService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: sales_enquiries_scailo_pb_js_1.SalesEnquiryItemsSearchRequest,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiriesServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.SalesEnquiriesService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.SalesEnquiriesService.UploadSalesEnquiryItems
         */
        uploadSalesEnquiryItems: {
            name: "UploadSalesEnquiryItems",
            I: base_scailo_pb_js_1.IdentifierWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a contact
         *
         * @generated from rpc Scailo.SalesEnquiriesService.AddSalesEnquiryContact
         */
        addSalesEnquiryContact: {
            name: "AddSalesEnquiryContact",
            I: sales_enquiries_scailo_pb_js_1.SalesEnquiriesServiceContactCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a contact
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ApproveSalesEnquiryContact
         */
        approveSalesEnquiryContact: {
            name: "ApproveSalesEnquiryContact",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a contact
         *
         * @generated from rpc Scailo.SalesEnquiriesService.DeleteSalesEnquiryContact
         */
        deleteSalesEnquiryContact: {
            name: "DeleteSalesEnquiryContact",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a contact for the given ID
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ViewSalesEnquiryContactByID
         */
        viewSalesEnquiryContactByID: {
            name: "ViewSalesEnquiryContactByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiryContact,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all contacts for given sales enquiry UUID
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ViewSalesEnquiryContacts
         */
        viewSalesEnquiryContacts: {
            name: "ViewSalesEnquiryContacts",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiryContactsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiry,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiry,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiryAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiriesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiriesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: sales_enquiries_scailo_pb_js_1.SalesEnquiriesServicePaginationReq,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiriesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all the amendments made
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ViewAmendments
         */
        viewAmendments: {
            name: "ViewAmendments",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.AmendmentLogsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all sales orders IDs that are associated with the given sales enquiry ID
         *
         * @generated from rpc Scailo.SalesEnquiriesService.ViewAssociatedSalesOrdersIDs
         */
        viewAssociatedSalesOrdersIDs: {
            name: "ViewAssociatedSalesOrdersIDs",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download sales enquiry with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.SalesEnquiriesService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.SalesEnquiriesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: sales_enquiries_scailo_pb_js_1.SalesEnquiriesServiceSearchAllReq,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiriesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.SalesEnquiriesService.Filter
         */
        filter: {
            name: "Filter",
            I: sales_enquiries_scailo_pb_js_1.SalesEnquiriesServiceFilterReq,
            O: sales_enquiries_scailo_pb_js_1.SalesEnquiriesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.SalesEnquiriesService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.SalesEnquiriesService.Count
         */
        count: {
            name: "Count",
            I: sales_enquiries_scailo_pb_js_1.SalesEnquiriesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
