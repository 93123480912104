"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file qc_samples.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.QCSamplesService = void 0;
const qc_samples_scailo_pb_js_1 = require("./qc_samples.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each qc sample
 *
 * @generated from service Scailo.QCSamplesService
 */
exports.QCSamplesService = {
    typeName: "Scailo.QCSamplesService",
    methods: {
        /**
         * Create
         *
         * @generated from rpc Scailo.QCSamplesService.Create
         */
        create: {
            name: "Create",
            I: qc_samples_scailo_pb_js_1.QCSamplesServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierUUIDsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update
         *
         * @generated from rpc Scailo.QCSamplesService.Update
         */
        update: {
            name: "Update",
            I: qc_samples_scailo_pb_js_1.QCSamplesServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Finish
         *
         * @generated from rpc Scailo.QCSamplesService.Finish
         */
        finish: {
            name: "Finish",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Accept
         *
         * @generated from rpc Scailo.QCSamplesService.Accept
         */
        accept: {
            name: "Accept",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Accept With Deviation
         *
         * @generated from rpc Scailo.QCSamplesService.AcceptWithDeviation
         */
        acceptWithDeviation: {
            name: "AcceptWithDeviation",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reject
         *
         * @generated from rpc Scailo.QCSamplesService.Reject
         */
        reject: {
            name: "Reject",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Cancel
         *
         * @generated from rpc Scailo.QCSamplesService.Cancel
         */
        cancel: {
            name: "Cancel",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.QCSamplesService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.QCSamplesService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.QCSamplesService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an parameter in a qc sample
         *
         * @generated from rpc Scailo.QCSamplesService.UpdateParameter
         */
        updateParameter: {
            name: "UpdateParameter",
            I: qc_samples_scailo_pb_js_1.QCSamplesServiceParameterUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View QCSample Parameter by ID
         *
         * @generated from rpc Scailo.QCSamplesService.ViewParameterByID
         */
        viewParameterByID: {
            name: "ViewParameterByID",
            I: base_scailo_pb_js_1.Identifier,
            O: qc_samples_scailo_pb_js_1.QCSampleParameter,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all parameters for given qc sample UUID
         *
         * @generated from rpc Scailo.QCSamplesService.ViewParameters
         */
        viewParameters: {
            name: "ViewParameters",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: qc_samples_scailo_pb_js_1.QCSampleParametersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the qc sample parameter
         *
         * @generated from rpc Scailo.QCSamplesService.ViewParameterHistory
         */
        viewParameterHistory: {
            name: "ViewParameterHistory",
            I: qc_samples_scailo_pb_js_1.QCSampleParameterHistoryRequest,
            O: qc_samples_scailo_pb_js_1.QCSampleParameterHistoryList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View qc sample parameters for given qc sample ID with pagination
         *
         * @generated from rpc Scailo.QCSamplesService.ViewPaginatedParameters
         */
        viewPaginatedParameters: {
            name: "ViewPaginatedParameters",
            I: qc_samples_scailo_pb_js_1.QCSampleParametersSearchRequest,
            O: qc_samples_scailo_pb_js_1.QCSamplesServicePaginatedParametersResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through qc sample parameters with pagination
         *
         * @generated from rpc Scailo.QCSamplesService.SearchParametersWithPagination
         */
        searchParametersWithPagination: {
            name: "SearchParametersWithPagination",
            I: qc_samples_scailo_pb_js_1.QCSampleParametersSearchRequest,
            O: qc_samples_scailo_pb_js_1.QCSamplesServicePaginatedParametersResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.QCSamplesService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: qc_samples_scailo_pb_js_1.QCSample,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.QCSamplesService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: qc_samples_scailo_pb_js_1.QCSample,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.QCSamplesService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: qc_samples_scailo_pb_js_1.QCSampleAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.QCSamplesService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: qc_samples_scailo_pb_js_1.QCSamplesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.QCSamplesService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: qc_samples_scailo_pb_js_1.QCSamplesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.QCSamplesService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: qc_samples_scailo_pb_js_1.QCSamplesServicePaginationReq,
            O: qc_samples_scailo_pb_js_1.QCSamplesServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.QCSamplesService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: qc_samples_scailo_pb_js_1.QCSamplesServiceSearchAllReq,
            O: qc_samples_scailo_pb_js_1.QCSamplesWithMetadataList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.QCSamplesService.Filter
         */
        filter: {
            name: "Filter",
            I: qc_samples_scailo_pb_js_1.QCSamplesServiceFilterReq,
            O: qc_samples_scailo_pb_js_1.QCSamplesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.QCSamplesService.Count
         */
        count: {
            name: "Count",
            I: qc_samples_scailo_pb_js_1.QCSamplesServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
