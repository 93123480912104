"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file bank_accounts.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankAccountsService = void 0;
const bank_accounts_scailo_pb_js_1 = require("./bank_accounts.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each bank account
 *
 * @generated from service Scailo.BankAccountsService
 */
exports.BankAccountsService = {
    typeName: "Scailo.BankAccountsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.BankAccountsService.Create
         */
        create: {
            name: "Create",
            I: bank_accounts_scailo_pb_js_1.BankAccountsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.BankAccountsService.Draft
         */
        draft: {
            name: "Draft",
            I: bank_accounts_scailo_pb_js_1.BankAccountsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.BankAccountsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: bank_accounts_scailo_pb_js_1.BankAccountsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.BankAccountsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.BankAccountsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.BankAccountsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.BankAccountsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.BankAccountsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: bank_accounts_scailo_pb_js_1.BankAccountsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.BankAccountsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.BankAccountsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.BankAccountsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail (Identifier) returns (IdentifierResponse);
         *
         * @generated from rpc Scailo.BankAccountsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.BankAccountsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: bank_accounts_scailo_pb_js_1.BankAccount,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.BankAccountsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: bank_accounts_scailo_pb_js_1.BankAccountsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.BankAccountsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: bank_accounts_scailo_pb_js_1.BankAccountsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.BankAccountsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: bank_accounts_scailo_pb_js_1.BankAccountsServicePaginationReq,
            O: bank_accounts_scailo_pb_js_1.BankAccountsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.BankAccountsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: bank_accounts_scailo_pb_js_1.BankAccountsServiceSearchAllReq,
            O: bank_accounts_scailo_pb_js_1.BankAccountsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.BankAccountsService.Filter
         */
        filter: {
            name: "Filter",
            I: bank_accounts_scailo_pb_js_1.BankAccountsServiceFilterReq,
            O: bank_accounts_scailo_pb_js_1.BankAccountsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.BankAccountsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given count criteria
         *
         * @generated from rpc Scailo.BankAccountsService.Count
         */
        count: {
            name: "Count",
            I: bank_accounts_scailo_pb_js_1.BankAccountsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
