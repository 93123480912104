import { inwardJobFIMRFiltersMenu, inwardJobFIMRIndividualMenu, inwardJobFIMRInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleInwardJobFreeIssueMaterialReturnFilters } from "./freeissuereturns.filters";
import { handleInwardJobFreeIssueMaterialReturnInsights } from "./freeissuereturns.insights";
import { handleIndividualInwardJobFreeIssueMaterialReturn } from "./individual";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(inwardJobFIMRFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, inwardJobFIMRFiltersMenu)) {
            handleInwardJobFreeIssueMaterialReturnFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });

    r.add(inwardJobFIMRInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, inwardJobFIMRInsightsMenu)) {
            handleInwardJobFreeIssueMaterialReturnInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(inwardJobFIMRIndividualMenu.href, async (ctx) => {
        if (await interceptPage(ctx, inwardJobFIMRFiltersMenu)) {
            handleIndividualInwardJobFreeIssueMaterialReturn(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}