"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file general_settings.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneralSettingsService = void 0;
const general_settings_scailo_pb_js_1 = require("./general_settings.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on general settings
 *
 * @generated from service Scailo.GeneralSettingsService
 */
exports.GeneralSettingsService = {
    typeName: "Scailo.GeneralSettingsService",
    methods: {
        /**
         * Update General Settings
         *
         * @generated from rpc Scailo.GeneralSettingsService.UpdateSettings
         */
        updateSettings: {
            name: "UpdateSettings",
            I: general_settings_scailo_pb_js_1.GeneralSettingsUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the current settings
         *
         * @generated from rpc Scailo.GeneralSettingsService.ViewSettings
         */
        viewSettings: {
            name: "ViewSettings",
            I: base_scailo_pb_js_1.Empty,
            O: general_settings_scailo_pb_js_1.GeneralSettings,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update the logo of the organization
         *
         * @generated from rpc Scailo.GeneralSettingsService.UpdateOrganizationLogo
         */
        updateOrganizationLogo: {
            name: "UpdateOrganizationLogo",
            I: base_scailo_pb_js_1.StandardFile,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View current logo of the organization
         *
         * @generated from rpc Scailo.GeneralSettingsService.ViewOrganizationLogo
         */
        viewOrganizationLogo: {
            name: "ViewOrganizationLogo",
            I: base_scailo_pb_js_1.Empty,
            O: base_scailo_pb_js_1.ImageResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
