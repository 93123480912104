import { creditNoteFiltersMenu, creditNoteIndividualMenu, creditNoteInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleCreditNoteFilters } from "./creditnotes.filters";
import { handleCreditNoteInsights } from "./creditnotes.insights";
import { handleIndividualCreditNote } from "./individual";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(creditNoteFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, creditNoteFiltersMenu)) {
            handleCreditNoteFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(creditNoteInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, creditNoteInsightsMenu)) {
            handleCreditNoteInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(creditNoteIndividualMenu.href, async (ctx) => {
        if (await interceptPage(ctx, creditNoteFiltersMenu)) {
            handleIndividualCreditNote(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}