"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file inward_jobs.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.InwardJobsService = void 0;
const inward_jobs_scailo_pb_js_1 = require("./inward_jobs.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each inward job
 *
 * @generated from service Scailo.InwardJobsService
 */
exports.InwardJobsService = {
    typeName: "Scailo.InwardJobsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.InwardJobsService.Create
         */
        create: {
            name: "Create",
            I: inward_jobs_scailo_pb_js_1.InwardJobsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.InwardJobsService.Draft
         */
        draft: {
            name: "Draft",
            I: inward_jobs_scailo_pb_js_1.InwardJobsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.InwardJobsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: inward_jobs_scailo_pb_js_1.InwardJobsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.InwardJobsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.InwardJobsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.InwardJobsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.InwardJobsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.InwardJobsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: inward_jobs_scailo_pb_js_1.InwardJobsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.InwardJobsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.InwardJobsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.InwardJobsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.InwardJobsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.InwardJobsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.RepeatWithDeliveryDate,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.InwardJobsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.InwardJobsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.InwardJobsService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the inward job
         *
         * @generated from rpc Scailo.InwardJobsService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: inward_jobs_scailo_pb_js_1.InwardJobsServiceAutofillRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Inward Job can be marked as completed (is true when all the inward items have been ordered and all the outward items have been received)
         *
         * @generated from rpc Scailo.InwardJobsService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Inward items within an Inward Job have been ordered (through a Sales Order)
         *
         * @generated from rpc Scailo.InwardJobsService.IsOrdered
         */
        isOrdered: {
            name: "IsOrdered",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Outward items within an Inward Job have been received (through Free Issue Material)
         *
         * @generated from rpc Scailo.InwardJobsService.IsReceived
         */
        isReceived: {
            name: "IsReceived",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add multiple items to a inward job
         *
         * @generated from rpc Scailo.InwardJobsService.AddMultipleInwardJobInwardItems
         */
        addMultipleInwardJobInwardItems: {
            name: "AddMultipleInwardJobInwardItems",
            I: inward_jobs_scailo_pb_js_1.InwardJobsServiceMultipleInwardItemsCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an inward item to a inward job
         *
         * @generated from rpc Scailo.InwardJobsService.AddInwardJobInwardItem
         */
        addInwardJobInwardItem: {
            name: "AddInwardJobInwardItem",
            I: inward_jobs_scailo_pb_js_1.InwardJobsServiceInwardItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an inward item in a inward job
         *
         * @generated from rpc Scailo.InwardJobsService.ModifyInwardJobInwardItem
         */
        modifyInwardJobInwardItem: {
            name: "ModifyInwardJobInwardItem",
            I: inward_jobs_scailo_pb_js_1.InwardJobsServiceInwardItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an inward item in a inward job
         *
         * @generated from rpc Scailo.InwardJobsService.ApproveInwardJobInwardItem
         */
        approveInwardJobInwardItem: {
            name: "ApproveInwardJobInwardItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an inward item in a inward job
         *
         * @generated from rpc Scailo.InwardJobsService.DeleteInwardJobInwardItem
         */
        deleteInwardJobInwardItem: {
            name: "DeleteInwardJobInwardItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a inward job
         *
         * @generated from rpc Scailo.InwardJobsService.ReorderInwardJobInwardItems
         */
        reorderInwardJobInwardItems: {
            name: "ReorderInwardJobInwardItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Inward Job Inward Item by ID
         *
         * @generated from rpc Scailo.InwardJobsService.ViewInwardJobInwardItemByID
         */
        viewInwardJobInwardItemByID: {
            name: "ViewInwardJobInwardItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: inward_jobs_scailo_pb_js_1.InwardJobInwardItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved inward job inward items for given inward job ID
         *
         * @generated from rpc Scailo.InwardJobsService.ViewApprovedInwardJobInwardItems
         */
        viewApprovedInwardJobInwardItems: {
            name: "ViewApprovedInwardJobInwardItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: inward_jobs_scailo_pb_js_1.InwardJobsInwardItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved inward job inward items for given inward job ID
         *
         * @generated from rpc Scailo.InwardJobsService.ViewUnapprovedInwardJobInwardItems
         */
        viewUnapprovedInwardJobInwardItems: {
            name: "ViewUnapprovedInwardJobInwardItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: inward_jobs_scailo_pb_js_1.InwardJobsInwardItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the inward job inward item
         *
         * @generated from rpc Scailo.InwardJobsService.ViewInwardJobInwardItemHistory
         */
        viewInwardJobInwardItemHistory: {
            name: "ViewInwardJobInwardItemHistory",
            I: inward_jobs_scailo_pb_js_1.InwardJobInwardItemHistoryRequest,
            O: inward_jobs_scailo_pb_js_1.InwardJobsInwardItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved inward job inward items for given inward job ID with pagination
         *
         * @generated from rpc Scailo.InwardJobsService.ViewPaginatedApprovedInwardJobInwardItems
         */
        viewPaginatedApprovedInwardJobInwardItems: {
            name: "ViewPaginatedApprovedInwardJobInwardItems",
            I: inward_jobs_scailo_pb_js_1.InwardJobInwardItemsSearchRequest,
            O: inward_jobs_scailo_pb_js_1.InwardJobsServicePaginatedInwardItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved inward job inward items for given inward job ID with pagination
         *
         * @generated from rpc Scailo.InwardJobsService.ViewPaginatedUnapprovedInwardJobInwardItems
         */
        viewPaginatedUnapprovedInwardJobInwardItems: {
            name: "ViewPaginatedUnapprovedInwardJobInwardItems",
            I: inward_jobs_scailo_pb_js_1.InwardJobInwardItemsSearchRequest,
            O: inward_jobs_scailo_pb_js_1.InwardJobsServicePaginatedInwardItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through inward job inward items with pagination
         *
         * @generated from rpc Scailo.InwardJobsService.SearchInwardItemsWithPagination
         */
        searchInwardItemsWithPagination: {
            name: "SearchInwardItemsWithPagination",
            I: inward_jobs_scailo_pb_js_1.InwardJobInwardItemsSearchRequest,
            O: inward_jobs_scailo_pb_js_1.InwardJobsServicePaginatedInwardItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download the CSV template that could be used to upload inward items from the filled CSV file
         *
         * @generated from rpc Scailo.InwardJobsService.DownloadInwardItemsCSVTemplate
         */
        downloadInwardItemsCSVTemplate: {
            name: "DownloadInwardItemsCSVTemplate",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload inward items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.InwardJobsService.UploadInwardJobInwardItems
         */
        uploadInwardJobInwardItems: {
            name: "UploadInwardJobInwardItems",
            I: base_scailo_pb_js_1.IdentifierWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add multiple items to a inward job
         *
         * @generated from rpc Scailo.InwardJobsService.AddMultipleInwardJobOutwardItems
         */
        addMultipleInwardJobOutwardItems: {
            name: "AddMultipleInwardJobOutwardItems",
            I: inward_jobs_scailo_pb_js_1.InwardJobsServiceMultipleOutwardItemsCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an outward item to a inward job
         *
         * @generated from rpc Scailo.InwardJobsService.AddInwardJobOutwardItem
         */
        addInwardJobOutwardItem: {
            name: "AddInwardJobOutwardItem",
            I: inward_jobs_scailo_pb_js_1.InwardJobsServiceOutwardItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an outward item in a inward job
         *
         * @generated from rpc Scailo.InwardJobsService.ModifyInwardJobOutwardItem
         */
        modifyInwardJobOutwardItem: {
            name: "ModifyInwardJobOutwardItem",
            I: inward_jobs_scailo_pb_js_1.InwardJobsServiceOutwardItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an outward item in a inward job
         *
         * @generated from rpc Scailo.InwardJobsService.ApproveInwardJobOutwardItem
         */
        approveInwardJobOutwardItem: {
            name: "ApproveInwardJobOutwardItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an outward item in a inward job
         *
         * @generated from rpc Scailo.InwardJobsService.DeleteInwardJobOutwardItem
         */
        deleteInwardJobOutwardItem: {
            name: "DeleteInwardJobOutwardItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a inward job
         *
         * @generated from rpc Scailo.InwardJobsService.ReorderInwardJobOutwardItems
         */
        reorderInwardJobOutwardItems: {
            name: "ReorderInwardJobOutwardItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Inward Job Outward Item by ID
         *
         * @generated from rpc Scailo.InwardJobsService.ViewInwardJobOutwardItemByID
         */
        viewInwardJobOutwardItemByID: {
            name: "ViewInwardJobOutwardItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: inward_jobs_scailo_pb_js_1.InwardJobOutwardItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved inward job outward items for given inward job ID
         *
         * @generated from rpc Scailo.InwardJobsService.ViewApprovedInwardJobOutwardItems
         */
        viewApprovedInwardJobOutwardItems: {
            name: "ViewApprovedInwardJobOutwardItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: inward_jobs_scailo_pb_js_1.InwardJobsOutwardItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved inward job outward items for given inward job ID
         *
         * @generated from rpc Scailo.InwardJobsService.ViewUnapprovedInwardJobOutwardItems
         */
        viewUnapprovedInwardJobOutwardItems: {
            name: "ViewUnapprovedInwardJobOutwardItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: inward_jobs_scailo_pb_js_1.InwardJobsOutwardItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the inward job outward item
         *
         * @generated from rpc Scailo.InwardJobsService.ViewInwardJobOutwardItemHistory
         */
        viewInwardJobOutwardItemHistory: {
            name: "ViewInwardJobOutwardItemHistory",
            I: inward_jobs_scailo_pb_js_1.InwardJobOutwardItemHistoryRequest,
            O: inward_jobs_scailo_pb_js_1.InwardJobsOutwardItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved inward job outward items for given inward job ID with pagination
         *
         * @generated from rpc Scailo.InwardJobsService.ViewPaginatedApprovedInwardJobOutwardItems
         */
        viewPaginatedApprovedInwardJobOutwardItems: {
            name: "ViewPaginatedApprovedInwardJobOutwardItems",
            I: inward_jobs_scailo_pb_js_1.InwardJobOutwardItemsSearchRequest,
            O: inward_jobs_scailo_pb_js_1.InwardJobsServicePaginatedOutwardItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved inward job outward items for given inward job ID with pagination
         *
         * @generated from rpc Scailo.InwardJobsService.ViewPaginatedUnapprovedInwardJobOutwardItems
         */
        viewPaginatedUnapprovedInwardJobOutwardItems: {
            name: "ViewPaginatedUnapprovedInwardJobOutwardItems",
            I: inward_jobs_scailo_pb_js_1.InwardJobOutwardItemsSearchRequest,
            O: inward_jobs_scailo_pb_js_1.InwardJobsServicePaginatedOutwardItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through inward job outward items with pagination
         *
         * @generated from rpc Scailo.InwardJobsService.SearchOutwardItemsWithPagination
         */
        searchOutwardItemsWithPagination: {
            name: "SearchOutwardItemsWithPagination",
            I: inward_jobs_scailo_pb_js_1.InwardJobOutwardItemsSearchRequest,
            O: inward_jobs_scailo_pb_js_1.InwardJobsServicePaginatedOutwardItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download the CSV template that could be used to upload outward items from the filled CSV file
         *
         * @generated from rpc Scailo.InwardJobsService.DownloadOutwardItemsCSVTemplate
         */
        downloadOutwardItemsCSVTemplate: {
            name: "DownloadOutwardItemsCSVTemplate",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload outward items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.InwardJobsService.UploadInwardJobOutwardItems
         */
        uploadInwardJobOutwardItems: {
            name: "UploadInwardJobOutwardItems",
            I: base_scailo_pb_js_1.IdentifierWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a contact
         *
         * @generated from rpc Scailo.InwardJobsService.AddInwardJobContact
         */
        addInwardJobContact: {
            name: "AddInwardJobContact",
            I: inward_jobs_scailo_pb_js_1.InwardJobsServiceContactCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a contact
         *
         * @generated from rpc Scailo.InwardJobsService.ApproveInwardJobContact
         */
        approveInwardJobContact: {
            name: "ApproveInwardJobContact",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a contact
         *
         * @generated from rpc Scailo.InwardJobsService.DeleteInwardJobContact
         */
        deleteInwardJobContact: {
            name: "DeleteInwardJobContact",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a contact for the given ID
         *
         * @generated from rpc Scailo.InwardJobsService.ViewInwardJobContactByID
         */
        viewInwardJobContactByID: {
            name: "ViewInwardJobContactByID",
            I: base_scailo_pb_js_1.Identifier,
            O: inward_jobs_scailo_pb_js_1.InwardJobContact,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all contacts for given inward job UUID
         *
         * @generated from rpc Scailo.InwardJobsService.ViewInwardJobContacts
         */
        viewInwardJobContacts: {
            name: "ViewInwardJobContacts",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: inward_jobs_scailo_pb_js_1.InwardJobContactsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.InwardJobsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: inward_jobs_scailo_pb_js_1.InwardJob,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.InwardJobsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: inward_jobs_scailo_pb_js_1.InwardJob,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.InwardJobsService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: inward_jobs_scailo_pb_js_1.InwardJobAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.InwardJobsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: inward_jobs_scailo_pb_js_1.InwardJobsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.InwardJobsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: inward_jobs_scailo_pb_js_1.InwardJobsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.InwardJobsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: inward_jobs_scailo_pb_js_1.InwardJobsServicePaginationReq,
            O: inward_jobs_scailo_pb_js_1.InwardJobsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all sales order IDs that are associated with the given inward job ID
         *
         * @generated from rpc Scailo.InwardJobsService.ViewAssociatedSalesOrderIDs
         */
        viewAssociatedSalesOrderIDs: {
            name: "ViewAssociatedSalesOrderIDs",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective inward items for the given inward job (all families that could be purchased)
         *
         * @generated from rpc Scailo.InwardJobsService.ViewProspectiveInwardFamilies
         */
        viewProspectiveInwardFamilies: {
            name: "ViewProspectiveInwardFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective inward job inward item info for the given family ID and inward job ID
         *
         * @generated from rpc Scailo.InwardJobsService.ViewProspectiveInwardJobInwardItem
         */
        viewProspectiveInwardJobInwardItem: {
            name: "ViewProspectiveInwardJobInwardItem",
            I: inward_jobs_scailo_pb_js_1.InwardJobInwardItemProspectiveInfoRequest,
            O: inward_jobs_scailo_pb_js_1.InwardJobsServiceInwardItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective outward items for the given inward job (all families that could be sold)
         *
         * @generated from rpc Scailo.InwardJobsService.ViewProspectiveOutwardFamilies
         */
        viewProspectiveOutwardFamilies: {
            name: "ViewProspectiveOutwardFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective inward job outward item info for the given family ID and inward job ID
         *
         * @generated from rpc Scailo.InwardJobsService.ViewProspectiveInwardJobOutwardItem
         */
        viewProspectiveInwardJobOutwardItem: {
            name: "ViewProspectiveInwardJobOutwardItem",
            I: inward_jobs_scailo_pb_js_1.InwardJobOutwardItemProspectiveInfoRequest,
            O: inward_jobs_scailo_pb_js_1.InwardJobsServiceOutwardItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download inward job with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.InwardJobsService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.InwardJobsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: inward_jobs_scailo_pb_js_1.InwardJobsServiceSearchAllReq,
            O: inward_jobs_scailo_pb_js_1.InwardJobsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.InwardJobsService.Filter
         */
        filter: {
            name: "Filter",
            I: inward_jobs_scailo_pb_js_1.InwardJobsServiceFilterReq,
            O: inward_jobs_scailo_pb_js_1.InwardJobsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.InwardJobsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.InwardJobsService.Count
         */
        count: {
            name: "Count",
            I: inward_jobs_scailo_pb_js_1.InwardJobsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
