"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file sales_returns.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesReturnsServiceSearchAllReq = exports.SalesReturnsServiceCountReq = exports.SalesReturnsServiceFilterReq = exports.SalesReturnsServicePaginationResponse = exports.SalesReturnsServicePaginationReq = exports.SalesReturnsServiceAlreadyAddedQuantityForSourceRequest = exports.SalesReturnsServicePaginatedItemsResponse = exports.SalesReturnItemsSearchRequest = exports.SalesReturnItemProspectiveInfoRequest = exports.SalesReturnItemHistoryRequest = exports.SalesReturnsItemsList = exports.SalesReturnsList = exports.SalesReturnItem = exports.SalesReturnsServiceItemUpdateRequest = exports.SalesReturnsServiceItemCreateRequest = exports.SalesReturn = exports.SalesReturnAncillaryParameters = exports.SalesReturnsServiceUpdateRequest = exports.SalesReturnsServiceCreateRequest = exports.SALES_RETURN_BILLING_STATUS = exports.SALES_RETURN_SORT_KEY = exports.SALES_RETURN_ITEM_STATUS = exports.SALES_RETURN_ITEM_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.SALES_RETURN_ITEM_SORT_KEY
 */
var SALES_RETURN_ITEM_SORT_KEY;
(function (SALES_RETURN_ITEM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: SALES_RETURN_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    SALES_RETURN_ITEM_SORT_KEY[SALES_RETURN_ITEM_SORT_KEY["SALES_RETURN_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "SALES_RETURN_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: SALES_RETURN_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    SALES_RETURN_ITEM_SORT_KEY[SALES_RETURN_ITEM_SORT_KEY["SALES_RETURN_ITEM_SORT_KEY_CREATED_AT"] = 1] = "SALES_RETURN_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: SALES_RETURN_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    SALES_RETURN_ITEM_SORT_KEY[SALES_RETURN_ITEM_SORT_KEY["SALES_RETURN_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "SALES_RETURN_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: SALES_RETURN_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    SALES_RETURN_ITEM_SORT_KEY[SALES_RETURN_ITEM_SORT_KEY["SALES_RETURN_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "SALES_RETURN_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: SALES_RETURN_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    SALES_RETURN_ITEM_SORT_KEY[SALES_RETURN_ITEM_SORT_KEY["SALES_RETURN_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "SALES_RETURN_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: SALES_RETURN_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    SALES_RETURN_ITEM_SORT_KEY[SALES_RETURN_ITEM_SORT_KEY["SALES_RETURN_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "SALES_RETURN_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the family ID
     *
     * @generated from enum value: SALES_RETURN_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    SALES_RETURN_ITEM_SORT_KEY[SALES_RETURN_ITEM_SORT_KEY["SALES_RETURN_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "SALES_RETURN_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch ordered results by the internal quantity
     *
     * @generated from enum value: SALES_RETURN_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    SALES_RETURN_ITEM_SORT_KEY[SALES_RETURN_ITEM_SORT_KEY["SALES_RETURN_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "SALES_RETURN_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch ordered results by the client unit of material ID
     *
     * @generated from enum value: SALES_RETURN_ITEM_SORT_KEY_CLIENT_UOM_ID = 12;
     */
    SALES_RETURN_ITEM_SORT_KEY[SALES_RETURN_ITEM_SORT_KEY["SALES_RETURN_ITEM_SORT_KEY_CLIENT_UOM_ID"] = 12] = "SALES_RETURN_ITEM_SORT_KEY_CLIENT_UOM_ID";
    /**
     * Fetch ordered results by the client quantity
     *
     * @generated from enum value: SALES_RETURN_ITEM_SORT_KEY_CLIENT_QUANTITY = 13;
     */
    SALES_RETURN_ITEM_SORT_KEY[SALES_RETURN_ITEM_SORT_KEY["SALES_RETURN_ITEM_SORT_KEY_CLIENT_QUANTITY"] = 13] = "SALES_RETURN_ITEM_SORT_KEY_CLIENT_QUANTITY";
})(SALES_RETURN_ITEM_SORT_KEY || (exports.SALES_RETURN_ITEM_SORT_KEY = SALES_RETURN_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_RETURN_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(SALES_RETURN_ITEM_SORT_KEY, "Scailo.SALES_RETURN_ITEM_SORT_KEY", [
    { no: 0, name: "SALES_RETURN_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "SALES_RETURN_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "SALES_RETURN_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "SALES_RETURN_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "SALES_RETURN_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "SALES_RETURN_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "SALES_RETURN_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "SALES_RETURN_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "SALES_RETURN_ITEM_SORT_KEY_CLIENT_UOM_ID" },
    { no: 13, name: "SALES_RETURN_ITEM_SORT_KEY_CLIENT_QUANTITY" },
]);
/**
 *
 * Describes the applicable statuses of sales return items
 *
 * @generated from enum Scailo.SALES_RETURN_ITEM_STATUS
 */
var SALES_RETURN_ITEM_STATUS;
(function (SALES_RETURN_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: SALES_RETURN_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    SALES_RETURN_ITEM_STATUS[SALES_RETURN_ITEM_STATUS["SALES_RETURN_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "SALES_RETURN_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the sales return items must have been approved
     *
     * @generated from enum value: SALES_RETURN_ITEM_STATUS_APPROVED = 1;
     */
    SALES_RETURN_ITEM_STATUS[SALES_RETURN_ITEM_STATUS["SALES_RETURN_ITEM_STATUS_APPROVED"] = 1] = "SALES_RETURN_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the sales return items must be waiting for approval
     *
     * @generated from enum value: SALES_RETURN_ITEM_STATUS_UNAPPROVED = 2;
     */
    SALES_RETURN_ITEM_STATUS[SALES_RETURN_ITEM_STATUS["SALES_RETURN_ITEM_STATUS_UNAPPROVED"] = 2] = "SALES_RETURN_ITEM_STATUS_UNAPPROVED";
})(SALES_RETURN_ITEM_STATUS || (exports.SALES_RETURN_ITEM_STATUS = SALES_RETURN_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_RETURN_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(SALES_RETURN_ITEM_STATUS, "Scailo.SALES_RETURN_ITEM_STATUS", [
    { no: 0, name: "SALES_RETURN_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "SALES_RETURN_ITEM_STATUS_APPROVED" },
    { no: 2, name: "SALES_RETURN_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.SALES_RETURN_SORT_KEY
 */
var SALES_RETURN_SORT_KEY;
(function (SALES_RETURN_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: SALES_RETURN_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    SALES_RETURN_SORT_KEY[SALES_RETURN_SORT_KEY["SALES_RETURN_SORT_KEY_ID_UNSPECIFIED"] = 0] = "SALES_RETURN_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: SALES_RETURN_SORT_KEY_CREATED_AT = 1;
     */
    SALES_RETURN_SORT_KEY[SALES_RETURN_SORT_KEY["SALES_RETURN_SORT_KEY_CREATED_AT"] = 1] = "SALES_RETURN_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: SALES_RETURN_SORT_KEY_MODIFIED_AT = 2;
     */
    SALES_RETURN_SORT_KEY[SALES_RETURN_SORT_KEY["SALES_RETURN_SORT_KEY_MODIFIED_AT"] = 2] = "SALES_RETURN_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: SALES_RETURN_SORT_KEY_APPROVED_ON = 3;
     */
    SALES_RETURN_SORT_KEY[SALES_RETURN_SORT_KEY["SALES_RETURN_SORT_KEY_APPROVED_ON"] = 3] = "SALES_RETURN_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: SALES_RETURN_SORT_KEY_APPROVED_BY = 4;
     */
    SALES_RETURN_SORT_KEY[SALES_RETURN_SORT_KEY["SALES_RETURN_SORT_KEY_APPROVED_BY"] = 4] = "SALES_RETURN_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: SALES_RETURN_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    SALES_RETURN_SORT_KEY[SALES_RETURN_SORT_KEY["SALES_RETURN_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "SALES_RETURN_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: SALES_RETURN_SORT_KEY_COMPLETED_ON = 6;
     */
    SALES_RETURN_SORT_KEY[SALES_RETURN_SORT_KEY["SALES_RETURN_SORT_KEY_COMPLETED_ON"] = 6] = "SALES_RETURN_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: SALES_RETURN_SORT_KEY_REFERENCE_ID = 10;
     */
    SALES_RETURN_SORT_KEY[SALES_RETURN_SORT_KEY["SALES_RETURN_SORT_KEY_REFERENCE_ID"] = 10] = "SALES_RETURN_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: SALES_RETURN_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    SALES_RETURN_SORT_KEY[SALES_RETURN_SORT_KEY["SALES_RETURN_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "SALES_RETURN_SORT_KEY_FINAL_REF_NUMBER";
})(SALES_RETURN_SORT_KEY || (exports.SALES_RETURN_SORT_KEY = SALES_RETURN_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_RETURN_SORT_KEY)
protobuf_1.proto3.util.setEnumType(SALES_RETURN_SORT_KEY, "Scailo.SALES_RETURN_SORT_KEY", [
    { no: 0, name: "SALES_RETURN_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "SALES_RETURN_SORT_KEY_CREATED_AT" },
    { no: 2, name: "SALES_RETURN_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "SALES_RETURN_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "SALES_RETURN_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "SALES_RETURN_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "SALES_RETURN_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "SALES_RETURN_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "SALES_RETURN_SORT_KEY_FINAL_REF_NUMBER" },
]);
/**
 *
 * Describes the available billing statuses
 *
 * @generated from enum Scailo.SALES_RETURN_BILLING_STATUS
 */
var SALES_RETURN_BILLING_STATUS;
(function (SALES_RETURN_BILLING_STATUS) {
    /**
     * Any billing status
     *
     * @generated from enum value: SALES_RETURN_BILLING_STATUS_ANY_UNSPECIFIED = 0;
     */
    SALES_RETURN_BILLING_STATUS[SALES_RETURN_BILLING_STATUS["SALES_RETURN_BILLING_STATUS_ANY_UNSPECIFIED"] = 0] = "SALES_RETURN_BILLING_STATUS_ANY_UNSPECIFIED";
    /**
     * Sales return is billed
     *
     * @generated from enum value: SALES_RETURN_BILLING_STATUS_BILLED = 1;
     */
    SALES_RETURN_BILLING_STATUS[SALES_RETURN_BILLING_STATUS["SALES_RETURN_BILLING_STATUS_BILLED"] = 1] = "SALES_RETURN_BILLING_STATUS_BILLED";
    /**
     * Sales return is unbilled
     *
     * @generated from enum value: SALES_RETURN_BILLING_STATUS_UNBILLED = 2;
     */
    SALES_RETURN_BILLING_STATUS[SALES_RETURN_BILLING_STATUS["SALES_RETURN_BILLING_STATUS_UNBILLED"] = 2] = "SALES_RETURN_BILLING_STATUS_UNBILLED";
})(SALES_RETURN_BILLING_STATUS || (exports.SALES_RETURN_BILLING_STATUS = SALES_RETURN_BILLING_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(SALES_RETURN_BILLING_STATUS)
protobuf_1.proto3.util.setEnumType(SALES_RETURN_BILLING_STATUS, "Scailo.SALES_RETURN_BILLING_STATUS", [
    { no: 0, name: "SALES_RETURN_BILLING_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "SALES_RETURN_BILLING_STATUS_BILLED" },
    { no: 2, name: "SALES_RETURN_BILLING_STATUS_UNBILLED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.SalesReturnsServiceCreateRequest
 */
class SalesReturnsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales return
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 15;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnsServiceCreateRequest, a, b);
    }
}
exports.SalesReturnsServiceCreateRequest = SalesReturnsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.SalesReturnsServiceUpdateRequest
 */
class SalesReturnsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales return
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnsServiceUpdateRequest, a, b);
    }
}
exports.SalesReturnsServiceUpdateRequest = SalesReturnsServiceUpdateRequest;
/**
 *
 * Stores the UUID references of the record
 *
 * @generated from message Scailo.SalesReturnAncillaryParameters
 */
class SalesReturnAncillaryParameters extends protobuf_1.Message {
    /**
     * The UUID of the ref_id (the UUID of the associated ref_id)
     *
     * @generated from field: string ref_uuid = 213;
     */
    refUuid = "";
    /**
     * The UUID of the location (the UUID of the associated location_id)
     *
     * @generated from field: string location_uuid = 215;
     */
    locationUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnAncillaryParameters";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 213, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 215, name: "location_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnAncillaryParameters().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnAncillaryParameters().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnAncillaryParameters().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnAncillaryParameters, a, b);
    }
}
exports.SalesReturnAncillaryParameters = SalesReturnAncillaryParameters;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.SalesReturn
 */
class SalesReturn extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales return
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this sales return
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this sales return
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this sales return was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales return
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 15;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The list of associated sales return items
     *
     * @generated from field: repeated Scailo.SalesReturnItem list = 20;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturn";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "list", kind: "message", T: SalesReturnItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturn().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturn().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturn().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturn, a, b);
    }
}
exports.SalesReturn = SalesReturn;
/**
 *
 * Describes the parameters required to add an item to a sales return
 *
 * @generated from message Scailo.SalesReturnsServiceItemCreateRequest
 */
class SalesReturnsServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the sales return ID
     *
     * @generated from field: uint64 sales_return_id = 10;
     */
    salesReturnId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash to be dispatched
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The quantity (in cents) being dispatched in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 14;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being dispatched in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 15;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnsServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnsServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnsServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnsServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnsServiceItemCreateRequest, a, b);
    }
}
exports.SalesReturnsServiceItemCreateRequest = SalesReturnsServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a sales return
 *
 * @generated from message Scailo.SalesReturnsServiceItemUpdateRequest
 */
class SalesReturnsServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash to be dispatched
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The quantity (in cents) being dispatched in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 14;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being dispatched in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 15;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnsServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnsServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnsServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnsServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnsServiceItemUpdateRequest, a, b);
    }
}
exports.SalesReturnsServiceItemUpdateRequest = SalesReturnsServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a sales return
 *
 * @generated from message Scailo.SalesReturnItem
 */
class SalesReturnItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this sales return
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the sales return ID
     *
     * @generated from field: uint64 sales_return_id = 10;
     */
    salesReturnId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash to be dispatched
     *
     * @generated from field: string item_hash = 12;
     */
    itemHash = "";
    /**
     * The quantity (in cents) being dispatched in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 13;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 14;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being dispatched in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 15;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "sales_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnItem, a, b);
    }
}
exports.SalesReturnItem = SalesReturnItem;
/**
 *
 * Describes the message consisting of the list of sales returns
 *
 * @generated from message Scailo.SalesReturnsList
 */
class SalesReturnsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesReturn list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesReturn, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnsList, a, b);
    }
}
exports.SalesReturnsList = SalesReturnsList;
/**
 *
 * Describes the message consisting of the list of sales return items
 *
 * @generated from message Scailo.SalesReturnsItemsList
 */
class SalesReturnsItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.SalesReturnItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnsItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: SalesReturnItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnsItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnsItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnsItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnsItemsList, a, b);
    }
}
exports.SalesReturnsItemsList = SalesReturnsItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.SalesReturnItemHistoryRequest
 */
class SalesReturnItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the sales return ID
     *
     * @generated from field: uint64 sales_return_id = 10;
     */
    salesReturnId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "sales_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnItemHistoryRequest, a, b);
    }
}
exports.SalesReturnItemHistoryRequest = SalesReturnItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective sales return item
 *
 * @generated from message Scailo.SalesReturnItemProspectiveInfoRequest
 */
class SalesReturnItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the sales return ID
     *
     * @generated from field: uint64 sales_return_id = 10;
     */
    salesReturnId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "sales_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnItemProspectiveInfoRequest, a, b);
    }
}
exports.SalesReturnItemProspectiveInfoRequest = SalesReturnItemProspectiveInfoRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.SalesReturnItemsSearchRequest
 */
class SalesReturnItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_RETURN_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_RETURN_ITEM_SORT_KEY.SALES_RETURN_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.SALES_RETURN_ITEM_STATUS status = 7;
     */
    status = SALES_RETURN_ITEM_STATUS.SALES_RETURN_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the sales return
     *
     * @generated from field: uint64 sales_return_id = 20;
     */
    salesReturnId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 22;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Stores the item hash to be dispatched
     *
     * @generated from field: string item_hash = 23;
     */
    itemHash = "";
    /**
     * The ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 24;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_RETURN_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_RETURN_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "sales_return_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "item_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnItemsSearchRequest, a, b);
    }
}
exports.SalesReturnItemsSearchRequest = SalesReturnItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.SalesReturnsServicePaginatedItemsResponse
 */
class SalesReturnsServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.SalesReturnItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnsServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: SalesReturnItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnsServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnsServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnsServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnsServicePaginatedItemsResponse, a, b);
    }
}
exports.SalesReturnsServicePaginatedItemsResponse = SalesReturnsServicePaginatedItemsResponse;
/**
 *
 * Describes the request payload to retrieve the quantity that has already been added for the specific ref_from, ref_id and family_id
 *
 * @generated from message Scailo.SalesReturnsServiceAlreadyAddedQuantityForSourceRequest
 */
class SalesReturnsServiceAlreadyAddedQuantityForSourceRequest extends protobuf_1.Message {
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 1;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 2;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated family ID
     *
     * @generated from field: uint64 family_id = 3;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnsServiceAlreadyAddedQuantityForSourceRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnsServiceAlreadyAddedQuantityForSourceRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnsServiceAlreadyAddedQuantityForSourceRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnsServiceAlreadyAddedQuantityForSourceRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnsServiceAlreadyAddedQuantityForSourceRequest, a, b);
    }
}
exports.SalesReturnsServiceAlreadyAddedQuantityForSourceRequest = SalesReturnsServiceAlreadyAddedQuantityForSourceRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.SalesReturnsServicePaginationReq
 */
class SalesReturnsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_RETURN_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_RETURN_SORT_KEY.SALES_RETURN_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this sales return
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_RETURN_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnsServicePaginationReq, a, b);
    }
}
exports.SalesReturnsServicePaginationReq = SalesReturnsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.SalesReturnsServicePaginationResponse
 */
class SalesReturnsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.SalesReturn payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: SalesReturn, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnsServicePaginationResponse, a, b);
    }
}
exports.SalesReturnsServicePaginationResponse = SalesReturnsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.SalesReturnsServiceFilterReq
 */
class SalesReturnsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_RETURN_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_RETURN_SORT_KEY.SALES_RETURN_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this sales return
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales return
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 25;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The status of the sales return bill
     *
     * @generated from field: Scailo.SALES_RETURN_BILLING_STATUS billing_status = 40;
     */
    billingStatus = SALES_RETURN_BILLING_STATUS.SALES_RETURN_BILLING_STATUS_ANY_UNSPECIFIED;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 50;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Sales Order related filters
     * The associated consignee client ID of the linked sales order
     *
     * @generated from field: uint64 consignee_client_id = 60;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked sales order
     *
     * @generated from field: uint64 buyer_client_id = 61;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project of the linked sales order
     *
     * @generated from field: uint64 project_id = 62;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_RETURN_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "billing_status", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_RETURN_BILLING_STATUS) },
        { no: 50, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 61, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 62, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnsServiceFilterReq, a, b);
    }
}
exports.SalesReturnsServiceFilterReq = SalesReturnsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.SalesReturnsServiceCountReq
 */
class SalesReturnsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this sales return
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the sales return
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The location ID of where the record is created
     *
     * @generated from field: uint64 location_id = 25;
     */
    locationId = protobuf_1.protoInt64.zero;
    /**
     * The status of the sales return bill
     *
     * @generated from field: Scailo.SALES_RETURN_BILLING_STATUS billing_status = 40;
     */
    billingStatus = SALES_RETURN_BILLING_STATUS.SALES_RETURN_BILLING_STATUS_ANY_UNSPECIFIED;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 50;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * Sales Order related filters
     * The associated consignee client ID of the linked sales order
     *
     * @generated from field: uint64 consignee_client_id = 60;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked sales order
     *
     * @generated from field: uint64 buyer_client_id = 61;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project of the linked sales order
     *
     * @generated from field: uint64 project_id = 62;
     */
    projectId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "location_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "billing_status", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_RETURN_BILLING_STATUS) },
        { no: 50, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 61, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 62, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnsServiceCountReq, a, b);
    }
}
exports.SalesReturnsServiceCountReq = SalesReturnsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.SalesReturnsServiceSearchAllReq
 */
class SalesReturnsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SALES_RETURN_SORT_KEY sort_key = 5;
     */
    sortKey = SALES_RETURN_SORT_KEY.SALES_RETURN_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The status of the sales return bill
     *
     * @generated from field: Scailo.SALES_RETURN_BILLING_STATUS billing_status = 40;
     */
    billingStatus = SALES_RETURN_BILLING_STATUS.SALES_RETURN_BILLING_STATUS_ANY_UNSPECIFIED;
    /**
     * Sales Order related filters
     * The associated consignee client ID of the linked sales order
     *
     * @generated from field: uint64 consignee_client_id = 60;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked sales order
     *
     * @generated from field: uint64 buyer_client_id = 61;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.SalesReturnsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_RETURN_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "billing_status", kind: "enum", T: protobuf_1.proto3.getEnumType(SALES_RETURN_BILLING_STATUS) },
        { no: 60, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 61, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new SalesReturnsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SalesReturnsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SalesReturnsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(SalesReturnsServiceSearchAllReq, a, b);
    }
}
exports.SalesReturnsServiceSearchAllReq = SalesReturnsServiceSearchAllReq;
