import { salesOrderFiltersMenu, salesOrderIndividualMenu, salesOrderInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleSalesOrderFilters } from "./salesorders.filters";
import { handleSalesOrderInsights } from "./salesorders.insights";
import { handleIndividualSalesOrder } from "./individual";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(salesOrderFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, salesOrderFiltersMenu)) {
            handleSalesOrderFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(salesOrderInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, salesOrderInsightsMenu)) {
            handleSalesOrderInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(salesOrderIndividualMenu.href, async (ctx) => {
        if (await interceptPage(ctx, salesOrderFiltersMenu)) {
            handleIndividualSalesOrder(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}