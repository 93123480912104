import * as ui from "../../ui";
import { _returnInCents, randomId } from "../../utilities";
import { SALES_RETURN_BILLING_STATUS, SALES_RETURN_SORT_KEY } from "@kernelminds/scailo-sdk";

function renderPrimaryFiltersSection() {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Primary Filters", titleMdColSpan: 3 });

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Reference Id", inputType: "text", dataMapper: "referenceId", dataType: "string", value: "", mdColSpan: 6, helpText: "Reference Id is the primary identifier that is created manually. Filter records by the value of this field. Leave it empty to ignore this field." }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Final Ref Number", inputType: "text", dataMapper: "finalRefNumber", dataType: "string", value: "", mdColSpan: 6, helpText: "Final Ref Number is an automatically assigned sequence number that is created when the record is approved. Filter records by the value of this field. Leave it empty to ignore this field." }));

    contentGrid.appendChild(ui.renderSelectForEntireEnum({
        id: randomId(),
        label: "Billing Status",
        nameSplitsAt: "SALES_RETURN_BILLING_STATUS_",
        enumObject: SALES_RETURN_BILLING_STATUS,
        dataMapper: "billingStatus",
        dataType: "number",
        value: "",
        mdColSpan: 6,
        helpText: "Filter by billing status. Set it to 'Any' to ignore this field. Set it to 'Billed' to only show billed records. Set it to 'Unbilled' to only show unbilled records.",
        excludeZeroethValuedEnum: false,
        readonly: false,
    }));

    contentGrid.appendChild(ui.emptyDiv());

    const refIdElementId = randomId();
    contentGrid.appendChild(ui.renderSelect({ id: refIdElementId, readonly: false, label: "Sales Order Reference", dataMapper: "refId", dataType: "bigint", value: "", mdColSpan: 6, helpText: "Select the Sales Order.", options: [] }));

    const locationIdElementId = randomId();
    contentGrid.appendChild(ui.renderSelect({ id: locationIdElementId, readonly: false, label: "Location Reference", dataMapper: "locationId", dataType: "bigint", value: "", mdColSpan: 6, helpText: "Select the Location.", options: [] }));

    return { grid, refIdElementId, locationIdElementId }
}

export function _renderPageFilters(context: "Records" | "Insights") {
    let primaryFiltersSection = renderPrimaryFiltersSection();
    let statusFiltersSection = ui.renderStatusFiltersSection();
    let timeFiltersSection = ui.renderTimeFiltersSection();
    let sortFiltersSection = ui.renderSortFiltersSection();

    // Attach specific sort key
    sortFiltersSection.contentGrid.prepend(ui.renderSelectForPartialEnum({
        id: randomId(),
        label: "Sort By",
        nameSplitsAt: "SALES_RETURN_SORT_KEY_",
        enumObject: SALES_RETURN_SORT_KEY,
        enumValues: [
            SALES_RETURN_SORT_KEY.SALES_RETURN_SORT_KEY_CREATED_AT,
            SALES_RETURN_SORT_KEY.SALES_RETURN_SORT_KEY_MODIFIED_AT,
            SALES_RETURN_SORT_KEY.SALES_RETURN_SORT_KEY_APPROVED_ON,
            SALES_RETURN_SORT_KEY.SALES_RETURN_SORT_KEY_COMPLETED_ON,
            SALES_RETURN_SORT_KEY.SALES_RETURN_SORT_KEY_REFERENCE_ID,
            SALES_RETURN_SORT_KEY.SALES_RETURN_SORT_KEY_FINAL_REF_NUMBER,
        ],
        dataMapper: "sortKey",
        dataType: "number",
        value: "",
        mdColSpan: 12,
        helpText: "Sort the response with the selected field.",
        excludeZeroethValuedEnum: false,
        readonly: false,
    }));

    let buttonsDiv = document.createElement("div");
    buttonsDiv.className = `col-span-12 pl-4 float-right`;

    let getButtonId = randomId();
    let getButton = document.createElement("button");
    getButton.id = getButtonId;
    getButton.className = "btn btn-outline btn-success btn-md mr-4";
    getButton.innerText = `Get ${context}`;
    buttonsDiv.append(getButton);

    let resetButtonId = randomId();
    let resetButton = document.createElement("button");
    resetButton.id = resetButtonId;
    resetButton.className = "btn btn-outline btn-info btn-md mr-4";
    resetButton.innerText = "Reset";
    buttonsDiv.append(resetButton);

    const formId = randomId();

    return {
        html: `
            <div class="grid grid-cols-1 gap-6 mb-6">
                <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f5f8f3] w-full shadow-lg rounded">
                    <div class="rounded-t mb-0 px-0 border-0">
                        ${ui.renderPageTitleSection({ title: "Search for Sales Returns" }).outerHTML}
                    </div>
                    <form id="${formId}">
                        ${primaryFiltersSection.grid.outerHTML}
                        <hr class="m-5">
                        ${statusFiltersSection.outerHTML}
                        <hr class="m-5">
                        ${timeFiltersSection.outerHTML}
                        <hr class="m-5">
                        ${sortFiltersSection.grid.outerHTML}
                        <hr class="m-5">
                        ${buttonsDiv.outerHTML}
                    </form>
                </div>
            </div>
        `,
        formId,
        resetButtonId,
        getButtonId,
        refIdElementId: primaryFiltersSection.refIdElementId,
        locationIdElementId: primaryFiltersSection.locationIdElementId
    } 
}