"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file client_streams.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientStreamClientSubscribersList = exports.ClientStreamClientSubscriber = exports.ClientStreamsServiceClientSubscriberCreateRequest = exports.ClientStreamsServiceImportInternalSubscribersRequest = exports.ClientStreamInternalSubscribersList = exports.ClientStreamInternalSubscriber = exports.ClientStreamsServiceInternalSubscriberCreateRequest = exports.ClientStreamMessageReceiptsList = exports.ClientStreamMessageReceipt = exports.ClientStreamsServicePaginatedMessagesResponse = exports.ClientStreamMessagesSearchRequest = exports.ClientStreamMessagesList = exports.ClientStreamMessage = exports.ClientStreamsServiceMessageCreateRequest = exports.ClientStreamsServiceSearchAllReq = exports.ClientStreamsServiceCountReq = exports.ClientStreamsServiceFilterReq = exports.ClientStreamsServicePaginationResponse = exports.ClientStreamsServicePaginationReq = exports.ClientStreamsList = exports.ClientStream = exports.ClientStreamsServiceUpdateRequest = exports.ClientStreamsServiceCreateRequest = exports.LogbookLogClientStreamLC = exports.CLIENT_STREAM_MESSAGE_SORT_KEY = exports.CLIENT_STREAM_MESSAGE_TYPE = exports.CLIENT_STREAM_SORT_KEY = exports.CLIENT_STREAM_REF_FROM = exports.CLIENT_STREAM_LIFECYCLE = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the lifecycle status of each client stream
 *
 * @generated from enum Scailo.CLIENT_STREAM_LIFECYCLE
 */
var CLIENT_STREAM_LIFECYCLE;
(function (CLIENT_STREAM_LIFECYCLE) {
    /**
     * Used only in filters
     *
     * @generated from enum value: CLIENT_STREAM_LIFECYCLE_ANY_UNSPECIFIED = 0;
     */
    CLIENT_STREAM_LIFECYCLE[CLIENT_STREAM_LIFECYCLE["CLIENT_STREAM_LIFECYCLE_ANY_UNSPECIFIED"] = 0] = "CLIENT_STREAM_LIFECYCLE_ANY_UNSPECIFIED";
    /**
     * Denotes that the client stream is open
     *
     * @generated from enum value: CLIENT_STREAM_LIFECYCLE_OPEN = 1;
     */
    CLIENT_STREAM_LIFECYCLE[CLIENT_STREAM_LIFECYCLE["CLIENT_STREAM_LIFECYCLE_OPEN"] = 1] = "CLIENT_STREAM_LIFECYCLE_OPEN";
    /**
     * Denotes that the client stream has completed
     *
     * @generated from enum value: CLIENT_STREAM_LIFECYCLE_COMPLETED = 2;
     */
    CLIENT_STREAM_LIFECYCLE[CLIENT_STREAM_LIFECYCLE["CLIENT_STREAM_LIFECYCLE_COMPLETED"] = 2] = "CLIENT_STREAM_LIFECYCLE_COMPLETED";
    /**
     * Denotes that the client stream has been cancelled
     *
     * @generated from enum value: CLIENT_STREAM_LIFECYCLE_CANCELLED = 3;
     */
    CLIENT_STREAM_LIFECYCLE[CLIENT_STREAM_LIFECYCLE["CLIENT_STREAM_LIFECYCLE_CANCELLED"] = 3] = "CLIENT_STREAM_LIFECYCLE_CANCELLED";
})(CLIENT_STREAM_LIFECYCLE || (exports.CLIENT_STREAM_LIFECYCLE = CLIENT_STREAM_LIFECYCLE = {}));
// Retrieve enum metadata with: proto3.getEnumType(CLIENT_STREAM_LIFECYCLE)
protobuf_1.proto3.util.setEnumType(CLIENT_STREAM_LIFECYCLE, "Scailo.CLIENT_STREAM_LIFECYCLE", [
    { no: 0, name: "CLIENT_STREAM_LIFECYCLE_ANY_UNSPECIFIED" },
    { no: 1, name: "CLIENT_STREAM_LIFECYCLE_OPEN" },
    { no: 2, name: "CLIENT_STREAM_LIFECYCLE_COMPLETED" },
    { no: 3, name: "CLIENT_STREAM_LIFECYCLE_CANCELLED" },
]);
/**
 *
 * Describes the available client stream references
 *
 * @generated from enum Scailo.CLIENT_STREAM_REF_FROM
 */
var CLIENT_STREAM_REF_FROM;
(function (CLIENT_STREAM_REF_FROM) {
    /**
     * Used only in filters
     *
     * @generated from enum value: CLIENT_STREAM_REF_FROM_ANY_UNSPECIFIED = 0;
     */
    CLIENT_STREAM_REF_FROM[CLIENT_STREAM_REF_FROM["CLIENT_STREAM_REF_FROM_ANY_UNSPECIFIED"] = 0] = "CLIENT_STREAM_REF_FROM_ANY_UNSPECIFIED";
    /**
     * Denotes that the reference is from a sales order
     *
     * @generated from enum value: CLIENT_STREAM_REF_FROM_SALES_ORDER = 1;
     */
    CLIENT_STREAM_REF_FROM[CLIENT_STREAM_REF_FROM["CLIENT_STREAM_REF_FROM_SALES_ORDER"] = 1] = "CLIENT_STREAM_REF_FROM_SALES_ORDER";
    /**
     * Denotes that the reference is from a goods dispatch
     *
     * @generated from enum value: CLIENT_STREAM_REF_FROM_GOODS_DISPATCH = 10;
     */
    CLIENT_STREAM_REF_FROM[CLIENT_STREAM_REF_FROM["CLIENT_STREAM_REF_FROM_GOODS_DISPATCH"] = 10] = "CLIENT_STREAM_REF_FROM_GOODS_DISPATCH";
    /**
     * Denotes that the reference is from a sales invoice
     *
     * @generated from enum value: CLIENT_STREAM_REF_FROM_SALES_INVOICE = 11;
     */
    CLIENT_STREAM_REF_FROM[CLIENT_STREAM_REF_FROM["CLIENT_STREAM_REF_FROM_SALES_INVOICE"] = 11] = "CLIENT_STREAM_REF_FROM_SALES_INVOICE";
    /**
     * Denotes that the reference is from a sales return
     *
     * @generated from enum value: CLIENT_STREAM_REF_FROM_SALES_RETURN = 20;
     */
    CLIENT_STREAM_REF_FROM[CLIENT_STREAM_REF_FROM["CLIENT_STREAM_REF_FROM_SALES_RETURN"] = 20] = "CLIENT_STREAM_REF_FROM_SALES_RETURN";
    /**
     * Denotes that the reference is from a credit note
     *
     * @generated from enum value: CLIENT_STREAM_REF_FROM_CREDIT_NOTE = 21;
     */
    CLIENT_STREAM_REF_FROM[CLIENT_STREAM_REF_FROM["CLIENT_STREAM_REF_FROM_CREDIT_NOTE"] = 21] = "CLIENT_STREAM_REF_FROM_CREDIT_NOTE";
    /**
     * Denotes that the reference is from a sales receipt
     *
     * @generated from enum value: CLIENT_STREAM_REF_FROM_SALES_RECEIPT = 30;
     */
    CLIENT_STREAM_REF_FROM[CLIENT_STREAM_REF_FROM["CLIENT_STREAM_REF_FROM_SALES_RECEIPT"] = 30] = "CLIENT_STREAM_REF_FROM_SALES_RECEIPT";
    /**
     * Denotes that the reference is from a sales quotation
     *
     * @generated from enum value: CLIENT_STREAM_REF_FROM_SALES_QUOTATION = 40;
     */
    CLIENT_STREAM_REF_FROM[CLIENT_STREAM_REF_FROM["CLIENT_STREAM_REF_FROM_SALES_QUOTATION"] = 40] = "CLIENT_STREAM_REF_FROM_SALES_QUOTATION";
    /**
     * Denotes that the reference is from a sales enquiry
     *
     * @generated from enum value: CLIENT_STREAM_REF_FROM_SALES_ENQUIRY = 50;
     */
    CLIENT_STREAM_REF_FROM[CLIENT_STREAM_REF_FROM["CLIENT_STREAM_REF_FROM_SALES_ENQUIRY"] = 50] = "CLIENT_STREAM_REF_FROM_SALES_ENQUIRY";
})(CLIENT_STREAM_REF_FROM || (exports.CLIENT_STREAM_REF_FROM = CLIENT_STREAM_REF_FROM = {}));
// Retrieve enum metadata with: proto3.getEnumType(CLIENT_STREAM_REF_FROM)
protobuf_1.proto3.util.setEnumType(CLIENT_STREAM_REF_FROM, "Scailo.CLIENT_STREAM_REF_FROM", [
    { no: 0, name: "CLIENT_STREAM_REF_FROM_ANY_UNSPECIFIED" },
    { no: 1, name: "CLIENT_STREAM_REF_FROM_SALES_ORDER" },
    { no: 10, name: "CLIENT_STREAM_REF_FROM_GOODS_DISPATCH" },
    { no: 11, name: "CLIENT_STREAM_REF_FROM_SALES_INVOICE" },
    { no: 20, name: "CLIENT_STREAM_REF_FROM_SALES_RETURN" },
    { no: 21, name: "CLIENT_STREAM_REF_FROM_CREDIT_NOTE" },
    { no: 30, name: "CLIENT_STREAM_REF_FROM_SALES_RECEIPT" },
    { no: 40, name: "CLIENT_STREAM_REF_FROM_SALES_QUOTATION" },
    { no: 50, name: "CLIENT_STREAM_REF_FROM_SALES_ENQUIRY" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.CLIENT_STREAM_SORT_KEY
 */
var CLIENT_STREAM_SORT_KEY;
(function (CLIENT_STREAM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: CLIENT_STREAM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    CLIENT_STREAM_SORT_KEY[CLIENT_STREAM_SORT_KEY["CLIENT_STREAM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "CLIENT_STREAM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: CLIENT_STREAM_SORT_KEY_CREATED_AT = 1;
     */
    CLIENT_STREAM_SORT_KEY[CLIENT_STREAM_SORT_KEY["CLIENT_STREAM_SORT_KEY_CREATED_AT"] = 1] = "CLIENT_STREAM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: CLIENT_STREAM_SORT_KEY_MODIFIED_AT = 2;
     */
    CLIENT_STREAM_SORT_KEY[CLIENT_STREAM_SORT_KEY["CLIENT_STREAM_SORT_KEY_MODIFIED_AT"] = 2] = "CLIENT_STREAM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: CLIENT_STREAM_SORT_KEY_COMPLETED_ON = 6;
     */
    CLIENT_STREAM_SORT_KEY[CLIENT_STREAM_SORT_KEY["CLIENT_STREAM_SORT_KEY_COMPLETED_ON"] = 6] = "CLIENT_STREAM_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the title
     *
     * @generated from enum value: CLIENT_STREAM_SORT_KEY_TITLE = 10;
     */
    CLIENT_STREAM_SORT_KEY[CLIENT_STREAM_SORT_KEY["CLIENT_STREAM_SORT_KEY_TITLE"] = 10] = "CLIENT_STREAM_SORT_KEY_TITLE";
})(CLIENT_STREAM_SORT_KEY || (exports.CLIENT_STREAM_SORT_KEY = CLIENT_STREAM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(CLIENT_STREAM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(CLIENT_STREAM_SORT_KEY, "Scailo.CLIENT_STREAM_SORT_KEY", [
    { no: 0, name: "CLIENT_STREAM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "CLIENT_STREAM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "CLIENT_STREAM_SORT_KEY_MODIFIED_AT" },
    { no: 6, name: "CLIENT_STREAM_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "CLIENT_STREAM_SORT_KEY_TITLE" },
]);
/**
 *
 * Describes the available message types for a client stream message
 *
 * @generated from enum Scailo.CLIENT_STREAM_MESSAGE_TYPE
 */
var CLIENT_STREAM_MESSAGE_TYPE;
(function (CLIENT_STREAM_MESSAGE_TYPE) {
    /**
     * Used only for filters
     *
     * @generated from enum value: CLIENT_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED = 0;
     */
    CLIENT_STREAM_MESSAGE_TYPE[CLIENT_STREAM_MESSAGE_TYPE["CLIENT_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED"] = 0] = "CLIENT_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED";
    /**
     * Denotes that the message was sent by a user
     *
     * @generated from enum value: CLIENT_STREAM_MESSAGE_TYPE_USER = 1;
     */
    CLIENT_STREAM_MESSAGE_TYPE[CLIENT_STREAM_MESSAGE_TYPE["CLIENT_STREAM_MESSAGE_TYPE_USER"] = 1] = "CLIENT_STREAM_MESSAGE_TYPE_USER";
    /**
     * Denotes that the message was sent by the system
     *
     * @generated from enum value: CLIENT_STREAM_MESSAGE_TYPE_SYSTEM = 2;
     */
    CLIENT_STREAM_MESSAGE_TYPE[CLIENT_STREAM_MESSAGE_TYPE["CLIENT_STREAM_MESSAGE_TYPE_SYSTEM"] = 2] = "CLIENT_STREAM_MESSAGE_TYPE_SYSTEM";
})(CLIENT_STREAM_MESSAGE_TYPE || (exports.CLIENT_STREAM_MESSAGE_TYPE = CLIENT_STREAM_MESSAGE_TYPE = {}));
// Retrieve enum metadata with: proto3.getEnumType(CLIENT_STREAM_MESSAGE_TYPE)
protobuf_1.proto3.util.setEnumType(CLIENT_STREAM_MESSAGE_TYPE, "Scailo.CLIENT_STREAM_MESSAGE_TYPE", [
    { no: 0, name: "CLIENT_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED" },
    { no: 1, name: "CLIENT_STREAM_MESSAGE_TYPE_USER" },
    { no: 2, name: "CLIENT_STREAM_MESSAGE_TYPE_SYSTEM" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.CLIENT_STREAM_MESSAGE_SORT_KEY
 */
var CLIENT_STREAM_MESSAGE_SORT_KEY;
(function (CLIENT_STREAM_MESSAGE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: CLIENT_STREAM_MESSAGE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    CLIENT_STREAM_MESSAGE_SORT_KEY[CLIENT_STREAM_MESSAGE_SORT_KEY["CLIENT_STREAM_MESSAGE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "CLIENT_STREAM_MESSAGE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: CLIENT_STREAM_MESSAGE_SORT_KEY_CREATED_AT = 1;
     */
    CLIENT_STREAM_MESSAGE_SORT_KEY[CLIENT_STREAM_MESSAGE_SORT_KEY["CLIENT_STREAM_MESSAGE_SORT_KEY_CREATED_AT"] = 1] = "CLIENT_STREAM_MESSAGE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: CLIENT_STREAM_MESSAGE_SORT_KEY_MODIFIED_AT = 2;
     */
    CLIENT_STREAM_MESSAGE_SORT_KEY[CLIENT_STREAM_MESSAGE_SORT_KEY["CLIENT_STREAM_MESSAGE_SORT_KEY_MODIFIED_AT"] = 2] = "CLIENT_STREAM_MESSAGE_SORT_KEY_MODIFIED_AT";
})(CLIENT_STREAM_MESSAGE_SORT_KEY || (exports.CLIENT_STREAM_MESSAGE_SORT_KEY = CLIENT_STREAM_MESSAGE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(CLIENT_STREAM_MESSAGE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(CLIENT_STREAM_MESSAGE_SORT_KEY, "Scailo.CLIENT_STREAM_MESSAGE_SORT_KEY", [
    { no: 0, name: "CLIENT_STREAM_MESSAGE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "CLIENT_STREAM_MESSAGE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "CLIENT_STREAM_MESSAGE_SORT_KEY_MODIFIED_AT" },
]);
/**
 *
 * Describes each parameter that's part of the logbook (does not include historical data of the object) with the operation being an client stream lifecycle status
 *
 * @generated from message Scailo.LogbookLogClientStreamLC
 */
class LogbookLogClientStreamLC extends protobuf_1.Message {
    /**
     * ID of the resource
     *
     * @generated from field: uint64 id = 1;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Stores if the resource is active
     *
     * @generated from field: bool is_active = 2;
     */
    isActive = false;
    /**
     * Stores the timestamp of when this resource was created
     *
     * @generated from field: uint64 timestamp = 3;
     */
    timestamp = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the resource
     *
     * @generated from field: string ref_uuid = 10;
     */
    refUuid = "";
    /**
     * The operation that was performed
     *
     * @generated from field: Scailo.CLIENT_STREAM_LIFECYCLE operation = 11;
     */
    operation = CLIENT_STREAM_LIFECYCLE.CLIENT_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The username of the user who performed this operation
     *
     * @generated from field: string username = 12;
     */
    username = "";
    /**
     * The name of the person who made this entry
     *
     * @generated from field: string name = 13;
     */
    name = "";
    /**
     * The ID of the user who made this entry
     *
     * @generated from field: uint64 user_id = 14;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * The comment generated by the application
     *
     * @generated from field: string app_comment = 15;
     */
    appComment = "";
    /**
     * The comment entered by the user
     *
     * @generated from field: string user_comment = 16;
     */
    userComment = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LogbookLogClientStreamLC";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 3, name: "timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "operation", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_LIFECYCLE) },
        { no: 12, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "app_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new LogbookLogClientStreamLC().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LogbookLogClientStreamLC().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LogbookLogClientStreamLC().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LogbookLogClientStreamLC, a, b);
    }
}
exports.LogbookLogClientStreamLC = LogbookLogClientStreamLC;
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.ClientStreamsServiceCreateRequest
 */
class ClientStreamsServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 7;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The title of the client stream
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * The ID of the client
     *
     * @generated from field: uint64 client_id = 11;
     */
    clientId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference
     *
     * @generated from field: Scailo.CLIENT_STREAM_REF_FROM ref_from = 12;
     */
    refFrom = CLIENT_STREAM_REF_FROM.CLIENT_STREAM_REF_FROM_ANY_UNSPECIFIED;
    /**
     * The associated reference ID
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Assign self (the user creating the stream) as an internal subscriber
     *
     * @generated from field: bool assign_self_as_internal_subscriber = 80;
     */
    assignSelfAsInternalSubscriber = false;
    /**
     * Assign self (the user creating the stream) as a client subscriber (this would allow client users to also create a stream)
     *
     * @generated from field: bool assign_self_as_client_subscriber = 81;
     */
    assignSelfAsClientSubscriber = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamsServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_REF_FROM) },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 80, name: "assign_self_as_internal_subscriber", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 81, name: "assign_self_as_client_subscriber", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamsServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamsServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamsServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamsServiceCreateRequest, a, b);
    }
}
exports.ClientStreamsServiceCreateRequest = ClientStreamsServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.ClientStreamsServiceUpdateRequest
 */
class ClientStreamsServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 7;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The title of the client stream
     *
     * @generated from field: string title = 10;
     */
    title = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamsServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 7, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamsServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamsServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamsServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamsServiceUpdateRequest, a, b);
    }
}
exports.ClientStreamsServiceUpdateRequest = ClientStreamsServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.ClientStream
 */
class ClientStream extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this client stream
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The status of this client stream
     *
     * @generated from field: Scailo.CLIENT_STREAM_LIFECYCLE status = 4;
     */
    status = CLIENT_STREAM_LIFECYCLE.CLIENT_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this client stream
     *
     * @generated from field: repeated Scailo.LogbookLogClientStreamLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this client stream was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 7;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder UUID (will be empty if vault_folder_id is 0)
     *
     * @generated from field: string vault_folder_uuid = 107;
     */
    vaultFolderUuid = "";
    /**
     * The title of the client stream
     *
     * @generated from field: string title = 10;
     */
    title = "";
    /**
     * The ID of the client
     *
     * @generated from field: uint64 client_id = 11;
     */
    clientId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference
     *
     * @generated from field: Scailo.CLIENT_STREAM_REF_FROM ref_from = 12;
     */
    refFrom = CLIENT_STREAM_REF_FROM.CLIENT_STREAM_REF_FROM_ANY_UNSPECIFIED;
    /**
     * The associated reference ID
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated reference UUID
     *
     * @generated from field: string ref_uuid = 14;
     */
    refUuid = "";
    /**
     * Stores the internal reference that is automatically generated
     *
     * @generated from field: string internal_ref = 50;
     */
    internalRef = "";
    /**
     * Stores the number of unread messages in this stream for the specific user (on the basis of the auth token)
     *
     * @generated from field: uint64 unread_count = 60;
     */
    unreadCount = protobuf_1.protoInt64.zero;
    /**
     * Stores the total number of messages in the stream
     *
     * @generated from field: uint64 message_count = 61;
     */
    messageCount = protobuf_1.protoInt64.zero;
    /**
     * Stores the username of the user who added the last message
     *
     * @generated from field: string last_message_by = 62;
     */
    lastMessageBy = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStream";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_LIFECYCLE) },
        { no: 5, name: "logs", kind: "message", T: LogbookLogClientStreamLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 7, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 107, name: "vault_folder_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_REF_FROM) },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "internal_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 60, name: "unread_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 61, name: "message_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 62, name: "last_message_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStream().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStream().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStream().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStream, a, b);
    }
}
exports.ClientStream = ClientStream;
/**
 *
 * Describes the message consisting of the list of records
 *
 * @generated from message Scailo.ClientStreamsList
 */
class ClientStreamsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ClientStream list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ClientStream, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamsList, a, b);
    }
}
exports.ClientStreamsList = ClientStreamsList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.ClientStreamsServicePaginationReq
 */
class ClientStreamsServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.CLIENT_STREAM_SORT_KEY sort_key = 5;
     */
    sortKey = CLIENT_STREAM_SORT_KEY.CLIENT_STREAM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this client stream
     *
     * @generated from field: Scailo.CLIENT_STREAM_LIFECYCLE status = 6;
     */
    status = CLIENT_STREAM_LIFECYCLE.CLIENT_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamsServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_LIFECYCLE) },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamsServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamsServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamsServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamsServicePaginationReq, a, b);
    }
}
exports.ClientStreamsServicePaginationReq = ClientStreamsServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.ClientStreamsServicePaginationResponse
 */
class ClientStreamsServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.ClientStream payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamsServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: ClientStream, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamsServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamsServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamsServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamsServicePaginationResponse, a, b);
    }
}
exports.ClientStreamsServicePaginationResponse = ClientStreamsServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.ClientStreamsServiceFilterReq
 */
class ClientStreamsServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.CLIENT_STREAM_SORT_KEY sort_key = 5;
     */
    sortKey = CLIENT_STREAM_SORT_KEY.CLIENT_STREAM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this client stream
     *
     * @generated from field: Scailo.CLIENT_STREAM_LIFECYCLE status = 10;
     */
    status = CLIENT_STREAM_LIFECYCLE.CLIENT_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * Stores the internal reference that is automatically generated
     *
     * @generated from field: string internal_ref = 18;
     */
    internalRef = "";
    /**
     * The title of the client stream
     *
     * @generated from field: string title = 30;
     */
    title = "";
    /**
     * ------------------------------------------------
     * Filter by the associated client ID
     *
     * @generated from field: uint64 client_id = 40;
     */
    clientId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated reference
     *
     * @generated from field: Scailo.CLIENT_STREAM_REF_FROM ref_from = 41;
     */
    refFrom = CLIENT_STREAM_REF_FROM.CLIENT_STREAM_REF_FROM_ANY_UNSPECIFIED;
    /**
     * Filter by the associated reference ID
     *
     * ------------------------------------------------
     *
     * @generated from field: uint64 ref_id = 42;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated internal subscriber user ID
     *
     * @generated from field: uint64 internal_subscriber_user_id = 60;
     */
    internalSubscriberUserId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated client subscriber user ID
     *
     * @generated from field: uint64 client_subscriber_user_id = 61;
     */
    clientSubscriberUserId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamsServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_LIFECYCLE) },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "internal_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_REF_FROM) },
        { no: 42, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "internal_subscriber_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 61, name: "client_subscriber_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamsServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamsServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamsServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamsServiceFilterReq, a, b);
    }
}
exports.ClientStreamsServiceFilterReq = ClientStreamsServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.ClientStreamsServiceCountReq
 */
class ClientStreamsServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this client stream
     *
     * @generated from field: Scailo.CLIENT_STREAM_LIFECYCLE status = 10;
     */
    status = CLIENT_STREAM_LIFECYCLE.CLIENT_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * Stores the internal reference that is automatically generated
     *
     * @generated from field: string internal_ref = 18;
     */
    internalRef = "";
    /**
     * The title of the client stream
     *
     * @generated from field: string title = 30;
     */
    title = "";
    /**
     * ------------------------------------------------
     * Filter by the associated client ID
     *
     * @generated from field: uint64 client_id = 40;
     */
    clientId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated reference
     *
     * @generated from field: Scailo.CLIENT_STREAM_REF_FROM ref_from = 41;
     */
    refFrom = CLIENT_STREAM_REF_FROM.CLIENT_STREAM_REF_FROM_ANY_UNSPECIFIED;
    /**
     * Filter by the associated reference ID
     *
     * ------------------------------------------------
     *
     * @generated from field: uint64 ref_id = 42;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated internal subscriber user ID
     *
     * @generated from field: uint64 internal_subscriber_user_id = 60;
     */
    internalSubscriberUserId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated client subscriber user ID
     *
     * @generated from field: uint64 client_subscriber_user_id = 61;
     */
    clientSubscriberUserId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamsServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_LIFECYCLE) },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "internal_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 41, name: "ref_from", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_REF_FROM) },
        { no: 42, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "internal_subscriber_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 61, name: "client_subscriber_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamsServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamsServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamsServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamsServiceCountReq, a, b);
    }
}
exports.ClientStreamsServiceCountReq = ClientStreamsServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.ClientStreamsServiceSearchAllReq
 */
class ClientStreamsServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.CLIENT_STREAM_SORT_KEY sort_key = 5;
     */
    sortKey = CLIENT_STREAM_SORT_KEY.CLIENT_STREAM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.CLIENT_STREAM_LIFECYCLE status = 10;
     */
    status = CLIENT_STREAM_LIFECYCLE.CLIENT_STREAM_LIFECYCLE_ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * ------------------------------------------------
     * Filter by the associated client ID
     *
     * ------------------------------------------------
     *
     * @generated from field: uint64 client_id = 40;
     */
    clientId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated internal subscriber user ID
     *
     * @generated from field: uint64 internal_subscriber_user_id = 60;
     */
    internalSubscriberUserId = protobuf_1.protoInt64.zero;
    /**
     * Filter by the associated client subscriber user ID
     *
     * @generated from field: uint64 client_subscriber_user_id = 61;
     */
    clientSubscriberUserId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamsServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_LIFECYCLE) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 60, name: "internal_subscriber_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 61, name: "client_subscriber_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamsServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamsServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamsServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamsServiceSearchAllReq, a, b);
    }
}
exports.ClientStreamsServiceSearchAllReq = ClientStreamsServiceSearchAllReq;
/**
 *
 * Describes the parameters required to add a message to an client stream
 *
 * @generated from message Scailo.ClientStreamsServiceMessageCreateRequest
 */
class ClientStreamsServiceMessageCreateRequest extends protobuf_1.Message {
    /**
     * The type of the message
     *
     * @generated from field: Scailo.CLIENT_STREAM_MESSAGE_TYPE message_type = 8;
     */
    messageType = CLIENT_STREAM_MESSAGE_TYPE.CLIENT_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED;
    /**
     * Stores the client stream UUID
     *
     * @generated from field: string client_stream_uuid = 10;
     */
    clientStreamUuid = "";
    /**
     * Stores the optional UUID of the message that this message is a response to
     *
     * @generated from field: string response_to_message_uuid = 11;
     */
    responseToMessageUuid = "";
    /**
     * The content of the message
     *
     * @generated from field: string content = 20;
     */
    content = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamsServiceMessageCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 8, name: "message_type", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_MESSAGE_TYPE) },
        { no: 10, name: "client_stream_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "response_to_message_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamsServiceMessageCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamsServiceMessageCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamsServiceMessageCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamsServiceMessageCreateRequest, a, b);
    }
}
exports.ClientStreamsServiceMessageCreateRequest = ClientStreamsServiceMessageCreateRequest;
/**
 *
 * Describes the parameters that constitute a message associated to an client stream
 *
 * @generated from message Scailo.ClientStreamMessage
 */
class ClientStreamMessage extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this client stream
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The type of the message
     *
     * @generated from field: Scailo.CLIENT_STREAM_MESSAGE_TYPE message_type = 8;
     */
    messageType = CLIENT_STREAM_MESSAGE_TYPE.CLIENT_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED;
    /**
     * Stores the client stream ID
     *
     * @generated from field: uint64 client_stream_id = 10;
     */
    clientStreamId = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional UUID of the message that this message is a response to
     *
     * @generated from field: string response_to_message_uuid = 11;
     */
    responseToMessageUuid = "";
    /**
     * The content of the message
     *
     * @generated from field: string content = 20;
     */
    content = "";
    /**
     * Stores the internal reference that is automatically generated
     *
     * @generated from field: string internal_ref = 50;
     */
    internalRef = "";
    /**
     * Stores if the message has been read by the user
     *
     * @generated from field: bool is_read = 60;
     */
    isRead = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamMessage";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 8, name: "message_type", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_MESSAGE_TYPE) },
        { no: 10, name: "client_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "response_to_message_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 50, name: "internal_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 60, name: "is_read", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamMessage().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamMessage().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamMessage().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamMessage, a, b);
    }
}
exports.ClientStreamMessage = ClientStreamMessage;
/**
 *
 * Describes the message consisting of the list of client stream messages
 *
 * @generated from message Scailo.ClientStreamMessagesList
 */
class ClientStreamMessagesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ClientStreamMessage list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamMessagesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ClientStreamMessage, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamMessagesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamMessagesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamMessagesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamMessagesList, a, b);
    }
}
exports.ClientStreamMessagesList = ClientStreamMessagesList;
/**
 *
 * Describes the request payload to retrieve messages.
 *
 * @generated from message Scailo.ClientStreamMessagesSearchRequest
 */
class ClientStreamMessagesSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.CLIENT_STREAM_MESSAGE_SORT_KEY sort_key = 5;
     */
    sortKey = CLIENT_STREAM_MESSAGE_SORT_KEY.CLIENT_STREAM_MESSAGE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The type of the message
     *
     * @generated from field: Scailo.CLIENT_STREAM_MESSAGE_TYPE message_type = 8;
     */
    messageType = CLIENT_STREAM_MESSAGE_TYPE.CLIENT_STREAM_MESSAGE_TYPE_ANY_UNSPECIFIED;
    /**
     * The ID of the client stream
     *
     * @generated from field: uint64 client_stream_id = 20;
     */
    clientStreamId = protobuf_1.protoInt64.zero;
    /**
     * Stores the optional UUID of the message that this message is a response to
     *
     * @generated from field: string response_to_message_uuid = 30;
     */
    responseToMessageUuid = "";
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamMessagesSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_MESSAGE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 8, name: "message_type", kind: "enum", T: protobuf_1.proto3.getEnumType(CLIENT_STREAM_MESSAGE_TYPE) },
        { no: 20, name: "client_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "response_to_message_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamMessagesSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamMessagesSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamMessagesSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamMessagesSearchRequest, a, b);
    }
}
exports.ClientStreamMessagesSearchRequest = ClientStreamMessagesSearchRequest;
/**
 *
 * Describes the response to a pagination messages request
 *
 * @generated from message Scailo.ClientStreamsServicePaginatedMessagesResponse
 */
class ClientStreamsServicePaginatedMessagesResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.ClientStreamMessage payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamsServicePaginatedMessagesResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: ClientStreamMessage, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamsServicePaginatedMessagesResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamsServicePaginatedMessagesResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamsServicePaginatedMessagesResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamsServicePaginatedMessagesResponse, a, b);
    }
}
exports.ClientStreamsServicePaginatedMessagesResponse = ClientStreamsServicePaginatedMessagesResponse;
/**
 *
 * Describes the parameters that constitute a message receipt
 *
 * @generated from message Scailo.ClientStreamMessageReceipt
 */
class ClientStreamMessageReceipt extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this client stream
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the client stream message UUID
     *
     * @generated from field: string client_stream_message_uuid = 10;
     */
    clientStreamMessageUuid = "";
    /**
     * The ID of the user who read this
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the message has been read by the user
     *
     * @generated from field: bool is_read = 12;
     */
    isRead = false;
    /**
     * Stores the timestamp of when the message was read
     *
     * @generated from field: uint64 read_at = 13;
     */
    readAt = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamMessageReceipt";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 10, name: "client_stream_message_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "is_read", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 13, name: "read_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamMessageReceipt().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamMessageReceipt().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamMessageReceipt().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamMessageReceipt, a, b);
    }
}
exports.ClientStreamMessageReceipt = ClientStreamMessageReceipt;
/**
 *
 * Describes the message consisting of the list of client stream message receipts
 *
 * @generated from message Scailo.ClientStreamMessageReceiptsList
 */
class ClientStreamMessageReceiptsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ClientStreamMessageReceipt list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamMessageReceiptsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ClientStreamMessageReceipt, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamMessageReceiptsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamMessageReceiptsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamMessageReceiptsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamMessageReceiptsList, a, b);
    }
}
exports.ClientStreamMessageReceiptsList = ClientStreamMessageReceiptsList;
/**
 *
 * Describes the parameters necessary to create an internal subscriber
 *
 * @generated from message Scailo.ClientStreamsServiceInternalSubscriberCreateRequest
 */
class ClientStreamsServiceInternalSubscriberCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the client stream ID
     *
     * @generated from field: uint64 client_stream_id = 10;
     */
    clientStreamId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user ID
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamsServiceInternalSubscriberCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "client_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamsServiceInternalSubscriberCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamsServiceInternalSubscriberCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamsServiceInternalSubscriberCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamsServiceInternalSubscriberCreateRequest, a, b);
    }
}
exports.ClientStreamsServiceInternalSubscriberCreateRequest = ClientStreamsServiceInternalSubscriberCreateRequest;
/**
 *
 * Describes the parameters that constitute an internal subscriber
 *
 * @generated from message Scailo.ClientStreamInternalSubscriber
 */
class ClientStreamInternalSubscriber extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this client stream internal subscriber
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the client stream ID
     *
     * @generated from field: uint64 client_stream_id = 10;
     */
    clientStreamId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user ID
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user UUID (determined by the user_id)
     *
     * @generated from field: string user_uuid = 30;
     */
    userUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamInternalSubscriber";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "client_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamInternalSubscriber().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamInternalSubscriber().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamInternalSubscriber().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamInternalSubscriber, a, b);
    }
}
exports.ClientStreamInternalSubscriber = ClientStreamInternalSubscriber;
/**
 *
 * Describes the message consisting of the list of internal subscribers
 *
 * @generated from message Scailo.ClientStreamInternalSubscribersList
 */
class ClientStreamInternalSubscribersList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ClientStreamInternalSubscriber list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamInternalSubscribersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ClientStreamInternalSubscriber, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamInternalSubscribersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamInternalSubscribersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamInternalSubscribersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamInternalSubscribersList, a, b);
    }
}
exports.ClientStreamInternalSubscribersList = ClientStreamInternalSubscribersList;
/**
 *
 * Describes the data model to handle importing of internal subscribers from the given identifier representing a team or a department
 *
 * @generated from message Scailo.ClientStreamsServiceImportInternalSubscribersRequest
 */
class ClientStreamsServiceImportInternalSubscribersRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the client stream
     *
     * @generated from field: uint64 client_stream_id = 2;
     */
    clientStreamId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the team or the department from which internal subscribers need to be added to the client stream
     *
     * @generated from field: uint64 resource_id = 3;
     */
    resourceId = protobuf_1.protoInt64.zero;
    /**
     * Stores if the existing internal subscribers in the client stream need to be deleted before adding from the source record
     *
     * @generated from field: bool delete_existing = 4;
     */
    deleteExisting = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamsServiceImportInternalSubscribersRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "client_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "resource_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "delete_existing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamsServiceImportInternalSubscribersRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamsServiceImportInternalSubscribersRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamsServiceImportInternalSubscribersRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamsServiceImportInternalSubscribersRequest, a, b);
    }
}
exports.ClientStreamsServiceImportInternalSubscribersRequest = ClientStreamsServiceImportInternalSubscribersRequest;
/**
 *
 * Describes the parameters necessary to create a client stream client subscriber
 *
 * @generated from message Scailo.ClientStreamsServiceClientSubscriberCreateRequest
 */
class ClientStreamsServiceClientSubscriberCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the client stream ID
     *
     * @generated from field: uint64 client_stream_id = 10;
     */
    clientStreamId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user ID
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamsServiceClientSubscriberCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "client_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamsServiceClientSubscriberCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamsServiceClientSubscriberCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamsServiceClientSubscriberCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamsServiceClientSubscriberCreateRequest, a, b);
    }
}
exports.ClientStreamsServiceClientSubscriberCreateRequest = ClientStreamsServiceClientSubscriberCreateRequest;
/**
 *
 * Describes the parameters that constitute a client stream client subscriber
 *
 * @generated from message Scailo.ClientStreamClientSubscriber
 */
class ClientStreamClientSubscriber extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this client stream client subscriber
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the client stream ID
     *
     * @generated from field: uint64 client_stream_id = 10;
     */
    clientStreamId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user ID
     *
     * @generated from field: uint64 user_id = 11;
     */
    userId = protobuf_1.protoInt64.zero;
    /**
     * Stores the user UUID (determined by the user_id)
     *
     * @generated from field: string user_uuid = 30;
     */
    userUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamClientSubscriber";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "client_stream_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "user_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamClientSubscriber().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamClientSubscriber().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamClientSubscriber().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamClientSubscriber, a, b);
    }
}
exports.ClientStreamClientSubscriber = ClientStreamClientSubscriber;
/**
 *
 * Describes the message consisting of the list of client stream client subscribers
 *
 * @generated from message Scailo.ClientStreamClientSubscribersList
 */
class ClientStreamClientSubscribersList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ClientStreamClientSubscriber list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientStreamClientSubscribersList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ClientStreamClientSubscriber, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ClientStreamClientSubscribersList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientStreamClientSubscribersList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientStreamClientSubscribersList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientStreamClientSubscribersList, a, b);
    }
}
exports.ClientStreamClientSubscribersList = ClientStreamClientSubscribersList;
