"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file work_orders.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrdersService = void 0;
const work_orders_scailo_pb_js_1 = require("./work_orders.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each work order
 *
 * @generated from service Scailo.WorkOrdersService
 */
exports.WorkOrdersService = {
    typeName: "Scailo.WorkOrdersService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.WorkOrdersService.Create
         */
        create: {
            name: "Create",
            I: work_orders_scailo_pb_js_1.WorkOrdersServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.WorkOrdersService.Draft
         */
        draft: {
            name: "Draft",
            I: work_orders_scailo_pb_js_1.WorkOrdersServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.WorkOrdersService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: work_orders_scailo_pb_js_1.WorkOrdersServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.WorkOrdersService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.WorkOrdersService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.WorkOrdersService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.WorkOrdersService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.WorkOrdersService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: work_orders_scailo_pb_js_1.WorkOrdersServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.WorkOrdersService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.WorkOrdersService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.WorkOrdersService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.WorkOrdersService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.WorkOrdersService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.RepeatWithDeliveryDate,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.WorkOrdersService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.WorkOrdersService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.WorkOrdersService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the work order
         *
         * @generated from rpc Scailo.WorkOrdersService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: work_orders_scailo_pb_js_1.WorkOrdersServiceAutofillRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Work Order can be marked as completed
         *
         * @generated from rpc Scailo.WorkOrdersService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a work order
         *
         * @generated from rpc Scailo.WorkOrdersService.AddWorkOrderItem
         */
        addWorkOrderItem: {
            name: "AddWorkOrderItem",
            I: work_orders_scailo_pb_js_1.WorkOrdersServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a work order
         *
         * @generated from rpc Scailo.WorkOrdersService.ModifyWorkOrderItem
         */
        modifyWorkOrderItem: {
            name: "ModifyWorkOrderItem",
            I: work_orders_scailo_pb_js_1.WorkOrdersServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a work order
         *
         * @generated from rpc Scailo.WorkOrdersService.ApproveWorkOrderItem
         */
        approveWorkOrderItem: {
            name: "ApproveWorkOrderItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a work order
         *
         * @generated from rpc Scailo.WorkOrdersService.DeleteWorkOrderItem
         */
        deleteWorkOrderItem: {
            name: "DeleteWorkOrderItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a work order
         *
         * @generated from rpc Scailo.WorkOrdersService.ReorderWorkOrderItems
         */
        reorderWorkOrderItems: {
            name: "ReorderWorkOrderItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Work Order Item by ID
         *
         * @generated from rpc Scailo.WorkOrdersService.ViewWorkOrderItemByID
         */
        viewWorkOrderItemByID: {
            name: "ViewWorkOrderItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: work_orders_scailo_pb_js_1.WorkOrderItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved work order items for given work order ID
         *
         * @generated from rpc Scailo.WorkOrdersService.ViewApprovedWorkOrderItems
         */
        viewApprovedWorkOrderItems: {
            name: "ViewApprovedWorkOrderItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: work_orders_scailo_pb_js_1.WorkOrdersItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved work order items for given work order ID
         *
         * @generated from rpc Scailo.WorkOrdersService.ViewUnapprovedWorkOrderItems
         */
        viewUnapprovedWorkOrderItems: {
            name: "ViewUnapprovedWorkOrderItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: work_orders_scailo_pb_js_1.WorkOrdersItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the work order item
         *
         * @generated from rpc Scailo.WorkOrdersService.ViewWorkOrderItemHistory
         */
        viewWorkOrderItemHistory: {
            name: "ViewWorkOrderItemHistory",
            I: work_orders_scailo_pb_js_1.WorkOrderItemHistoryRequest,
            O: work_orders_scailo_pb_js_1.WorkOrdersItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved work order items for given work order ID with pagination
         *
         * @generated from rpc Scailo.WorkOrdersService.ViewPaginatedApprovedWorkOrderItems
         */
        viewPaginatedApprovedWorkOrderItems: {
            name: "ViewPaginatedApprovedWorkOrderItems",
            I: work_orders_scailo_pb_js_1.WorkOrderItemsSearchRequest,
            O: work_orders_scailo_pb_js_1.WorkOrdersServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved work order items for given work order ID with pagination
         *
         * @generated from rpc Scailo.WorkOrdersService.ViewPaginatedUnapprovedWorkOrderItems
         */
        viewPaginatedUnapprovedWorkOrderItems: {
            name: "ViewPaginatedUnapprovedWorkOrderItems",
            I: work_orders_scailo_pb_js_1.WorkOrderItemsSearchRequest,
            O: work_orders_scailo_pb_js_1.WorkOrdersServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through work order items with pagination
         *
         * @generated from rpc Scailo.WorkOrdersService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: work_orders_scailo_pb_js_1.WorkOrderItemsSearchRequest,
            O: work_orders_scailo_pb_js_1.WorkOrdersServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.WorkOrdersService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: work_orders_scailo_pb_js_1.WorkOrder,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.WorkOrdersService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: work_orders_scailo_pb_js_1.WorkOrder,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.WorkOrdersService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: work_orders_scailo_pb_js_1.WorkOrderAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.WorkOrdersService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: work_orders_scailo_pb_js_1.WorkOrdersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.WorkOrdersService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: work_orders_scailo_pb_js_1.WorkOrdersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.WorkOrdersService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: work_orders_scailo_pb_js_1.WorkOrdersServicePaginationReq,
            O: work_orders_scailo_pb_js_1.WorkOrdersServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given work order
         *
         * @generated from rpc Scailo.WorkOrdersService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective work order item info for the given family ID and work order ID
         *
         * @generated from rpc Scailo.WorkOrdersService.ViewProspectiveWorkOrderItem
         */
        viewProspectiveWorkOrderItem: {
            name: "ViewProspectiveWorkOrderItem",
            I: work_orders_scailo_pb_js_1.WorkOrderItemProspectiveInfoRequest,
            O: work_orders_scailo_pb_js_1.WorkOrdersServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download work order with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.WorkOrdersService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.WorkOrdersService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: work_orders_scailo_pb_js_1.WorkOrdersServiceSearchAllReq,
            O: work_orders_scailo_pb_js_1.WorkOrdersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.WorkOrdersService.Filter
         */
        filter: {
            name: "Filter",
            I: work_orders_scailo_pb_js_1.WorkOrdersServiceFilterReq,
            O: work_orders_scailo_pb_js_1.WorkOrdersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.WorkOrdersService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.WorkOrdersService.Count
         */
        count: {
            name: "Count",
            I: work_orders_scailo_pb_js_1.WorkOrdersServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
