"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file login.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginService = void 0;
const login_scailo_pb_js_1 = require("./login.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the methods applicable on the login service
 *
 * @generated from service Scailo.LoginService
 */
exports.LoginService = {
    typeName: "Scailo.LoginService",
    methods: {
        /**
         * Login using credentials belonging to a user and evaluate using user's primary role (this has been deprecated. Use LoginAsEmployeePrimary instead)
         *
         * @generated from rpc Scailo.LoginService.UserLoginPrimary
         * @deprecated
         */
        userLoginPrimary: {
            name: "UserLoginPrimary",
            I: login_scailo_pb_js_1.UserLoginRequest,
            O: login_scailo_pb_js_1.UserLoginResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Login as employee using primary role
         *
         * @generated from rpc Scailo.LoginService.LoginAsEmployeePrimary
         */
        loginAsEmployeePrimary: {
            name: "LoginAsEmployeePrimary",
            I: login_scailo_pb_js_1.UserLoginRequest,
            O: login_scailo_pb_js_1.UserLoginResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Login as employee using mobile role
         *
         * @generated from rpc Scailo.LoginService.LoginAsEmployeeSecondary
         */
        loginAsEmployeeSecondary: {
            name: "LoginAsEmployeeSecondary",
            I: login_scailo_pb_js_1.UserLoginRequest,
            O: login_scailo_pb_js_1.UserLoginResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Login using credentials belonging to a vendor user
         *
         * @generated from rpc Scailo.LoginService.LoginAsVendorUser
         */
        loginAsVendorUser: {
            name: "LoginAsVendorUser",
            I: login_scailo_pb_js_1.UserLoginRequest,
            O: login_scailo_pb_js_1.VendorUserLoginResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Login using credentials belonging to a client user
         *
         * @generated from rpc Scailo.LoginService.LoginAsClientUser
         */
        loginAsClientUser: {
            name: "LoginAsClientUser",
            I: login_scailo_pb_js_1.UserLoginRequest,
            O: login_scailo_pb_js_1.ClientUserLoginResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Check if the auth token is still valid
         *
         * @generated from rpc Scailo.LoginService.IsAuthTokenValid
         */
        isAuthTokenValid: {
            name: "IsAuthTokenValid",
            I: login_scailo_pb_js_1.AuthTokenValidityRequest,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Logout
         *
         * @generated from rpc Scailo.LoginService.Logout
         */
        logout: {
            name: "Logout",
            I: login_scailo_pb_js_1.LogoutRequest,
            O: login_scailo_pb_js_1.LogoutResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View login history for the given username
         *
         * @generated from rpc Scailo.LoginService.ViewHistory
         */
        viewHistory: {
            name: "ViewHistory",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: login_scailo_pb_js_1.UserLoginHistoryList,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
