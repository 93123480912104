"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file tax_groups.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaxGroupsService = void 0;
const tax_groups_scailo_pb_js_1 = require("./tax_groups.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
/**
 *
 * Describes the common methods applicable on each tax group
 *
 * @generated from service Scailo.TaxGroupsService
 */
exports.TaxGroupsService = {
    typeName: "Scailo.TaxGroupsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.TaxGroupsService.Create
         */
        create: {
            name: "Create",
            I: tax_groups_scailo_pb_js_1.TaxGroupsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.TaxGroupsService.Draft
         */
        draft: {
            name: "Draft",
            I: tax_groups_scailo_pb_js_1.TaxGroupsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.TaxGroupsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: tax_groups_scailo_pb_js_1.TaxGroupsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.TaxGroupsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.TaxGroupsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.TaxGroupsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.TaxGroupsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.TaxGroupsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: tax_groups_scailo_pb_js_1.TaxGroupsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.TaxGroupsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.TaxGroupsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.TaxGroupsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * Send Email
         * rpc SendEmail (Identifier) returns (IdentifierResponse);
         *
         * @generated from rpc Scailo.TaxGroupsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a param to a tax group
         *
         * @generated from rpc Scailo.TaxGroupsService.AddTaxGroupItem
         */
        addTaxGroupItem: {
            name: "AddTaxGroupItem",
            I: tax_groups_scailo_pb_js_1.TaxGroupsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify a param in a tax group
         *
         * @generated from rpc Scailo.TaxGroupsService.ModifyTaxGroupItem
         */
        modifyTaxGroupItem: {
            name: "ModifyTaxGroupItem",
            I: tax_groups_scailo_pb_js_1.TaxGroupsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a param in a tax group
         *
         * @generated from rpc Scailo.TaxGroupsService.ApproveTaxGroupItem
         */
        approveTaxGroupItem: {
            name: "ApproveTaxGroupItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a param in a tax group
         *
         * @generated from rpc Scailo.TaxGroupsService.DeleteTaxGroupItem
         */
        deleteTaxGroupItem: {
            name: "DeleteTaxGroupItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder params in a tax group
         *
         * @generated from rpc Scailo.TaxGroupsService.ReorderTaxGroupItems
         */
        reorderTaxGroupItems: {
            name: "ReorderTaxGroupItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Tax Group Item by ID
         *
         * @generated from rpc Scailo.TaxGroupsService.ViewTaxGroupItemByID
         */
        viewTaxGroupItemByID: {
            name: "ViewTaxGroupItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: tax_groups_scailo_pb_js_1.TaxGroupItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved tax group params for given tax group ID
         *
         * @generated from rpc Scailo.TaxGroupsService.ViewApprovedTaxGroupItems
         */
        viewApprovedTaxGroupItems: {
            name: "ViewApprovedTaxGroupItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: tax_groups_scailo_pb_js_1.TaxGroupsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved tax group params for given tax group ID
         *
         * @generated from rpc Scailo.TaxGroupsService.ViewUnapprovedTaxGroupItems
         */
        viewUnapprovedTaxGroupItems: {
            name: "ViewUnapprovedTaxGroupItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: tax_groups_scailo_pb_js_1.TaxGroupsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the tax group item
         *
         * @generated from rpc Scailo.TaxGroupsService.ViewTaxGroupItemHistory
         */
        viewTaxGroupItemHistory: {
            name: "ViewTaxGroupItemHistory",
            I: tax_groups_scailo_pb_js_1.TaxGroupItemHistoryRequest,
            O: tax_groups_scailo_pb_js_1.TaxGroupsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.TaxGroupsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: tax_groups_scailo_pb_js_1.TaxGroup,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.TaxGroupsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: tax_groups_scailo_pb_js_1.TaxGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.TaxGroupsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: tax_groups_scailo_pb_js_1.TaxGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.TaxGroupsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: tax_groups_scailo_pb_js_1.TaxGroupsServicePaginationReq,
            O: tax_groups_scailo_pb_js_1.TaxGroupsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.TaxGroupsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: tax_groups_scailo_pb_js_1.TaxGroupsServiceSearchAllReq,
            O: tax_groups_scailo_pb_js_1.TaxGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.TaxGroupsService.Filter
         */
        filter: {
            name: "Filter",
            I: tax_groups_scailo_pb_js_1.TaxGroupsServiceFilterReq,
            O: tax_groups_scailo_pb_js_1.TaxGroupsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.TaxGroupsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.TaxGroupsService.Count
         */
        count: {
            name: "Count",
            I: tax_groups_scailo_pb_js_1.TaxGroupsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
