"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file purchases_orders.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurchasesOrdersService = void 0;
const purchases_orders_scailo_pb_js_1 = require("./purchases_orders.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each purchase order
 *
 * @generated from service Scailo.PurchasesOrdersService
 */
exports.PurchasesOrdersService = {
    typeName: "Scailo.PurchasesOrdersService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.PurchasesOrdersService.Create
         */
        create: {
            name: "Create",
            I: purchases_orders_scailo_pb_js_1.PurchasesOrdersServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.PurchasesOrdersService.Draft
         */
        draft: {
            name: "Draft",
            I: purchases_orders_scailo_pb_js_1.PurchasesOrdersServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.PurchasesOrdersService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: purchases_orders_scailo_pb_js_1.PurchasesOrdersServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.PurchasesOrdersService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.PurchasesOrdersService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.PurchasesOrdersService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.PurchasesOrdersService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.PurchasesOrdersService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: purchases_orders_scailo_pb_js_1.PurchasesOrdersServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.PurchasesOrdersService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.PurchasesOrdersService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.PurchasesOrdersService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.PurchasesOrdersService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.PurchasesOrdersService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.RepeatWithDeliveryDate,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.PurchasesOrdersService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.PurchasesOrdersService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.PurchasesOrdersService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Autofill the purchase order
         *
         * @generated from rpc Scailo.PurchasesOrdersService.Autofill
         */
        autofill: {
            name: "Autofill",
            I: purchases_orders_scailo_pb_js_1.PurchasesOrdersServiceAutofillRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Amend the purchase order and send for revision
         *
         * @generated from rpc Scailo.PurchasesOrdersService.Amend
         */
        amend: {
            name: "Amend",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a purchase order
         *
         * @generated from rpc Scailo.PurchasesOrdersService.AddPurchaseOrderItem
         */
        addPurchaseOrderItem: {
            name: "AddPurchaseOrderItem",
            I: purchases_orders_scailo_pb_js_1.PurchasesOrdersServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a purchase order
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ModifyPurchaseOrderItem
         */
        modifyPurchaseOrderItem: {
            name: "ModifyPurchaseOrderItem",
            I: purchases_orders_scailo_pb_js_1.PurchasesOrdersServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a purchase order
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ApprovePurchaseOrderItem
         */
        approvePurchaseOrderItem: {
            name: "ApprovePurchaseOrderItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a purchase order
         *
         * @generated from rpc Scailo.PurchasesOrdersService.DeletePurchaseOrderItem
         */
        deletePurchaseOrderItem: {
            name: "DeletePurchaseOrderItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a purchase order
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ReorderPurchaseOrderItems
         */
        reorderPurchaseOrderItems: {
            name: "ReorderPurchaseOrderItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Purchase Order Item by ID
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewPurchaseOrderItemByID
         */
        viewPurchaseOrderItemByID: {
            name: "ViewPurchaseOrderItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: purchases_orders_scailo_pb_js_1.PurchaseOrderItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Purchase Order Item's price after factoring in the discount
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewPurchaseOrderItemPrice
         */
        viewPurchaseOrderItemPrice: {
            name: "ViewPurchaseOrderItemPrice",
            I: purchases_orders_scailo_pb_js_1.PurchaseOrderItemProspectiveInfoRequest,
            O: base_scailo_pb_js_1.PriceResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved purchase order items for given purchase order ID
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewApprovedPurchaseOrderItems
         */
        viewApprovedPurchaseOrderItems: {
            name: "ViewApprovedPurchaseOrderItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: purchases_orders_scailo_pb_js_1.PurchaseOrderItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved purchase order items for given purchase order ID
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewUnapprovedPurchaseOrderItems
         */
        viewUnapprovedPurchaseOrderItems: {
            name: "ViewUnapprovedPurchaseOrderItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: purchases_orders_scailo_pb_js_1.PurchaseOrderItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the purchase order item
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewPurchaseOrderItemHistory
         */
        viewPurchaseOrderItemHistory: {
            name: "ViewPurchaseOrderItemHistory",
            I: purchases_orders_scailo_pb_js_1.PurchaseOrderItemHistoryRequest,
            O: purchases_orders_scailo_pb_js_1.PurchaseOrderItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved purchase order items for given purchase order ID with pagination
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewPaginatedApprovedPurchaseOrderItems
         */
        viewPaginatedApprovedPurchaseOrderItems: {
            name: "ViewPaginatedApprovedPurchaseOrderItems",
            I: purchases_orders_scailo_pb_js_1.PurchaseOrderItemsSearchRequest,
            O: purchases_orders_scailo_pb_js_1.PurchasesOrdersServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved purchase order items for given purchase order ID with pagination
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewPaginatedUnapprovedPurchaseOrderItems
         */
        viewPaginatedUnapprovedPurchaseOrderItems: {
            name: "ViewPaginatedUnapprovedPurchaseOrderItems",
            I: purchases_orders_scailo_pb_js_1.PurchaseOrderItemsSearchRequest,
            O: purchases_orders_scailo_pb_js_1.PurchasesOrdersServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through purchase order items with pagination
         *
         * @generated from rpc Scailo.PurchasesOrdersService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: purchases_orders_scailo_pb_js_1.PurchaseOrderItemsSearchRequest,
            O: purchases_orders_scailo_pb_js_1.PurchasesOrdersServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * CSV operations
         * Download the CSV file that could be used to upload items from the filled CSV file. The same file can also be used as a template when there are no existing records
         *
         * @generated from rpc Scailo.PurchasesOrdersService.DownloadAsCSV
         */
        downloadAsCSV: {
            name: "DownloadAsCSV",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Upload items using a CSV file. This is an idempotent operation. All the existing items are deleted before adding the items from the file.
         *
         * @generated from rpc Scailo.PurchasesOrdersService.UploadPurchaseOrderItems
         */
        uploadPurchaseOrderItems: {
            name: "UploadPurchaseOrderItems",
            I: base_scailo_pb_js_1.IdentifierWithFile,
            O: base_scailo_pb_js_1.IdentifiersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a contact
         *
         * @generated from rpc Scailo.PurchasesOrdersService.AddPurchaseOrderContact
         */
        addPurchaseOrderContact: {
            name: "AddPurchaseOrderContact",
            I: purchases_orders_scailo_pb_js_1.PurchasesOrdersServiceContactCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a contact
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ApprovePurchaseOrderContact
         */
        approvePurchaseOrderContact: {
            name: "ApprovePurchaseOrderContact",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a contact
         *
         * @generated from rpc Scailo.PurchasesOrdersService.DeletePurchaseOrderContact
         */
        deletePurchaseOrderContact: {
            name: "DeletePurchaseOrderContact",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a contact for the given ID
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewPurchaseOrderContactByID
         */
        viewPurchaseOrderContactByID: {
            name: "ViewPurchaseOrderContactByID",
            I: base_scailo_pb_js_1.Identifier,
            O: purchases_orders_scailo_pb_js_1.PurchaseOrderContact,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all contacts for given purchase order UUID
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewPurchaseOrderContacts
         */
        viewPurchaseOrderContacts: {
            name: "ViewPurchaseOrderContacts",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: purchases_orders_scailo_pb_js_1.PurchaseOrderContactsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add a reference
         *
         * @generated from rpc Scailo.PurchasesOrdersService.AddPurchaseOrderReference
         */
        addPurchaseOrderReference: {
            name: "AddPurchaseOrderReference",
            I: purchases_orders_scailo_pb_js_1.PurchasesOrdersServiceReferenceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve a reference
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ApprovePurchaseOrderReference
         */
        approvePurchaseOrderReference: {
            name: "ApprovePurchaseOrderReference",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete a reference
         *
         * @generated from rpc Scailo.PurchasesOrdersService.DeletePurchaseOrderReference
         */
        deletePurchaseOrderReference: {
            name: "DeletePurchaseOrderReference",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View a reference for the given ID
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewPurchaseOrderReferenceByID
         */
        viewPurchaseOrderReferenceByID: {
            name: "ViewPurchaseOrderReferenceByID",
            I: base_scailo_pb_js_1.Identifier,
            O: purchases_orders_scailo_pb_js_1.PurchaseOrderReference,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all references for given purchase order ID
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewPurchaseOrderReferences
         */
        viewPurchaseOrderReferences: {
            name: "ViewPurchaseOrderReferences",
            I: base_scailo_pb_js_1.Identifier,
            O: purchases_orders_scailo_pb_js_1.PurchaseOrderReferencesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: purchases_orders_scailo_pb_js_1.PurchaseOrder,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: purchases_orders_scailo_pb_js_1.PurchaseOrder,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: purchases_orders_scailo_pb_js_1.PurchaseOrderAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View only essential components of the record
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewEssentialByID
         */
        viewEssentialByID: {
            name: "ViewEssentialByID",
            I: base_scailo_pb_js_1.Identifier,
            O: purchases_orders_scailo_pb_js_1.PurchaseOrder,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: purchases_orders_scailo_pb_js_1.PurchasesOrdersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: purchases_orders_scailo_pb_js_1.PurchasesOrdersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: purchases_orders_scailo_pb_js_1.PurchasesOrdersServicePaginationReq,
            O: purchases_orders_scailo_pb_js_1.PurchasesOrdersServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all the amendments made
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewAmendments
         */
        viewAmendments: {
            name: "ViewAmendments",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.AmendmentLogsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Inventory Statistics of Purchase Order
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewInventoryStatistics
         */
        viewInventoryStatistics: {
            name: "ViewInventoryStatistics",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: purchases_orders_scailo_pb_js_1.PurchaseOrderInventoryStatistics,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Billing Statistics of Purchase Order
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewBillingStatistics
         */
        viewBillingStatistics: {
            name: "ViewBillingStatistics",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: purchases_orders_scailo_pb_js_1.PurchaseOrderBillingStatistics,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Inventory Match of all the families within a Purchase Order
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewInventoryMatch
         */
        viewInventoryMatch: {
            name: "ViewInventoryMatch",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: purchases_orders_scailo_pb_js_1.PurchaseOrderInventoryMatchList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given purchase order
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective purchase order item info for the given family ID and purchase order ID
         *
         * @generated from rpc Scailo.PurchasesOrdersService.ViewProspectivePurchaseOrderItem
         */
        viewProspectivePurchaseOrderItem: {
            name: "ViewProspectivePurchaseOrderItem",
            I: purchases_orders_scailo_pb_js_1.PurchaseOrderItemProspectiveInfoRequest,
            O: purchases_orders_scailo_pb_js_1.PurchasesOrdersServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Other view operations
         * Download purchase order with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.PurchasesOrdersService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.PurchasesOrdersService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: purchases_orders_scailo_pb_js_1.PurchasesOrdersServiceSearchAllReq,
            O: purchases_orders_scailo_pb_js_1.PurchasesOrdersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.PurchasesOrdersService.Filter
         */
        filter: {
            name: "Filter",
            I: purchases_orders_scailo_pb_js_1.PurchasesOrdersServiceFilterReq,
            O: purchases_orders_scailo_pb_js_1.PurchasesOrdersList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.PurchasesOrdersService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.PurchasesOrdersService.Count
         */
        count: {
            name: "Count",
            I: purchases_orders_scailo_pb_js_1.PurchasesOrdersServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Returns the sum of the total value of all the records that match the given criteria
         *
         * @generated from rpc Scailo.PurchasesOrdersService.AccruedValue
         */
        accruedValue: {
            name: "AccruedValue",
            I: purchases_orders_scailo_pb_js_1.PurchasesOrdersServiceCountReq,
            O: base_scailo_pb_js_1.SumResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
