import { BOOL_FILTER, SALES_ORDER_SORT_KEY, SalesOrdersServiceCountReq, SalesOrdersServiceFilterReq, SORT_ORDER, STANDARD_LIFECYCLE_STATUS, SALES_INVOICE_SORT_KEY, SalesInvoicesServiceCountReq, SalesInvoicesServiceFilterReq, getClientForSalesOrdersService, getClientForSalesInvoicesService } from "@kernelminds/scailo-sdk";
import { dashboardMenu } from "../menus";
import { Router, context } from "./../router";
import { downloadButtonClass, downloadData, handlePageForInvalidPermissions, interceptPage, internationalizeMoney, randomId, round } from "./../utilities";
import { protoInt64 } from "@bufbuild/protobuf";
import { viewSalesOrders, viewSalesInvoices } from "../tables";
import { handleSalesOrderInventoryMatch } from "../orders/salesorders/salesorders.filters";
import { getTransport } from "../clients";
import { Transport } from "@connectrpc/connect";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(dashboardMenu.href, async (ctx) => {
        if (await interceptPage(ctx, dashboardMenu)) {
            handleDashboardPage(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}

const today = protoInt64.parse(Math.round((new Date()).getTime() / 1000));
const lastYearToday = protoInt64.parse(Math.round((new Date(new Date().setFullYear(new Date().getFullYear() - 1))).getTime() / 1000));
const last2YearToday = protoInt64.parse(Math.round((new Date(new Date().setFullYear(new Date().getFullYear() - 2))).getTime() / 1000));
const lastMonthToday = protoInt64.parse(Math.round((new Date(new Date().setMonth(new Date().getMonth() - 1))).getTime() / 1000));
const last2MonthToday = protoInt64.parse(Math.round((new Date(new Date().setMonth(new Date().getMonth() - 2))).getTime() / 1000));


async function handleDashboardPage(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }

    let salesOrderSection = document.createElement("div");
    content.appendChild(salesOrderSection);

    let salesInvoiceSection = document.createElement("div");
    salesInvoiceSection.className = "mt-10";
    content.appendChild(salesInvoiceSection);

    const transport = getTransport();

    renderSalesOrderInsights(salesOrderSection, transport);
    renderSalesInvoiceInsights(salesInvoiceSection, transport);
}

async function renderSalesOrderInsights(primaryGrid: HTMLDivElement, transport: Transport) {
    const salesOrderClient = getClientForSalesOrdersService(transport);
    let section = document.createElement("div");
    section.className = "grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-6";
    primaryGrid.appendChild(section);

    const salesOrderYearToDateQuery = new SalesOrdersServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        approvedOnStart: lastYearToday,
        approvedOnEnd: today,
    });
    const salesOrderPrevYearCycleQuery = new SalesOrdersServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        approvedOnStart: last2YearToday,
        approvedOnEnd: lastYearToday - protoInt64.parse(86400),
    });

    const salesOrderMonthToDateQuery = new SalesOrdersServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        approvedOnStart: lastMonthToday,
        approvedOnEnd: today,
    });
    const salesOrderPrevMonthCycleQuery = new SalesOrdersServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        approvedOnStart: last2MonthToday,
        approvedOnEnd: lastMonthToday - protoInt64.parse(86400),
    });

    // Get lifetime
    const salesOrderLifetimeCountQuery = new SalesOrdersServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
    });

    const salesOrderYearToDateFilterQuery = new SalesOrdersServiceFilterReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        sortKey: SALES_ORDER_SORT_KEY.SALES_ORDER_SORT_KEY_APPROVED_ON,
        sortOrder: SORT_ORDER.DESCENDING,
        approvedOnStart: lastYearToday,
        approvedOnEnd: today,
        status: STANDARD_LIFECYCLE_STATUS.STANDING,
        count: protoInt64.parse(-1),
    });

    let [
        salesOrderAccruedValueYearToDate,
        salesOrderCountYearToDate,
        salesOrderAccruedValuePrevYearCycle,
        salesOrderCountPrevYearCycle,

        salesOrderAccruedValueMonthToDate,
        salesOrderCountMonthToDate,
        salesOrderAccruedValuePrevMonthCycle,
        salesOrderCountPrevMonthCycle,

        salesOrderLifetimeAccruedValue,
        salesOrderLifetimeCount,


        salesOrderFilterYearToDate,
    ] = await Promise.all([
        salesOrderClient.accruedValue(salesOrderYearToDateQuery),
        salesOrderClient.count(salesOrderYearToDateQuery),
        salesOrderClient.accruedValue(salesOrderPrevYearCycleQuery),
        salesOrderClient.count(salesOrderPrevYearCycleQuery),

        salesOrderClient.accruedValue(salesOrderMonthToDateQuery),
        salesOrderClient.count(salesOrderMonthToDateQuery),
        salesOrderClient.accruedValue(salesOrderPrevMonthCycleQuery),
        salesOrderClient.count(salesOrderPrevMonthCycleQuery),

        salesOrderClient.accruedValue(salesOrderLifetimeCountQuery),
        salesOrderClient.count(salesOrderLifetimeCountQuery),

        salesOrderClient.filter(salesOrderYearToDateFilterQuery),
    ]);


    section.appendChild(renderCard({
        valueSection: {
            currentValue: salesOrderLifetimeAccruedValue.sum,
            currentValueTooltip: `Lifetime Sales Order Value`,
            internationalizeCurrentValue: true,
            previousValue: 0,
            showPreviousValue: false,
            previousValueTooltip: ``,
            showPreviousValueTooltip: false,
            title: "Lifetime Accrued Sales Order Value",
        },
        countSection: {
            currentValue: parseInt(String(salesOrderLifetimeCount.count)),
            currentValueTooltip: `Lifetime Sales Order Count`,
            internationalizeCurrentValue: false,
            previousValue: 0,
            showPreviousValue: false,
            previousValueTooltip: ``,
            showPreviousValueTooltip: false,
            title: "Lifetime Accrued Sales Order Count",
        }
    }));

    section.appendChild(renderCard({
        valueSection: {
            currentValue: salesOrderAccruedValueYearToDate.sum,
            currentValueTooltip: `Total Order Value from ${new Date(parseInt(String(salesOrderYearToDateQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(salesOrderYearToDateQuery.approvedOnEnd)) * 1000).toDateString()}`,
            internationalizeCurrentValue: true,
            previousValue: salesOrderAccruedValuePrevYearCycle.sum,
            showPreviousValue: true,
            previousValueTooltip: `Order Value was ${internationalizeMoney(salesOrderAccruedValuePrevYearCycle.sum)} from ${new Date(parseInt(String(salesOrderPrevYearCycleQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(salesOrderPrevYearCycleQuery.approvedOnEnd)) * 1000).toDateString()}`,
            showPreviousValueTooltip: true,
            title: "Accrued Order Value Year To Date",
        },
        countSection: {
            currentValue: parseInt(String(salesOrderCountYearToDate.count)),
            currentValueTooltip: `Total Order Count from ${new Date(parseInt(String(salesOrderYearToDateQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(salesOrderYearToDateQuery.approvedOnEnd)) * 1000).toDateString()}`,
            internationalizeCurrentValue: false,
            previousValue: parseInt(String(salesOrderCountPrevYearCycle.count)),
            showPreviousValue: true,
            previousValueTooltip: `Order Count was ${parseInt(internationalizeMoney(parseInt(String(salesOrderCountPrevYearCycle.count))))} from ${new Date(parseInt(String(salesOrderPrevYearCycleQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(salesOrderPrevYearCycleQuery.approvedOnEnd)) * 1000).toDateString()}`,
            showPreviousValueTooltip: true,
            title: "Accrued Order Count Year To Date",
        }

    }));

    section.appendChild(renderCard({
        valueSection: {
            currentValue: salesOrderAccruedValueMonthToDate.sum,
            currentValueTooltip: `Total Order Value from ${new Date(parseInt(String(salesOrderMonthToDateQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(salesOrderMonthToDateQuery.approvedOnEnd)) * 1000).toDateString()}`,
            internationalizeCurrentValue: true,
            previousValue: salesOrderAccruedValuePrevMonthCycle.sum,
            showPreviousValue: true,
            previousValueTooltip: `Order Value was ${internationalizeMoney(salesOrderAccruedValuePrevMonthCycle.sum)} from ${new Date(parseInt(String(salesOrderPrevMonthCycleQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(salesOrderPrevMonthCycleQuery.approvedOnEnd)) * 1000).toDateString()}`,
            showPreviousValueTooltip: true,
            title: "Accrued Order Value Month To Date",
        },
        countSection: {
            currentValue: parseInt(String(salesOrderCountMonthToDate.count)),
            currentValueTooltip: `Total Order Count from ${new Date(parseInt(String(salesOrderMonthToDateQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(salesOrderMonthToDateQuery.approvedOnEnd)) * 1000).toDateString()}`,
            internationalizeCurrentValue: false,
            previousValue: parseInt(String(salesOrderCountPrevMonthCycle.count)),
            showPreviousValue: true,
            previousValueTooltip: `Order Count was ${parseInt(internationalizeMoney(parseInt(String(salesOrderCountPrevMonthCycle.count))))} from ${new Date(parseInt(String(salesOrderPrevMonthCycleQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(salesOrderPrevMonthCycleQuery.approvedOnEnd)) * 1000).toDateString()}`,
            showPreviousValueTooltip: true,
            title: "Accrued Order Count Month To Date",
        }
    }));

    let tableDiv = document.createElement("div");
    tableDiv.className = "overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f5f8f3] w-full shadow-lg rounded";
    primaryGrid.appendChild(tableDiv);

    const inventoryMatchClass = randomId();

    const recordsDiv = await viewSalesOrders(salesOrderFilterYearToDate.list, { inventoryMatchClass });
    tableDiv.appendChild(recordsDiv.table);

    // Setup PDF downloads
    let pdfDownloadButtons = tableDiv.getElementsByClassName(downloadButtonClass);
    for (let i = 0; i < pdfDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await salesOrderClient.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        });
    }

    // Setup inventory match
    handleSalesOrderInventoryMatch(inventoryMatchClass, tableDiv, salesOrderClient);
}

async function renderSalesInvoiceInsights(primaryGrid: HTMLDivElement, transport: Transport) {
    const salesInvoiceClient = getClientForSalesInvoicesService(transport);
    let section = document.createElement("div");
    section.className = "grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-6";
    primaryGrid.appendChild(section);

    const salesInvoiceYearToDateQuery = new SalesInvoicesServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        approvedOnStart: lastYearToday,
        approvedOnEnd: today,
    });
    const salesInvoicePrevYearCycleQuery = new SalesInvoicesServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        approvedOnStart: last2YearToday,
        approvedOnEnd: lastYearToday - protoInt64.parse(86400),
    });

    const salesInvoiceMonthToDateQuery = new SalesInvoicesServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        approvedOnStart: lastMonthToday,
        approvedOnEnd: today,
    });
    const salesInvoicePrevMonthCycleQuery = new SalesInvoicesServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        approvedOnStart: last2MonthToday,
        approvedOnEnd: lastMonthToday - protoInt64.parse(86400),
    });

    // Get lifetime
    const salesInvoiceLifetimeCountQuery = new SalesInvoicesServiceCountReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
    });

    const salesInvoiceYearToDateFilterQuery = new SalesInvoicesServiceFilterReq({
        isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
        sortKey: SALES_INVOICE_SORT_KEY.SALES_INVOICE_SORT_KEY_APPROVED_ON,
        sortOrder: SORT_ORDER.DESCENDING,
        approvedOnStart: lastYearToday,
        approvedOnEnd: today,
        status: STANDARD_LIFECYCLE_STATUS.STANDING,
        count: protoInt64.parse(-1),
    });

    let [
        salesInvoiceAccruedValueYearToDate,
        salesInvoiceCountYearToDate,
        salesInvoiceAccruedValuePrevYearCycle,
        salesInvoiceCountPrevYearCycle,

        salesInvoiceAccruedValueMonthToDate,
        salesInvoiceCountMonthToDate,
        salesInvoiceAccruedValuePrevMonthCycle,
        salesInvoiceCountPrevMonthCycle,

        salesInvoiceLifetimeAccruedValue,
        salesInvoiceLifetimeCount,


        salesInvoiceFilterYearToDate,
    ] = await Promise.all([
        salesInvoiceClient.accruedValue(salesInvoiceYearToDateQuery),
        salesInvoiceClient.count(salesInvoiceYearToDateQuery),
        salesInvoiceClient.accruedValue(salesInvoicePrevYearCycleQuery),
        salesInvoiceClient.count(salesInvoicePrevYearCycleQuery),

        salesInvoiceClient.accruedValue(salesInvoiceMonthToDateQuery),
        salesInvoiceClient.count(salesInvoiceMonthToDateQuery),
        salesInvoiceClient.accruedValue(salesInvoicePrevMonthCycleQuery),
        salesInvoiceClient.count(salesInvoicePrevMonthCycleQuery),

        salesInvoiceClient.accruedValue(salesInvoiceLifetimeCountQuery),
        salesInvoiceClient.count(salesInvoiceLifetimeCountQuery),

        salesInvoiceClient.filter(salesInvoiceYearToDateFilterQuery),
    ]);


    section.appendChild(renderCard({
        valueSection: {
            currentValue: salesInvoiceLifetimeAccruedValue.sum,
            currentValueTooltip: `Lifetime Sales Invoice Value`,
            internationalizeCurrentValue: true,
            previousValue: 0,
            showPreviousValue: false,
            previousValueTooltip: ``,
            showPreviousValueTooltip: false,
            title: "Lifetime Accrued Sales Invoice Value",
        },
        countSection: {
            currentValue: parseInt(String(salesInvoiceLifetimeCount.count)),
            currentValueTooltip: `Lifetime Sales Invoice Count`,
            internationalizeCurrentValue: false,
            previousValue: 0,
            showPreviousValue: false,
            previousValueTooltip: ``,
            showPreviousValueTooltip: false,
            title: "Lifetime Accrued Sales Invoice Count",
        }
    }));

    section.appendChild(renderCard({
        valueSection: {
            currentValue: salesInvoiceAccruedValueYearToDate.sum,
            currentValueTooltip: `Total Invoice Value from ${new Date(parseInt(String(salesInvoiceYearToDateQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(salesInvoiceYearToDateQuery.approvedOnEnd)) * 1000).toDateString()}`,
            internationalizeCurrentValue: true,
            previousValue: salesInvoiceAccruedValuePrevYearCycle.sum,
            showPreviousValue: true,
            previousValueTooltip: `Invoice Value was ${internationalizeMoney(salesInvoiceAccruedValuePrevYearCycle.sum)} from ${new Date(parseInt(String(salesInvoicePrevYearCycleQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(salesInvoicePrevYearCycleQuery.approvedOnEnd)) * 1000).toDateString()}`,
            showPreviousValueTooltip: true,
            title: "Accrued Invoice Value Year To Date",
        },
        countSection: {
            currentValue: parseInt(String(salesInvoiceCountYearToDate.count)),
            currentValueTooltip: `Total Invoice Count from ${new Date(parseInt(String(salesInvoiceYearToDateQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(salesInvoiceYearToDateQuery.approvedOnEnd)) * 1000).toDateString()}`,
            internationalizeCurrentValue: false,
            previousValue: parseInt(String(salesInvoiceCountPrevYearCycle.count)),
            showPreviousValue: true,
            previousValueTooltip: `Invoice Count was ${parseInt(internationalizeMoney(parseInt(String(salesInvoiceCountPrevYearCycle.count))))} from ${new Date(parseInt(String(salesInvoicePrevYearCycleQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(salesInvoicePrevYearCycleQuery.approvedOnEnd)) * 1000).toDateString()}`,
            showPreviousValueTooltip: true,
            title: "Accrued Invoice Count Year To Date",
        }

    }));

    section.appendChild(renderCard({
        valueSection: {
            currentValue: salesInvoiceAccruedValueMonthToDate.sum,
            currentValueTooltip: `Total Invoice Value from ${new Date(parseInt(String(salesInvoiceMonthToDateQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(salesInvoiceMonthToDateQuery.approvedOnEnd)) * 1000).toDateString()}`,
            internationalizeCurrentValue: true,
            previousValue: salesInvoiceAccruedValuePrevMonthCycle.sum,
            showPreviousValue: true,
            previousValueTooltip: `Invoice Value was ${internationalizeMoney(salesInvoiceAccruedValuePrevMonthCycle.sum)} from ${new Date(parseInt(String(salesInvoicePrevMonthCycleQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(salesInvoicePrevMonthCycleQuery.approvedOnEnd)) * 1000).toDateString()}`,
            showPreviousValueTooltip: true,
            title: "Accrued Invoice Value Month To Date",
        },
        countSection: {
            currentValue: parseInt(String(salesInvoiceCountMonthToDate.count)),
            currentValueTooltip: `Total Invoice Count from ${new Date(parseInt(String(salesInvoiceMonthToDateQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(salesInvoiceMonthToDateQuery.approvedOnEnd)) * 1000).toDateString()}`,
            internationalizeCurrentValue: false,
            previousValue: parseInt(String(salesInvoiceCountPrevMonthCycle.count)),
            showPreviousValue: true,
            previousValueTooltip: `Invoice Count was ${parseInt(internationalizeMoney(parseInt(String(salesInvoiceCountPrevMonthCycle.count))))} from ${new Date(parseInt(String(salesInvoicePrevMonthCycleQuery.approvedOnStart)) * 1000).toDateString()} to ${new Date(parseInt(String(salesInvoicePrevMonthCycleQuery.approvedOnEnd)) * 1000).toDateString()}`,
            showPreviousValueTooltip: true,
            title: "Accrued Invoice Count Month To Date",
        }
    }));

    let tableDiv = document.createElement("div");
    tableDiv.className = "overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f5f8f3] w-full shadow-lg rounded";
    primaryGrid.appendChild(tableDiv);

    const recordsDiv = await viewSalesInvoices(salesInvoiceFilterYearToDate.list);
    tableDiv.appendChild(recordsDiv.table);

    // Setup PDF downloads
    let pdfDownloadButtons = tableDiv.getElementsByClassName(downloadButtonClass);
    for (let i = 0; i < pdfDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await salesInvoiceClient.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        });
    }
}

function renderCard(opts: {
    valueSection: {
        currentValue: number,
        currentValueTooltip: string,
        internationalizeCurrentValue: boolean,
        showPreviousValue: boolean,
        previousValue: number,
        showPreviousValueTooltip: boolean,
        previousValueTooltip: string,
        title: string,
    },
    countSection: {
        currentValue: number,
        currentValueTooltip: string,
        internationalizeCurrentValue: boolean,
        showPreviousValue: boolean,
        previousValue: number,
        showPreviousValueTooltip: boolean,
        previousValueTooltip: string,
        title: string,
    }
}) {

    if (opts.valueSection.previousValue == 0) {
        opts.valueSection.previousValue = 1;
    }
    if (opts.countSection.previousValue == 0) {
        opts.countSection.previousValue = 1;
    }

    let valueChange = (opts.valueSection.currentValue > opts.valueSection.previousValue ? "+" : "") + round(((opts.valueSection.currentValue - opts.valueSection.previousValue) / opts.valueSection.previousValue) * 100) + "%";
    let valueChangeClass = opts.valueSection.currentValue > opts.valueSection.previousValue ? "text-emerald-500" : "text-rose-500";

    let countChange = (opts.countSection.currentValue > opts.countSection.previousValue ? "+" : "") + round(((opts.countSection.currentValue - opts.countSection.previousValue) / opts.countSection.previousValue) * 100) + "%";
    let countChangeClass = opts.countSection.currentValue > opts.countSection.previousValue ? "text-emerald-500" : "text-rose-500";

    let card = document.createElement("div");
    card.className = "bg-white rounded-md border border-gray-100 p-6 shadow-md shadow-black/5";
    card.innerHTML = `
        <div class="flex justify-between mb-4">
            <div>
                <div class="flex items-center mb-1">
                    <div class="text-2xl font-semibold tooltip" data-tip="${opts.valueSection.currentValueTooltip}">${opts.valueSection.internationalizeCurrentValue ? internationalizeMoney(opts.valueSection.currentValue) : opts.valueSection.currentValue}</div>
                    ${!opts.valueSection.showPreviousValue ? "" : `<div class="p-1 rounded bg-emerald-500/10 ${valueChangeClass} text-[12px] font-semibold leading-none ml-2 tooltip" data-tip="${opts.valueSection.previousValueTooltip}">${valueChange}</div>`}
                </div>
                <div class="text-sm font-medium text-gray-400">${opts.valueSection.title}</div>
            </div>
        </div>

        <div class="flex justify-between mb-4">
            <div>
                <div class="flex items-center mb-1">
                    <div class="text-2xl font-semibold tooltip" data-tip="${opts.countSection.currentValueTooltip}">${opts.countSection.internationalizeCurrentValue ? internationalizeMoney(opts.countSection.currentValue) : opts.countSection.currentValue}</div>
                    ${!opts.countSection.showPreviousValue ? "" : `<div class="p-1 rounded bg-emerald-500/10 ${countChangeClass} text-[12px] font-semibold leading-none ml-2 tooltip" data-tip="${opts.countSection.previousValueTooltip}">${countChange}</div>`}
                </div>
                <div class="text-sm font-medium text-gray-400">${opts.countSection.title}</div>
            </div>
        </div>
    `;
    return card
}