"use strict";
// @generated by protoc-gen-connect-es v0.12.0 with parameter "target=ts"
// @generated from file inward_jobs_free_issue_materials_returns.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.InwardJobsFreeIssueMaterialsReturnsService = void 0;
const inward_jobs_free_issue_materials_returns_scailo_pb_js_1 = require("./inward_jobs_free_issue_materials_returns.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
const protobuf_1 = require("@bufbuild/protobuf");
const families_scailo_pb_js_1 = require("./families.scailo_pb.js");
/**
 *
 * Describes the common methods applicable on each inward job free issue material return
 *
 * @generated from service Scailo.InwardJobsFreeIssueMaterialsReturnsService
 */
exports.InwardJobsFreeIssueMaterialsReturnsService = {
    typeName: "Scailo.InwardJobsFreeIssueMaterialsReturnsService",
    methods: {
        /**
         * Create and send for verification
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.Create
         */
        create: {
            name: "Create",
            I: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Create and save as draft
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.Draft
         */
        draft: {
            name: "Draft",
            I: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsServiceCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update draft
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.DraftUpdate
         */
        draftUpdate: {
            name: "DraftUpdate",
            I: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send for verification
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.SendForVerification
         */
        sendForVerification: {
            name: "SendForVerification",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Verify
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.Verify
         */
        verify: {
            name: "Verify",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.Approve
         */
        approve: {
            name: "Approve",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send For Revision
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.SendForRevision
         */
        sendForRevision: {
            name: "SendForRevision",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Update revision
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.RevisionUpdate
         */
        revisionUpdate: {
            name: "RevisionUpdate",
            I: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsServiceUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Halt
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.Halt
         */
        halt: {
            name: "Halt",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Discard
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.Discard
         */
        discard: {
            name: "Discard",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Restore
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.Restore
         */
        restore: {
            name: "Restore",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Complete
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.Complete
         */
        complete: {
            name: "Complete",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Repeat
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.Repeat
         */
        repeat: {
            name: "Repeat",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reopen
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.Reopen
         */
        reopen: {
            name: "Reopen",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add comment
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.CommentAdd
         */
        commentAdd: {
            name: "CommentAdd",
            I: base_scailo_pb_js_1.IdentifierUUIDWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Send Email
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.SendEmail
         */
        sendEmail: {
            name: "SendEmail",
            I: base_scailo_pb_js_1.IdentifierWithEmailAttributes,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Checks if the Inward Job Free Issue Material Return can be marked as completed
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.IsCompletable
         */
        isCompletable: {
            name: "IsCompletable",
            I: base_scailo_pb_js_1.Identifier,
            O: base_scailo_pb_js_1.BooleanResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Add an item to a inward job free issue material return
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.AddInwardJobFreeIssueMaterialReturnItem
         */
        addInwardJobFreeIssueMaterialReturnItem: {
            name: "AddInwardJobFreeIssueMaterialReturnItem",
            I: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsServiceItemCreateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Modify an item in a inward job free issue material return
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ModifyInwardJobFreeIssueMaterialReturnItem
         */
        modifyInwardJobFreeIssueMaterialReturnItem: {
            name: "ModifyInwardJobFreeIssueMaterialReturnItem",
            I: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsServiceItemUpdateRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Approve an item in a inward job free issue material return
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ApproveInwardJobFreeIssueMaterialReturnItem
         */
        approveInwardJobFreeIssueMaterialReturnItem: {
            name: "ApproveInwardJobFreeIssueMaterialReturnItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Delete an item in a inward job free issue material return
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.DeleteInwardJobFreeIssueMaterialReturnItem
         */
        deleteInwardJobFreeIssueMaterialReturnItem: {
            name: "DeleteInwardJobFreeIssueMaterialReturnItem",
            I: base_scailo_pb_js_1.IdentifierWithUserComment,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Reorder items in a inward job free issue material return
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ReorderInwardJobFreeIssueMaterialReturnItems
         */
        reorderInwardJobFreeIssueMaterialReturnItems: {
            name: "ReorderInwardJobFreeIssueMaterialReturnItems",
            I: base_scailo_pb_js_1.ReorderItemsRequest,
            O: base_scailo_pb_js_1.IdentifierResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Inward Job Free Issue Material Return Item by ID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ViewInwardJobFreeIssueMaterialReturnItemByID
         */
        viewInwardJobFreeIssueMaterialReturnItemByID: {
            name: "ViewInwardJobFreeIssueMaterialReturnItemByID",
            I: base_scailo_pb_js_1.Identifier,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobFreeIssueMaterialReturnItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View Inward Job Free Issue Material Return Item by Inventory Hash
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ViewInwardJobFreeIssueMaterialReturnItemByInventoryHash
         */
        viewInwardJobFreeIssueMaterialReturnItemByInventoryHash: {
            name: "ViewInwardJobFreeIssueMaterialReturnItemByInventoryHash",
            I: base_scailo_pb_js_1.SimpleSearchReq,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobFreeIssueMaterialReturnItem,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved inward job free issue material return items for given inward job free issue material return ID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ViewApprovedInwardJobFreeIssueMaterialReturnItems
         */
        viewApprovedInwardJobFreeIssueMaterialReturnItems: {
            name: "ViewApprovedInwardJobFreeIssueMaterialReturnItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved inward job free issue material return items for given inward job free issue material return ID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ViewUnapprovedInwardJobFreeIssueMaterialReturnItems
         */
        viewUnapprovedInwardJobFreeIssueMaterialReturnItems: {
            name: "ViewUnapprovedInwardJobFreeIssueMaterialReturnItems",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the history of the inward job free issue material return item
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ViewInwardJobFreeIssueMaterialReturnItemHistory
         */
        viewInwardJobFreeIssueMaterialReturnItemHistory: {
            name: "ViewInwardJobFreeIssueMaterialReturnItemHistory",
            I: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobFreeIssueMaterialReturnItemHistoryRequest,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsItemsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View approved inward job free issue material return items for given inward job free issue material return ID with pagination
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ViewPaginatedApprovedInwardJobFreeIssueMaterialReturnItems
         */
        viewPaginatedApprovedInwardJobFreeIssueMaterialReturnItems: {
            name: "ViewPaginatedApprovedInwardJobFreeIssueMaterialReturnItems",
            I: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobFreeIssueMaterialReturnItemsSearchRequest,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View unapproved inward job free issue material return items for given inward job free issue material return ID with pagination
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ViewPaginatedUnapprovedInwardJobFreeIssueMaterialReturnItems
         */
        viewPaginatedUnapprovedInwardJobFreeIssueMaterialReturnItems: {
            name: "ViewPaginatedUnapprovedInwardJobFreeIssueMaterialReturnItems",
            I: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobFreeIssueMaterialReturnItemsSearchRequest,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Search through inward job free issue material return items with pagination
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.SearchItemsWithPagination
         */
        searchItemsWithPagination: {
            name: "SearchItemsWithPagination",
            I: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobFreeIssueMaterialReturnItemsSearchRequest,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsServicePaginatedItemsResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by ID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ViewByID
         */
        viewByID: {
            name: "ViewByID",
            I: base_scailo_pb_js_1.Identifier,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobFreeIssueMaterialReturn,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View by UUID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ViewByUUID
         */
        viewByUUID: {
            name: "ViewByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobFreeIssueMaterialReturn,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View the ancillary parameters (UUIDs of the internal references) by UUID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ViewAncillaryParametersByUUID
         */
        viewAncillaryParametersByUUID: {
            name: "ViewAncillaryParametersByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobFreeIssueMaterialReturnAncillaryParameters,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ViewAll
         */
        viewAll: {
            name: "ViewAll",
            I: base_scailo_pb_js_1.ActiveStatus,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all with the given entity UUID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ViewAllForEntityUUID
         */
        viewAllForEntityUUID: {
            name: "ViewAllForEntityUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View with pagination
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ViewWithPagination
         */
        viewWithPagination: {
            name: "ViewWithPagination",
            I: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsServicePaginationReq,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsServicePaginationResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective families for the given inward job free issue material return
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ViewProspectiveFamilies
         */
        viewProspectiveFamilies: {
            name: "ViewProspectiveFamilies",
            I: base_scailo_pb_js_1.IdentifierWithSearchKey,
            O: families_scailo_pb_js_1.FamiliesList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View prospective inward job free issue material return item info for the given family ID and inward job free issue material return ID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ViewProspectiveInwardJobFreeIssueMaterialReturnItem
         */
        viewProspectiveInwardJobFreeIssueMaterialReturnItem: {
            name: "ViewProspectiveInwardJobFreeIssueMaterialReturnItem",
            I: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobFreeIssueMaterialReturnItemProspectiveInfoRequest,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsServiceItemCreateRequest,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Other view operations
         * Download inward job free issue material return with the given IdentifierUUID (can be used to allow public downloads)
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.DownloadByUUID
         */
        downloadByUUID: {
            name: "DownloadByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Download the label for the inward job free issue material return with the given IdentifierUUID
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.DownloadLabelByUUID
         */
        downloadLabelByUUID: {
            name: "DownloadLabelByUUID",
            I: base_scailo_pb_js_1.IdentifierUUID,
            O: base_scailo_pb_js_1.StandardFile,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View already added quantities
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.ViewAddedFamilyQuantityForSource
         */
        viewAddedFamilyQuantityForSource: {
            name: "ViewAddedFamilyQuantityForSource",
            I: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsServiceAlreadyAddedQuantityForSourceRequest,
            O: base_scailo_pb_js_1.DualQuantitiesResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given search key
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.SearchAll
         */
        searchAll: {
            name: "SearchAll",
            I: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsServiceSearchAllReq,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * View all that match the given filter criteria
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.Filter
         */
        filter: {
            name: "Filter",
            I: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsServiceFilterReq,
            O: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsList,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count in status
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.CountInStatus
         */
        countInStatus: {
            name: "CountInStatus",
            I: base_scailo_pb_js_1.CountInSLCStatusRequest,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
        /**
         * Count all that match the given criteria
         *
         * @generated from rpc Scailo.InwardJobsFreeIssueMaterialsReturnsService.Count
         */
        count: {
            name: "Count",
            I: inward_jobs_free_issue_materials_returns_scailo_pb_js_1.InwardJobsFreeIssueMaterialsReturnsServiceCountReq,
            O: base_scailo_pb_js_1.CountResponse,
            kind: protobuf_1.MethodKind.Unary,
        },
    }
};
