import { inwardJobFIMFiltersMenu, inwardJobFIMIndividualsMenu, inwardJobFIMInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router } from "../../router";
import { handleInwardJobFreeIssueMaterialFilters } from "./freeissues.filters";
import { handleInwardJobFreeIssueMaterialInsights } from "./freeissues.insights";
import { handleIndividualInwardJobFreeIssueMaterial } from "./individual";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(inwardJobFIMFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, inwardJobFIMFiltersMenu)) {
            handleInwardJobFreeIssueMaterialFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(inwardJobFIMInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, inwardJobFIMInsightsMenu)) {
            handleInwardJobFreeIssueMaterialInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(inwardJobFIMIndividualsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, inwardJobFIMFiltersMenu)) {
            handleIndividualInwardJobFreeIssueMaterial(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}