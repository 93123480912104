"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file workflows_rules.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowEvent = exports.WorkflowsRulesServiceSearchAllReq = exports.WorkflowsRulesServiceCountReq = exports.WorkflowsRulesServiceFilterReq = exports.WorkflowsRulesServicePaginationResponse = exports.WorkflowsRulesServicePaginationReq = exports.WorkflowsRulesList = exports.WorkflowRule = exports.WorkflowsRulesServiceUpdateRequest = exports.WorkflowsRulesServiceCreateRequest = exports.WORKFLOW_RULE_SORT_KEY = exports.WORKFLOW_RULE_EXECUTE_ON = exports.WORKFLOW_RULE_MOMENT = exports.WORKFLOW_RULE_SERVICE_NAME = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Stores all the possible service names for which a workflow rule can be created
 *
 * @generated from enum Scailo.WORKFLOW_RULE_SERVICE_NAME
 */
var WORKFLOW_RULE_SERVICE_NAME;
(function (WORKFLOW_RULE_SERVICE_NAME) {
    /**
     * Used only for filters
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_ANY_UNSPECIFIED = 0;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_ANY_UNSPECIFIED"] = 0] = "WORKFLOW_RULE_SERVICE_NAME_ANY_UNSPECIFIED";
    /**
     * Apply the rule for absences service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_ABSENCES = 10;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_ABSENCES"] = 10] = "WORKFLOW_RULE_SERVICE_NAME_ABSENCES";
    /**
     * Apply the rule for action codes service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_ACTIONS_CODES = 20;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_ACTIONS_CODES"] = 20] = "WORKFLOW_RULE_SERVICE_NAME_ACTIONS_CODES";
    /**
     * Apply the rule for activities groups service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_ACTIVITIES_GROUPS = 30;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_ACTIVITIES_GROUPS"] = 30] = "WORKFLOW_RULE_SERVICE_NAME_ACTIVITIES_GROUPS";
    /**
     * Apply the rule for activities statuses service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_ACTIVITIES_STATUSES = 40;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_ACTIVITIES_STATUSES"] = 40] = "WORKFLOW_RULE_SERVICE_NAME_ACTIVITIES_STATUSES";
    /**
     * Apply the rule for activities tags service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_ACTIVITIES_TAGS = 50;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_ACTIVITIES_TAGS"] = 50] = "WORKFLOW_RULE_SERVICE_NAME_ACTIVITIES_TAGS";
    /**
     * Apply the rule for activities service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_ACTIVITIES = 60;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_ACTIVITIES"] = 60] = "WORKFLOW_RULE_SERVICE_NAME_ACTIVITIES";
    /**
     * Apply the rule for announcements service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_ANNOUNCEMENTS = 70;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_ANNOUNCEMENTS"] = 70] = "WORKFLOW_RULE_SERVICE_NAME_ANNOUNCEMENTS";
    /**
     * Apply the rule for asset indents service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_ASSET_INDENTS = 80;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_ASSET_INDENTS"] = 80] = "WORKFLOW_RULE_SERVICE_NAME_ASSET_INDENTS";
    /**
     * Apply the rule for associates service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_ASSOCIATES = 90;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_ASSOCIATES"] = 90] = "WORKFLOW_RULE_SERVICE_NAME_ASSOCIATES";
    /**
     * Apply the rule for attendances amendments service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_ATTENDANCES_AMENDMENTS = 100;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_ATTENDANCES_AMENDMENTS"] = 100] = "WORKFLOW_RULE_SERVICE_NAME_ATTENDANCES_AMENDMENTS";
    /**
     * Apply the rules for attendances service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_ATTENDANCES = 110;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_ATTENDANCES"] = 110] = "WORKFLOW_RULE_SERVICE_NAME_ATTENDANCES";
    /**
     * Apply the rule for bank accounts service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_BANK_ACCOUNTS = 200;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_BANK_ACCOUNTS"] = 200] = "WORKFLOW_RULE_SERVICE_NAME_BANK_ACCOUNTS";
    /**
     * Apply the rule for clients service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_CLIENTS = 300;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_CLIENTS"] = 300] = "WORKFLOW_RULE_SERVICE_NAME_CLIENTS";
    /**
     * Apply the rule for components service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_COMPONENTS = 310;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_COMPONENTS"] = 310] = "WORKFLOW_RULE_SERVICE_NAME_COMPONENTS";
    /**
     * Apply the rule for credit notes service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_CREDIT_NOTES = 320;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_CREDIT_NOTES"] = 320] = "WORKFLOW_RULE_SERVICE_NAME_CREDIT_NOTES";
    /**
     * Apply the rule for currencies service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_CURRENCIES = 330;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_CURRENCIES"] = 330] = "WORKFLOW_RULE_SERVICE_NAME_CURRENCIES";
    /**
     * Apply the rule for debit notes service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_DEBIT_NOTES = 4000;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_DEBIT_NOTES"] = 4000] = "WORKFLOW_RULE_SERVICE_NAME_DEBIT_NOTES";
    /**
     * Apply the rule for departments service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_DEPARTMENTS = 410;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_DEPARTMENTS"] = 410] = "WORKFLOW_RULE_SERVICE_NAME_DEPARTMENTS";
    /**
     * Apply the rule for equations families service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_EQUATIONS_FAMILIES = 500;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_EQUATIONS_FAMILIES"] = 500] = "WORKFLOW_RULE_SERVICE_NAME_EQUATIONS_FAMILIES";
    /**
     * Apply the rule for equations replaceables service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_EQUATIONS_REPLACEABLES = 510;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_EQUATIONS_REPLACEABLES"] = 510] = "WORKFLOW_RULE_SERVICE_NAME_EQUATIONS_REPLACEABLES";
    /**
     * Apply the rule for equations sales bundles service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_EQUATIONS_SALES_BUNDLES = 520;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_EQUATIONS_SALES_BUNDLES"] = 520] = "WORKFLOW_RULE_SERVICE_NAME_EQUATIONS_SALES_BUNDLES";
    /**
     * Apply the rule for equations work orders service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_EQUATIONS_WORK_ORDERS = 530;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_EQUATIONS_WORK_ORDERS"] = 530] = "WORKFLOW_RULE_SERVICE_NAME_EQUATIONS_WORK_ORDERS";
    /**
     * Apply the rule for equipments service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_EQUIPMENTS = 540;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_EQUIPMENTS"] = 540] = "WORKFLOW_RULE_SERVICE_NAME_EQUIPMENTS";
    /**
     * Apply the rule for expenses service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_EXPENSES = 550;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_EXPENSES"] = 550] = "WORKFLOW_RULE_SERVICE_NAME_EXPENSES";
    /**
     * Apply the rule for families service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_FAMILIES = 600;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_FAMILIES"] = 600] = "WORKFLOW_RULE_SERVICE_NAME_FAMILIES";
    /**
     * Apply the rule for feedstocks service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_FEEDSTOCKS = 610;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_FEEDSTOCKS"] = 610] = "WORKFLOW_RULE_SERVICE_NAME_FEEDSTOCKS";
    /**
     * Apply the rule for goals service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_GOALS = 700;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_GOALS"] = 700] = "WORKFLOW_RULE_SERVICE_NAME_GOALS";
    /**
     * Apply the rule for goods dispatches service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_GOODS_DISPATCHES = 710;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_GOODS_DISPATCHES"] = 710] = "WORKFLOW_RULE_SERVICE_NAME_GOODS_DISPATCHES";
    /**
     * Apply the rule for goods receipts service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_GOODS_RECEIPTS = 720;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_GOODS_RECEIPTS"] = 720] = "WORKFLOW_RULE_SERVICE_NAME_GOODS_RECEIPTS";
    /**
     * Apply the rule for holidays service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_HOLIDAYS = 800;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_HOLIDAYS"] = 800] = "WORKFLOW_RULE_SERVICE_NAME_HOLIDAYS";
    /**
     * Apply the rule for infrastructures service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_INFRASTRUCTURES = 900;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_INFRASTRUCTURES"] = 900] = "WORKFLOW_RULE_SERVICE_NAME_INFRASTRUCTURES";
    /**
     * Apply the rule for inventory service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_INVENTORY = 910;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_INVENTORY"] = 910] = "WORKFLOW_RULE_SERVICE_NAME_INVENTORY";
    /**
     * Apply the rule for inward jobs free issue materials returns service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_INWARD_JOBS_FREE_ISSUE_MATERIALS_RETURNS = 1000;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_INWARD_JOBS_FREE_ISSUE_MATERIALS_RETURNS"] = 1000] = "WORKFLOW_RULE_SERVICE_NAME_INWARD_JOBS_FREE_ISSUE_MATERIALS_RETURNS";
    /**
     * Apply the rule for inward jobs free issue materials service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_INWARD_JOBS_FREE_ISSUE_MATERIALS = 1010;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_INWARD_JOBS_FREE_ISSUE_MATERIALS"] = 1010] = "WORKFLOW_RULE_SERVICE_NAME_INWARD_JOBS_FREE_ISSUE_MATERIALS";
    /**
     * Apply the rule for inward jobs service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_INWARD_JOBS = 1020;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_INWARD_JOBS"] = 1020] = "WORKFLOW_RULE_SERVICE_NAME_INWARD_JOBS";
    /**
     * Apply the rule for leaves adjustments service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_LEAVES_ADJUSTMENTS = 1100;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_LEAVES_ADJUSTMENTS"] = 1100] = "WORKFLOW_RULE_SERVICE_NAME_LEAVES_ADJUSTMENTS";
    /**
     * Apply the rule for leaves requests service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_LEAVES_REQUESTS = 1110;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_LEAVES_REQUESTS"] = 1110] = "WORKFLOW_RULE_SERVICE_NAME_LEAVES_REQUESTS";
    /**
     * Apply the rule for meetings service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_MEETINGS = 1200;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_MEETINGS"] = 1200] = "WORKFLOW_RULE_SERVICE_NAME_MEETINGS";
    /**
     * Apply the rule for merchandises service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_MERCHANDISES = 1210;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_MERCHANDISES"] = 1210] = "WORKFLOW_RULE_SERVICE_NAME_MERCHANDISES";
    /**
     * Apply the rule for notes service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_NOTES = 1300;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_NOTES"] = 1300] = "WORKFLOW_RULE_SERVICE_NAME_NOTES";
    /**
     * Apply the rule for on duties service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_ON_DUTIES = 1400;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_ON_DUTIES"] = 1400] = "WORKFLOW_RULE_SERVICE_NAME_ON_DUTIES";
    /**
     * Apply the rule for outward jobs free issue materials returns service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_OUTWARD_JOBS_FREE_ISSUE_MATERIALS_RETURNS = 1410;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_OUTWARD_JOBS_FREE_ISSUE_MATERIALS_RETURNS"] = 1410] = "WORKFLOW_RULE_SERVICE_NAME_OUTWARD_JOBS_FREE_ISSUE_MATERIALS_RETURNS";
    /**
     * Apply the rule for outward jobs free issue materials service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_OUTWARD_JOBS_FREE_ISSUE_MATERIALS = 1420;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_OUTWARD_JOBS_FREE_ISSUE_MATERIALS"] = 1420] = "WORKFLOW_RULE_SERVICE_NAME_OUTWARD_JOBS_FREE_ISSUE_MATERIALS";
    /**
     * Apply the rule for outward jobs service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_OUTWARD_JOBS = 1430;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_OUTWARD_JOBS"] = 1430] = "WORKFLOW_RULE_SERVICE_NAME_OUTWARD_JOBS";
    /**
     * Apply the rule for overtimes service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_OVERTIMES = 1440;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_OVERTIMES"] = 1440] = "WORKFLOW_RULE_SERVICE_NAME_OVERTIMES";
    /**
     * Apply the rule for production indents service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_PRODUCTION_INDENTS = 1500;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_PRODUCTION_INDENTS"] = 1500] = "WORKFLOW_RULE_SERVICE_NAME_PRODUCTION_INDENTS";
    /**
     * Apply the rule for production plans service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_PRODUCTION_PLANS = 1510;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_PRODUCTION_PLANS"] = 1510] = "WORKFLOW_RULE_SERVICE_NAME_PRODUCTION_PLANS";
    /**
     * Apply the rule for products service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_PRODUCTS = 1520;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_PRODUCTS"] = 1520] = "WORKFLOW_RULE_SERVICE_NAME_PRODUCTS";
    /**
     * Apply the rule for projects service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_PROJECTS = 1530;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_PROJECTS"] = 1530] = "WORKFLOW_RULE_SERVICE_NAME_PROJECTS";
    /**
     * Apply the rule for purchases enquiries service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_PURCHASES_ENQUIRIES = 1540;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_PURCHASES_ENQUIRIES"] = 1540] = "WORKFLOW_RULE_SERVICE_NAME_PURCHASES_ENQUIRIES";
    /**
     * Apply the rule for purchases indents service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_PURCHASES_INDENTS = 1550;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_PURCHASES_INDENTS"] = 1550] = "WORKFLOW_RULE_SERVICE_NAME_PURCHASES_INDENTS";
    /**
     * Apply the rule for purchases orders service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_PURCHASES_ORDERS = 1560;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_PURCHASES_ORDERS"] = 1560] = "WORKFLOW_RULE_SERVICE_NAME_PURCHASES_ORDERS";
    /**
     * Apply the rule for supply offers service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_SUPPLY_OFFERS = 1565;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_SUPPLY_OFFERS"] = 1565] = "WORKFLOW_RULE_SERVICE_NAME_SUPPLY_OFFERS";
    /**
     * Apply the rule for purchases payments service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_PURCHASES_PAYMENTS = 1570;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_PURCHASES_PAYMENTS"] = 1570] = "WORKFLOW_RULE_SERVICE_NAME_PURCHASES_PAYMENTS";
    /**
     * Apply the rule for purchases returns service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_PURCHASES_RETURNS = 1580;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_PURCHASES_RETURNS"] = 1580] = "WORKFLOW_RULE_SERVICE_NAME_PURCHASES_RETURNS";
    /**
     * Apply the rule for qc groups service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_QC_GROUPS = 1600;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_QC_GROUPS"] = 1600] = "WORKFLOW_RULE_SERVICE_NAME_QC_GROUPS";
    /**
     * Apply the rule for qc samples service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_QC_SAMPLES = 1610;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_QC_SAMPLES"] = 1610] = "WORKFLOW_RULE_SERVICE_NAME_QC_SAMPLES";
    /**
     * Apply the rule for quotations requests service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_QUOTATIONS_REQUESTS = 1620;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_QUOTATIONS_REQUESTS"] = 1620] = "WORKFLOW_RULE_SERVICE_NAME_QUOTATIONS_REQUESTS";
    /**
     * Apply the rule for quotations responses service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_QUOTATIONS_RESPONSES = 1630;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_QUOTATIONS_RESPONSES"] = 1630] = "WORKFLOW_RULE_SERVICE_NAME_QUOTATIONS_RESPONSES";
    /**
     * Apply the rule for replaceable indents service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_REPLACEABLE_INDENTS = 1700;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_REPLACEABLE_INDENTS"] = 1700] = "WORKFLOW_RULE_SERVICE_NAME_REPLACEABLE_INDENTS";
    /**
     * Apply the rule for roles service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_ROLES = 1710;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_ROLES"] = 1710] = "WORKFLOW_RULE_SERVICE_NAME_ROLES";
    /**
     * Apply the rule for salaries service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_SALARIES = 1800;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_SALARIES"] = 1800] = "WORKFLOW_RULE_SERVICE_NAME_SALARIES";
    /**
     * Apply the rule for sales enquiries service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_SALES_ENQUIRIES = 1810;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_SALES_ENQUIRIES"] = 1810] = "WORKFLOW_RULE_SERVICE_NAME_SALES_ENQUIRIES";
    /**
     * Apply the rule for sales invoices service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_SALES_INVOICES = 1820;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_SALES_INVOICES"] = 1820] = "WORKFLOW_RULE_SERVICE_NAME_SALES_INVOICES";
    /**
     * Apply the rule for proforma invoices service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_PROFORMA_INVOICES = 1825;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_PROFORMA_INVOICES"] = 1825] = "WORKFLOW_RULE_SERVICE_NAME_PROFORMA_INVOICES";
    /**
     * Apply the rule for sales orders service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_SALES_ORDERS = 1830;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_SALES_ORDERS"] = 1830] = "WORKFLOW_RULE_SERVICE_NAME_SALES_ORDERS";
    /**
     * Apply the rule for sales quotations service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_SALES_QUOTATIONS = 1840;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_SALES_QUOTATIONS"] = 1840] = "WORKFLOW_RULE_SERVICE_NAME_SALES_QUOTATIONS";
    /**
     * Apply the rule for sales receipts service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_SALES_RECEIPTS = 1850;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_SALES_RECEIPTS"] = 1850] = "WORKFLOW_RULE_SERVICE_NAME_SALES_RECEIPTS";
    /**
     * Apply the rule for sales returns service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_SALES_RETURNS = 1860;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_SALES_RETURNS"] = 1860] = "WORKFLOW_RULE_SERVICE_NAME_SALES_RETURNS";
    /**
     * Apply the rule for stock audits service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_STOCK_AUDITS = 1870;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_STOCK_AUDITS"] = 1870] = "WORKFLOW_RULE_SERVICE_NAME_STOCK_AUDITS";
    /**
     * Apply the rule for stock issuances service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_STOCK_ISSUANCES = 1880;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_STOCK_ISSUANCES"] = 1880] = "WORKFLOW_RULE_SERVICE_NAME_STOCK_ISSUANCES";
    /**
     * Apply the rule for stock returns service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_STOCK_RETURNS = 1890;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_STOCK_RETURNS"] = 1890] = "WORKFLOW_RULE_SERVICE_NAME_STOCK_RETURNS";
    /**
     * Apply the rule for users service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_USERS = 1900;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_USERS"] = 1900] = "WORKFLOW_RULE_SERVICE_NAME_USERS";
    /**
     * Apply the rule for vendor invoices service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_VENDOR_INVOICES = 2000;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_VENDOR_INVOICES"] = 2000] = "WORKFLOW_RULE_SERVICE_NAME_VENDOR_INVOICES";
    /**
     * Apply the rule for vendors service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_VENDORS = 2010;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_VENDORS"] = 2010] = "WORKFLOW_RULE_SERVICE_NAME_VENDORS";
    /**
     * Apply the rule for visitations service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_VISITATIONS = 2020;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_VISITATIONS"] = 2020] = "WORKFLOW_RULE_SERVICE_NAME_VISITATIONS";
    /**
     * Apply the rule for work orders service
     *
     * @generated from enum value: WORKFLOW_RULE_SERVICE_NAME_WORK_ORDERS = 2100;
     */
    WORKFLOW_RULE_SERVICE_NAME[WORKFLOW_RULE_SERVICE_NAME["WORKFLOW_RULE_SERVICE_NAME_WORK_ORDERS"] = 2100] = "WORKFLOW_RULE_SERVICE_NAME_WORK_ORDERS";
})(WORKFLOW_RULE_SERVICE_NAME || (exports.WORKFLOW_RULE_SERVICE_NAME = WORKFLOW_RULE_SERVICE_NAME = {}));
// Retrieve enum metadata with: proto3.getEnumType(WORKFLOW_RULE_SERVICE_NAME)
protobuf_1.proto3.util.setEnumType(WORKFLOW_RULE_SERVICE_NAME, "Scailo.WORKFLOW_RULE_SERVICE_NAME", [
    { no: 0, name: "WORKFLOW_RULE_SERVICE_NAME_ANY_UNSPECIFIED" },
    { no: 10, name: "WORKFLOW_RULE_SERVICE_NAME_ABSENCES" },
    { no: 20, name: "WORKFLOW_RULE_SERVICE_NAME_ACTIONS_CODES" },
    { no: 30, name: "WORKFLOW_RULE_SERVICE_NAME_ACTIVITIES_GROUPS" },
    { no: 40, name: "WORKFLOW_RULE_SERVICE_NAME_ACTIVITIES_STATUSES" },
    { no: 50, name: "WORKFLOW_RULE_SERVICE_NAME_ACTIVITIES_TAGS" },
    { no: 60, name: "WORKFLOW_RULE_SERVICE_NAME_ACTIVITIES" },
    { no: 70, name: "WORKFLOW_RULE_SERVICE_NAME_ANNOUNCEMENTS" },
    { no: 80, name: "WORKFLOW_RULE_SERVICE_NAME_ASSET_INDENTS" },
    { no: 90, name: "WORKFLOW_RULE_SERVICE_NAME_ASSOCIATES" },
    { no: 100, name: "WORKFLOW_RULE_SERVICE_NAME_ATTENDANCES_AMENDMENTS" },
    { no: 110, name: "WORKFLOW_RULE_SERVICE_NAME_ATTENDANCES" },
    { no: 200, name: "WORKFLOW_RULE_SERVICE_NAME_BANK_ACCOUNTS" },
    { no: 300, name: "WORKFLOW_RULE_SERVICE_NAME_CLIENTS" },
    { no: 310, name: "WORKFLOW_RULE_SERVICE_NAME_COMPONENTS" },
    { no: 320, name: "WORKFLOW_RULE_SERVICE_NAME_CREDIT_NOTES" },
    { no: 330, name: "WORKFLOW_RULE_SERVICE_NAME_CURRENCIES" },
    { no: 4000, name: "WORKFLOW_RULE_SERVICE_NAME_DEBIT_NOTES" },
    { no: 410, name: "WORKFLOW_RULE_SERVICE_NAME_DEPARTMENTS" },
    { no: 500, name: "WORKFLOW_RULE_SERVICE_NAME_EQUATIONS_FAMILIES" },
    { no: 510, name: "WORKFLOW_RULE_SERVICE_NAME_EQUATIONS_REPLACEABLES" },
    { no: 520, name: "WORKFLOW_RULE_SERVICE_NAME_EQUATIONS_SALES_BUNDLES" },
    { no: 530, name: "WORKFLOW_RULE_SERVICE_NAME_EQUATIONS_WORK_ORDERS" },
    { no: 540, name: "WORKFLOW_RULE_SERVICE_NAME_EQUIPMENTS" },
    { no: 550, name: "WORKFLOW_RULE_SERVICE_NAME_EXPENSES" },
    { no: 600, name: "WORKFLOW_RULE_SERVICE_NAME_FAMILIES" },
    { no: 610, name: "WORKFLOW_RULE_SERVICE_NAME_FEEDSTOCKS" },
    { no: 700, name: "WORKFLOW_RULE_SERVICE_NAME_GOALS" },
    { no: 710, name: "WORKFLOW_RULE_SERVICE_NAME_GOODS_DISPATCHES" },
    { no: 720, name: "WORKFLOW_RULE_SERVICE_NAME_GOODS_RECEIPTS" },
    { no: 800, name: "WORKFLOW_RULE_SERVICE_NAME_HOLIDAYS" },
    { no: 900, name: "WORKFLOW_RULE_SERVICE_NAME_INFRASTRUCTURES" },
    { no: 910, name: "WORKFLOW_RULE_SERVICE_NAME_INVENTORY" },
    { no: 1000, name: "WORKFLOW_RULE_SERVICE_NAME_INWARD_JOBS_FREE_ISSUE_MATERIALS_RETURNS" },
    { no: 1010, name: "WORKFLOW_RULE_SERVICE_NAME_INWARD_JOBS_FREE_ISSUE_MATERIALS" },
    { no: 1020, name: "WORKFLOW_RULE_SERVICE_NAME_INWARD_JOBS" },
    { no: 1100, name: "WORKFLOW_RULE_SERVICE_NAME_LEAVES_ADJUSTMENTS" },
    { no: 1110, name: "WORKFLOW_RULE_SERVICE_NAME_LEAVES_REQUESTS" },
    { no: 1200, name: "WORKFLOW_RULE_SERVICE_NAME_MEETINGS" },
    { no: 1210, name: "WORKFLOW_RULE_SERVICE_NAME_MERCHANDISES" },
    { no: 1300, name: "WORKFLOW_RULE_SERVICE_NAME_NOTES" },
    { no: 1400, name: "WORKFLOW_RULE_SERVICE_NAME_ON_DUTIES" },
    { no: 1410, name: "WORKFLOW_RULE_SERVICE_NAME_OUTWARD_JOBS_FREE_ISSUE_MATERIALS_RETURNS" },
    { no: 1420, name: "WORKFLOW_RULE_SERVICE_NAME_OUTWARD_JOBS_FREE_ISSUE_MATERIALS" },
    { no: 1430, name: "WORKFLOW_RULE_SERVICE_NAME_OUTWARD_JOBS" },
    { no: 1440, name: "WORKFLOW_RULE_SERVICE_NAME_OVERTIMES" },
    { no: 1500, name: "WORKFLOW_RULE_SERVICE_NAME_PRODUCTION_INDENTS" },
    { no: 1510, name: "WORKFLOW_RULE_SERVICE_NAME_PRODUCTION_PLANS" },
    { no: 1520, name: "WORKFLOW_RULE_SERVICE_NAME_PRODUCTS" },
    { no: 1530, name: "WORKFLOW_RULE_SERVICE_NAME_PROJECTS" },
    { no: 1540, name: "WORKFLOW_RULE_SERVICE_NAME_PURCHASES_ENQUIRIES" },
    { no: 1550, name: "WORKFLOW_RULE_SERVICE_NAME_PURCHASES_INDENTS" },
    { no: 1560, name: "WORKFLOW_RULE_SERVICE_NAME_PURCHASES_ORDERS" },
    { no: 1565, name: "WORKFLOW_RULE_SERVICE_NAME_SUPPLY_OFFERS" },
    { no: 1570, name: "WORKFLOW_RULE_SERVICE_NAME_PURCHASES_PAYMENTS" },
    { no: 1580, name: "WORKFLOW_RULE_SERVICE_NAME_PURCHASES_RETURNS" },
    { no: 1600, name: "WORKFLOW_RULE_SERVICE_NAME_QC_GROUPS" },
    { no: 1610, name: "WORKFLOW_RULE_SERVICE_NAME_QC_SAMPLES" },
    { no: 1620, name: "WORKFLOW_RULE_SERVICE_NAME_QUOTATIONS_REQUESTS" },
    { no: 1630, name: "WORKFLOW_RULE_SERVICE_NAME_QUOTATIONS_RESPONSES" },
    { no: 1700, name: "WORKFLOW_RULE_SERVICE_NAME_REPLACEABLE_INDENTS" },
    { no: 1710, name: "WORKFLOW_RULE_SERVICE_NAME_ROLES" },
    { no: 1800, name: "WORKFLOW_RULE_SERVICE_NAME_SALARIES" },
    { no: 1810, name: "WORKFLOW_RULE_SERVICE_NAME_SALES_ENQUIRIES" },
    { no: 1820, name: "WORKFLOW_RULE_SERVICE_NAME_SALES_INVOICES" },
    { no: 1825, name: "WORKFLOW_RULE_SERVICE_NAME_PROFORMA_INVOICES" },
    { no: 1830, name: "WORKFLOW_RULE_SERVICE_NAME_SALES_ORDERS" },
    { no: 1840, name: "WORKFLOW_RULE_SERVICE_NAME_SALES_QUOTATIONS" },
    { no: 1850, name: "WORKFLOW_RULE_SERVICE_NAME_SALES_RECEIPTS" },
    { no: 1860, name: "WORKFLOW_RULE_SERVICE_NAME_SALES_RETURNS" },
    { no: 1870, name: "WORKFLOW_RULE_SERVICE_NAME_STOCK_AUDITS" },
    { no: 1880, name: "WORKFLOW_RULE_SERVICE_NAME_STOCK_ISSUANCES" },
    { no: 1890, name: "WORKFLOW_RULE_SERVICE_NAME_STOCK_RETURNS" },
    { no: 1900, name: "WORKFLOW_RULE_SERVICE_NAME_USERS" },
    { no: 2000, name: "WORKFLOW_RULE_SERVICE_NAME_VENDOR_INVOICES" },
    { no: 2010, name: "WORKFLOW_RULE_SERVICE_NAME_VENDORS" },
    { no: 2020, name: "WORKFLOW_RULE_SERVICE_NAME_VISITATIONS" },
    { no: 2100, name: "WORKFLOW_RULE_SERVICE_NAME_WORK_ORDERS" },
]);
/**
 *
 * Stores all the possible moments for which a workflow rule can be created
 *
 * @generated from enum Scailo.WORKFLOW_RULE_MOMENT
 */
var WORKFLOW_RULE_MOMENT;
(function (WORKFLOW_RULE_MOMENT) {
    /**
     * Used only for filters
     *
     * @generated from enum value: WORKFLOW_RULE_MOMENT_ANY_UNSPECIFIED = 0;
     */
    WORKFLOW_RULE_MOMENT[WORKFLOW_RULE_MOMENT["WORKFLOW_RULE_MOMENT_ANY_UNSPECIFIED"] = 0] = "WORKFLOW_RULE_MOMENT_ANY_UNSPECIFIED";
    /**
     * Run the rule at the beginning of the transaction
     *
     * @generated from enum value: WORKFLOW_RULE_MOMENT_BEGIN = 1;
     */
    WORKFLOW_RULE_MOMENT[WORKFLOW_RULE_MOMENT["WORKFLOW_RULE_MOMENT_BEGIN"] = 1] = "WORKFLOW_RULE_MOMENT_BEGIN";
    /**
     * Run the rule at the end of the transaction
     *
     * @generated from enum value: WORKFLOW_RULE_MOMENT_END = 2;
     */
    WORKFLOW_RULE_MOMENT[WORKFLOW_RULE_MOMENT["WORKFLOW_RULE_MOMENT_END"] = 2] = "WORKFLOW_RULE_MOMENT_END";
})(WORKFLOW_RULE_MOMENT || (exports.WORKFLOW_RULE_MOMENT = WORKFLOW_RULE_MOMENT = {}));
// Retrieve enum metadata with: proto3.getEnumType(WORKFLOW_RULE_MOMENT)
protobuf_1.proto3.util.setEnumType(WORKFLOW_RULE_MOMENT, "Scailo.WORKFLOW_RULE_MOMENT", [
    { no: 0, name: "WORKFLOW_RULE_MOMENT_ANY_UNSPECIFIED" },
    { no: 1, name: "WORKFLOW_RULE_MOMENT_BEGIN" },
    { no: 2, name: "WORKFLOW_RULE_MOMENT_END" },
]);
/**
 *
 * Stores all the possible values for when the rule should be triggered. Possible values are Success and Failure.
 *
 * @generated from enum Scailo.WORKFLOW_RULE_EXECUTE_ON
 */
var WORKFLOW_RULE_EXECUTE_ON;
(function (WORKFLOW_RULE_EXECUTE_ON) {
    /**
     * Used only for filters
     *
     * @generated from enum value: WORKFLOW_RULE_EXECUTE_ON_ANY_UNSPECIFIED = 0;
     */
    WORKFLOW_RULE_EXECUTE_ON[WORKFLOW_RULE_EXECUTE_ON["WORKFLOW_RULE_EXECUTE_ON_ANY_UNSPECIFIED"] = 0] = "WORKFLOW_RULE_EXECUTE_ON_ANY_UNSPECIFIED";
    /**
     * Execute the rule when the transaction is successful
     *
     * @generated from enum value: WORKFLOW_RULE_EXECUTE_ON_SUCCESS = 1;
     */
    WORKFLOW_RULE_EXECUTE_ON[WORKFLOW_RULE_EXECUTE_ON["WORKFLOW_RULE_EXECUTE_ON_SUCCESS"] = 1] = "WORKFLOW_RULE_EXECUTE_ON_SUCCESS";
    /**
     * Execute the rule when the transaction fails
     *
     * @generated from enum value: WORKFLOW_RULE_EXECUTE_ON_FAILURE = 2;
     */
    WORKFLOW_RULE_EXECUTE_ON[WORKFLOW_RULE_EXECUTE_ON["WORKFLOW_RULE_EXECUTE_ON_FAILURE"] = 2] = "WORKFLOW_RULE_EXECUTE_ON_FAILURE";
})(WORKFLOW_RULE_EXECUTE_ON || (exports.WORKFLOW_RULE_EXECUTE_ON = WORKFLOW_RULE_EXECUTE_ON = {}));
// Retrieve enum metadata with: proto3.getEnumType(WORKFLOW_RULE_EXECUTE_ON)
protobuf_1.proto3.util.setEnumType(WORKFLOW_RULE_EXECUTE_ON, "Scailo.WORKFLOW_RULE_EXECUTE_ON", [
    { no: 0, name: "WORKFLOW_RULE_EXECUTE_ON_ANY_UNSPECIFIED" },
    { no: 1, name: "WORKFLOW_RULE_EXECUTE_ON_SUCCESS" },
    { no: 2, name: "WORKFLOW_RULE_EXECUTE_ON_FAILURE" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.WORKFLOW_RULE_SORT_KEY
 */
var WORKFLOW_RULE_SORT_KEY;
(function (WORKFLOW_RULE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: WORKFLOW_RULE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    WORKFLOW_RULE_SORT_KEY[WORKFLOW_RULE_SORT_KEY["WORKFLOW_RULE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "WORKFLOW_RULE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: WORKFLOW_RULE_SORT_KEY_CREATED_AT = 1;
     */
    WORKFLOW_RULE_SORT_KEY[WORKFLOW_RULE_SORT_KEY["WORKFLOW_RULE_SORT_KEY_CREATED_AT"] = 1] = "WORKFLOW_RULE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: WORKFLOW_RULE_SORT_KEY_MODIFIED_AT = 2;
     */
    WORKFLOW_RULE_SORT_KEY[WORKFLOW_RULE_SORT_KEY["WORKFLOW_RULE_SORT_KEY_MODIFIED_AT"] = 2] = "WORKFLOW_RULE_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: WORKFLOW_RULE_SORT_KEY_APPROVED_ON = 3;
     */
    WORKFLOW_RULE_SORT_KEY[WORKFLOW_RULE_SORT_KEY["WORKFLOW_RULE_SORT_KEY_APPROVED_ON"] = 3] = "WORKFLOW_RULE_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: WORKFLOW_RULE_SORT_KEY_APPROVED_BY = 4;
     */
    WORKFLOW_RULE_SORT_KEY[WORKFLOW_RULE_SORT_KEY["WORKFLOW_RULE_SORT_KEY_APPROVED_BY"] = 4] = "WORKFLOW_RULE_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: WORKFLOW_RULE_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    WORKFLOW_RULE_SORT_KEY[WORKFLOW_RULE_SORT_KEY["WORKFLOW_RULE_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "WORKFLOW_RULE_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: WORKFLOW_RULE_SORT_KEY_COMPLETED_ON = 6;
     */
    WORKFLOW_RULE_SORT_KEY[WORKFLOW_RULE_SORT_KEY["WORKFLOW_RULE_SORT_KEY_COMPLETED_ON"] = 6] = "WORKFLOW_RULE_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: WORKFLOW_RULE_SORT_KEY_NAME = 10;
     */
    WORKFLOW_RULE_SORT_KEY[WORKFLOW_RULE_SORT_KEY["WORKFLOW_RULE_SORT_KEY_NAME"] = 10] = "WORKFLOW_RULE_SORT_KEY_NAME";
    /**
     * Fetch ordered results by the service name
     *
     * @generated from enum value: WORKFLOW_RULE_SORT_KEY_SERVICE_NAME = 11;
     */
    WORKFLOW_RULE_SORT_KEY[WORKFLOW_RULE_SORT_KEY["WORKFLOW_RULE_SORT_KEY_SERVICE_NAME"] = 11] = "WORKFLOW_RULE_SORT_KEY_SERVICE_NAME";
})(WORKFLOW_RULE_SORT_KEY || (exports.WORKFLOW_RULE_SORT_KEY = WORKFLOW_RULE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(WORKFLOW_RULE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(WORKFLOW_RULE_SORT_KEY, "Scailo.WORKFLOW_RULE_SORT_KEY", [
    { no: 0, name: "WORKFLOW_RULE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "WORKFLOW_RULE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "WORKFLOW_RULE_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "WORKFLOW_RULE_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "WORKFLOW_RULE_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "WORKFLOW_RULE_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "WORKFLOW_RULE_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "WORKFLOW_RULE_SORT_KEY_NAME" },
    { no: 11, name: "WORKFLOW_RULE_SORT_KEY_SERVICE_NAME" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.WorkflowsRulesServiceCreateRequest
 */
class WorkflowsRulesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the workflow rule
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The ID of the user who is to be notified when this rule is triggered. The notification will most likely be in the form of an email.
     *
     * @generated from field: uint64 notify_user_id = 12;
     */
    notifyUserId = protobuf_1.protoInt64.zero;
    /**
     * The description of the workflow rule
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The name of the service for which this rule is applicable for
     *
     * @generated from field: Scailo.WORKFLOW_RULE_SERVICE_NAME service_name = 20;
     */
    serviceName = WORKFLOW_RULE_SERVICE_NAME.WORKFLOW_RULE_SERVICE_NAME_ANY_UNSPECIFIED;
    /**
     * Stores the status of the record. The rule is triggered when the status is reached.
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS record_status = 21;
     */
    recordStatus = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the moment at which the rule should be triggered
     *
     * @generated from field: Scailo.WORKFLOW_RULE_MOMENT moment = 22;
     */
    moment = WORKFLOW_RULE_MOMENT.WORKFLOW_RULE_MOMENT_ANY_UNSPECIFIED;
    /**
     * Stores the value for when the rule should be triggered
     *
     * @generated from field: Scailo.WORKFLOW_RULE_EXECUTE_ON execute_on = 23;
     */
    executeOn = WORKFLOW_RULE_EXECUTE_ON.WORKFLOW_RULE_EXECUTE_ON_ANY_UNSPECIFIED;
    /**
     * Stores any additional static payload that is provided by the user and needs to be relayed in the rule
     *
     * @generated from field: bytes user_payload = 40;
     */
    userPayload = new Uint8Array(0);
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkflowsRulesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "notify_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "service_name", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_SERVICE_NAME) },
        { no: 21, name: "record_status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 22, name: "moment", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_MOMENT) },
        { no: 23, name: "execute_on", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_EXECUTE_ON) },
        { no: 40, name: "user_payload", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    ]);
    static fromBinary(bytes, options) {
        return new WorkflowsRulesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkflowsRulesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkflowsRulesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkflowsRulesServiceCreateRequest, a, b);
    }
}
exports.WorkflowsRulesServiceCreateRequest = WorkflowsRulesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.WorkflowsRulesServiceUpdateRequest
 */
class WorkflowsRulesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the workflow rule
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The ID of the user who is to be notified when this rule is triggered. The notification will most likely be in the form of an email.
     *
     * @generated from field: uint64 notify_user_id = 12;
     */
    notifyUserId = protobuf_1.protoInt64.zero;
    /**
     * The description of the workflow rule
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The name of the service for which this rule is applicable for
     *
     * @generated from field: Scailo.WORKFLOW_RULE_SERVICE_NAME service_name = 20;
     */
    serviceName = WORKFLOW_RULE_SERVICE_NAME.WORKFLOW_RULE_SERVICE_NAME_ANY_UNSPECIFIED;
    /**
     * Stores the status of the record. The rule is triggered when the status is reached.
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS record_status = 21;
     */
    recordStatus = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the moment at which the rule should be triggered
     *
     * @generated from field: Scailo.WORKFLOW_RULE_MOMENT moment = 22;
     */
    moment = WORKFLOW_RULE_MOMENT.WORKFLOW_RULE_MOMENT_ANY_UNSPECIFIED;
    /**
     * Stores the value for when the rule should be triggered
     *
     * @generated from field: Scailo.WORKFLOW_RULE_EXECUTE_ON execute_on = 23;
     */
    executeOn = WORKFLOW_RULE_EXECUTE_ON.WORKFLOW_RULE_EXECUTE_ON_ANY_UNSPECIFIED;
    /**
     * Stores any additional static payload that is provided by the user and needs to be relayed in the rule
     *
     * @generated from field: bytes user_payload = 40;
     */
    userPayload = new Uint8Array(0);
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkflowsRulesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "notify_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "service_name", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_SERVICE_NAME) },
        { no: 21, name: "record_status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 22, name: "moment", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_MOMENT) },
        { no: 23, name: "execute_on", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_EXECUTE_ON) },
        { no: 40, name: "user_payload", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    ]);
    static fromBinary(bytes, options) {
        return new WorkflowsRulesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkflowsRulesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkflowsRulesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkflowsRulesServiceUpdateRequest, a, b);
    }
}
exports.WorkflowsRulesServiceUpdateRequest = WorkflowsRulesServiceUpdateRequest;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.WorkflowRule
 */
class WorkflowRule extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this workflow rule
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this workflow rule
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this workflow rule
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this workflow rule was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The name of the workflow rule
     *
     * @generated from field: string name = 10;
     */
    name = "";
    /**
     * The unique autogenerated code of the workflow rule
     *
     * @generated from field: string code = 11;
     */
    code = "";
    /**
     * The ID of the user who is to be notified when this rule is triggered. The notification will most likely be in the form of an email.
     *
     * @generated from field: uint64 notify_user_id = 12;
     */
    notifyUserId = protobuf_1.protoInt64.zero;
    /**
     * The description of the workflow rule
     *
     * @generated from field: string description = 13;
     */
    description = "";
    /**
     * The name of the service for which this rule is applicable for
     *
     * @generated from field: Scailo.WORKFLOW_RULE_SERVICE_NAME service_name = 20;
     */
    serviceName = WORKFLOW_RULE_SERVICE_NAME.WORKFLOW_RULE_SERVICE_NAME_ANY_UNSPECIFIED;
    /**
     * Stores the status of the record. The rule is triggered when the status is reached.
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS record_status = 21;
     */
    recordStatus = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the moment at which the rule should be triggered
     *
     * @generated from field: Scailo.WORKFLOW_RULE_MOMENT moment = 22;
     */
    moment = WORKFLOW_RULE_MOMENT.WORKFLOW_RULE_MOMENT_ANY_UNSPECIFIED;
    /**
     * Stores the value for when the rule should be triggered
     *
     * @generated from field: Scailo.WORKFLOW_RULE_EXECUTE_ON execute_on = 23;
     */
    executeOn = WORKFLOW_RULE_EXECUTE_ON.WORKFLOW_RULE_EXECUTE_ON_ANY_UNSPECIFIED;
    /**
     * Stores any additional static payload that is provided by the user and needs to be relayed in the rule
     *
     * @generated from field: bytes user_payload = 40;
     */
    userPayload = new Uint8Array(0);
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkflowRule";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "notify_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "service_name", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_SERVICE_NAME) },
        { no: 21, name: "record_status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 22, name: "moment", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_MOMENT) },
        { no: 23, name: "execute_on", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_EXECUTE_ON) },
        { no: 40, name: "user_payload", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    ]);
    static fromBinary(bytes, options) {
        return new WorkflowRule().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkflowRule().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkflowRule().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkflowRule, a, b);
    }
}
exports.WorkflowRule = WorkflowRule;
/**
 *
 * Describes the message consisting of the list of workflows rules
 *
 * @generated from message Scailo.WorkflowsRulesList
 */
class WorkflowsRulesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.WorkflowRule list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkflowsRulesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: WorkflowRule, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new WorkflowsRulesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkflowsRulesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkflowsRulesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkflowsRulesList, a, b);
    }
}
exports.WorkflowsRulesList = WorkflowsRulesList;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.WorkflowsRulesServicePaginationReq
 */
class WorkflowsRulesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.WORKFLOW_RULE_SORT_KEY sort_key = 5;
     */
    sortKey = WORKFLOW_RULE_SORT_KEY.WORKFLOW_RULE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this workflow rule
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkflowsRulesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new WorkflowsRulesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkflowsRulesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkflowsRulesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkflowsRulesServicePaginationReq, a, b);
    }
}
exports.WorkflowsRulesServicePaginationReq = WorkflowsRulesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.WorkflowsRulesServicePaginationResponse
 */
class WorkflowsRulesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.WorkflowRule payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkflowsRulesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: WorkflowRule, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new WorkflowsRulesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkflowsRulesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkflowsRulesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkflowsRulesServicePaginationResponse, a, b);
    }
}
exports.WorkflowsRulesServicePaginationResponse = WorkflowsRulesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.WorkflowsRulesServiceFilterReq
 */
class WorkflowsRulesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.WORKFLOW_RULE_SORT_KEY sort_key = 5;
     */
    sortKey = WORKFLOW_RULE_SORT_KEY.WORKFLOW_RULE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this workflow rule
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The name of the workflow rule
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The code of the workflow rule
     *
     * @generated from field: string code = 21;
     */
    code = "";
    /**
     * The ID of the user who is being notified
     *
     * @generated from field: uint64 notify_user_id = 22;
     */
    notifyUserId = protobuf_1.protoInt64.zero;
    /**
     * The name of the service for which this rule is applicable for
     *
     * @generated from field: Scailo.WORKFLOW_RULE_SERVICE_NAME service_name = 30;
     */
    serviceName = WORKFLOW_RULE_SERVICE_NAME.WORKFLOW_RULE_SERVICE_NAME_ANY_UNSPECIFIED;
    /**
     * Stores the status of the record. The rule is triggered when the status is reached.
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS record_status = 31;
     */
    recordStatus = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the moment at which the rule should be triggered
     *
     * @generated from field: Scailo.WORKFLOW_RULE_MOMENT moment = 32;
     */
    moment = WORKFLOW_RULE_MOMENT.WORKFLOW_RULE_MOMENT_ANY_UNSPECIFIED;
    /**
     * Stores the value for when the rule should be triggered
     *
     * @generated from field: Scailo.WORKFLOW_RULE_EXECUTE_ON execute_on = 33;
     */
    executeOn = WORKFLOW_RULE_EXECUTE_ON.WORKFLOW_RULE_EXECUTE_ON_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkflowsRulesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "notify_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "service_name", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_SERVICE_NAME) },
        { no: 31, name: "record_status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 32, name: "moment", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_MOMENT) },
        { no: 33, name: "execute_on", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_EXECUTE_ON) },
    ]);
    static fromBinary(bytes, options) {
        return new WorkflowsRulesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkflowsRulesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkflowsRulesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkflowsRulesServiceFilterReq, a, b);
    }
}
exports.WorkflowsRulesServiceFilterReq = WorkflowsRulesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.WorkflowsRulesServiceCountReq
 */
class WorkflowsRulesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this workflow rule
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The name of the workflow rule
     *
     * @generated from field: string name = 20;
     */
    name = "";
    /**
     * The code of the workflow rule
     *
     * @generated from field: string code = 21;
     */
    code = "";
    /**
     * The ID of the user who is being notified
     *
     * @generated from field: uint64 notify_user_id = 22;
     */
    notifyUserId = protobuf_1.protoInt64.zero;
    /**
     * The name of the service for which this rule is applicable for
     *
     * @generated from field: Scailo.WORKFLOW_RULE_SERVICE_NAME service_name = 30;
     */
    serviceName = WORKFLOW_RULE_SERVICE_NAME.WORKFLOW_RULE_SERVICE_NAME_ANY_UNSPECIFIED;
    /**
     * Stores the status of the record. The rule is triggered when the status is reached.
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS record_status = 31;
     */
    recordStatus = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the moment at which the rule should be triggered
     *
     * @generated from field: Scailo.WORKFLOW_RULE_MOMENT moment = 32;
     */
    moment = WORKFLOW_RULE_MOMENT.WORKFLOW_RULE_MOMENT_ANY_UNSPECIFIED;
    /**
     * Stores the value for when the rule should be triggered
     *
     * @generated from field: Scailo.WORKFLOW_RULE_EXECUTE_ON execute_on = 33;
     */
    executeOn = WORKFLOW_RULE_EXECUTE_ON.WORKFLOW_RULE_EXECUTE_ON_ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkflowsRulesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "notify_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "service_name", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_SERVICE_NAME) },
        { no: 31, name: "record_status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 32, name: "moment", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_MOMENT) },
        { no: 33, name: "execute_on", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_EXECUTE_ON) },
    ]);
    static fromBinary(bytes, options) {
        return new WorkflowsRulesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkflowsRulesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkflowsRulesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkflowsRulesServiceCountReq, a, b);
    }
}
exports.WorkflowsRulesServiceCountReq = WorkflowsRulesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.WorkflowsRulesServiceSearchAllReq
 */
class WorkflowsRulesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.WORKFLOW_RULE_SORT_KEY sort_key = 5;
     */
    sortKey = WORKFLOW_RULE_SORT_KEY.WORKFLOW_RULE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkflowsRulesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new WorkflowsRulesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkflowsRulesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkflowsRulesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkflowsRulesServiceSearchAllReq, a, b);
    }
}
exports.WorkflowsRulesServiceSearchAllReq = WorkflowsRulesServiceSearchAllReq;
/**
 *
 * Describes the request payload that is sent to Redis whenever an event occurs
 *
 * @generated from message Scailo.WorkflowEvent
 */
class WorkflowEvent extends protobuf_1.Message {
    /**
     * The ID of the trigger that could be used to update the status of the trigger
     *
     * @generated from field: uint64 event_id = 1;
     */
    eventId = protobuf_1.protoInt64.zero;
    /**
     * The code of the workflow rule
     *
     * @generated from field: string rule_code = 2;
     */
    ruleCode = "";
    /**
     * The name of the service for which this rule is applicable for
     *
     * @generated from field: Scailo.WORKFLOW_RULE_SERVICE_NAME service_name = 10;
     */
    serviceName = WORKFLOW_RULE_SERVICE_NAME.WORKFLOW_RULE_SERVICE_NAME_ANY_UNSPECIFIED;
    /**
     * The status of the transaction
     *
     * @generated from field: Scailo.WORKFLOW_RULE_EXECUTE_ON transaction_status = 20;
     */
    transactionStatus = WORKFLOW_RULE_EXECUTE_ON.WORKFLOW_RULE_EXECUTE_ON_ANY_UNSPECIFIED;
    /**
     * The payload of the transaction that just took place (could be sales order, etc.)
     *
     * @generated from field: bytes transaction_payload = 30;
     */
    transactionPayload = new Uint8Array(0);
    /**
     * The configured static user payload of the workflow rule
     *
     * @generated from field: bytes user_payload = 40;
     */
    userPayload = new Uint8Array(0);
    /**
     * The username of the user who triggered the workflow
     *
     * @generated from field: string username = 50;
     */
    username = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.WorkflowEvent";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "event_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "rule_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "service_name", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_SERVICE_NAME) },
        { no: 20, name: "transaction_status", kind: "enum", T: protobuf_1.proto3.getEnumType(WORKFLOW_RULE_EXECUTE_ON) },
        { no: 30, name: "transaction_payload", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
        { no: 40, name: "user_payload", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
        { no: 50, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new WorkflowEvent().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new WorkflowEvent().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new WorkflowEvent().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(WorkflowEvent, a, b);
    }
}
exports.WorkflowEvent = WorkflowEvent;
