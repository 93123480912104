import { redirectTo } from "./ui";
import { showFailureAlert, showSuccessAlert } from "./utilities";

let ws: WebSocket;

/**Stores the information of each websocket message */
interface WSMessage {
    messageType: "auth" | "aloha" | "loginReqd" | "pushNotification" | "pushNotificationUUID" | "vaultNotification" | "routeUpdate" | "reload" | "app_update"
    messageData: any
}

/**Stores the information of each websocket notification */
interface WSNotification {
    id: number
    title: string
    category: string
    message: string
    actor: string
    for_user: string

    /**Stores the UID of the menu that is affected by this notification */
    menu_uid: string
    /**Stores the ID of the element that this notification has affected */
    element_id: number
    /**Stores the UUID of the element that this notification has affected */
    element_uuid: string

    link: string
    linkable: boolean
    silent: boolean
    active: boolean
    timestamp: number
}

export function init() {
    var protocol = "";
    if (window.location.protocol == "http:") {
        protocol = "ws:";
    } else {
        protocol = "wss:";
    }

    try {
        ws = new WebSocket(protocol + "//" + window.location.host + "/ws");
    } catch (e) {
        showFailureAlert("Network error: Connection could not be established with the server");
        setTimeout(init, 5000);
        return
    }

    ws.onopen = function (evt) {
        let obj: WSMessage = {
            messageType: "auth",
            messageData: ""
        }
        ws.send(JSON.stringify(obj));
    }
    ws.onclose = function (evt) {
        // console.log("Closed connection!");
        showFailureAlert("Connection to the server is lost. Retrying...");
        setTimeout(init, 1000);
    }
    ws.onmessage = async function (evt) {
        var message: WSMessage = JSON.parse(evt.data);
        if (message.messageType == "aloha") {
            showSuccessAlert("Connection established with the sever");
        } else if (message.messageType == "pushNotification") {
            const parsedMessage = (<WSNotification>message.messageData);
            // Also check if the UI needs to be reloaded
            const pathname = location.pathname.split("/");
            if (pathname[pathname.length - 1] == parsedMessage.element_uuid) {
                // showSuccessAlert(parsedMessage.message);
                pathname.pop();
                // Redirect the UI
                const url = pathname.join("/") + "/" + parsedMessage.element_uuid;
                redirectTo(url, true);
            }
        }
    }
}

/**Waits for the given timeout */
async function wait(n: number) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(null);
        }, n);
    });
}

// function handleIncomingWSNotification(message: structs.WSNotification, contextMenu: Menu) {
//     // Reload the UI
//     checkIfUINeedsToReload(message, contextMenu);
//     if (!message.silent) {
//         notifications.displayWSNotification(message);
//     }
// }