import { 
    salesEnquiryFiltersMenu,
    salesEnquiryIndividualMenu, 
    // salesEnquiryInsightsMenu 
} from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleSalesEnquiryFilters } from "./salesenquiries.filters";
import { handleSalesEnquiryInsights } from "./salesenquiries.insights";
import { handleIndividualSalesEnquiry } from "./individual";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(salesEnquiryFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, salesEnquiryFiltersMenu)) {
            handleSalesEnquiryFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    // r.add(salesEnquiryInsightsMenu.href, async (ctx) => {
    //     if (await interceptPage(ctx, salesEnquiryInsightsMenu)) {
    //         handleSalesEnquiryInsights(ctx);
    //     } else {
    //         handlePageForInvalidPermissions(ctx);
    //     }
    // });
    r.add(salesEnquiryIndividualMenu.href, async (ctx) => {
        if (await interceptPage(ctx, salesEnquiryFiltersMenu)) {
            handleIndividualSalesEnquiry(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}