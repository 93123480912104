"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file quotations_responses.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuotationsResponsesServiceItemsFilterReq = exports.QuotationsResponsesServiceSearchAllReq = exports.QuotationsResponsesServiceCountReq = exports.QuotationsResponsesServiceFilterReq = exports.QuotationsResponsesServicePaginationResponse = exports.QuotationsResponsesServicePaginationReq = exports.QuotationsResponsesServicePaginatedItemsResponse = exports.QuotationResponseItemsSearchRequest = exports.QuotationResponseItemHistoryRequest = exports.QuotationsResponsesItemsList = exports.QuotationsResponsesList = exports.QuotationResponseItem = exports.QuotationsResponsesServiceItemUpdateRequest = exports.QuotationsResponsesServiceItemCreateRequest = exports.QuotationResponse = exports.QuotationResponseAncillaryParameters = exports.QuotationsResponsesServiceUpdateRequest = exports.QuotationsResponsesServiceCreateRequest = exports.QUOTATION_RESPONSE_SORT_KEY = exports.QUOTATION_RESPONSE_ITEM_STATUS = exports.QUOTATION_RESPONSE_ITEM_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.QUOTATION_RESPONSE_ITEM_SORT_KEY
 */
var QUOTATION_RESPONSE_ITEM_SORT_KEY;
(function (QUOTATION_RESPONSE_ITEM_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: QUOTATION_RESPONSE_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    QUOTATION_RESPONSE_ITEM_SORT_KEY[QUOTATION_RESPONSE_ITEM_SORT_KEY["QUOTATION_RESPONSE_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "QUOTATION_RESPONSE_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: QUOTATION_RESPONSE_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    QUOTATION_RESPONSE_ITEM_SORT_KEY[QUOTATION_RESPONSE_ITEM_SORT_KEY["QUOTATION_RESPONSE_ITEM_SORT_KEY_CREATED_AT"] = 1] = "QUOTATION_RESPONSE_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: QUOTATION_RESPONSE_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    QUOTATION_RESPONSE_ITEM_SORT_KEY[QUOTATION_RESPONSE_ITEM_SORT_KEY["QUOTATION_RESPONSE_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "QUOTATION_RESPONSE_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: QUOTATION_RESPONSE_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    QUOTATION_RESPONSE_ITEM_SORT_KEY[QUOTATION_RESPONSE_ITEM_SORT_KEY["QUOTATION_RESPONSE_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "QUOTATION_RESPONSE_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: QUOTATION_RESPONSE_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    QUOTATION_RESPONSE_ITEM_SORT_KEY[QUOTATION_RESPONSE_ITEM_SORT_KEY["QUOTATION_RESPONSE_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "QUOTATION_RESPONSE_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: QUOTATION_RESPONSE_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    QUOTATION_RESPONSE_ITEM_SORT_KEY[QUOTATION_RESPONSE_ITEM_SORT_KEY["QUOTATION_RESPONSE_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "QUOTATION_RESPONSE_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the name
     *
     * @generated from enum value: QUOTATION_RESPONSE_ITEM_SORT_KEY_NAME = 10;
     */
    QUOTATION_RESPONSE_ITEM_SORT_KEY[QUOTATION_RESPONSE_ITEM_SORT_KEY["QUOTATION_RESPONSE_ITEM_SORT_KEY_NAME"] = 10] = "QUOTATION_RESPONSE_ITEM_SORT_KEY_NAME";
    /**
     * Fetch ordered results by the internal quantity
     *
     * @generated from enum value: QUOTATION_RESPONSE_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    QUOTATION_RESPONSE_ITEM_SORT_KEY[QUOTATION_RESPONSE_ITEM_SORT_KEY["QUOTATION_RESPONSE_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "QUOTATION_RESPONSE_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch ordered results by the vendor quantity
     *
     * @generated from enum value: QUOTATION_RESPONSE_ITEM_SORT_KEY_VENDOR_QUANTITY = 12;
     */
    QUOTATION_RESPONSE_ITEM_SORT_KEY[QUOTATION_RESPONSE_ITEM_SORT_KEY["QUOTATION_RESPONSE_ITEM_SORT_KEY_VENDOR_QUANTITY"] = 12] = "QUOTATION_RESPONSE_ITEM_SORT_KEY_VENDOR_QUANTITY";
    /**
     * Fetch ordered results by the vendor unit price
     *
     * @generated from enum value: QUOTATION_RESPONSE_ITEM_SORT_KEY_VENDOR_UNIT_PRICE = 13;
     */
    QUOTATION_RESPONSE_ITEM_SORT_KEY[QUOTATION_RESPONSE_ITEM_SORT_KEY["QUOTATION_RESPONSE_ITEM_SORT_KEY_VENDOR_UNIT_PRICE"] = 13] = "QUOTATION_RESPONSE_ITEM_SORT_KEY_VENDOR_UNIT_PRICE";
    /**
     * Fetch ordered results by the delivery time in days
     *
     * @generated from enum value: QUOTATION_RESPONSE_ITEM_SORT_KEY_DELIVERY_TIME = 14;
     */
    QUOTATION_RESPONSE_ITEM_SORT_KEY[QUOTATION_RESPONSE_ITEM_SORT_KEY["QUOTATION_RESPONSE_ITEM_SORT_KEY_DELIVERY_TIME"] = 14] = "QUOTATION_RESPONSE_ITEM_SORT_KEY_DELIVERY_TIME";
})(QUOTATION_RESPONSE_ITEM_SORT_KEY || (exports.QUOTATION_RESPONSE_ITEM_SORT_KEY = QUOTATION_RESPONSE_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(QUOTATION_RESPONSE_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(QUOTATION_RESPONSE_ITEM_SORT_KEY, "Scailo.QUOTATION_RESPONSE_ITEM_SORT_KEY", [
    { no: 0, name: "QUOTATION_RESPONSE_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "QUOTATION_RESPONSE_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "QUOTATION_RESPONSE_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "QUOTATION_RESPONSE_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "QUOTATION_RESPONSE_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "QUOTATION_RESPONSE_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "QUOTATION_RESPONSE_ITEM_SORT_KEY_NAME" },
    { no: 11, name: "QUOTATION_RESPONSE_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "QUOTATION_RESPONSE_ITEM_SORT_KEY_VENDOR_QUANTITY" },
    { no: 13, name: "QUOTATION_RESPONSE_ITEM_SORT_KEY_VENDOR_UNIT_PRICE" },
    { no: 14, name: "QUOTATION_RESPONSE_ITEM_SORT_KEY_DELIVERY_TIME" },
]);
/**
 *
 * Describes the applicable statuses of quotation response items
 *
 * @generated from enum Scailo.QUOTATION_RESPONSE_ITEM_STATUS
 */
var QUOTATION_RESPONSE_ITEM_STATUS;
(function (QUOTATION_RESPONSE_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: QUOTATION_RESPONSE_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    QUOTATION_RESPONSE_ITEM_STATUS[QUOTATION_RESPONSE_ITEM_STATUS["QUOTATION_RESPONSE_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "QUOTATION_RESPONSE_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the quotation response items must have been approved
     *
     * @generated from enum value: QUOTATION_RESPONSE_ITEM_STATUS_APPROVED = 1;
     */
    QUOTATION_RESPONSE_ITEM_STATUS[QUOTATION_RESPONSE_ITEM_STATUS["QUOTATION_RESPONSE_ITEM_STATUS_APPROVED"] = 1] = "QUOTATION_RESPONSE_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the quotation response items must be waiting for approval
     *
     * @generated from enum value: QUOTATION_RESPONSE_ITEM_STATUS_UNAPPROVED = 2;
     */
    QUOTATION_RESPONSE_ITEM_STATUS[QUOTATION_RESPONSE_ITEM_STATUS["QUOTATION_RESPONSE_ITEM_STATUS_UNAPPROVED"] = 2] = "QUOTATION_RESPONSE_ITEM_STATUS_UNAPPROVED";
})(QUOTATION_RESPONSE_ITEM_STATUS || (exports.QUOTATION_RESPONSE_ITEM_STATUS = QUOTATION_RESPONSE_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(QUOTATION_RESPONSE_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(QUOTATION_RESPONSE_ITEM_STATUS, "Scailo.QUOTATION_RESPONSE_ITEM_STATUS", [
    { no: 0, name: "QUOTATION_RESPONSE_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "QUOTATION_RESPONSE_ITEM_STATUS_APPROVED" },
    { no: 2, name: "QUOTATION_RESPONSE_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.QUOTATION_RESPONSE_SORT_KEY
 */
var QUOTATION_RESPONSE_SORT_KEY;
(function (QUOTATION_RESPONSE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: QUOTATION_RESPONSE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    QUOTATION_RESPONSE_SORT_KEY[QUOTATION_RESPONSE_SORT_KEY["QUOTATION_RESPONSE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "QUOTATION_RESPONSE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: QUOTATION_RESPONSE_SORT_KEY_CREATED_AT = 1;
     */
    QUOTATION_RESPONSE_SORT_KEY[QUOTATION_RESPONSE_SORT_KEY["QUOTATION_RESPONSE_SORT_KEY_CREATED_AT"] = 1] = "QUOTATION_RESPONSE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: QUOTATION_RESPONSE_SORT_KEY_MODIFIED_AT = 2;
     */
    QUOTATION_RESPONSE_SORT_KEY[QUOTATION_RESPONSE_SORT_KEY["QUOTATION_RESPONSE_SORT_KEY_MODIFIED_AT"] = 2] = "QUOTATION_RESPONSE_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: QUOTATION_RESPONSE_SORT_KEY_APPROVED_ON = 3;
     */
    QUOTATION_RESPONSE_SORT_KEY[QUOTATION_RESPONSE_SORT_KEY["QUOTATION_RESPONSE_SORT_KEY_APPROVED_ON"] = 3] = "QUOTATION_RESPONSE_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: QUOTATION_RESPONSE_SORT_KEY_APPROVED_BY = 4;
     */
    QUOTATION_RESPONSE_SORT_KEY[QUOTATION_RESPONSE_SORT_KEY["QUOTATION_RESPONSE_SORT_KEY_APPROVED_BY"] = 4] = "QUOTATION_RESPONSE_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: QUOTATION_RESPONSE_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    QUOTATION_RESPONSE_SORT_KEY[QUOTATION_RESPONSE_SORT_KEY["QUOTATION_RESPONSE_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "QUOTATION_RESPONSE_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: QUOTATION_RESPONSE_SORT_KEY_COMPLETED_ON = 6;
     */
    QUOTATION_RESPONSE_SORT_KEY[QUOTATION_RESPONSE_SORT_KEY["QUOTATION_RESPONSE_SORT_KEY_COMPLETED_ON"] = 6] = "QUOTATION_RESPONSE_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: QUOTATION_RESPONSE_SORT_KEY_REFERENCE_ID = 10;
     */
    QUOTATION_RESPONSE_SORT_KEY[QUOTATION_RESPONSE_SORT_KEY["QUOTATION_RESPONSE_SORT_KEY_REFERENCE_ID"] = 10] = "QUOTATION_RESPONSE_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: QUOTATION_RESPONSE_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    QUOTATION_RESPONSE_SORT_KEY[QUOTATION_RESPONSE_SORT_KEY["QUOTATION_RESPONSE_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "QUOTATION_RESPONSE_SORT_KEY_FINAL_REF_NUMBER";
})(QUOTATION_RESPONSE_SORT_KEY || (exports.QUOTATION_RESPONSE_SORT_KEY = QUOTATION_RESPONSE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(QUOTATION_RESPONSE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(QUOTATION_RESPONSE_SORT_KEY, "Scailo.QUOTATION_RESPONSE_SORT_KEY", [
    { no: 0, name: "QUOTATION_RESPONSE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "QUOTATION_RESPONSE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "QUOTATION_RESPONSE_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "QUOTATION_RESPONSE_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "QUOTATION_RESPONSE_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "QUOTATION_RESPONSE_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "QUOTATION_RESPONSE_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "QUOTATION_RESPONSE_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "QUOTATION_RESPONSE_SORT_KEY_FINAL_REF_NUMBER" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.QuotationsResponsesServiceCreateRequest
 */
class QuotationsResponsesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the quotation response
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated quotation request
     *
     * @generated from field: uint64 quotation_request_id = 12;
     */
    quotationRequestId = protobuf_1.protoInt64.zero;
    /**
     * The associated party type
     *
     * @generated from field: string ref_from = 13;
     */
    refFrom = "";
    /**
     * The associated party ID
     *
     * @generated from field: uint64 ref_id = 14;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The description of the quotation response
     *
     * @generated from field: string description = 16;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsResponsesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "quotation_request_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsResponsesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsResponsesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsResponsesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsResponsesServiceCreateRequest, a, b);
    }
}
exports.QuotationsResponsesServiceCreateRequest = QuotationsResponsesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.QuotationsResponsesServiceUpdateRequest
 */
class QuotationsResponsesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the quotation response
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The description of the quotation response
     *
     * @generated from field: string description = 16;
     */
    description = "";
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsResponsesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsResponsesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsResponsesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsResponsesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsResponsesServiceUpdateRequest, a, b);
    }
}
exports.QuotationsResponsesServiceUpdateRequest = QuotationsResponsesServiceUpdateRequest;
/**
 *
 * Stores the UUID references of the record
 *
 * @generated from message Scailo.QuotationResponseAncillaryParameters
 */
class QuotationResponseAncillaryParameters extends protobuf_1.Message {
    /**
     * The UUID of the quotation_request_id (the UUID of the associated quotation_request_id)
     *
     * @generated from field: string quotation_request_uuid = 212;
     */
    quotationRequestUuid = "";
    /**
     * The UUID of the currency (the UUID of the associated currency_id)
     *
     * @generated from field: string currency_uuid = 215;
     */
    currencyUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationResponseAncillaryParameters";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 212, name: "quotation_request_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 215, name: "currency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationResponseAncillaryParameters().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationResponseAncillaryParameters().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationResponseAncillaryParameters().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationResponseAncillaryParameters, a, b);
    }
}
exports.QuotationResponseAncillaryParameters = QuotationResponseAncillaryParameters;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.QuotationResponse
 */
class QuotationResponse extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this quotation response
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this quotation response
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this quotation response
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this quotation response was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the quotation response
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The associated quotation request
     *
     * @generated from field: uint64 quotation_request_id = 12;
     */
    quotationRequestId = protobuf_1.protoInt64.zero;
    /**
     * The associated party type
     *
     * @generated from field: string ref_from = 13;
     */
    refFrom = "";
    /**
     * The associated party ID
     *
     * @generated from field: uint64 ref_id = 14;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated currency
     *
     * @generated from field: uint64 currency_id = 15;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The description of the quotation response
     *
     * @generated from field: string description = 16;
     */
    description = "";
    /**
     * The list of associated quotation response items
     *
     * @generated from field: repeated Scailo.QuotationResponseItem list = 20;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "quotation_request_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 20, name: "list", kind: "message", T: QuotationResponseItem, repeated: true },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationResponse, a, b);
    }
}
exports.QuotationResponse = QuotationResponse;
/**
 *
 * Describes the parameters required to add an item to a quotation response
 *
 * @generated from message Scailo.QuotationsResponsesServiceItemCreateRequest
 */
class QuotationsResponsesServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quotation response ID
     *
     * @generated from field: uint64 quotation_response_id = 10;
     */
    quotationResponseId = protobuf_1.protoInt64.zero;
    /**
     * Stores the corresponding item ID of the item from the quotation request
     *
     * @generated from field: uint64 quotation_request_item_id = 11;
     */
    quotationRequestItemId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 12;
     */
    name = "";
    /**
     * Stores the HSN/SAC code of the item
     *
     * @generated from field: string hsn_sac_code = 13;
     */
    hsnSacCode = "";
    /**
     * The internal unit of material
     *
     * @generated from field: uint64 uom_id = 14;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The internal quantity offered (in cents)
     *
     * @generated from field: uint64 internal_quantity = 15;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 16;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * The quantity offered in vendor's unit of material (in cents)
     *
     * @generated from field: uint64 vendor_quantity = 17;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    /**
     * The unit price per vendor's unit of material
     *
     * @generated from field: uint64 vendor_unit_price = 18;
     */
    vendorUnitPrice = protobuf_1.protoInt64.zero;
    /**
     * The associated tax group ID
     *
     * @generated from field: uint64 tax_group_id = 19;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The delivery time in days (in cents)
     *
     * @generated from field: uint64 delivery_time_in_days = 20;
     */
    deliveryTimeInDays = protobuf_1.protoInt64.zero;
    /**
     * The specifications of the item
     *
     * @generated from field: string specifications = 21;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsResponsesServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "quotation_response_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "quotation_request_item_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "hsn_sac_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "delivery_time_in_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsResponsesServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsResponsesServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsResponsesServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsResponsesServiceItemCreateRequest, a, b);
    }
}
exports.QuotationsResponsesServiceItemCreateRequest = QuotationsResponsesServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a quotation response
 *
 * @generated from message Scailo.QuotationsResponsesServiceItemUpdateRequest
 */
class QuotationsResponsesServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 12;
     */
    name = "";
    /**
     * Stores the HSN/SAC code of the item
     *
     * @generated from field: string hsn_sac_code = 13;
     */
    hsnSacCode = "";
    /**
     * The internal unit of material
     *
     * @generated from field: uint64 uom_id = 14;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The internal quantity offered (in cents)
     *
     * @generated from field: uint64 internal_quantity = 15;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 16;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * The quantity offered in vendor's unit of material (in cents)
     *
     * @generated from field: uint64 vendor_quantity = 17;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    /**
     * The unit price per vendor's unit of material
     *
     * @generated from field: uint64 vendor_unit_price = 18;
     */
    vendorUnitPrice = protobuf_1.protoInt64.zero;
    /**
     * The associated tax group ID
     *
     * @generated from field: uint64 tax_group_id = 19;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The delivery time in days (in cents)
     *
     * @generated from field: uint64 delivery_time_in_days = 20;
     */
    deliveryTimeInDays = protobuf_1.protoInt64.zero;
    /**
     * The specifications of the item
     *
     * @generated from field: string specifications = 21;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsResponsesServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "hsn_sac_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "delivery_time_in_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsResponsesServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsResponsesServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsResponsesServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsResponsesServiceItemUpdateRequest, a, b);
    }
}
exports.QuotationsResponsesServiceItemUpdateRequest = QuotationsResponsesServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a quotation response
 *
 * @generated from message Scailo.QuotationResponseItem
 */
class QuotationResponseItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this quotation response
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quotation response ID
     *
     * @generated from field: uint64 quotation_response_id = 10;
     */
    quotationResponseId = protobuf_1.protoInt64.zero;
    /**
     * Stores the corresponding item ID of the item from the quotation request
     *
     * @generated from field: uint64 quotation_request_item_id = 11;
     */
    quotationRequestItemId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 12;
     */
    name = "";
    /**
     * Stores the HSN/SAC code of the item
     *
     * @generated from field: string hsn_sac_code = 13;
     */
    hsnSacCode = "";
    /**
     * The internal unit of material
     *
     * @generated from field: uint64 uom_id = 14;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The internal quantity offered (in cents)
     *
     * @generated from field: uint64 internal_quantity = 15;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * The vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 16;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * The quantity offered in vendor's unit of material (in cents)
     *
     * @generated from field: uint64 vendor_quantity = 17;
     */
    vendorQuantity = protobuf_1.protoInt64.zero;
    /**
     * The unit price per vendor's unit of material
     *
     * @generated from field: uint64 vendor_unit_price = 18;
     */
    vendorUnitPrice = protobuf_1.protoInt64.zero;
    /**
     * The associated tax group ID
     *
     * @generated from field: uint64 tax_group_id = 19;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The delivery time in days (in cents)
     *
     * @generated from field: uint64 delivery_time_in_days = 20;
     */
    deliveryTimeInDays = protobuf_1.protoInt64.zero;
    /**
     * The specifications of the item
     *
     * @generated from field: string specifications = 21;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationResponseItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "quotation_response_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "quotation_request_item_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "hsn_sac_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "vendor_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "vendor_unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 19, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "delivery_time_in_days", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationResponseItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationResponseItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationResponseItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationResponseItem, a, b);
    }
}
exports.QuotationResponseItem = QuotationResponseItem;
/**
 *
 * Describes the message consisting of the list of quotations responses
 *
 * @generated from message Scailo.QuotationsResponsesList
 */
class QuotationsResponsesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.QuotationResponse list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsResponsesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: QuotationResponse, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsResponsesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsResponsesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsResponsesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsResponsesList, a, b);
    }
}
exports.QuotationsResponsesList = QuotationsResponsesList;
/**
 *
 * Describes the message consisting of the list of quotation response items
 *
 * @generated from message Scailo.QuotationsResponsesItemsList
 */
class QuotationsResponsesItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.QuotationResponseItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsResponsesItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: QuotationResponseItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsResponsesItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsResponsesItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsResponsesItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsResponsesItemsList, a, b);
    }
}
exports.QuotationsResponsesItemsList = QuotationsResponsesItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.QuotationResponseItemHistoryRequest
 */
class QuotationResponseItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the quotation response ID
     *
     * @generated from field: uint64 quotation_response_id = 10;
     */
    quotationResponseId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 11;
     */
    name = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationResponseItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "quotation_response_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationResponseItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationResponseItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationResponseItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationResponseItemHistoryRequest, a, b);
    }
}
exports.QuotationResponseItemHistoryRequest = QuotationResponseItemHistoryRequest;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.QuotationResponseItemsSearchRequest
 */
class QuotationResponseItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.QUOTATION_RESPONSE_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = QUOTATION_RESPONSE_ITEM_SORT_KEY.QUOTATION_RESPONSE_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.QUOTATION_RESPONSE_ITEM_STATUS status = 7;
     */
    status = QUOTATION_RESPONSE_ITEM_STATUS.QUOTATION_RESPONSE_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quotation response ID
     *
     * @generated from field: uint64 quotation_response_id = 20;
     */
    quotationResponseId = protobuf_1.protoInt64.zero;
    /**
     * Stores the corresponding item ID of the item from the quotation request
     *
     * @generated from field: uint64 quotation_request_item_id = 21;
     */
    quotationRequestItemId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional unit of material ID
     *
     * @generated from field: uint64 uom_id = 24;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional vendor unit of material ID
     *
     * @generated from field: uint64 vendor_uom_id = 26;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores an optional tax group ID
     *
     * @generated from field: uint64 tax_group_id = 29;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationResponseItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(QUOTATION_RESPONSE_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(QUOTATION_RESPONSE_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "quotation_response_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "quotation_request_item_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 29, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationResponseItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationResponseItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationResponseItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationResponseItemsSearchRequest, a, b);
    }
}
exports.QuotationResponseItemsSearchRequest = QuotationResponseItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.QuotationsResponsesServicePaginatedItemsResponse
 */
class QuotationsResponsesServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.QuotationResponseItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsResponsesServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: QuotationResponseItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsResponsesServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsResponsesServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsResponsesServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsResponsesServicePaginatedItemsResponse, a, b);
    }
}
exports.QuotationsResponsesServicePaginatedItemsResponse = QuotationsResponsesServicePaginatedItemsResponse;
/**
 *
 * Describes a pagination response to retrieve records
 *
 * @generated from message Scailo.QuotationsResponsesServicePaginationReq
 */
class QuotationsResponsesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.QUOTATION_RESPONSE_SORT_KEY sort_key = 5;
     */
    sortKey = QUOTATION_RESPONSE_SORT_KEY.QUOTATION_RESPONSE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this quotation response
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsResponsesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(QUOTATION_RESPONSE_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsResponsesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsResponsesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsResponsesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsResponsesServicePaginationReq, a, b);
    }
}
exports.QuotationsResponsesServicePaginationReq = QuotationsResponsesServicePaginationReq;
/**
 *
 * Describes the response to a pagination response
 *
 * @generated from message Scailo.QuotationsResponsesServicePaginationResponse
 */
class QuotationsResponsesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination response
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.QuotationResponse payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsResponsesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: QuotationResponse, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsResponsesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsResponsesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsResponsesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsResponsesServicePaginationResponse, a, b);
    }
}
exports.QuotationsResponsesServicePaginationResponse = QuotationsResponsesServicePaginationResponse;
/**
 *
 * Describes the base response payload of a filter search
 *
 * @generated from message Scailo.QuotationsResponsesServiceFilterReq
 */
class QuotationsResponsesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.QUOTATION_RESPONSE_SORT_KEY sort_key = 5;
     */
    sortKey = QUOTATION_RESPONSE_SORT_KEY.QUOTATION_RESPONSE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this quotation response
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the quotation response
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated quotation request
     *
     * @generated from field: uint64 quotation_request_id = 22;
     */
    quotationRequestId = protobuf_1.protoInt64.zero;
    /**
     * The associated currency
     *
     * @generated from field: uint64 currency_id = 25;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The associated vendor ID
     *
     * @generated from field: uint64 vendor_id = 40;
     */
    vendorId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsResponsesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(QUOTATION_RESPONSE_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "quotation_request_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsResponsesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsResponsesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsResponsesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsResponsesServiceFilterReq, a, b);
    }
}
exports.QuotationsResponsesServiceFilterReq = QuotationsResponsesServiceFilterReq;
/**
 *
 * Describes the base response payload of a count search
 *
 * @generated from message Scailo.QuotationsResponsesServiceCountReq
 */
class QuotationsResponsesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this quotation response
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the quotation response
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated quotation request
     *
     * @generated from field: uint64 quotation_request_id = 22;
     */
    quotationRequestId = protobuf_1.protoInt64.zero;
    /**
     * The associated currency
     *
     * @generated from field: uint64 currency_id = 25;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The associated vendor ID
     *
     * @generated from field: uint64 vendor_id = 40;
     */
    vendorId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsResponsesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "quotation_request_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsResponsesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsResponsesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsResponsesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsResponsesServiceCountReq, a, b);
    }
}
exports.QuotationsResponsesServiceCountReq = QuotationsResponsesServiceCountReq;
/**
 *
 * Describes the response payload for performing a generic search operation on records
 *
 * @generated from message Scailo.QuotationsResponsesServiceSearchAllReq
 */
class QuotationsResponsesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.QUOTATION_RESPONSE_SORT_KEY sort_key = 5;
     */
    sortKey = QUOTATION_RESPONSE_SORT_KEY.QUOTATION_RESPONSE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsResponsesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(QUOTATION_RESPONSE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsResponsesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsResponsesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsResponsesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsResponsesServiceSearchAllReq, a, b);
    }
}
exports.QuotationsResponsesServiceSearchAllReq = QuotationsResponsesServiceSearchAllReq;
/**
 *
 * Describes the request payload to filter quotation response items
 *
 * @generated from message Scailo.QuotationsResponsesServiceItemsFilterReq
 */
class QuotationsResponsesServiceItemsFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.QUOTATION_RESPONSE_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = QUOTATION_RESPONSE_ITEM_SORT_KEY.QUOTATION_RESPONSE_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quotation response ID
     *
     * @generated from field: uint64 quotation_response_id = 20;
     */
    quotationResponseId = protobuf_1.protoInt64.zero;
    /**
     * Stores the corresponding item ID of the item from the quotation request
     *
     * @generated from field: uint64 quotation_request_item_id = 21;
     */
    quotationRequestItemId = protobuf_1.protoInt64.zero;
    /**
     * Stores the name of the item
     *
     * @generated from field: string name = 22;
     */
    name = "";
    /**
     * Stores the HSN/SAC code of the item
     *
     * @generated from field: string hsn_sac_code = 23;
     */
    hsnSacCode = "";
    /**
     * The internal unit of material
     *
     * @generated from field: uint64 uom_id = 24;
     */
    uomId = protobuf_1.protoInt64.zero;
    /**
     * The vendor's unit of material
     *
     * @generated from field: uint64 vendor_uom_id = 26;
     */
    vendorUomId = protobuf_1.protoInt64.zero;
    /**
     * The associated tax group ID
     *
     * @generated from field: uint64 tax_group_id = 29;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.QuotationsResponsesServiceItemsFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(QUOTATION_RESPONSE_ITEM_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "quotation_response_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "quotation_request_item_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 22, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "hsn_sac_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 24, name: "uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 26, name: "vendor_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 29, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new QuotationsResponsesServiceItemsFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new QuotationsResponsesServiceItemsFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new QuotationsResponsesServiceItemsFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(QuotationsResponsesServiceItemsFilterReq, a, b);
    }
}
exports.QuotationsResponsesServiceItemsFilterReq = QuotationsResponsesServiceItemsFilterReq;
