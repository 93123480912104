"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file login.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientUserLoginResponse = exports.VendorUserLoginResponse = exports.UserLoginHistoryList = exports.UserLoginHistory = exports.LogoutResponse = exports.LogoutRequest = exports.AuthTokenValidityRequest = exports.UserLoginResponse = exports.UserLoginRequest = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the data structure for a user to login
 *
 * @generated from message Scailo.UserLoginRequest
 */
class UserLoginRequest extends protobuf_1.Message {
    /**
     * Username of the user
     *
     * @generated from field: string username = 1;
     */
    username = "";
    /**
     * The password of the user
     *
     * @generated from field: string plain_text_password = 12;
     */
    plainTextPassword = "";
    /**
     * The MFA OTP of the user
     *
     * @generated from field: string otp = 13;
     */
    otp = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UserLoginRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "plain_text_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "otp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new UserLoginRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UserLoginRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UserLoginRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UserLoginRequest, a, b);
    }
}
exports.UserLoginRequest = UserLoginRequest;
/**
 *
 * Describes the response to a user login request
 *
 * @generated from message Scailo.UserLoginResponse
 */
class UserLoginResponse extends protobuf_1.Message {
    /**
     * Username of the user that just logged in
     *
     * @generated from field: string username = 1;
     */
    username = "";
    /**
     * The token that needs to be used as part of every request
     *
     * @generated from field: string auth_token = 2;
     */
    authToken = "";
    /**
     * The unix timestamp after which the auth_token is invalid
     *
     * @generated from field: uint64 expires_at = 3;
     */
    expiresAt = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UserLoginResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 3, name: "expires_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new UserLoginResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UserLoginResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UserLoginResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UserLoginResponse, a, b);
    }
}
exports.UserLoginResponse = UserLoginResponse;
/**
 *
 * Describes the data structure required to check if the login is still valid
 *
 * @generated from message Scailo.AuthTokenValidityRequest
 */
class AuthTokenValidityRequest extends protobuf_1.Message {
    /**
     * The token whose validity needs to be determined
     *
     * @generated from field: string auth_token = 1;
     */
    authToken = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.AuthTokenValidityRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new AuthTokenValidityRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new AuthTokenValidityRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new AuthTokenValidityRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(AuthTokenValidityRequest, a, b);
    }
}
exports.AuthTokenValidityRequest = AuthTokenValidityRequest;
/**
 *
 * Describes the data structure required to logout a user
 *
 * @generated from message Scailo.LogoutRequest
 */
class LogoutRequest extends protobuf_1.Message {
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LogoutRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => []);
    static fromBinary(bytes, options) {
        return new LogoutRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LogoutRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LogoutRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LogoutRequest, a, b);
    }
}
exports.LogoutRequest = LogoutRequest;
/**
 *
 * Describes the response data structure to a logout request
 *
 * @generated from message Scailo.LogoutResponse
 */
class LogoutResponse extends protobuf_1.Message {
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.LogoutResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => []);
    static fromBinary(bytes, options) {
        return new LogoutResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new LogoutResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new LogoutResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(LogoutResponse, a, b);
    }
}
exports.LogoutResponse = LogoutResponse;
/**
 *
 * Stores the user login history
 *
 * @generated from message Scailo.UserLoginHistory
 */
class UserLoginHistory extends protobuf_1.Message {
    /**
     * Storages a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Storages the metadata of this storage
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * The URL scheme
     *
     * @generated from field: string url_scheme = 10;
     */
    urlScheme = "";
    /**
     * The username
     *
     * @generated from field: string username = 11;
     */
    username = "";
    /**
     * The IP address
     *
     * @generated from field: string ip_addr = 12;
     */
    ipAddr = "";
    /**
     * The expiry timestamp
     *
     * @generated from field: uint64 expires_at = 13;
     */
    expiresAt = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UserLoginHistory";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 10, name: "url_scheme", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ip_addr", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "expires_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new UserLoginHistory().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UserLoginHistory().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UserLoginHistory().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UserLoginHistory, a, b);
    }
}
exports.UserLoginHistory = UserLoginHistory;
/**
 *
 * Describes the message consisting of the list of user logins
 *
 * @generated from message Scailo.UserLoginHistoryList
 */
class UserLoginHistoryList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.UserLoginHistory list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.UserLoginHistoryList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: UserLoginHistory, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new UserLoginHistoryList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UserLoginHistoryList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UserLoginHistoryList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(UserLoginHistoryList, a, b);
    }
}
exports.UserLoginHistoryList = UserLoginHistoryList;
/**
 *
 * Describes the response to a vendor user login request
 *
 * @generated from message Scailo.VendorUserLoginResponse
 */
class VendorUserLoginResponse extends protobuf_1.Message {
    /**
     * Username of the user that just logged in
     *
     * @generated from field: string username = 1;
     */
    username = "";
    /**
     * The token that needs to be used as part of every request
     *
     * @generated from field: string auth_token = 2;
     */
    authToken = "";
    /**
     * The unix timestamp after which the auth_token is invalid
     *
     * @generated from field: uint64 expires_at = 3;
     */
    expiresAt = protobuf_1.protoInt64.zero;
    /**
     * The ID of the vendor
     *
     * @generated from field: uint64 vendor_id = 10;
     */
    vendorId = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the vendor
     *
     * @generated from field: string vendor_uuid = 11;
     */
    vendorUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.VendorUserLoginResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 3, name: "expires_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "vendor_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "vendor_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new VendorUserLoginResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new VendorUserLoginResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new VendorUserLoginResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(VendorUserLoginResponse, a, b);
    }
}
exports.VendorUserLoginResponse = VendorUserLoginResponse;
/**
 *
 * Describes the response to a client user login request
 *
 * @generated from message Scailo.ClientUserLoginResponse
 */
class ClientUserLoginResponse extends protobuf_1.Message {
    /**
     * Username of the user that just logged in
     *
     * @generated from field: string username = 1;
     */
    username = "";
    /**
     * The token that needs to be used as part of every request
     *
     * @generated from field: string auth_token = 2;
     */
    authToken = "";
    /**
     * The unix timestamp after which the auth_token is invalid
     *
     * @generated from field: uint64 expires_at = 3;
     */
    expiresAt = protobuf_1.protoInt64.zero;
    /**
     * The ID of the client
     *
     * @generated from field: uint64 client_id = 10;
     */
    clientId = protobuf_1.protoInt64.zero;
    /**
     * The UUID of the client
     *
     * @generated from field: string client_uuid = 11;
     */
    clientUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ClientUserLoginResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 3, name: "expires_at", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "client_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ClientUserLoginResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ClientUserLoginResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ClientUserLoginResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ClientUserLoginResponse, a, b);
    }
}
exports.ClientUserLoginResponse = ClientUserLoginResponse;
