import { Interceptor, UnaryResponse, StreamResponse, ConnectError, Code } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { showFailureAlert, toTitleCase } from "./utilities";
import { PostData } from "./fetches";

// appendAuthToken is an interceptor that appends the auth token to the request prior to sending the request
const appendAuthToken: Interceptor = (next) => async (req) => {
    return await next(req).catch((err: ConnectError) => {
        if (err) {
            if (err.code == Code.Unauthenticated) {
                location.href = "/";
                return;
            }
            showFailureAlert(err.message);
        }
    }).then(resp => {
        return <UnaryResponse | StreamResponse>resp;
    });
};

// appendCSRFToken is an interceptor that fetches and appends a CSRF token to the request prior to sending the request
const appendCSRFToken: Interceptor = (next) => async (req) => {
    let csrfToken = await PostData("/csrf", {
        route: (new URL(req.url)).pathname,
    }, "json");
    req.header.append("csrf_token", csrfToken["csrf_token"]);
    return await next(req).catch(err => {
        if (err) {
            // This error will be handled by the appendAuthToken() function
            if (err) {
                showFailureAlert(toTitleCase(err["rawMessage"] || ""));
            }
            throw err;
        }
    }).then(resp => {
        return <UnaryResponse | StreamResponse>resp;
    });
};

export function getTransport() {
    return createConnectTransport({
        // Need to use binary format (at least for the time being)
        baseUrl: location.origin, useBinaryFormat: false, interceptors: [
            appendAuthToken
        ]
    });
}

/**Provides the transport for clients that are used for writing data */
export function getWriteTransport() {
    return createConnectTransport({
        // Need to use binary format (at least for the time being)
        baseUrl: location.origin, useBinaryFormat: false, interceptors: [
            appendAuthToken,
            appendCSRFToken
        ]
    });
}