import { _returnInCents, createObjectFromForm, showFailureAlert } from "../../utilities";
import { context } from "./../../router";
import { getClientForQCSamplesService, QC_SAMPLE_LIFECYCLE, QCSample, QCSamplesServiceFilterReq } from "@kernelminds/scailo-sdk";
import { _renderPageFilters } from "./searchcomponents";
import ApexCharts from 'apexcharts';
import { getTransport } from "../../clients";

export function handleQcSampleInsights(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    let { html, formId, resetButtonId, getButtonId } = _renderPageFilters("Insights");
    let insightsHtml = document.createElement("div");
    insightsHtml.innerHTML = html

    content.appendChild(insightsHtml);

    let chartsContainer = document.createElement("div");
    // chartsContainer.className = "grid grid-cols-12";
    content.appendChild(chartsContainer);

    (<HTMLButtonElement>document.getElementById(resetButtonId)).addEventListener("click", async evt => {
        evt.preventDefault();
        handleQcSampleInsights(ctx);
    });

    let getButton = (<HTMLButtonElement>document.getElementById(getButtonId));
    const transport = getTransport();

    getButton.addEventListener("click", async evt => {
        evt.preventDefault();
        while (chartsContainer.firstChild) {
            chartsContainer.removeChild(chartsContainer.firstChild);
        }

        getButton.disabled = true;
        getButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

        let client = getClientForQCSamplesService(transport);
        let [filterResp] = await Promise.all([
            client.filter(new QCSamplesServiceFilterReq(createObjectFromForm(formId))),
        ]);

        getButton.disabled = false;
        getButton.innerText = `Get Insights`;

        const insightRecords = filterResp.list;

        if (!insightRecords.length) {
            showFailureAlert("No Records Found");
            return;
        }
        
        let chartsDiv = document.createElement("div");
        chartsDiv.className = "overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f5f8f3] w-full shadow-lg rounded grid grid-cols-12";
        chartsContainer.appendChild(chartsDiv);

        const commonTheme = {
            palette: 'palette2',
        };
        // Display all the insights here
        displayAllRelevantCharts(insightRecords, chartsDiv, commonTheme);
    });
}

/**Displays all the charts relevant to production indents */
export function displayAllRelevantCharts(qcsamples: QCSample[], chartsDiv: HTMLDivElement, commonTheme: Object) {
    let openCount = 0;
    let finishedCount = 0;
    let acceptedCount = 0;
    let acceptedWithDeviationCount = 0;
    let rejectedCount = 0;
    let cancelledCount = 0;
    for (let i = 0; i < qcsamples.length; i++) {
        let qcsample = qcsamples[i];
        if (qcsample.status === QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_OPEN) {
            openCount++;
        } else if (qcsample.status === QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_FINISHED) {
            finishedCount++;
        } else if (qcsample.status === QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_ACCEPTED) {
            acceptedCount++;
        } else if (qcsample.status === QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_ACCEPTED_WITH_DEVIATION) {
            acceptedWithDeviationCount++;
        } else if (qcsample.status === QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_REJECTED) {
            rejectedCount++;
        } else if (qcsample.status === QC_SAMPLE_LIFECYCLE.QC_SAMPLE_LIFECYCLE_CANCELLED) {
            cancelledCount++;
        }
    }

    let options = {
        title: {
            text: `Total Samples: ${qcsamples.length}`,
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: -10,
            floating: true,
            style: {
                fontSize: "20px",
                fontWeight: "light",
                color: "#263238"
            },
        },
        theme: commonTheme,
        series: [
            {
                name: "Open",
                data: [
                    {
                        x: "Open",
                        y: openCount,
                    }
                ]
            }, {
                name: "Finished",
                data: [
                    {
                        x: "Finished",
                        y: finishedCount,
                    },
                ]
            }, {
                name: "Accepted",
                data: [
                    {
                        x: "Accepted",
                        y: acceptedCount,
                    },
                    {
                        x: "Accepted With Deviation",
                        y: acceptedWithDeviationCount,
                    },
                ]
            }, {
                name: "Rejected",
                data: [
                    {
                        x: "Rejected",
                        y: rejectedCount,
                    },
                ]
            }, {
                name: "Cancelled",
                data: [
                    {
                        x: "Cancelled",
                        y: cancelledCount,
                    },
                ]
            }
        ],
        chart: {
            height: 500,
            type: "treemap",
        },
        legend: {
            position: "top"
        },

        // tooltip: {
        //     custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        //         // let qcsample = qcsamples[dataPointIndex];
        //         // return `
        //         //     <ul style='background-color: #424242; color: #F5F5F5; padding: 20px; margin: 0px;'>
        //         //         <li>QC Sample Number: ${qcsample.approvalMetadata.approvedOn > 0 ? qcsample.finalRefNumber : qcsample.referenceId}</li>
        //         //         <li>QC Sample Line Item Count: ${parseFloat(round(qcsample.list.length))}</li>
        //         //         <li>Cumulative Line Item Count: ${cumulativeSeriesData[dataPointIndex]}</li>
        //         //         <li>Approved Date: ${convertBigIntTimestampToDate(qcsample.approvalMetadata.approvedOn)}</li>
        //         //     </ul>
        //         // `;
        //     }
        // }
    };

    let chart = new ApexCharts(chartsDiv, options);
    chart.render();
}