import { salesInvoiceFiltersMenu, salesInvoiceIndividualMenu, salesInvoiceInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router } from "../../router";
import { handleSalesInvoiceFilters } from "./salesinvoices.filters";
import { handleSalesInvoiceInsights } from "./salesinvoices.insights";
import { handleIndividualSalesInvoice } from "./individual";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(salesInvoiceFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, salesInvoiceFiltersMenu)) {
            handleSalesInvoiceFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(salesInvoiceInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, salesInvoiceInsightsMenu)) {
            handleSalesInvoiceInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(salesInvoiceIndividualMenu.href, async (ctx) => {
        if (await interceptPage(ctx, salesInvoiceFiltersMenu)) {
            handleIndividualSalesInvoice(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}