import { proformaInvoiceFiltersMenu, proformaInvoiceIndividualMenu, proformaInvoiceInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router } from "../../router";
import { handleProformaInvoiceFilters } from "./proformainvoices.filters";
import { handleProformaInvoiceInsights } from "./proformainvoices.insights";
import { handleIndividualProformaInvoice } from "./individual";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(proformaInvoiceFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, proformaInvoiceFiltersMenu)) {
            handleProformaInvoiceFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(proformaInvoiceInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, proformaInvoiceInsightsMenu)) {
            handleProformaInvoiceInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(proformaInvoiceIndividualMenu.href, async (ctx) => {
        if (await interceptPage(ctx, proformaInvoiceFiltersMenu)) {
            handleIndividualProformaInvoice(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}