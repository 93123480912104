"use strict";
// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file proforma_invoices.scailo.proto (package Scailo, syntax proto3)
/* eslint-disable */
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProformaInvoicesServicePaginatedItemsResponse = exports.ProformaInvoiceItemsSearchRequest = exports.ProformaInvoicesServiceSearchAllReq = exports.ProformaInvoicesServiceCountReq = exports.ProformaInvoicesServiceFilterReq = exports.ProformaInvoicesServicePaginationResponse = exports.ProformaInvoicesServicePaginationReq = exports.ProformaInvoicesServiceAlreadyAddedQuantityForSourceRequest = exports.ProformaInvoiceItemProspectiveInfoRequest = exports.ProformaInvoiceItemHistoryRequest = exports.ProformaInvoiceItemsList = exports.ProformaInvoicesList = exports.ProformaInvoiceItem = exports.ProformaInvoicesServiceItemUpdateRequest = exports.ProformaInvoicesServiceItemCreateRequest = exports.ProformaInvoice = exports.ProformaInvoiceAncillaryParameters = exports.ProformaInvoicesServiceAutofillRequest = exports.ProformaInvoicesServiceUpdateRequest = exports.ProformaInvoicesServiceCreateRequest = exports.PROFORMA_INVOICE_ITEM_STATUS = exports.PROFORMA_INVOICE_ITEM_SORT_KEY = exports.PROFORMA_INVOICE_BILLING_STATUS = exports.PROFORMA_INVOICE_SORT_KEY = void 0;
const protobuf_1 = require("@bufbuild/protobuf");
const forms_fields_data_scailo_pb_js_1 = require("./forms_fields_data.scailo_pb.js");
const base_scailo_pb_js_1 = require("./base.scailo_pb.js");
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PROFORMA_INVOICE_SORT_KEY
 */
var PROFORMA_INVOICE_SORT_KEY;
(function (PROFORMA_INVOICE_SORT_KEY) {
    /**
     * Fetch ordered results by id
     *
     * @generated from enum value: PROFORMA_INVOICE_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PROFORMA_INVOICE_SORT_KEY[PROFORMA_INVOICE_SORT_KEY["PROFORMA_INVOICE_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PROFORMA_INVOICE_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch ordered results by the creation timestamp
     *
     * @generated from enum value: PROFORMA_INVOICE_SORT_KEY_CREATED_AT = 1;
     */
    PROFORMA_INVOICE_SORT_KEY[PROFORMA_INVOICE_SORT_KEY["PROFORMA_INVOICE_SORT_KEY_CREATED_AT"] = 1] = "PROFORMA_INVOICE_SORT_KEY_CREATED_AT";
    /**
     * Fetch ordered results by the modified timestamp
     *
     * @generated from enum value: PROFORMA_INVOICE_SORT_KEY_MODIFIED_AT = 2;
     */
    PROFORMA_INVOICE_SORT_KEY[PROFORMA_INVOICE_SORT_KEY["PROFORMA_INVOICE_SORT_KEY_MODIFIED_AT"] = 2] = "PROFORMA_INVOICE_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch ordered results by the approved on timestamp
     *
     * @generated from enum value: PROFORMA_INVOICE_SORT_KEY_APPROVED_ON = 3;
     */
    PROFORMA_INVOICE_SORT_KEY[PROFORMA_INVOICE_SORT_KEY["PROFORMA_INVOICE_SORT_KEY_APPROVED_ON"] = 3] = "PROFORMA_INVOICE_SORT_KEY_APPROVED_ON";
    /**
     * Fetch ordered results by the approved by field
     *
     * @generated from enum value: PROFORMA_INVOICE_SORT_KEY_APPROVED_BY = 4;
     */
    PROFORMA_INVOICE_SORT_KEY[PROFORMA_INVOICE_SORT_KEY["PROFORMA_INVOICE_SORT_KEY_APPROVED_BY"] = 4] = "PROFORMA_INVOICE_SORT_KEY_APPROVED_BY";
    /**
     * Fetch ordered results by the approver's role ID
     *
     * @generated from enum value: PROFORMA_INVOICE_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PROFORMA_INVOICE_SORT_KEY[PROFORMA_INVOICE_SORT_KEY["PROFORMA_INVOICE_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PROFORMA_INVOICE_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch ordered results by the approver's completed on timestamp
     *
     * @generated from enum value: PROFORMA_INVOICE_SORT_KEY_COMPLETED_ON = 6;
     */
    PROFORMA_INVOICE_SORT_KEY[PROFORMA_INVOICE_SORT_KEY["PROFORMA_INVOICE_SORT_KEY_COMPLETED_ON"] = 6] = "PROFORMA_INVOICE_SORT_KEY_COMPLETED_ON";
    /**
     * Fetch ordered results by the reference ID
     *
     * @generated from enum value: PROFORMA_INVOICE_SORT_KEY_REFERENCE_ID = 10;
     */
    PROFORMA_INVOICE_SORT_KEY[PROFORMA_INVOICE_SORT_KEY["PROFORMA_INVOICE_SORT_KEY_REFERENCE_ID"] = 10] = "PROFORMA_INVOICE_SORT_KEY_REFERENCE_ID";
    /**
     * Fetch ordered results by the final ref number
     *
     * @generated from enum value: PROFORMA_INVOICE_SORT_KEY_FINAL_REF_NUMBER = 11;
     */
    PROFORMA_INVOICE_SORT_KEY[PROFORMA_INVOICE_SORT_KEY["PROFORMA_INVOICE_SORT_KEY_FINAL_REF_NUMBER"] = 11] = "PROFORMA_INVOICE_SORT_KEY_FINAL_REF_NUMBER";
    /**
     * Fetch ordered results by the amendment count
     *
     * @generated from enum value: PROFORMA_INVOICE_SORT_KEY_AMENDMENT_COUNT = 18;
     */
    PROFORMA_INVOICE_SORT_KEY[PROFORMA_INVOICE_SORT_KEY["PROFORMA_INVOICE_SORT_KEY_AMENDMENT_COUNT"] = 18] = "PROFORMA_INVOICE_SORT_KEY_AMENDMENT_COUNT";
    /**
     * Fetch ordered results by the total value
     *
     * @generated from enum value: PROFORMA_INVOICE_SORT_KEY_TOTAL_VALUE = 30;
     */
    PROFORMA_INVOICE_SORT_KEY[PROFORMA_INVOICE_SORT_KEY["PROFORMA_INVOICE_SORT_KEY_TOTAL_VALUE"] = 30] = "PROFORMA_INVOICE_SORT_KEY_TOTAL_VALUE";
})(PROFORMA_INVOICE_SORT_KEY || (exports.PROFORMA_INVOICE_SORT_KEY = PROFORMA_INVOICE_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PROFORMA_INVOICE_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PROFORMA_INVOICE_SORT_KEY, "Scailo.PROFORMA_INVOICE_SORT_KEY", [
    { no: 0, name: "PROFORMA_INVOICE_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PROFORMA_INVOICE_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PROFORMA_INVOICE_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PROFORMA_INVOICE_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PROFORMA_INVOICE_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PROFORMA_INVOICE_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 6, name: "PROFORMA_INVOICE_SORT_KEY_COMPLETED_ON" },
    { no: 10, name: "PROFORMA_INVOICE_SORT_KEY_REFERENCE_ID" },
    { no: 11, name: "PROFORMA_INVOICE_SORT_KEY_FINAL_REF_NUMBER" },
    { no: 18, name: "PROFORMA_INVOICE_SORT_KEY_AMENDMENT_COUNT" },
    { no: 30, name: "PROFORMA_INVOICE_SORT_KEY_TOTAL_VALUE" },
]);
/**
 *
 * Describes the available billing statuses
 *
 * @generated from enum Scailo.PROFORMA_INVOICE_BILLING_STATUS
 */
var PROFORMA_INVOICE_BILLING_STATUS;
(function (PROFORMA_INVOICE_BILLING_STATUS) {
    /**
     * Any billing status
     *
     * @generated from enum value: PROFORMA_INVOICE_BILLING_STATUS_ANY_UNSPECIFIED = 0;
     */
    PROFORMA_INVOICE_BILLING_STATUS[PROFORMA_INVOICE_BILLING_STATUS["PROFORMA_INVOICE_BILLING_STATUS_ANY_UNSPECIFIED"] = 0] = "PROFORMA_INVOICE_BILLING_STATUS_ANY_UNSPECIFIED";
    /**
     * Goods dispatch is billed
     *
     * @generated from enum value: PROFORMA_INVOICE_BILLING_STATUS_BILLED = 1;
     */
    PROFORMA_INVOICE_BILLING_STATUS[PROFORMA_INVOICE_BILLING_STATUS["PROFORMA_INVOICE_BILLING_STATUS_BILLED"] = 1] = "PROFORMA_INVOICE_BILLING_STATUS_BILLED";
    /**
     * Goods dispatch is unbilled
     *
     * @generated from enum value: PROFORMA_INVOICE_BILLING_STATUS_UNBILLED = 2;
     */
    PROFORMA_INVOICE_BILLING_STATUS[PROFORMA_INVOICE_BILLING_STATUS["PROFORMA_INVOICE_BILLING_STATUS_UNBILLED"] = 2] = "PROFORMA_INVOICE_BILLING_STATUS_UNBILLED";
})(PROFORMA_INVOICE_BILLING_STATUS || (exports.PROFORMA_INVOICE_BILLING_STATUS = PROFORMA_INVOICE_BILLING_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(PROFORMA_INVOICE_BILLING_STATUS)
protobuf_1.proto3.util.setEnumType(PROFORMA_INVOICE_BILLING_STATUS, "Scailo.PROFORMA_INVOICE_BILLING_STATUS", [
    { no: 0, name: "PROFORMA_INVOICE_BILLING_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "PROFORMA_INVOICE_BILLING_STATUS_BILLED" },
    { no: 2, name: "PROFORMA_INVOICE_BILLING_STATUS_UNBILLED" },
]);
/**
 *
 * Describes the available sort keys
 *
 * @generated from enum Scailo.PROFORMA_INVOICE_ITEM_SORT_KEY
 */
var PROFORMA_INVOICE_ITEM_SORT_KEY;
(function (PROFORMA_INVOICE_ITEM_SORT_KEY) {
    /**
     * Fetch invoiced results by id
     *
     * @generated from enum value: PROFORMA_INVOICE_ITEM_SORT_KEY_ID_UNSPECIFIED = 0;
     */
    PROFORMA_INVOICE_ITEM_SORT_KEY[PROFORMA_INVOICE_ITEM_SORT_KEY["PROFORMA_INVOICE_ITEM_SORT_KEY_ID_UNSPECIFIED"] = 0] = "PROFORMA_INVOICE_ITEM_SORT_KEY_ID_UNSPECIFIED";
    /**
     * Fetch invoiced results by the creation timestamp
     *
     * @generated from enum value: PROFORMA_INVOICE_ITEM_SORT_KEY_CREATED_AT = 1;
     */
    PROFORMA_INVOICE_ITEM_SORT_KEY[PROFORMA_INVOICE_ITEM_SORT_KEY["PROFORMA_INVOICE_ITEM_SORT_KEY_CREATED_AT"] = 1] = "PROFORMA_INVOICE_ITEM_SORT_KEY_CREATED_AT";
    /**
     * Fetch invoiced results by the modified timestamp
     *
     * @generated from enum value: PROFORMA_INVOICE_ITEM_SORT_KEY_MODIFIED_AT = 2;
     */
    PROFORMA_INVOICE_ITEM_SORT_KEY[PROFORMA_INVOICE_ITEM_SORT_KEY["PROFORMA_INVOICE_ITEM_SORT_KEY_MODIFIED_AT"] = 2] = "PROFORMA_INVOICE_ITEM_SORT_KEY_MODIFIED_AT";
    /**
     * Fetch invoiced results by the approved on timestamp
     *
     * @generated from enum value: PROFORMA_INVOICE_ITEM_SORT_KEY_APPROVED_ON = 3;
     */
    PROFORMA_INVOICE_ITEM_SORT_KEY[PROFORMA_INVOICE_ITEM_SORT_KEY["PROFORMA_INVOICE_ITEM_SORT_KEY_APPROVED_ON"] = 3] = "PROFORMA_INVOICE_ITEM_SORT_KEY_APPROVED_ON";
    /**
     * Fetch invoiced results by the approved by field
     *
     * @generated from enum value: PROFORMA_INVOICE_ITEM_SORT_KEY_APPROVED_BY = 4;
     */
    PROFORMA_INVOICE_ITEM_SORT_KEY[PROFORMA_INVOICE_ITEM_SORT_KEY["PROFORMA_INVOICE_ITEM_SORT_KEY_APPROVED_BY"] = 4] = "PROFORMA_INVOICE_ITEM_SORT_KEY_APPROVED_BY";
    /**
     * Fetch invoiced results by the approver's role ID
     *
     * @generated from enum value: PROFORMA_INVOICE_ITEM_SORT_KEY_APPROVER_ROLE_ID = 5;
     */
    PROFORMA_INVOICE_ITEM_SORT_KEY[PROFORMA_INVOICE_ITEM_SORT_KEY["PROFORMA_INVOICE_ITEM_SORT_KEY_APPROVER_ROLE_ID"] = 5] = "PROFORMA_INVOICE_ITEM_SORT_KEY_APPROVER_ROLE_ID";
    /**
     * Fetch invoiced results by the family ID
     *
     * @generated from enum value: PROFORMA_INVOICE_ITEM_SORT_KEY_FAMILY_ID = 10;
     */
    PROFORMA_INVOICE_ITEM_SORT_KEY[PROFORMA_INVOICE_ITEM_SORT_KEY["PROFORMA_INVOICE_ITEM_SORT_KEY_FAMILY_ID"] = 10] = "PROFORMA_INVOICE_ITEM_SORT_KEY_FAMILY_ID";
    /**
     * Fetch invoiced results by the internal quantity
     *
     * @generated from enum value: PROFORMA_INVOICE_ITEM_SORT_KEY_INTERNAL_QUANTITY = 11;
     */
    PROFORMA_INVOICE_ITEM_SORT_KEY[PROFORMA_INVOICE_ITEM_SORT_KEY["PROFORMA_INVOICE_ITEM_SORT_KEY_INTERNAL_QUANTITY"] = 11] = "PROFORMA_INVOICE_ITEM_SORT_KEY_INTERNAL_QUANTITY";
    /**
     * Fetch invoiced results by the client unit of material ID
     *
     * @generated from enum value: PROFORMA_INVOICE_ITEM_SORT_KEY_CLIENT_UOM_ID = 12;
     */
    PROFORMA_INVOICE_ITEM_SORT_KEY[PROFORMA_INVOICE_ITEM_SORT_KEY["PROFORMA_INVOICE_ITEM_SORT_KEY_CLIENT_UOM_ID"] = 12] = "PROFORMA_INVOICE_ITEM_SORT_KEY_CLIENT_UOM_ID";
    /**
     * Fetch invoiced results by the client quantity
     *
     * @generated from enum value: PROFORMA_INVOICE_ITEM_SORT_KEY_CLIENT_QUANTITY = 13;
     */
    PROFORMA_INVOICE_ITEM_SORT_KEY[PROFORMA_INVOICE_ITEM_SORT_KEY["PROFORMA_INVOICE_ITEM_SORT_KEY_CLIENT_QUANTITY"] = 13] = "PROFORMA_INVOICE_ITEM_SORT_KEY_CLIENT_QUANTITY";
    /**
     * Fetch invoiced results by the client family code
     *
     * @generated from enum value: PROFORMA_INVOICE_ITEM_SORT_KEY_CLIENT_FAMILY_CODE = 14;
     */
    PROFORMA_INVOICE_ITEM_SORT_KEY[PROFORMA_INVOICE_ITEM_SORT_KEY["PROFORMA_INVOICE_ITEM_SORT_KEY_CLIENT_FAMILY_CODE"] = 14] = "PROFORMA_INVOICE_ITEM_SORT_KEY_CLIENT_FAMILY_CODE";
    /**
     * Fetch invoiced results by the unit price
     *
     * @generated from enum value: PROFORMA_INVOICE_ITEM_SORT_KEY_UNIT_PRICE = 15;
     */
    PROFORMA_INVOICE_ITEM_SORT_KEY[PROFORMA_INVOICE_ITEM_SORT_KEY["PROFORMA_INVOICE_ITEM_SORT_KEY_UNIT_PRICE"] = 15] = "PROFORMA_INVOICE_ITEM_SORT_KEY_UNIT_PRICE";
    /**
     * Fetch invoiced results by the tax group ID
     *
     * @generated from enum value: PROFORMA_INVOICE_ITEM_SORT_KEY_TAX_GROUP_ID = 16;
     */
    PROFORMA_INVOICE_ITEM_SORT_KEY[PROFORMA_INVOICE_ITEM_SORT_KEY["PROFORMA_INVOICE_ITEM_SORT_KEY_TAX_GROUP_ID"] = 16] = "PROFORMA_INVOICE_ITEM_SORT_KEY_TAX_GROUP_ID";
})(PROFORMA_INVOICE_ITEM_SORT_KEY || (exports.PROFORMA_INVOICE_ITEM_SORT_KEY = PROFORMA_INVOICE_ITEM_SORT_KEY = {}));
// Retrieve enum metadata with: proto3.getEnumType(PROFORMA_INVOICE_ITEM_SORT_KEY)
protobuf_1.proto3.util.setEnumType(PROFORMA_INVOICE_ITEM_SORT_KEY, "Scailo.PROFORMA_INVOICE_ITEM_SORT_KEY", [
    { no: 0, name: "PROFORMA_INVOICE_ITEM_SORT_KEY_ID_UNSPECIFIED" },
    { no: 1, name: "PROFORMA_INVOICE_ITEM_SORT_KEY_CREATED_AT" },
    { no: 2, name: "PROFORMA_INVOICE_ITEM_SORT_KEY_MODIFIED_AT" },
    { no: 3, name: "PROFORMA_INVOICE_ITEM_SORT_KEY_APPROVED_ON" },
    { no: 4, name: "PROFORMA_INVOICE_ITEM_SORT_KEY_APPROVED_BY" },
    { no: 5, name: "PROFORMA_INVOICE_ITEM_SORT_KEY_APPROVER_ROLE_ID" },
    { no: 10, name: "PROFORMA_INVOICE_ITEM_SORT_KEY_FAMILY_ID" },
    { no: 11, name: "PROFORMA_INVOICE_ITEM_SORT_KEY_INTERNAL_QUANTITY" },
    { no: 12, name: "PROFORMA_INVOICE_ITEM_SORT_KEY_CLIENT_UOM_ID" },
    { no: 13, name: "PROFORMA_INVOICE_ITEM_SORT_KEY_CLIENT_QUANTITY" },
    { no: 14, name: "PROFORMA_INVOICE_ITEM_SORT_KEY_CLIENT_FAMILY_CODE" },
    { no: 15, name: "PROFORMA_INVOICE_ITEM_SORT_KEY_UNIT_PRICE" },
    { no: 16, name: "PROFORMA_INVOICE_ITEM_SORT_KEY_TAX_GROUP_ID" },
]);
/**
 *
 * Describes the applicable statuses of proforma invoice items
 *
 * @generated from enum Scailo.PROFORMA_INVOICE_ITEM_STATUS
 */
var PROFORMA_INVOICE_ITEM_STATUS;
(function (PROFORMA_INVOICE_ITEM_STATUS) {
    /**
     * Denotes that status be disregarded. This is used only within search APIs
     *
     * @generated from enum value: PROFORMA_INVOICE_ITEM_STATUS_ANY_UNSPECIFIED = 0;
     */
    PROFORMA_INVOICE_ITEM_STATUS[PROFORMA_INVOICE_ITEM_STATUS["PROFORMA_INVOICE_ITEM_STATUS_ANY_UNSPECIFIED"] = 0] = "PROFORMA_INVOICE_ITEM_STATUS_ANY_UNSPECIFIED";
    /**
     * Denotes that the proforma invoice items must have been approved
     *
     * @generated from enum value: PROFORMA_INVOICE_ITEM_STATUS_APPROVED = 1;
     */
    PROFORMA_INVOICE_ITEM_STATUS[PROFORMA_INVOICE_ITEM_STATUS["PROFORMA_INVOICE_ITEM_STATUS_APPROVED"] = 1] = "PROFORMA_INVOICE_ITEM_STATUS_APPROVED";
    /**
     * Denotes that the proforma invoice items must be waiting for approval
     *
     * @generated from enum value: PROFORMA_INVOICE_ITEM_STATUS_UNAPPROVED = 2;
     */
    PROFORMA_INVOICE_ITEM_STATUS[PROFORMA_INVOICE_ITEM_STATUS["PROFORMA_INVOICE_ITEM_STATUS_UNAPPROVED"] = 2] = "PROFORMA_INVOICE_ITEM_STATUS_UNAPPROVED";
})(PROFORMA_INVOICE_ITEM_STATUS || (exports.PROFORMA_INVOICE_ITEM_STATUS = PROFORMA_INVOICE_ITEM_STATUS = {}));
// Retrieve enum metadata with: proto3.getEnumType(PROFORMA_INVOICE_ITEM_STATUS)
protobuf_1.proto3.util.setEnumType(PROFORMA_INVOICE_ITEM_STATUS, "Scailo.PROFORMA_INVOICE_ITEM_STATUS", [
    { no: 0, name: "PROFORMA_INVOICE_ITEM_STATUS_ANY_UNSPECIFIED" },
    { no: 1, name: "PROFORMA_INVOICE_ITEM_STATUS_APPROVED" },
    { no: 2, name: "PROFORMA_INVOICE_ITEM_STATUS_UNAPPROVED" },
]);
/**
 *
 * Describes the parameters necessary to create a record
 *
 * @generated from message Scailo.ProformaInvoicesServiceCreateRequest
 */
class ProformaInvoicesServiceCreateRequest extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 2;
     */
    userComment = "";
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the proforma invoice
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the bank account
     *
     * @generated from field: uint64 bank_account_id = 15;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 16;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 17;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 18;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The excess tax group
     *
     * @generated from field: uint64 cumulative_excess_tax_group_id = 19;
     */
    cumulativeExcessTaxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The excess tax amount
     *
     * @generated from field: uint64 cumulative_excess_tax_amount = 20;
     */
    cumulativeExcessTaxAmount = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoicesServiceCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "bank_account_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 19, name: "cumulative_excess_tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "cumulative_excess_tax_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoicesServiceCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoicesServiceCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoicesServiceCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoicesServiceCreateRequest, a, b);
    }
}
exports.ProformaInvoicesServiceCreateRequest = ProformaInvoicesServiceCreateRequest;
/**
 *
 * Describes the parameters necessary to update a record
 *
 * @generated from message Scailo.ProformaInvoicesServiceUpdateRequest
 */
class ProformaInvoicesServiceUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record that needs to be updated
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * Optional boolean value that stores if a notification needs to be sent to users about the update to the record. This is useful when a subsequent operation needs to be performed immediately (such as send to verification after updating the revision)
     *
     * @generated from field: bool notify_users = 3;
     */
    notifyUsers = false;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the proforma invoice
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the bank account
     *
     * @generated from field: uint64 bank_account_id = 15;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 16;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 17;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 18;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The excess tax group
     *
     * @generated from field: uint64 cumulative_excess_tax_group_id = 19;
     */
    cumulativeExcessTaxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The excess tax amount
     *
     * @generated from field: uint64 cumulative_excess_tax_amount = 20;
     */
    cumulativeExcessTaxAmount = protobuf_1.protoInt64.zero;
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatumCreateRequest form_data = 30;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoicesServiceUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "notify_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "bank_account_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 19, name: "cumulative_excess_tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "cumulative_excess_tax_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatumCreateRequest, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoicesServiceUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoicesServiceUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoicesServiceUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoicesServiceUpdateRequest, a, b);
    }
}
exports.ProformaInvoicesServiceUpdateRequest = ProformaInvoicesServiceUpdateRequest;
/**
 *
 * Describes the parameters necessary to perform an autofill request
 *
 * @generated from message Scailo.ProformaInvoicesServiceAutofillRequest
 */
class ProformaInvoicesServiceAutofillRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The UUID of the record that needs to be updated
     *
     * @generated from field: string uuid = 2;
     */
    uuid = "";
    /**
     * Stores if services should also be autofilled
     *
     * @generated from field: bool include_services = 10;
     */
    includeServices = false;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoicesServiceAutofillRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "include_services", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoicesServiceAutofillRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoicesServiceAutofillRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoicesServiceAutofillRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoicesServiceAutofillRequest, a, b);
    }
}
exports.ProformaInvoicesServiceAutofillRequest = ProformaInvoicesServiceAutofillRequest;
/**
 *
 * Stores the UUID references of the record
 *
 * @generated from message Scailo.ProformaInvoiceAncillaryParameters
 */
class ProformaInvoiceAncillaryParameters extends protobuf_1.Message {
    /**
     * The UUID of the ref_id (the UUID of the associated ref_id)
     *
     * @generated from field: string ref_uuid = 213;
     */
    refUuid = "";
    /**
     * The UUID of the currency (the UUID of the associated currency)
     *
     * @generated from field: string currency_uuid = 214;
     */
    currencyUuid = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoiceAncillaryParameters";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 213, name: "ref_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 214, name: "currency_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoiceAncillaryParameters().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoiceAncillaryParameters().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoiceAncillaryParameters().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoiceAncillaryParameters, a, b);
    }
}
exports.ProformaInvoiceAncillaryParameters = ProformaInvoiceAncillaryParameters;
/**
 *
 * Describes the parameters that are part of a standard response
 *
 * @generated from message Scailo.ProformaInvoice
 */
class ProformaInvoice extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this proforma invoice
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * The status of this proforma invoice
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 4;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Stores the logs of every operation performed on this proforma invoice
     *
     * @generated from field: repeated Scailo.LogbookLogConciseSLC logs = 5;
     */
    logs = [];
    /**
     * The timestamp of when this proforma invoice was marked as completed
     *
     * @generated from field: uint64 completed_on = 6;
     */
    completedOn = protobuf_1.protoInt64.zero;
    /**
     * The associated vault folder ID
     *
     * @generated from field: uint64 vault_folder_id = 9;
     */
    vaultFolderId = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the proforma invoice
     *
     * @generated from field: string reference_id = 10;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 11;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 12;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 13;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the currency
     *
     * @generated from field: uint64 currency_id = 14;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the bank account
     *
     * @generated from field: uint64 bank_account_id = 15;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * Any miscellaneous cost
     *
     * @generated from field: uint64 miscellaneous_cost = 16;
     */
    miscellaneousCost = protobuf_1.protoInt64.zero;
    /**
     * The optional discount amount
     *
     * @generated from field: uint64 overall_discount = 17;
     */
    overallDiscount = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 18;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * The excess tax group
     *
     * @generated from field: uint64 cumulative_excess_tax_group_id = 19;
     */
    cumulativeExcessTaxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The excess tax amount
     *
     * @generated from field: uint64 cumulative_excess_tax_amount = 20;
     */
    cumulativeExcessTaxAmount = protobuf_1.protoInt64.zero;
    /**
     * Stores the total value of the proforma invoice (as a double, which requires no adjustments)
     *
     * @generated from field: double total_value = 21;
     */
    totalValue = 0;
    /**
     * The number of times that the proforma invoice has been amended
     *
     * @generated from field: uint64 amendment_count = 22;
     */
    amendmentCount = protobuf_1.protoInt64.zero;
    /**
     * The list of associated proforma invoice items
     *
     * @generated from field: repeated Scailo.ProformaInvoiceItem list = 30;
     */
    list = [];
    /**
     * The list of dynamic forms
     *
     * @generated from field: repeated Scailo.FormFieldDatum form_data = 40;
     */
    formData = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoice";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 5, name: "logs", kind: "message", T: base_scailo_pb_js_1.LogbookLogConciseSLC, repeated: true },
        { no: 6, name: "completed_on", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 9, name: "vault_folder_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 10, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 11, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 12, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 13, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "bank_account_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "miscellaneous_cost", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "overall_discount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 19, name: "cumulative_excess_tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "cumulative_excess_tax_amount", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "total_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
        { no: 22, name: "amendment_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 30, name: "list", kind: "message", T: ProformaInvoiceItem, repeated: true },
        { no: 40, name: "form_data", kind: "message", T: forms_fields_data_scailo_pb_js_1.FormFieldDatum, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoice().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoice().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoice().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoice, a, b);
    }
}
exports.ProformaInvoice = ProformaInvoice;
/**
 *
 * Describes the parameters required to add an item to a proforma invoice
 *
 * @generated from message Scailo.ProformaInvoicesServiceItemCreateRequest
 */
class ProformaInvoicesServiceItemCreateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * Stores the proforma invoice ID
     *
     * @generated from field: uint64 proforma_invoice_id = 10;
     */
    proformaInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being supplied in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 13;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being admitted in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 14;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * The family code as represented by the client
     *
     * @generated from field: string client_family_code = 15;
     */
    clientFamilyCode = "";
    /**
     * The unit price of the item (as supplied to the client)
     *
     * @generated from field: uint64 unit_price = 16;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 17;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 18;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 19;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoicesServiceItemCreateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "proforma_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 19, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoicesServiceItemCreateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoicesServiceItemCreateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoicesServiceItemCreateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoicesServiceItemCreateRequest, a, b);
    }
}
exports.ProformaInvoicesServiceItemCreateRequest = ProformaInvoicesServiceItemCreateRequest;
/**
 *
 * Describes the parameters required to update an item in a proforma invoice
 *
 * @generated from message Scailo.ProformaInvoicesServiceItemUpdateRequest
 */
class ProformaInvoicesServiceItemUpdateRequest extends protobuf_1.Message {
    /**
     * Stores any comment that the user might add during this operation
     *
     * @generated from field: string user_comment = 1;
     */
    userComment = "";
    /**
     * The ID of the record
     *
     * @generated from field: uint64 id = 2;
     */
    id = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being supplied in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 13;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being admitted in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 14;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * The family code as represented by the client
     *
     * @generated from field: string client_family_code = 15;
     */
    clientFamilyCode = "";
    /**
     * The unit price of the item (as supplied to the client)
     *
     * @generated from field: uint64 unit_price = 16;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 17;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 18;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 19;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoicesServiceItemUpdateRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 19, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoicesServiceItemUpdateRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoicesServiceItemUpdateRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoicesServiceItemUpdateRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoicesServiceItemUpdateRequest, a, b);
    }
}
exports.ProformaInvoicesServiceItemUpdateRequest = ProformaInvoicesServiceItemUpdateRequest;
/**
 *
 * Describes the parameters that constitute an item associated to a proforma invoice
 *
 * @generated from message Scailo.ProformaInvoiceItem
 */
class ProformaInvoiceItem extends protobuf_1.Message {
    /**
     * Stores a globally unique entity UUID. This will be set at the organization level
     *
     * @generated from field: string entity_uuid = 1;
     */
    entityUuid = "";
    /**
     * Stores the metadata of this proforma invoice
     *
     * @generated from field: Scailo.EmployeeMetadata metadata = 2;
     */
    metadata;
    /**
     * Stores the approval metadata
     *
     * @generated from field: Scailo.ApprovalMetadata approval_metadata = 3;
     */
    approvalMetadata;
    /**
     * Denotes if this record requires approval (or has been approved)
     *
     * @generated from field: bool need_approval = 4;
     */
    needApproval = false;
    /**
     * Stores any comment that the user might have added during an operation
     *
     * @generated from field: string user_comment = 5;
     */
    userComment = "";
    /**
     * Stores the proforma invoice ID
     *
     * @generated from field: uint64 proforma_invoice_id = 10;
     */
    proformaInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The quantity (in cents) being supplied in internal unit of material
     *
     * @generated from field: uint64 internal_quantity = 12;
     */
    internalQuantity = protobuf_1.protoInt64.zero;
    /**
     * Stores the ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 13;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the quantity (in cents) being admitted in client's unit of material
     *
     * @generated from field: uint64 client_quantity = 14;
     */
    clientQuantity = protobuf_1.protoInt64.zero;
    /**
     * The family code as represented by the client
     *
     * @generated from field: string client_family_code = 15;
     */
    clientFamilyCode = "";
    /**
     * The unit price of the item (as supplied to the client)
     *
     * @generated from field: uint64 unit_price = 16;
     */
    unitPrice = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated tax group
     *
     * @generated from field: uint64 tax_group_id = 17;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * The applicable round off amount (optional, and can be positive or negative)
     *
     * @generated from field: int64 round_off = 18;
     */
    roundOff = protobuf_1.protoInt64.zero;
    /**
     * Optional specifications
     *
     * @generated from field: string specifications = 19;
     */
    specifications = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoiceItem";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "metadata", kind: "message", T: base_scailo_pb_js_1.EmployeeMetadata },
        { no: 3, name: "approval_metadata", kind: "message", T: base_scailo_pb_js_1.ApprovalMetadata },
        { no: 4, name: "need_approval", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
        { no: 5, name: "user_comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "proforma_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "internal_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "client_quantity", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 16, name: "unit_price", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 17, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 18, name: "round_off", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 19, name: "specifications", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoiceItem().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoiceItem().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoiceItem().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoiceItem, a, b);
    }
}
exports.ProformaInvoiceItem = ProformaInvoiceItem;
/**
 *
 * Describes the message consisting of the list of proforma invoices
 *
 * @generated from message Scailo.ProformaInvoicesList
 */
class ProformaInvoicesList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ProformaInvoice list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoicesList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ProformaInvoice, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoicesList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoicesList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoicesList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoicesList, a, b);
    }
}
exports.ProformaInvoicesList = ProformaInvoicesList;
/**
 *
 * Describes the message consisting of the list of proforma invoice items
 *
 * @generated from message Scailo.ProformaInvoiceItemsList
 */
class ProformaInvoiceItemsList extends protobuf_1.Message {
    /**
     * List of records
     *
     * @generated from field: repeated Scailo.ProformaInvoiceItem list = 1;
     */
    list = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoiceItemsList";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "list", kind: "message", T: ProformaInvoiceItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoiceItemsList().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoiceItemsList().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoiceItemsList().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoiceItemsList, a, b);
    }
}
exports.ProformaInvoiceItemsList = ProformaInvoiceItemsList;
/**
 *
 * Describes the parameters that are required to retrieve the history of the record
 *
 * @generated from message Scailo.ProformaInvoiceItemHistoryRequest
 */
class ProformaInvoiceItemHistoryRequest extends protobuf_1.Message {
    /**
     * Stores the proforma invoice ID
     *
     * @generated from field: uint64 proforma_invoice_id = 10;
     */
    proformaInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoiceItemHistoryRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "proforma_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoiceItemHistoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoiceItemHistoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoiceItemHistoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoiceItemHistoryRequest, a, b);
    }
}
exports.ProformaInvoiceItemHistoryRequest = ProformaInvoiceItemHistoryRequest;
/**
 *
 * Describes the parameters that are required to retrieve the info of a prospective proforma invoice item
 *
 * @generated from message Scailo.ProformaInvoiceItemProspectiveInfoRequest
 */
class ProformaInvoiceItemProspectiveInfoRequest extends protobuf_1.Message {
    /**
     * Stores the proforma invoice ID
     *
     * @generated from field: uint64 proforma_invoice_id = 10;
     */
    proformaInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family ID
     *
     * @generated from field: uint64 family_id = 11;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoiceItemProspectiveInfoRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 10, name: "proforma_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoiceItemProspectiveInfoRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoiceItemProspectiveInfoRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoiceItemProspectiveInfoRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoiceItemProspectiveInfoRequest, a, b);
    }
}
exports.ProformaInvoiceItemProspectiveInfoRequest = ProformaInvoiceItemProspectiveInfoRequest;
/**
 *
 * Describes the request payload to retrieve the quantity that has already been added for the specific ref_from, ref_id and family_id
 *
 * @generated from message Scailo.ProformaInvoicesServiceAlreadyAddedQuantityForSourceRequest
 */
class ProformaInvoicesServiceAlreadyAddedQuantityForSourceRequest extends protobuf_1.Message {
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 1;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 2;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The associated family ID
     *
     * @generated from field: uint64 family_id = 3;
     */
    familyId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoicesServiceAlreadyAddedQuantityForSourceRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 2, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoicesServiceAlreadyAddedQuantityForSourceRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoicesServiceAlreadyAddedQuantityForSourceRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoicesServiceAlreadyAddedQuantityForSourceRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoicesServiceAlreadyAddedQuantityForSourceRequest, a, b);
    }
}
exports.ProformaInvoicesServiceAlreadyAddedQuantityForSourceRequest = ProformaInvoicesServiceAlreadyAddedQuantityForSourceRequest;
/**
 *
 * Describes a pagination request to retrieve records
 *
 * @generated from message Scailo.ProformaInvoicesServicePaginationReq
 */
class ProformaInvoicesServicePaginationReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PROFORMA_INVOICE_SORT_KEY sort_key = 5;
     */
    sortKey = PROFORMA_INVOICE_SORT_KEY.PROFORMA_INVOICE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The status of this proforma invoice
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 6;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoicesServicePaginationReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PROFORMA_INVOICE_SORT_KEY) },
        { no: 6, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoicesServicePaginationReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoicesServicePaginationReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoicesServicePaginationReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoicesServicePaginationReq, a, b);
    }
}
exports.ProformaInvoicesServicePaginationReq = ProformaInvoicesServicePaginationReq;
/**
 *
 * Describes the response to a pagination request
 *
 * @generated from message Scailo.ProformaInvoicesServicePaginationResponse
 */
class ProformaInvoicesServicePaginationResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.ProformaInvoice payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoicesServicePaginationResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: ProformaInvoice, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoicesServicePaginationResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoicesServicePaginationResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoicesServicePaginationResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoicesServicePaginationResponse, a, b);
    }
}
exports.ProformaInvoicesServicePaginationResponse = ProformaInvoicesServicePaginationResponse;
/**
 *
 * Describes the base request payload of a filter search
 *
 * @generated from message Scailo.ProformaInvoicesServiceFilterReq
 */
class ProformaInvoicesServiceFilterReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PROFORMA_INVOICE_SORT_KEY sort_key = 5;
     */
    sortKey = PROFORMA_INVOICE_SORT_KEY.PROFORMA_INVOICE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this proforma invoice
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the proforma invoice
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 24;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the bank account
     *
     * @generated from field: uint64 bank_account_id = 25;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The status of the proforma invoice bill
     *
     * @generated from field: Scailo.PROFORMA_INVOICE_BILLING_STATUS billing_status = 50;
     */
    billingStatus = PROFORMA_INVOICE_BILLING_STATUS.PROFORMA_INVOICE_BILLING_STATUS_ANY_UNSPECIFIED;
    /**
     * Sales Order related filters
     * The associated consignee client ID of the linked sales order
     *
     * @generated from field: uint64 consignee_client_id = 60;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked sales order
     *
     * @generated from field: uint64 buyer_client_id = 61;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project of the linked sales order
     *
     * @generated from field: uint64 project_id = 62;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Stores the minimum value of the proforma invoice (ignored if 0)
     *
     * @generated from field: uint64 total_value_min = 80;
     */
    totalValueMin = protobuf_1.protoInt64.zero;
    /**
     * Stores the maximum value of the proforma invoice (ignored if 0)
     *
     * @generated from field: uint64 total_value_max = 81;
     */
    totalValueMax = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoicesServiceFilterReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PROFORMA_INVOICE_SORT_KEY) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "bank_account_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "billing_status", kind: "enum", T: protobuf_1.proto3.getEnumType(PROFORMA_INVOICE_BILLING_STATUS) },
        { no: 60, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 61, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 62, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 80, name: "total_value_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 81, name: "total_value_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoicesServiceFilterReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoicesServiceFilterReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoicesServiceFilterReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoicesServiceFilterReq, a, b);
    }
}
exports.ProformaInvoicesServiceFilterReq = ProformaInvoicesServiceFilterReq;
/**
 *
 * Describes the base request payload of a count search
 *
 * @generated from message Scailo.ProformaInvoicesServiceCountReq
 */
class ProformaInvoicesServiceCountReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The minimum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_start = 101;
     */
    creationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by creation
     *
     * @generated from field: uint64 creation_timestamp_end = 102;
     */
    creationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The minimum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_start = 103;
     */
    modificationTimestampStart = protobuf_1.protoInt64.zero;
    /**
     * The maximum timestamp that needs to be considered to filter by modification
     *
     * @generated from field: uint64 modification_timestamp_end = 104;
     */
    modificationTimestampEnd = protobuf_1.protoInt64.zero;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 8;
     */
    entityUuid = "";
    /**
     * The status of this proforma invoice
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 11;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 12;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 13;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 14;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The start range of completed timestamp
     *
     * @generated from field: uint64 completed_on_start = 15;
     */
    completedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of completed timestamp
     *
     * @generated from field: uint64 completed_on_end = 16;
     */
    completedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The reference ID of the proforma invoice
     *
     * @generated from field: string reference_id = 20;
     */
    referenceId = "";
    /**
     * The unique reference number that has been automatically generated
     *
     * @generated from field: string final_ref_number = 21;
     */
    finalRefNumber = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated currency
     *
     * @generated from field: uint64 currency_id = 24;
     */
    currencyId = protobuf_1.protoInt64.zero;
    /**
     * The associated ID of the bank account
     *
     * @generated from field: uint64 bank_account_id = 25;
     */
    bankAccountId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 40;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The status of the proforma invoice bill
     *
     * @generated from field: Scailo.PROFORMA_INVOICE_BILLING_STATUS billing_status = 50;
     */
    billingStatus = PROFORMA_INVOICE_BILLING_STATUS.PROFORMA_INVOICE_BILLING_STATUS_ANY_UNSPECIFIED;
    /**
     * Sales Order related filters
     * The associated consignee client ID of the linked sales order
     *
     * @generated from field: uint64 consignee_client_id = 60;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked sales order
     *
     * @generated from field: uint64 buyer_client_id = 61;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the associated project of the linked sales order
     *
     * @generated from field: uint64 project_id = 62;
     */
    projectId = protobuf_1.protoInt64.zero;
    /**
     * Stores the minimum value of the proforma invoice (ignored if 0)
     *
     * @generated from field: uint64 total_value_min = 80;
     */
    totalValueMin = protobuf_1.protoInt64.zero;
    /**
     * Stores the maximum value of the proforma invoice (ignored if 0)
     *
     * @generated from field: uint64 total_value_max = 81;
     */
    totalValueMax = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoicesServiceCountReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 101, name: "creation_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 102, name: "creation_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 103, name: "modification_timestamp_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 104, name: "modification_timestamp_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 8, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 14, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 15, name: "completed_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 16, name: "completed_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 21, name: "final_ref_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 24, name: "currency_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "bank_account_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 50, name: "billing_status", kind: "enum", T: protobuf_1.proto3.getEnumType(PROFORMA_INVOICE_BILLING_STATUS) },
        { no: 60, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 61, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 62, name: "project_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 80, name: "total_value_min", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 81, name: "total_value_max", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoicesServiceCountReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoicesServiceCountReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoicesServiceCountReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoicesServiceCountReq, a, b);
    }
}
exports.ProformaInvoicesServiceCountReq = ProformaInvoicesServiceCountReq;
/**
 *
 * Describes the request payload for performing a generic search operation on records
 *
 * @generated from message Scailo.ProformaInvoicesServiceSearchAllReq
 */
class ProformaInvoicesServiceSearchAllReq extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PROFORMA_INVOICE_SORT_KEY sort_key = 5;
     */
    sortKey = PROFORMA_INVOICE_SORT_KEY.PROFORMA_INVOICE_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * Limit the search space to the given status
     *
     * @generated from field: Scailo.STANDARD_LIFECYCLE_STATUS status = 10;
     */
    status = base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS.ANY_UNSPECIFIED;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 11;
     */
    searchKey = "";
    /**
     * The associated reference
     *
     * @generated from field: string ref_from = 22;
     */
    refFrom = "";
    /**
     * The associated ID of the reference
     *
     * @generated from field: uint64 ref_id = 23;
     */
    refId = protobuf_1.protoInt64.zero;
    /**
     * The status of the proforma invoice bill
     *
     * @generated from field: Scailo.PROFORMA_INVOICE_BILLING_STATUS billing_status = 40;
     */
    billingStatus = PROFORMA_INVOICE_BILLING_STATUS.PROFORMA_INVOICE_BILLING_STATUS_ANY_UNSPECIFIED;
    /**
     * Sales Order related filters
     * The associated consignee client ID of the linked sales order
     *
     * @generated from field: uint64 consignee_client_id = 50;
     */
    consigneeClientId = protobuf_1.protoInt64.zero;
    /**
     * The associated buyer client ID of the linked sales order
     *
     * @generated from field: uint64 buyer_client_id = 51;
     */
    buyerClientId = protobuf_1.protoInt64.zero;
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoicesServiceSearchAllReq";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PROFORMA_INVOICE_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 10, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.STANDARD_LIFECYCLE_STATUS) },
        { no: 11, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 22, name: "ref_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 23, name: "ref_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "billing_status", kind: "enum", T: protobuf_1.proto3.getEnumType(PROFORMA_INVOICE_BILLING_STATUS) },
        { no: 50, name: "consignee_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 51, name: "buyer_client_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoicesServiceSearchAllReq().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoicesServiceSearchAllReq().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoicesServiceSearchAllReq().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoicesServiceSearchAllReq, a, b);
    }
}
exports.ProformaInvoicesServiceSearchAllReq = ProformaInvoicesServiceSearchAllReq;
/**
 *
 * Describes the request payload to retrieve approved or unapproved items.
 *
 * @generated from message Scailo.ProformaInvoiceItemsSearchRequest
 */
class ProformaInvoiceItemsSearchRequest extends protobuf_1.Message {
    /**
     * If true, then returns only active records. If false, then returns only inactive records
     *
     * @generated from field: Scailo.BOOL_FILTER is_active = 1;
     */
    isActive = base_scailo_pb_js_1.BOOL_FILTER.BOOL_FILTER_ANY_UNSPECIFIED;
    /**
     * The number of records that need to be sent in the response. Returns all records if it is set to -1
     *
     * @generated from field: int64 count = 2;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that need to be offset by before fetching the records
     *
     * @generated from field: uint64 offset = 3;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The sort order that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.SORT_ORDER sort_order = 4;
     */
    sortOrder = base_scailo_pb_js_1.SORT_ORDER.ASCENDING_UNSPECIFIED;
    /**
     * The sort key that is to be used to fetch the pagination response
     *
     * @generated from field: Scailo.PROFORMA_INVOICE_ITEM_SORT_KEY sort_key = 5;
     */
    sortKey = PROFORMA_INVOICE_ITEM_SORT_KEY.PROFORMA_INVOICE_ITEM_SORT_KEY_ID_UNSPECIFIED;
    /**
     * The entity UUID that is to be used to filter records
     *
     * @generated from field: string entity_uuid = 6;
     */
    entityUuid = "";
    /**
     * The status of the items
     *
     * @generated from field: Scailo.PROFORMA_INVOICE_ITEM_STATUS status = 7;
     */
    status = PROFORMA_INVOICE_ITEM_STATUS.PROFORMA_INVOICE_ITEM_STATUS_ANY_UNSPECIFIED;
    /**
     * The start range of approved timestamp
     *
     * @generated from field: uint64 approved_on_start = 10;
     */
    approvedOnStart = protobuf_1.protoInt64.zero;
    /**
     * The end range of approved timestamp
     *
     * @generated from field: uint64 approved_on_end = 11;
     */
    approvedOnEnd = protobuf_1.protoInt64.zero;
    /**
     * The ID of the approver
     *
     * @generated from field: uint64 approved_by_user_id = 12;
     */
    approvedByUserId = protobuf_1.protoInt64.zero;
    /**
     * The role ID of the approver
     *
     * @generated from field: uint64 approver_role_id = 13;
     */
    approverRoleId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the proforma invoice
     *
     * @generated from field: uint64 proforma_invoice_id = 20;
     */
    proformaInvoiceId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the family
     *
     * @generated from field: uint64 family_id = 21;
     */
    familyId = protobuf_1.protoInt64.zero;
    /**
     * The ID of the client's unit of material
     *
     * @generated from field: uint64 client_uom_id = 23;
     */
    clientUomId = protobuf_1.protoInt64.zero;
    /**
     * Stores the family code as given by the client
     *
     * @generated from field: string client_family_code = 25;
     */
    clientFamilyCode = "";
    /**
     * The ID of the tax group
     *
     * @generated from field: uint64 tax_group_id = 27;
     */
    taxGroupId = protobuf_1.protoInt64.zero;
    /**
     * Describes the key with which the search operation needs to be performed
     *
     * @generated from field: string search_key = 40;
     */
    searchKey = "";
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoiceItemsSearchRequest";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "is_active", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.BOOL_FILTER) },
        { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
        { no: 3, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "sort_order", kind: "enum", T: protobuf_1.proto3.getEnumType(base_scailo_pb_js_1.SORT_ORDER) },
        { no: 5, name: "sort_key", kind: "enum", T: protobuf_1.proto3.getEnumType(PROFORMA_INVOICE_ITEM_SORT_KEY) },
        { no: 6, name: "entity_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 7, name: "status", kind: "enum", T: protobuf_1.proto3.getEnumType(PROFORMA_INVOICE_ITEM_STATUS) },
        { no: 10, name: "approved_on_start", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 11, name: "approved_on_end", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 12, name: "approved_by_user_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 13, name: "approver_role_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 20, name: "proforma_invoice_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 21, name: "family_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 23, name: "client_uom_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 25, name: "client_family_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
        { no: 27, name: "tax_group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 40, name: "search_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoiceItemsSearchRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoiceItemsSearchRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoiceItemsSearchRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoiceItemsSearchRequest, a, b);
    }
}
exports.ProformaInvoiceItemsSearchRequest = ProformaInvoiceItemsSearchRequest;
/**
 *
 * Describes the response to a pagination items request
 *
 * @generated from message Scailo.ProformaInvoicesServicePaginatedItemsResponse
 */
class ProformaInvoicesServicePaginatedItemsResponse extends protobuf_1.Message {
    /**
     * The number of records in this payload
     *
     * @generated from field: uint64 count = 1;
     */
    count = protobuf_1.protoInt64.zero;
    /**
     * The number that has been offset before fetching the records. This is the same value that has been sent as part of the pagination request
     *
     * @generated from field: uint64 offset = 2;
     */
    offset = protobuf_1.protoInt64.zero;
    /**
     * The total number of records that are available
     *
     * @generated from field: uint64 total = 3;
     */
    total = protobuf_1.protoInt64.zero;
    /**
     * The list of records
     *
     * @generated from field: repeated Scailo.ProformaInvoiceItem payload = 4;
     */
    payload = [];
    constructor(data) {
        super();
        protobuf_1.proto3.util.initPartial(data, this);
    }
    static runtime = protobuf_1.proto3;
    static typeName = "Scailo.ProformaInvoicesServicePaginatedItemsResponse";
    static fields = protobuf_1.proto3.util.newFieldList(() => [
        { no: 1, name: "count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 2, name: "offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 3, name: "total", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
        { no: 4, name: "payload", kind: "message", T: ProformaInvoiceItem, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new ProformaInvoicesServicePaginatedItemsResponse().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ProformaInvoicesServicePaginatedItemsResponse().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ProformaInvoicesServicePaginatedItemsResponse().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return protobuf_1.proto3.util.equals(ProformaInvoicesServicePaginatedItemsResponse, a, b);
    }
}
exports.ProformaInvoicesServicePaginatedItemsResponse = ProformaInvoicesServicePaginatedItemsResponse;
