import { context } from "./../../router";
import { InwardJobFreeIssueMaterialReturn, InwardJobsFreeIssueMaterialsReturnsService, InwardJobFreeIssueMaterialReturnAncillaryParameters, Currency, Family, UnitOfMaterial, TaxGroup, InwardJob, InwardJobsService, InwardJobAncillaryParameters, getClientForInwardJobsFreeIssueMaterialsReturnsService, getClientForClientsService, getClientForAssociatesService, getClientForUnitsOfMaterialsService, getClientForInwardJobsService } from "@kernelminds/scailo-sdk";
import { emptyDiv, getLinkForInwardJob, renderFilterPrimarySubSection, renderInput, renderPageTitleSection, renderSpan, returnFormFromFields } from "../../ui";
import { convertBigIntTimestampToDate, convertCentsToMoney, decodeSLC, downloadData, internationalizeMoney, randomId, returnAssociateBadge, toTitleCase } from "../../utilities";
import { PromiseClient, Transport } from "@connectrpc/connect";
import { familiesListFromIDs } from "../../fetches";
import { getTransport } from "../../clients";

const downloadPDFButtonClass = "__download-pdf-btn";
// const downloadCSVButtonClass = "__download-csv-btn";
const downloadLabelButtonClass = "__download-label-btn";
const goToInwardJobButtonClass = "__go-to-inward-job-btn";

export async function handleIndividualInwardJobFreeIssueMaterialReturn(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    const transport = getTransport();
    const accessClient = getClientForInwardJobsFreeIssueMaterialsReturnsService(transport);
    const [inwardjobfreeissuematerialreturn, ancillaryParams] = await Promise.all([
        accessClient.viewByUUID({ uuid: ctx.params.uuid }),
        accessClient.viewAncillaryParametersByUUID({ uuid: ctx.params.uuid }),
    ]);

    document.title = inwardjobfreeissuematerialreturn.approvalMetadata?.approvedOn! > 0 ? inwardjobfreeissuematerialreturn.finalRefNumber : inwardjobfreeissuematerialreturn.referenceId;

    let container = document.createElement("div");
    container.className = "overflow-x-auto";
    content.appendChild(container);

    const readonly = true;
    const { formGrid } = await getForm(inwardjobfreeissuematerialreturn, ancillaryParams, readonly, accessClient, transport);

    container.appendChild(formGrid);

    // Setup PDF downloads
    let pdfDownloadButtons = container.getElementsByClassName(downloadPDFButtonClass);
    for (let i = 0; i < pdfDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        });
    }

    // Setup Label downloads
    let labelDownloadButtons = container.getElementsByClassName(downloadLabelButtonClass);
    for (let i = 0; i < labelDownloadButtons.length; i++) {
        let btn = <HTMLButtonElement>labelDownloadButtons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let file = await accessClient.downloadLabelByUUID({ uuid: btn.getAttribute("data-uuid") || "" });

            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            downloadData(file.content, "pdf", file.name.replace(".label", ""));
        });
    }

    // Setup CSV downloads
    // let csvDownloadButtons = container.getElementsByClassName(downloadCSVButtonClass);
    // for (let i = 0; i < csvDownloadButtons.length; i++) {
    //     let btn = <HTMLButtonElement>csvDownloadButtons[i];
    //     btn.addEventListener("click", async evt => {
    //         evt.preventDefault();

    //         const originalButtonHTML = btn.innerHTML;
    //         btn.disabled = true;
    //         btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
    //         let file = await accessClient.downloadAsCSV({ uuid: btn.getAttribute("data-uuid") || "" });

    //         btn.disabled = false;
    //         btn.innerHTML = originalButtonHTML;

    //         downloadData(file.content, "csv", file.name.replace(".csv", ""));
    //     });
    // }

}

async function renderReferencesSection(inwardjobfreeissuematerialreturn: InwardJobFreeIssueMaterialReturn, ancillaryParams: InwardJobFreeIssueMaterialReturnAncillaryParameters, inwardJob: InwardJob, readonly: boolean, accessClient: PromiseClient<typeof InwardJobsFreeIssueMaterialsReturnsService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "References", titleMdColSpan: 3 });

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Initial Reference", inputType: "text", dataMapper: "referenceId", dataType: "string", value: inwardjobfreeissuematerialreturn.referenceId, mdColSpan: 6, helpText: "Initial Reference of the record." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Final Reference", inputType: "text", dataMapper: "finalRefNumber", dataType: "string", value: inwardjobfreeissuematerialreturn.finalRefNumber, mdColSpan: 6, helpText: "Final Reference of the record that is automatically generated." }));
    // Display status as well
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Status", inputType: "text", dataMapper: "status", dataType: "string", value: toTitleCase(decodeSLC(inwardjobfreeissuematerialreturn.status)), mdColSpan: 3, helpText: "Status of the record." }));

    contentGrid.appendChild(emptyDiv());
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Inward Job Reference", inputType: "text", dataMapper: "refId", dataType: "string", value: inwardJob.approvalMetadata?.approvedOn! > 0 ? inwardJob.finalRefNumber : inwardJob.referenceId, mdColSpan: 6, helpText: "Reference of the Inward Job." }));

    return grid;
}

async function renderBuyerAndConsigneeSection(inwardjobfreeissuematerialreturn: InwardJobFreeIssueMaterialReturn, ancillaryParams: InwardJobAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof InwardJobsFreeIssueMaterialsReturnsService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Buyer Information", titleMdColSpan: 3 });

    // Get buyer and consignee
    const clientsAccessClient = getClientForClientsService(transport);
    const [buyer, consignee] = await Promise.all([
        clientsAccessClient.viewByUUID({ uuid: ancillaryParams.buyerClientUuid }),
        clientsAccessClient.viewByUUID({ uuid: ancillaryParams.consigneeClientUuid }),
    ]);

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Buyer", inputType: "text", dataMapper: "buyerClientId", dataType: "string", value: `${buyer.code.length > 0 ? `(${buyer.code})` : ''} ${buyer.name}`, mdColSpan: 6, helpText: "The information of the buyer." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Consignee", inputType: "text", dataMapper: "consigneeClientId", dataType: "string", value: `${consignee.code.length > 0 ? `(${consignee.code})` : ''} ${consignee.name}`, mdColSpan: 6, helpText: "The information of the consignee." }));

    return grid;
}

async function renderDatesSection(inwardjobfreeissuematerialreturn: InwardJobFreeIssueMaterialReturn, ancillaryParams: InwardJobFreeIssueMaterialReturnAncillaryParameters, inwardJob: InwardJob, readonly: boolean, accessClient: PromiseClient<typeof InwardJobsFreeIssueMaterialsReturnsService>): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Important Dates", titleMdColSpan: 3 });

    // Dates (creation and approval)
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Inward Job Free Issue Material Return Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(inwardjobfreeissuematerialreturn.metadata?.createdAt!), mdColSpan: 4, helpText: "The creation date of this Inward Job Free Issue Material Return." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Inward Job Free Issue Material Return Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(inwardjobfreeissuematerialreturn.approvalMetadata?.approvedOn!), mdColSpan: 4, helpText: "The approval date of this Inward Job Free Issue Material Return." }));

    contentGrid.appendChild(emptyDiv());

    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Inward Job Created On", inputType: "text", dataMapper: "createdAt", dataType: "string", value: convertBigIntTimestampToDate(inwardJob.metadata?.createdAt!), mdColSpan: 4, helpText: "The creation date of the associated Inward Job." }));
    contentGrid.appendChild(renderInput({ id: randomId(), readonly: readonly, label: "Inward Job Approved On", inputType: "text", dataMapper: "approvedOn", dataType: "string", value: convertBigIntTimestampToDate(inwardJob.approvalMetadata?.approvedOn!), mdColSpan: 4, helpText: "The approval date of the associated Inward Job." }));

    return grid;
}

async function renderContactsSection(inwardjob: InwardJob, readonly: boolean, accessClient: PromiseClient<typeof InwardJobsService>, transport: Transport): Promise<HTMLDivElement> {
    let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: "Contacts", titleMdColSpan: 3 });

    const associatesClient = getClientForAssociatesService(transport);

    let contacts = (await accessClient.viewInwardJobContacts({ uuid: inwardjob.metadata?.uuid })).list;
    let inwardJobAssociates = await Promise.all(contacts.map(c => c.associateUuid).map(uuid => {
        return associatesClient.viewByUUID({ uuid });
    }));

    inwardJobAssociates.forEach(contact => {
        if (contact) {
            contentGrid.appendChild(returnAssociateBadge(contact));
        }
    });

    return grid;
}

async function renderDynamicFormsSection(inwardjobfreeissuematerialreturn: InwardJobFreeIssueMaterialReturn, readonly: boolean, accessClient: PromiseClient<typeof InwardJobsFreeIssueMaterialsReturnsService>): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    if (inwardjobfreeissuematerialreturn.formData.length == 0) {
        return container;
    }

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Other Details` }));
    container.appendChild(containerTitle);

    let formFields = await returnFormFromFields(inwardjobfreeissuematerialreturn.formData, "inward-jobs-free-issue-materials-returns", true, readonly);

    container.appendChild(formFields);

    return container;
}

async function renderFamiliesSection(inwardjobfreeissuematerialreturn: InwardJobFreeIssueMaterialReturn, readonly: boolean, accessClient: PromiseClient<typeof InwardJobsFreeIssueMaterialsReturnsService>, transport: Transport): Promise<HTMLDivElement> {
    let container = document.createElement("div");

    let containerTitle = document.createElement("div");
    containerTitle.className = "rounded-t mb-0 px-0 border-0";
    containerTitle.appendChild(renderPageTitleSection({ title: `Line Items` }));

    container.appendChild(containerTitle);

    let familiesMap: Map<bigint, Family> = new Map();
    (await familiesListFromIDs(Array.from(new Set(inwardjobfreeissuematerialreturn.list.map(p => p.familyId))))).forEach(family => {
        familiesMap.set(family.metadata?.id!, family);
    });
    
    const uomClient = getClientForUnitsOfMaterialsService(transport);
    let unitsList = inwardjobfreeissuematerialreturn.list.map(p => p.clientUomId);
    familiesMap.forEach(family => {
        unitsList.push(family.uomId);
    });

    let uomsList = await Promise.all(Array.from(new Set(unitsList)).map(id => {
        return uomClient.viewByID({ id });
    }));
    let uomsMap: Map<bigint, UnitOfMaterial> = new Map();

    uomsList.forEach(uom => {
        uomsMap.set(uom.metadata!.id, uom);
    });

    inwardjobfreeissuematerialreturn.list.forEach((item, index) => {
        let { grid, contentGrid } = renderFilterPrimarySubSection({ subsectionTitle: `${index + 1}.`, titleMdColSpan: 1 });

        let fam = familiesMap.get(item.familyId) || new Family();

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Code", value: `${fam.code}`, mdColSpan: 3, helpText: `The code of the material` }));
        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Material Name", value: `${fam.name}`, mdColSpan: 9, helpText: `The name of the material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Client Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.clientQuantity)))} (${uomsMap.get(item.clientUomId)?.symbol}) ${uomsMap.get(item.clientUomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Client's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "Buyer Quantity & UoM", value: `${internationalizeMoney(parseFloat(convertCentsToMoney(item.internalQuantity)))} (${uomsMap.get(fam.uomId)?.symbol}) ${uomsMap.get(fam.uomId)?.name}`, mdColSpan: 3, helpText: `The quantity of the material in Buyer's unit of material` }));

        contentGrid.appendChild(renderSpan({ id: randomId(), label: "HSN/SAC Code", value: `${fam.hsnSacCode}`, mdColSpan: 3, helpText: `The HSN/SAC code of the material` }));

        container.appendChild(grid);

        let hr = document.createElement("hr");
        hr.classList.add("m-5");
        container.appendChild(hr);
    });

    return container;
}

async function getForm(inwardjobfreeissuematerialreturn: InwardJobFreeIssueMaterialReturn, ancillaryParams: InwardJobFreeIssueMaterialReturnAncillaryParameters, readonly: boolean, accessClient: PromiseClient<typeof InwardJobsFreeIssueMaterialsReturnsService>, transport: Transport) {
    let formGrid = document.createElement("div");
    formGrid.className = "grid grid-cols-1 gap-6 mb-6";
    const formId = randomId();

    const inwardJobsClient = getClientForInwardJobsService(transport);
    const [inwardJob, inwardJobAncillaryParams] = await Promise.all([
        inwardJobsClient.viewByUUID({ uuid: ancillaryParams.refUuid }),
        inwardJobsClient.viewAncillaryParametersByUUID({ uuid: ancillaryParams.refUuid }),
    ]);

    const [
        referencesSection,
        buyerAndConsigneeSection,
        datesSection,
        contactsSection,
        dynamicFormsSection,
        familiesSection,
    ] = await Promise.all([
        renderReferencesSection(inwardjobfreeissuematerialreturn, ancillaryParams, inwardJob, readonly, accessClient),
        renderBuyerAndConsigneeSection(inwardjobfreeissuematerialreturn, inwardJobAncillaryParams, readonly, accessClient, transport),
        renderDatesSection(inwardjobfreeissuematerialreturn, ancillaryParams, inwardJob, readonly, accessClient),
        renderContactsSection(inwardJob, readonly, inwardJobsClient, transport),
        renderDynamicFormsSection(inwardjobfreeissuematerialreturn, readonly, accessClient),
        renderFamiliesSection(inwardjobfreeissuematerialreturn, readonly, accessClient, transport)
    ]);

    let buttonContainer = document.createElement("div");
    buttonContainer.classList.add("col-span-12", "flex", "justify-center", "overflow-x-auto");

    // Download PDF button
    let downloadPDFButton = document.createElement("button");
    downloadPDFButton.id = randomId();
    downloadPDFButton.className = `btn btn-success btn-outline btn-sm ${downloadPDFButtonClass} mr-4`;
    downloadPDFButton.innerText = "Download PDF";
    downloadPDFButton.setAttribute("data-uuid", inwardjobfreeissuematerialreturn.metadata?.uuid!);
    downloadPDFButton.setAttribute("data-name", inwardjobfreeissuematerialreturn.approvalMetadata?.approvedOn! > 0 ? inwardjobfreeissuematerialreturn.finalRefNumber : inwardjobfreeissuematerialreturn.referenceId);
    buttonContainer.appendChild(downloadPDFButton);

    // Download Label button
    let downloadLabelButton = document.createElement("button");
    downloadLabelButton.id = randomId();
    downloadLabelButton.className = `btn btn-success btn-outline btn-sm ${downloadLabelButtonClass} mr-4`;
    downloadLabelButton.innerText = "Download Labels";
    downloadLabelButton.setAttribute("data-uuid", inwardjobfreeissuematerialreturn.metadata?.uuid!);
    downloadLabelButton.setAttribute("data-name", inwardjobfreeissuematerialreturn.approvalMetadata?.approvedOn! > 0 ? inwardjobfreeissuematerialreturn.finalRefNumber : inwardjobfreeissuematerialreturn.referenceId);
    buttonContainer.appendChild(downloadLabelButton);

    // Download CSV button
    // let downloadCSVButton = document.createElement("button");
    // downloadCSVButton.id = randomId();
    // downloadCSVButton.className = `btn btn-success btn-outline btn-sm ${downloadCSVButtonClass} mr-4`;
    // downloadCSVButton.innerText = "Download CSV";
    // downloadCSVButton.setAttribute("data-uuid", inwardjobfreeissuematerialreturn.metadata?.uuid!);
    // downloadCSVButton.setAttribute("data-name", inwardjobfreeissuematerialreturn.approvalMetadata?.approvedOn! > 0 ? inwardjobfreeissuematerialreturn.finalRefNumber : inwardjobfreeissuematerialreturn.referenceId);
    // buttonContainer.appendChild(downloadCSVButton);

    let goToInwardJobButton = document.createElement("a");
    goToInwardJobButton.className = `btn btn-info btn-outline btn-sm ${goToInwardJobButtonClass} mr-4`;
    goToInwardJobButton.innerText = "Go To Inward Job";
    goToInwardJobButton.href = getLinkForInwardJob(inwardJob.metadata?.uuid!)
    buttonContainer.appendChild(goToInwardJobButton);

    formGrid.innerHTML = `
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f5f8f3] w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                ${renderPageTitleSection({ title: `Inward Job Free Issue Material Return: ` + document.title }).outerHTML}
                ${buttonContainer.outerHTML}
            </div>
            <form id="${formId}">
                <hr class="m-5">
                ${referencesSection.outerHTML}
                <hr class="m-5">
                ${buyerAndConsigneeSection.outerHTML}
                <hr class="m-5">
                ${datesSection.outerHTML}
                <hr class="m-5">
                ${contactsSection.outerHTML}
                <hr class="m-5">
            </form>
        </div>
        ${dynamicFormsSection.childElementCount > 0 ? 
            `<div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f5f8f3] w-full shadow-lg rounded">
                ${dynamicFormsSection.outerHTML}
            </div>` 
        : ''}
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f5f8f3] w-full shadow-lg rounded">
            ${familiesSection.outerHTML}
        </div>
    `;

    return { formGrid }
}
